import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import styles from './buttonLock.module.scss';

interface Props {
    submitAction: () => void;
    text: string;
    icon?: JSX.Element;
}

function ButtonLock(props: Props) {

    //props
    const submitAction = props.submitAction;
    const text = props.text;
    const icon = props.icon;

    //state
    type TLabel = 'locked' | 'unlocked';

    const [lockState, setLockState] = useState<TLabel>('locked');

    //handlers


    return (
        <>
            <div className={`${styles.btnLock} d-flex`}>
                <Button variant="primary" className='mr-2' disabled={(lockState === 'locked') ? true : false} onClick={() => submitAction()}>
                    <span className='pr-2'>{icon}</span>{text}
                </Button>
                <p className='d-flex align-items-center mb-1'>
                    {
                        (lockState === 'locked') ?
                            <i className="fas fa-lock-alt text-muted cursor" onClick={() => setLockState('unlocked')}></i> :
                            <i className="fas fa-unlock-alt cursor" onClick={() => setLockState('locked')}></i>
                    }

                </p>
            </div>
        </>
    )
}

export default ButtonLock;