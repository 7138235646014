import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import styles from './listItemCheck.module.scss';

interface Props {
    onChange?: (value: React.FormEvent<HTMLInputElement>) => void;
    label?: string | JSX.Element;
    className?: string;
    isChecked?: boolean;
    as?: React.ElementType<any>;
    value: any;
}
export default function ListItemCheck({ onChange, label, className, as, isChecked, value }: Props) {

    const [checked, setChecked] = React.useState(isChecked);

    return (
        <>
            <ListGroup.Item as={(as) ? as : 'li'} className={`${styles.compContainer} ${className}`}>
                <Form.Check
                    type="checkbox"
                    label={label}
                    checked={checked}
                    value={(value) ? value : label}
                    onClick={() => setChecked((checked) ? false : true)}
                    onChange={(value) => (onChange) ? onChange(value) : null}
                />
            </ListGroup.Item>
        </>
    )
}