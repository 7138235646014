/*
  Footer component
*/
import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-treble";
import { useAppStore } from '../../../_Store';
import styles from './footer.module.scss';

//import images
import optimizLogoWhite from "../../../_assets/images/logo-optimiz-white.svg";
import protransLogoWhite from "../../../_assets/images/logo-protrans-white.svg";

export default function Footer() {
  const [{ ptrui_appVersion: appVersion, ptrui_appLogo: appLogo, ptrui_isAuth, ptrui_isFrontEndLogin }] = useAppStore();
  const currentYear = new Date().getFullYear();

  const shouldFooterRender = (ptrui_isFrontEndLogin) ? (ptrui_isAuth) ? true : false : true;

  return (
    <>
      <Container fluid className={styles.compContainer}>
        <Row className={styles.body}>
          <Col className="d-flex justify-content-center align-items-center">
            <Link to={"/"}>
              <img
                src={
                  appLogo === "optimiz"
                    ? optimizLogoWhite
                    : appLogo === "protrans"
                      ? protransLogoWhite
                      : ""
                }
                className="logo p-4"
                alt={
                  appLogo === "optimiz"
                    ? "Optimiz"
                    : appLogo === "protrans"
                      ? "Protrans"
                      : ""
                }
              />
            </Link>
          </Col>
        </Row>
        <Row className={styles.copyright}>
          <Col className="d-flex justify-content-between align-items-center">
            <p className="p-2 invisible">Version {appVersion}</p>
            <p className="p-2">
              &copy; Copyright {currentYear} ProTrans International In. All rights
            reserved.
          </p>
            <p className="p-2">Version {appVersion}</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}
