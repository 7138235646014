import { useContentStore } from './Store';

const useContent = () => {
    const [{ ptrui_content_openMobileWidgetArea: isWidgetAreaOpen, ptrui_content_isWidgetAreaMobile: isWidgetAreaMobile }, ContentStore, ContentUtil] = useContentStore();

    return {
        closeWidgetArea: () => ContentStore.update(ContentUtil.actions.ptrui_content_setOpenMobileWidgetArea, false),
        openWidgetArea: () => ContentStore.update(ContentUtil.actions.ptrui_content_setOpenMobileWidgetArea, true),
        toggleWidgetArea: () => ContentStore.toggle(ContentUtil.actions.ptrui_content_setOpenMobileWidgetArea, isWidgetAreaOpen),
        setIsWidgetAreaMobile: (isMobile: boolean) => ContentStore.update(ContentUtil.actions.ptrui_content_setIsWidgetAreaMobile, isMobile),
        isWidgetAreaMobile,
        isWidgetAreaOpen
    }
}

export default useContent;