import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNonInitialEffect } from '../../../hooks';
import uniqid from 'uniqid';
import useTimePicker from './use-time-picker';
import styles from './timePicker.module.scss';

interface Props {
    format?: 12 | 24;
    interval?: 15 | 30;
    onChange?: (option: { text: string; value: string; }) => void;
    defaultValue?: { value: string, text: string };
}

export default function TimePicker({ format, interval, onChange, defaultValue }: Props) {

    const { convertTimeBackTo24, outputTimeArray, extractTimePeriod } = useTimePicker();

    const [activeOption, setActiveOption] = React.useState<{ value: string, text: string }>((defaultValue) ? defaultValue : { text: '', value: '' });

    const [timePeriod, setTimePeriod] = React.useState<'PM' | 'AM'>((defaultValue) ? extractTimePeriod(defaultValue) : 'AM');

    const handleOption = (activeOption: { text: string, value: string }, timePeriod: 'PM' | 'AM') => {
        if (format !== 24 && activeOption.value.length > 0 && onChange !== undefined) {
            const timeArray = activeOption.text.split(' ');
            const purgedAMPMTime = timeArray[0];
            const option = {
                value: convertTimeBackTo24(activeOption.text.split(' ')[0], timePeriod),
                text: `${purgedAMPMTime} ${timePeriod}`
            }
            setActiveOption(option);
        }
    }

    useNonInitialEffect(() => {
        if (onChange) {
            onChange(activeOption);
        }
    }, [activeOption]);

    useNonInitialEffect(() => {
        handleOption(activeOption, timePeriod);
    }, [timePeriod]);

    return (
        <>
            <div className={`d-flex ${styles.compContainer} p-0 m-0`}>
                <div className={styles.timeOptions} style={{ width: (format !== 24) ? 'calc(100% - 100px)' : '100%' }}>
                    {
                        outputTimeArray(format, interval).map((time) => {
                            const value = (format === 24) ? time : convertTimeBackTo24(time, timePeriod);
                            const text = (format === 24) ? time : `${time} ${timePeriod}`;
                            const option = {
                                value: value,
                                text: text
                            };

                            return (
                                <React.Fragment key={uniqid()}>
                                    <Dropdown.ItemText
                                        className={`${styles.dropdownItem} ${(text === activeOption.text) ? styles.active : ''} cursor`}
                                        onClick={() => setActiveOption(option)}>
                                        {time}
                                    </Dropdown.ItemText>
                                </React.Fragment>
                            )

                        })
                    }
                </div>
                {
                    (format !== 24) ?
                        <div className={'d-flex align-items-center'} style={{ width: '100px' }}>
                            <div style={{ width: '100%' }}>
                                <Dropdown.ItemText
                                    onClick={() => { setTimePeriod('PM') }}
                                    className={`${styles.dropdownItem} ${(timePeriod === 'PM') ? styles.active : ''} text-center cursor py-3`}
                                    style={{ border: 'none' }}
                                >
                                    PM
                                    </Dropdown.ItemText>
                                <Dropdown.ItemText
                                    onClick={() => { setTimePeriod('AM') }}
                                    className={`${styles.dropdownItem} ${(timePeriod === 'AM') ? styles.active : ''} text-center cursor py-3`}
                                    style={{ border: 'none' }}
                                >
                                    AM
                                    </Dropdown.ItemText>
                            </div>
                        </div> : null
                }
            </div>
        </>
    )
}