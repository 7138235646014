import React, {useContext} from 'react';

//context
import SceneContainerContext from '../scene-container-context';

interface Props{
    half?: boolean,
    left?: boolean,
    right?: boolean,
    full?: boolean,
    children: JSX.Element[] | JSX.Element
}

function ContentRow(props: Props){

    //props variables
    const half = props.half;
    const left = props.left;
    const right = props.right;
    const full = props.full;

    //context
    const sceneContext:{collapsed?:boolean} = useContext(SceneContainerContext);

    //handler functions
    const handleSidePadding = () => {
        return(
              (right) ? 'pl-lg-2'
            : (left) ? 'pr-lg-2'
            : null
        )
    }

    return(
        <div className={`
            col-12
            ${
                (sceneContext !== undefined) ?
                    (half) ? `${(sceneContext.collapsed === false) ? 'col-lg-5' : 'col-lg-6'} ${handleSidePadding()}` :
                    (full) ? 'pb-4' :
                    (sceneContext.collapsed === false) ? 'col-lg-10' : 
                    (sceneContext.collapsed === true) ? 'col-lg-12 mr-4' :
                    'col-lg-10' : 
                    (half) ? ` col-lg-5 ${handleSidePadding()}` :
                    (full) ? 'pb-4' : 'col-lg-10'
            }
        `}>
            <div className={`row ${(full) ? 'mx-lg-0 px-lg-3' : ''}`} style={{height: 100 + '%'}}>
                {props.children}
            </div>
        </div>
    )
}

export default ContentRow;


// ${
//     (sceneContext !== undefined) ?
//         (half) ? `${(sceneContext.collapsed === false) ? 'col-lg-6' : 'col-lg-5'} ${handleSidePadding()}` :
//         (full) ? 'pb-4' :
//         (sceneContext.collapsed === false) ? 'col-lg-10' : 
//         (sceneContext.collapsed === true) ? 'col-lg-12' :
//         'col-lg-10' :
//         (half) ? ` 'col-lg-5' ${handleSidePadding()}` :
//         (full) ? 'pb-4' : 
//     'col-lg-10'
// }

