

import React, { useEffect } from 'react';
import uniqid from 'uniqid';
import { useDataGridContext } from '../data-grid-context';
import StatusIcons from '../status-icons';
import DayTable from './day-table';
import handleRowData from './handle-row-data';

interface Props {
    dataSet: {
        statusInfo: {
            status: string,
            issues: string[],
            exceptions: string[]
        }
    }[] | undefined
}

function RowData(props: Props) {

    //context
    type AppContextType = [
        {
            rowData: {
                render: boolean,
                data: (string | object)[],
                flags: {
                    status: string,
                    issues: string[],
                    exceptions: string[]
                }
            }[],
            flags: boolean,
            columns: {
                dataField: string
            }[]
        },
        ({ }) => object
    ];
    const [{ rowData, flags, columns }, dispatch]: AppContextType = useDataGridContext();

    //props
    const dataSet = props.dataSet;


    useEffect(() => {
        handleRowData(dataSet, flags, columns, dispatch);
    }, [dataSet]);



    //day table type
    type DayTableType = {
        dayTable: boolean,
        weekNum: string,
        weekDate: string,
        days: number[]
    }

    return (
        <tbody className='data-grid-row-data'>
            {
                (rowData.length > 0) ?

                    rowData.map((row) => {
                        return (
                            (row.render === true) ?
                                <tr key={uniqid()}>

                                    {
                                        (flags) ?
                                            <td valign='middle' className={`
                                text-center
                                ${
                                                (row.flags.status === 'late')
                                                    ? 'data-grid-row-status-red' :
                                                    (row.flags.status === 'on-time')
                                                        ? 'data-grid-row-status-green' :
                                                        (row.flags.status === 'update')
                                                            ? 'data-grid-row-status-yellow' :
                                                            (row.flags.status === 'all')
                                                                ? 'data-grid-row-status-grey' :
                                                                null
                                                }`


                                            } key={uniqid()}>
                                                <StatusIcons
                                                    issue={row.flags.issues}
                                                    exception={row.flags.exceptions}
                                                />
                                            </td>
                                            : null
                                    }
                                    {
                                        row.data.map((tableData) => {
                                            
                                            
                                            return (
                                                <React.Fragment key={uniqid()}>
                                                    {
                                                        ((tableData as DayTableType)?.dayTable !== undefined && (tableData as DayTableType).dayTable === true) ?
                                                            <DayTable
                                                                weekNum={(tableData as DayTableType).weekNum}
                                                                weekDate={(tableData as DayTableType).weekDate}
                                                                days={(tableData as DayTableType).days}
                                                            /> : <td>{tableData}</td>
                                                    }
                                                </React.Fragment>

                                            )

                                        })

                                    }
                                </tr>
                                : null
                        )

                    }) : null
            }
        </tbody>
    );
}


export default RowData;



