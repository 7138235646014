

    //builds row data objects and puts in state
    type ValueType = {
        // statusInfo: {
        //     status: string,
        //     issues: string[] | null,
        //     exceptions: string[] | null
        // }
    }
    type ColumnType = {
        dataField: string
    }[]


const handleRowData = (value: any | undefined, flags: boolean, columns: ColumnType, dispatch: any) => {
    let rowDataArray: object[] = [];

    value?.forEach((item: any) => {
        let dataArray = () => {
           delete item.statusInfo;
            let mappedItem: any = {}
            columns.map((col) => {
                mappedItem[col.dataField] = item[col.dataField];               
            });
            return (
                Object.values(mappedItem)
            )
        };
        let rowDataObject = {
            render: true,
            flags: item.statusInfo,
            data: dataArray()
        };
        rowDataArray.push(rowDataObject);
    })

    //sort by flags
    function sortByFlags(a: any, b: any) {
        let flag = (value: any) => {
            let item = (value.flags !== undefined) ? value.flags.status.toLowerCase() : '';
            let returnedValue = () => {
                switch (item) {
                    case 'late':
                        return 2;
                    case 'update':
                        return 1;
                    case 'on-time':
                        return 3;
                    case 'all':
                        return 4;
                    default:
                        return 0
                }
            }
            return returnedValue();
        }
        let comparison = 0;
        if (flag(a) > flag(b)) {
            comparison = 1;
        } else if (flag(a) < flag(b)) {
            comparison = -1;
        }
        return comparison;
    }

    let rowDataArraySorted = (flags === true) ? [...rowDataArray].sort(sortByFlags) : rowDataArray;

    //update tableContext

    dispatch({
        type: 'setRowData',
        setRowData: rowDataArraySorted
    })
}

export default handleRowData;