import React from 'react';
import { default as PageNavComp } from './page-nav';
import Item from './item';
import SubMenuItem from './sub-menu-item';
import Divider from './divider';
import { IPageNavComp, IPageNav } from './interfaces';

const PageNav: IPageNavComp<IPageNav> = (props) => <PageNavComp {...props} />;
PageNav.Item = Item;
PageNav.SubMenu = SubMenuItem;
PageNav.Divider = Divider;
export default PageNav;