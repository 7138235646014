import React from 'react';
import { Switch, Route } from 'react-router-treble';
import { lazyImport } from '../utils';
import { useConfig } from '../hooks';
const NotFound = lazyImport(() => import('../_components/templates/not-found'));
const LoginPage = lazyImport(() => import('../_components/templates/login'));

interface Props {
    routes: any;
}

export default function Routes({ routes }: Props) {

    const config = useConfig();

    const LoginRoute = () => (
        <Route path={'/login'}>
            <LoginPage />
        </Route>
    )

    const NotFoundRoute = () => (
        <Route>
            <NotFound />
        </Route>
    )

    const allRoutes = (config.isFrontEndLogin) ? [LoginRoute(), ...routes().props.children, NotFoundRoute()] : [...routes().props.children, NotFoundRoute()];

    return (
        <>
            <Switch>

                {/** App Pages */}
                {
                    React.Children.map(allRoutes, (Child: any) => (Child))
                }

            </Switch>
        </>
    )
}