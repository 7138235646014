import React, { useState } from "react";
import { useNonInitialEffect } from '../../../hooks';
import { Dropdown } from "react-bootstrap";
import { DateUtils } from "react-day-picker";
import { IDatePicker } from './interfaces';
import CustomInput from './custom-input';
import formatDate from './format-date';
import { DayPicker, InputField } from '../../atoms';
import InputDropdown from '../input-dropdown';
import styles from './dateSelector.module.scss';

export default function DateSelector({
  onDateChange,
  defaultDate,
  defaultRange,
  width,
  minWidth,
  disabled,
  readOnly,
  hideIcon,
  range,
  className,
  size
}: IDatePicker) {


  //Date State
  const [show, setShow] = useState(false);
  const [dateRange, setDateRange] = useState(defaultRange || { from: undefined, to: undefined });
  const [selectedDay, setSelectedDay] = useState(defaultDate || undefined);
  const [originalInitDate, setOriginalInitDate] = useState(!range ? defaultDate : defaultRange ? defaultRange.from : new Date());

  //defaultDate can be dynamic
  useNonInitialEffect(() => {
    if (range === undefined) {
      if (defaultDate !== undefined) {
        setSelectedDay(defaultDate);
      }
    }
  }, [defaultDate]);

  //defaultDateRange can be dynamic
  useNonInitialEffect(() => {
    if (range) {
      if (defaultRange !== undefined) {
        setDateRange(defaultRange);
      }
    }
  }, [defaultRange]);

  //formats dateRange into String
  const [formattedDateRange, setFormattedDateRange] = useState({
    from: formatDate(dateRange?.from),
    to: formatDate(dateRange?.to)
  });

  //formats selectedDay into String
  const [formattedSelectedDay, setFormattedSelectedDay] = useState(formatDate(selectedDay));

  //this allows for ranges to be set
  const modifiers = { start: dateRange.from, end: dateRange.to };

  const [placeHolder, setPlaceHolder] = React.useState('Choose a Date');

  const handleDayClick = (day: any) => {
    if (range) {
      let selectedDateRange = DateUtils.addDayToRange(day, dateRange as any);
      setDateRange(selectedDateRange)
    } else {
      setSelectedDay(day);
    }
  };

  const handleReset = () => {
    if (range) {
      setDateRange({ from: originalInitDate, to: undefined });
    } else {
      setSelectedDay(originalInitDate);
    }
    return originalInitDate;
  };

  const handleToday = () => {
    if (range) {
      setDateRange({ from: new Date(), to: undefined });
    } else {
      setSelectedDay(new Date());
    }
    return new Date();
  };

  React.useEffect(() => {
    if (!range) {
      setFormattedSelectedDay(formatDate(selectedDay));
    }
  }, [selectedDay]);

  React.useEffect(() => {
    if (range) {
      setFormattedDateRange((oldValue : any) =>{
        if(oldValue.from !== formatDate(dateRange?.from) && oldValue.to !== formatDate(dateRange?.to))
          return {
                from: formatDate(dateRange?.from),
                to: formatDate(dateRange?.to)
               };
          else
               return oldValue;
      })
    }
  }, [dateRange]);

  useNonInitialEffect(() => {

    if (range) {
      onDateChange(formattedDateRange);

    }
  }, [formattedDateRange]);

  React.useEffect(() => {

    if (!range) {
      onDateChange(formattedSelectedDay);

    }
  }, [formattedSelectedDay]);

  const handleDateRangePlaceHolder = () => {
    if ('from' in formattedDateRange && 'to' in formattedDateRange) {
        if (!(formattedDateRange.from === undefined && formattedDateRange.to === undefined)) {
            return `${(formattedDateRange.from !== undefined) ? formattedDateRange.from : ''} - ${(formattedDateRange.to !== undefined) ? formattedDateRange.to : ''}`
        }
    }
    return "Choose Date Range"
  }

  const handlePlaceholder = () => {
      if (range) {
          setPlaceHolder(handleDateRangePlaceHolder());
      }
      else {
          setPlaceHolder(formattedSelectedDay || 'Choose Date');
      }
  }

  React.useEffect(() => {
      handlePlaceholder();
  }, [formattedSelectedDay, formattedDateRange])

  return (
    <>
      <Dropdown style={{ width: (width) ? width : 'auto', minWidth: (minWidth) ? minWidth : 'none' }} className={className}
        onToggle={(isOpen, event, metadata) => {
          setShow(isOpen);
        }}
        show = {show}
      >
        <Dropdown.Toggle 
        as = {"div"}
        >        
        <InputField
          as={'select'}
          icon={(!hideIcon) ? <i className="fas fa-calendar-alt"></i> : undefined}
          readOnly={readOnly}
          disabled={disabled}
          size={size}
          width={width}
          caretDown = {false}
        >
            <option hidden>{placeHolder}</option>   
        </InputField>
        </Dropdown.Toggle>      
        <InputDropdown className={hideIcon ? styles.compContainer : styles.compContainer2} childrenClassName={styles.childrenContainer} withInputIcon={(hideIcon) ? false : true}>
          <DayPicker
            range={range}
            dateRange={dateRange}
            selectedDay={selectedDay}
            modifiers={modifiers as any}
            onDayClick={(e: any) => {  
              handleDayClick(e);
              if(!range)
                setShow(false); 
            }}
            handleReset={handleReset}
            handleToday={handleToday}
          />
        </InputDropdown>
      </Dropdown>
    </>
  );
}