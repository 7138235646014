import React from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';

export default function InputError() {

    return (
        <>
            <Dropdown.ItemText className='d-flex align-items-center'>
                <span className='text-danger'><i className="fas fa-exclamation-triangle pr-2"></i>Fetch Error</span>
            </Dropdown.ItemText>
        </>
    )
}