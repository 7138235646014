import React from 'react';
import { Link } from '../../../utils';
import { useAppStore } from '../../../_Store';
import optimizLogoColored from "../../../_assets/images/logo-optimiz-colored.svg";
import protransLogoColored from "../../../_assets/images/logo-protrans-colored.svg";
import styles from './appLogo.module.scss';

interface Props {
    className?: string;
}

export default function AppLogo({ className }: Props) {

    const [{ ptrui_appLogo: appLogo }] = useAppStore();

    return (
        <>
            {
                appLogo === "protrans" ? (
                    <Link to="/">
                        <img src={protransLogoColored} className={`${styles.compContainer} ${className}`} alt="Protrans" />
                    </Link>
                ) : appLogo === "optimiz" ? (
                    <Link to="/">
                        <img src={optimizLogoColored} className={`${styles.compContainer} ${className}`} alt="Optimiz" />
                    </Link>
                ) : null
            }
        </>
    )
}