import React from 'react';
import CaretToggle from '../caret-toggle';
import styles from './tableCellContent.module.scss';

interface Props {
    children?: JSX.Element | JSX.Element[];
    isExpandable?: boolean;
    isExpanded?: boolean;
    index?: number;
    onClick?: () => void;
}
export default function TableCellContent({ children, isExpandable, isExpanded, index, onClick }: Props) {

    return (
        <>
            <div className={`${(isExpandable) ? 'd-flex' : ''}`}>
                {
                    (isExpandable && index === 0) ?
                        <div className={`d-flex justify-content-start ${styles.caretContainer}`}>
                            <CaretToggle
                                open={(isExpanded) ? true : false}
                                onClick={(onClick) ? onClick : () => null}
                                isDisabled={(!isExpandable) ? true : false}
                            />
                        </div> : null
                }
                <div>
                    {children}
                </div>
            </div>
        </>
    )
}