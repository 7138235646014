import { createStore } from "treble-gsm";

export interface ITableDataStore {
  cachedTableData: any[];
  tableData: any[];
  queriedTableData: [];
  triggerTableDataFetch: boolean | any;
  fetchStatusCode: null | any;
  totalDataCount: boolean | number;
  shouldCachedDataRefresh: boolean;
}

const actionKeys = {
  ['updateCachedTableData']: 'updateCachedTableData',
  ['updateTableData']: 'updateTableData',
  ['updateQueriedTableData']: 'updateQueriedTableData',
  ['triggerTableDataFetch']: 'triggerTableDataFetch',
  ['updateFetchStatusCode']: 'updateFetchStatusCode',
  ['updateTotalDataCount']: 'updateTotalDataCount',
  ['updateShouldCachedDataRefresh']: 'updateShouldCachedDataRefresh'
}

export type TTableDataActions = typeof actionKeys;

const TableDataStore: any = createStore([
  {
    action: actionKeys.updateCachedTableData,
    state: {
      cachedTableData: []
    },
    features: {
      process: ({ dispatchValue }) => {
        return dispatchValue.map((item: { ptrui_cacheKey?: number, ptrui_sortKey?: number, [key: string]: any }, index: number) => (
          {
            ...item,
            ptrui_cacheKey: index,
            ptrui_sortKey: index,
            ptrui_isSelected: (typeof item.ptrui_isSelected === 'boolean') ? item.ptrui_isSelected : false
          }
        ));
      }
    }
  },
  {
    action: actionKeys.updateTableData,
    state: {
      tableData: [],
    },
    features: {
      process: ({ dispatchValue }) => {
        return dispatchValue.map((item: any, index: number) => {
          return { ...item, ptrui_sortKey: index };
        })
      }
    }
  },
  {
    action: actionKeys.triggerTableDataFetch,
    state: {
      triggerTableDataFetch: false,
    },
  },
  {
    action: actionKeys.updateFetchStatusCode,
    state: {
      fetchStatusCode: 'No Errors Detected',
    },
  },
  {
    action: actionKeys.updateTotalDataCount,
    state: {
      totalDataCount: false
    }
  },
  {
    action: actionKeys.updateShouldCachedDataRefresh,
    state: {
      shouldCachedDataRefresh: true
    }
  }
]);

export default TableDataStore;
