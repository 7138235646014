import React from 'react';
import { useLocation } from 'react-router-dom';
import { ListItem, ListItemCollapsed, ListItemLabel } from '../../../atoms';
import { usePageState } from '../../../../hooks';
import styles from './item.module.scss';

interface Props {
    text?: string;
    icon?: JSX.Element;
    link?: string;
    onClick?: () => void;
    children?: JSX.Element | JSX.Element;
    className?: string;
    badge?: string | number;
    badgeType?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
    ignorePageState?: boolean;
}

export default function Item({ icon, onClick, text, link, className, badge, badgeType, ignorePageState, children }: Props) {

    const page = usePageState();
    const location = useLocation();
    const isActive = (link === location.pathname) ? true : false;

    return (
        <>
            {
                (page.isCollapsed && !ignorePageState && !page.isMobile) ?
                    <ListItemCollapsed
                        onClick={() => (onClick) ? onClick() : null}
                        className={`text-white ${(link) ? styles.hoverEffect : ''} ${(isActive) ? styles.active : ''} ${className}`}
                        text={text}
                        path={link}
                    >
                        {
                            (children) ? children : <ListItemLabel icon={icon} badge={badge} badgeType={badgeType} />
                        }

                    </ListItemCollapsed> :
                    <ListItem
                        onClick={() => (onClick) ? onClick() : null}
                        className={`text-white ${(link) ? styles.hoverEffect : ''} ${(isActive) ? styles.active : ''} ${className}`}
                        text={text}
                        path={link}
                    >
                        {
                            (children) ? children : <ListItemLabel icon={icon} text={text} badge={badge} badgeType={badgeType} />
                        }

                    </ListItem>
            }
        </>
    )
}