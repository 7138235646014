import React, {useState, useEffect} from 'react';
import {default as ReactDatePicker} from 'react-datepicker';
import './_date-picker.scss';
//import Props from './date-picker-interface';
import CustomInput from './custom-input';

//react-datepicker docs
//https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md
//https://reactdatepicker.com/

interface Props{
    selectedDate?: React.Dispatch<React.SetStateAction<string>>;
    value: string;
    onChange?: (date: any) => void;
    style?: object;
    ref?: any;
}

function DatePicker(props: Props){

    const selectedDate = props.selectedDate;
    const onChange = props.onChange;
    const style = props.style;
    const value = props.value;
    const ref = props.ref;

    //state
    const [startDate, setStartDate] = useState(new Date(value));

    const handleOnChange = (date: Date) => {
        setStartDate(date);

        let month = formatNumber(new Date(date).getUTCMonth() + 1);
        let day = formatNumber(new Date(date).getUTCDate());
        let year = formatNumber(new Date(date).getUTCFullYear());
        let formatedDate = `${month}/${day}/${year}`;

        if (onChange) {
            onChange(formatedDate as any);
        };
    }

    const formatNumber = (number: number) => {
        let numberToFormat = number;
        return (numberToFormat < 10) ? '0' + numberToFormat.toString() : numberToFormat.toString();
    }

    useEffect(() => {
        //let month = formatNumber(new Date(startDate).getUTCMonth() + 1);
        //let day = formatNumber(new Date(startDate).getUTCDate());
        //let year = formatNumber(new Date(startDate).getUTCFullYear());
        //let formatedDate = `${month}/${day}/${year}`;
        //selectedDate(formatedDate);

    },[startDate])


    return(
        <>
            <ReactDatePicker 
                showPopperArrow={false}
                selected={startDate}
                onChange={
                    (date: Date) => {
                        handleOnChange(date); 
                    }
                }
                placeholderText="Select Date"
                customInput={<CustomInput style={style} />}
                shouldCloseOnSelect={false}
                dateFormat="MM/dd/yyyy"
                ref={ref}
            />
        </>
    )
}

export default DatePicker;

