import React from 'react';
import { useLogin } from '../../../hooks';
import styles from './userIcon.module.scss';

export default function UserIcon() {

    const { user } = useLogin();

    return (
        <>
            <div className={styles.compContainer}>
                {(user.profilePic.length === 0) ? (
                    <i className={`${styles.iconUser} fas fa-user-circle`}></i>
                ) : (
                    <img
                        src={user.profilePic}
                        alt="icon"
                        className="rounded-circle"
                    />
                )}
            </div>
        </>
    )
}