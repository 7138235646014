import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './contentColumn.module.scss';

interface Props {
    fullwidth?: boolean;
    children: JSX.Element[] | JSX.Element;
}

export default function ContentColumn({ children }: Props) {

    return (
        <>
            <Col
                className={`${styles.compContainer} pb-4 `}>
                <Row
                    className={`d-flex no-gutters `}
                >
                    <Col>
                        <Row
                            className={`pt-3 mt-0 d-flex justify-content-center no-gutters`}
                        >
                            {children}
                        </Row>
                    </Col>
                </Row>
            </Col>
        </>
    )
}