import React from 'react';
import { Card } from 'react-bootstrap';
import styles from './panelFooter.module.scss';

interface Props {
    children?: JSX.Element | JSX.Element[];
    className?: string;
}
export default function PanelFooter({ className, children }: Props) {

    return (
        <>
            <Card.Footer className={`d-flex  justify-content-between align-items-center p-2 ${styles.compContainer} ${className}`}>
                {children}
            </Card.Footer>
        </>
    )
}