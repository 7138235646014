import React from 'react';
import { useAppStore } from '../_Store';
import { clearPersist } from 'treble-gsm';
import { useNonInitialEffect } from './use-non-initial-mount-effect';

export interface IUser {
    id: number,
    firstName: string,
    lastName: string,
    userName: string,
    email: string,
    profilePic: string
}

export interface IUseLogin {
    (): {
        clearUser: () => void,
        setUser: ({ firstName, lastName, userName, email, profilePic }: IUser) => void,
        setLoading: (loading: boolean) => void,
        isAuth: boolean,
        setIsAuth: (isAuth: boolean) => void,
        user: IUser,
        setToken: (token: string) => void,
        token: string,
        clearToken: () => void,
        goToLogin: () => void,
        loading: boolean
    }
}

const useLogin: IUseLogin = () => {
    const emptyUserData = {
        id: 0,
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        profilePic: ''
    }

    const [{ ptrui_userInfo, ptrui_userToken, ptrui_isAuth, ptrui_loginLoadingState }, Store, Util] = useAppStore();
    const defaultUserInfo = (typeof ptrui_userInfo !== 'boolean') ? ptrui_userInfo : emptyUserData
    const [user, setUserState] = React.useState<IUser>(defaultUserInfo);
    const [isAuth, setIsAuthState] = React.useState(ptrui_isAuth);
    const [token, setTokenState] = React.useState(ptrui_userToken);
    const loading = (ptrui_loginLoadingState) ? true : false;

    const clearUser = () => {
        Store.reset(Util.actions.updateUserInfo);
        clearPersist('ptrui_userInfo');
    };

    const setUser = (user: IUser) => {
        const isData = (user?.firstName && user.firstName.length > 0 && user?.firstName && user.firstName.length > 0) ? true : false;
        if (isData) {
            Store.update(Util.actions.updateUserInfo, {
                id: user?.id || 0,
                firstName: user?.firstName || '',
                lastName: user?.lastName || '',
                userName: user?.userName || '',
                email: user?.email || '',
                profilePic: user?.profilePic || ''
            });
        }
    };

    const setToken = (token: string) => {
        Store.update(Util.actions.updateUserToken, token);
    }

    const setLoading = (loading: boolean) => {
        Store.update(Util.actions.ptrui_updateloginLoadingState, loading);
    }

    const setIsAuth = (isAuth: boolean) => {
        Store.update(Util.actions.ptrui_updateIsAuth, isAuth);
    }

    const clearToken = () => {
        clearPersist('ptrui_userToken');
        Store.reset(Util.actions.updateUserToken);
    };

    //will auto clear localstorage, state and redirect page to login screen
    const goToLogin = () => {
        clearUser();
        clearToken();
        setIsAuth(false);
    }

    //set user
    useNonInitialEffect(() => {
        if (typeof ptrui_userInfo !== 'boolean') {
            setUserState(ptrui_userInfo);
        }
    }, [ptrui_userInfo]);

    //set token
    useNonInitialEffect(() => {
        if (ptrui_userToken !== token) {
            setTokenState(ptrui_userToken);
        }
    }, [ptrui_userToken]);

    //is authenticated
    useNonInitialEffect(() => {
        setIsAuthState(ptrui_isAuth);
    }, [ptrui_isAuth])

    return {
        clearUser,
        setUser,
        setLoading,
        setIsAuth,
        isAuth,
        user,
        token,
        loading,
        setToken,
        clearToken,
        goToLogin
    }
}

export default useLogin;