﻿import React, { useState } from "react";
import { Form, Button, Col } from "react-bootstrap";

interface Props {
    onUploadFile: any;
}

function FileUpload({ onUploadFile }: Props) {
    const [selectedFile, setselectedFile] = useState<object | undefined>();

    const handleSelectFile = (event: any) => {
        event.preventDefault();
        setselectedFile(event.target.files[0]);
    };

    const handleUploadFile = (event: any) => {
        event.preventDefault();
        onUploadFile(selectedFile);
    };

    return (
        <>
            <Form>
                <Form.Control
                    type="file"
                    onChange={(event: any) => {
                        handleSelectFile(event as any);
                    }}
                ></Form.Control>
                <br />
                <Form.Row>
                    <Col sm="9">&nbsp;</Col>
                    <Col sm="3">
                        <Button
                            variant="primary"
                            onClick={(event: any) => {
                                handleUploadFile(event as any);
                            }}
                        >
                            Upload
                        </Button>
                    </Col>
                </Form.Row>
            </Form>
        </>
    );
}

export default FileUpload;
