import React from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import IAppMessage from './interfaces';
import useAppMessage from './use-app-message';
import styles from './appMessage.module.scss';

interface Props {
    message: IAppMessage.Data;
}

export default function AppMessage({ message }: Props) {

    const { shouldAnimate, dismiss, startDismissTimer, type, text } = useAppMessage(message);

    React.useEffect(() => {
        startDismissTimer();
    }, []);

    return (
        <>
            <Alert variant={type.color} className={`${styles.cardContainer} mb-0 ${shouldAnimate ? styles.dismissAnimate : ""
                }`}>
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <span className={styles.messageIcon}>
                            {
                                (type.icon === 'spinner') ?
                                    < Spinner animation="border" variant="info" style={{ width: '1.5rem', height: '1.5rem' }} className={'mr-3'} /> :
                                    <i className={`fas ${type.icon} pr-3`}></i>
                            }
                        </span>
                        <span>{text}</span>
                    </div>
                    <div className={`d-flex align-items-center`}>
                        <i className={`fal fa-times cursor ${styles.dismissBtn}`} onClick={() => dismiss()}></i>
                    </div>
                </div>
            </Alert>
        </>
    );
}