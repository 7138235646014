import React from 'react';

//import custom icons
import hazMat from './svg/haz-mat.svg';
import inBond from './svg/in-bond.svg';
import missingData from './svg/missing-data.svg';
import missingDocument from './svg/missing-document.svg';
import missingPfep from './svg/missing-pfep.svg';
import missingRate from './svg/missing-rate.svg';
import tenderException from './svg/tender-exception.svg';

interface IconListInterface{
    issues: {
        type: string, 
        icon: JSX.Element
    }[],
    exceptions: {
        type: string, 
        icon: JSX.Element
    }[]
}

//icon list object
const iconList: IconListInterface = {

    /***Issues***/
    issues: [
        //In Bond
        {
            type: 'in-bond', 
            icon: <span className='px-2' title="in bond"><img src={inBond} style={{width: '1.4rem', height: 'auto'}} alt='' /></span>
        },

        //Expedite-Air
        {
            type: 'expedite-air', 
            icon: <span style={{fontSize: '1rem'}} className='px-2 text-info' title='expedite air'><i className="fas fa-plane"></i></span>
        },

        //Expedite-Ground
        {
            type: 'expedite-ground', 
            icon: <span style={{fontSize: '1rem'}} className='px-2 text-info' title='expedite ground'><i className="fas fa-truck"></i></span>
        },

        //Expedite-Hand, Carry
        {
            type: 'expedite-hand', 
            icon: <span style={{fontSize: '1rem'}} className='px-2 text-info' title='expedite hand'><i className="fas fa-hand-holding-box"></i></span>
        },

        //Continuing Volume
        {
            type: 'continuing-volume', 
            icon: <span style={{fontSize: '1rem'}} className='px-2 text-purple' title='continuing volume'><i className="fas fa-truck"></i></span>
        },

        //Critical Track
        {
            type: 'critical-track', 
            icon: <span style={{fontSize: '1rem'}} className='px-2 text-orange' title='critical track'><i className="fas fa-exclamation-triangle"></i></span>
        },

        //HazMat
        {
            type: 'haz-mat', 
            icon: <span className='px-2' title="haz mat"><img src={hazMat} style={{width: '1.4rem', height: 'auto'}} alt='' /></span>
        }
    ],

    /***Exceptions***/
    exceptions: [
        //Late Alert, Dwell Alert
        {
            type: 'late-alert', 
            icon: <span style={{fontSize: '1rem'}} className='px-2 text-danger' title="late alert"><i className="fas fa-clock"></i></span>
        },

        //Verification Required
        {
            type: 'verification-required', 
            icon: <span style={{fontSize: '1rem'}} className='px-2 text-warning' title="verification required"><i className="fas fa-clipboard-check"></i></span>
        },

        //Missing Document
        {
            type: 'missing-document', 
            icon: <span className='px-2' title="missing document"><img src={missingDocument} style={{width: '1.25rem', height: 'auto'}} alt='' title="missing document"/></span>
        },

        //Missing Data
        {
            type: 'missing-data', 
            icon: <span className='px-2' title="missing data"><img src={missingData} style={{width: '1.4rem', height: 'auto'}} alt='' title="missing data"/></span>
        },

        //Missing PFEP
        {
            type: 'missing-pfep', 
            icon: <span className='px-2' title="missing pfep"><img src={missingPfep} style={{width: '1.4rem', height: 'auto'}} alt='' title="missing pfep"/></span>
        },

        //Missing Rate
        {
            type: 'missing-rate', 
            icon: <span className='px-2' title="missing rate"><img src={missingRate} style={{width: '1.4rem', height: 'auto'}} alt='' title="missing rate"/></span>
        },

        //Tender Exception, LTL Missing Pro
        {
            type: 'tender-exception', 
            icon: <span className='px-2' title="tender exception"><img src={tenderException} style={{width: '1.4rem', height: 'auto'}} alt='' title="tender exception"/></span>
        },

        //Pickup Exception, Delivery Exception
        {
            type: 'pickup-exception', 
            icon: <span style={{fontSize: '1rem'}} className='px-2 text-warning' title="pickup exception"><i className="fas fa-shipping-timed"></i></span>
        },

        //Lcoation Update Needed
        {
            type: 'location-update-needed', 
            icon: <span style={{fontSize: '1rem'}} className='px-2 text-warning' title="location update needed"><i className="fas fa-map-marker-alt-slash"></i></span>
        }
    ]
}

export {iconList};