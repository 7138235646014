import React from 'react';

interface Props {
    className?: string;
    size?: number;
    isActive?: boolean;
    disabled?: boolean;
}
export default function FilterIndicator({ size, className, isActive }: Props) {

    const iconSize = (size) ? `${(1 * size)}rem` : '1rem';
    const [active, setActive] = React.useState(isActive);

    React.useEffect(() => {
        setActive(isActive);
    }, [isActive]);

    return (
        <>
            <div className={`${className}`} style={{ fontSize: iconSize }}>
                {
                    (active) ?
                        <i className="fas fa-filter text-info"></i> :
                        <i className="fas fa-filter text-muted"></i>
                }
            </div>
        </>
    )
}