const useGridRenderData = () => {

    const windowData = (chunkedArr: any[][], activePage: number) => {
        const active = (activePage) ? activePage : 0;
        const count = (chunkedArr.length !== 0) ? chunkedArr.length - 1 : 0;
        const previous = chunkedArr[active - 1];
        const current = chunkedArr[active];
        const next = chunkedArr[active + 1];
        if (!(active > count)) {
            if (active > 0) {
                return [...previous, ...current];;
            }
            if (activePage === 0 && chunkedArr.length > 1) {
                return [...current, ...next];
            }
        }
        return current;
    }

    const chunkArray = (arr: any[], count: number) => {
        return Array.from({ length: Math.ceil(arr.length / count) }, (v, i) =>
            arr.slice(i * count, i * count + count)
        );

    }

    return {
        windowData,
        chunkArray
    }
}

export default useGridRenderData;