import React from 'react';
import styles from './header.module.scss';
interface Props {
    children?: JSX.Element | JSX.Element[]
}

function CardGridHeader({ children }: Props) {

    return (
        <>
            <div className={styles.header}>
                {children}
            </div>
        </>
    )
}

CardGridHeader.displayName = 'CardGridHeader';
export default CardGridHeader;