import React from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import { useInput } from '../../../hooks';

interface Props {
    className?: string;
    icon?: JSX.Element;
    size?: 'sm' | 'lg';
    value?: string | number | string[];
    as?: React.ElementType<any>;
    compRef?: React.Ref<any>;
    children?: JSX.Element | JSX.Element[];
    onClick?: (e: React.MouseEvent) => void;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    readOnly?: boolean;
    disabled?: boolean;
    placeHolder?: string;
    width?: string;
    staticInputValue?: boolean;
    type?: string;
    onChange?: (value: any) => void;
    caretDown?: boolean;
}

export default function InputField({ className, icon, size, value, as, compRef, onClick, onKeyDown, children, readOnly, placeHolder, width, disabled, type, onChange, caretDown, ...props }: Props) {


    return (
        <>
            <InputGroup className={`${className}`} style={{ width: (width) ? width : 'auto' }}>
                {
                    (icon) ?
                        <InputGroup.Prepend>
                            <InputGroup.Text>{icon}</InputGroup.Text>
                        </InputGroup.Prepend> : null
                }
                <Form.Control
                    {...props}
                    placeholder={placeHolder}
                    size={size}
                    className={`${(!readOnly && !disabled) ? 'cursor' : ''} ${className}`}
                    as={as}
                    value={(as === 'select') ? undefined : value}
                    readOnly={readOnly}
                    disabled={disabled}
                    ref={compRef}
                    type={type}
                    onKeyDown={(e: React.KeyboardEvent) => {
                        if (onKeyDown) {
                            onKeyDown(e);
                        }
                    }}
                    onClick={(e: React.MouseEvent) => {
                        if (!readOnly) {
                            if (onClick) {
                                onClick(e);
                            }
                        }
                    }}
                    onChange={(value) => {
                        if (onChange) {
                            onChange(value);
                        }
                    }}
                    style={caretDown ? { borderRight: "none" } : null}
                >
                    {children}
                </Form.Control>

                {caretDown &&
                    <div style={{ width: "7px", border: "1px solid #ced4da", borderLeft: "none", borderRight: "none" }} >
                    </div>
                }
                {caretDown &&

                    <div style={{ borderTopRightRadius: ".25rem", borderBottomRightRadius: ".25rem", borderColor: "rgb(206, 212, 218)", borderStyle: "solid", borderWidth: "1px", borderLeft: "none" }}>
                        <i onClick={onClick} className="fas fa-angle-down" style={{ color: "rgb(100 102 105)", fontSize: "15px", fontWeight: 400, position: "relative", top: "6px", left: "-4px" }}></i>
                    </div>

                }
            </InputGroup>
        </>
    )
}