import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { ListItemCollapsed, ListItemDropdown } from '../../atoms';
import { ListGroup } from '../../molecules';
import styles from './iconWithDropdown.module.scss';

interface Props {
    className?: string;
    classNameToggle?: string;
    classNameDropdown?: string;
    children?: JSX.Element | JSX.Element[];
    icon?: JSX.Element;
    path?: string;
    text?: string | JSX.Element;
    onClick?: () => void;
    drop?: 'up' | 'down' | 'left' | 'right';
    caret?: boolean;
    as?: React.ElementType<any>;
    toolTipPlacement?: 'top' | 'left' | 'right' | 'bottom';
    toolTip?: boolean;
    count?: number;
}

export default function IconWithDropdown({ className, children, icon, text, onClick, drop, caret, classNameToggle, classNameDropdown, as, toolTipPlacement, toolTip, count }: Props) {

    const isCounter = (typeof count === 'number') ? true : false;

    return (
        <>
            <Dropdown drop={(drop) ? drop : 'down'} className={className}>
                <Dropdown.Toggle as={(as) ? as : 'div'} className={styles.toggle}>
                    <ListItemCollapsed
                        count={count}
                        as={'div'}
                        icon={icon}
                        text={text}
                        className={`p-0 ${styles.compContainer} ${classNameToggle}`}
                        onClick={onClick}
                        caret={caret}
                        caretDir={(drop) ? drop : 'down'}
                        toolTipPlacement={(toolTipPlacement) ? toolTipPlacement : 'bottom'}
                        toolTip={toolTip}
                    />
                </Dropdown.Toggle>
                <ListItemDropdown className={classNameDropdown}>
                    <ListGroup className={'pl-0 ml-0'}>
                        {children}
                    </ListGroup>
                </ListItemDropdown>
            </Dropdown>
        </>
    )
}