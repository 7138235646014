import React from 'react';

interface Props{
    children: JSX.Element[] | JSX.Element
}

function LandingColumn(props: Props){
    return(
        <div className="scene-container-landing-column card-deck col-12 py-4">
            {props.children}
        </div>
    )
}

export default LandingColumn;