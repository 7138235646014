import React from 'react';
import useDataGrid from '../use-data-grid';

const useColumnSelector = () => {

    const grid = useDataGrid();

    //fires when value changes
    const onChange = (value: React.FormEvent<HTMLInputElement>) => {
        const data = {
            field: (value.target as any).value,
            enabled: (value.target as any).checked
        }
        const editedField = grid.columnFields.filter((column) => {
            if (column.field === data.field) {
                return column.field;
            }
        });
        grid.editColumnField(data.field, { ...editedField, enabled: data.enabled });
    }

    //fires when select all is checked
    const onSelectAll = (isChecked: boolean) => {
        const editedFields = grid.columnFields.map((column) => ({ ...column, enabled: isChecked }));
        grid.updateColumnFields(editedFields);
    }

    return {
        onChange,
        onSelectAll
    }
}

export default useColumnSelector;