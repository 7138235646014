import React from 'react';
import { Row, Col } from 'react-bootstrap';

export default function Preferences() {

    return (
        <>
            <p>Settings are under construction</p>
            {/* <Row>
                <Col>
                    <h3>Language</h3>
                </Col>
            </Row> */}
        </>
    )
}