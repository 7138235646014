import React from 'react';
import { useNonInitialEffect } from '../../../hooks';
import { useAutoSuggestStore } from './Store';
import { IAutoSuggest } from './interfaces';

const useAutoSuggest = () => {

    const [State, Store, Util] = useAutoSuggestStore();

    const [inputSize, setInputSize] = React.useState<IAutoSuggest.InputSize | undefined>(State.inputSize);
    const [activeInputValue, setActiveInputValue] = React.useState<IAutoSuggest.MenuItem | undefined>(State.activeInputValue);
    const [defaultInputValue, setDefaultInputValue] = React.useState<IAutoSuggest.MenuItem | undefined>(State.defaultInputValue);
    const [customSuggestionComp, setCustomSuggestionComp] = React.useState<IAutoSuggest.CustomSuggestionComp | undefined>(State.CustomSuggestionComp);
    const [placeHolderText, setPlaceHolderText] = React.useState<string | undefined>(State.placeHolderText);
    const [prependIcon, setPrependIcon] = React.useState<JSX.Element | boolean | undefined>(State.prependIcon);
    const [fetchStatusCode, setFetchStatusCode] = React.useState<IAutoSuggest.FetchStatusCode | undefined>(State.fetchStatusCode);
    const [onSelectHandler, setOnSelectHandler] = React.useState<IAutoSuggest.OnSelectHandler | undefined>(State.onSelectHandler);
    const [isDisabled, setIsDisabled] = React.useState<boolean | undefined>(State.isDisabled);
    const [inputClassNames, setInputClassNames] = React.useState<string | undefined>(State.inputClassNames);
    const [toggleMeta, setToggleMeta] = React.useState<IAutoSuggest.ToggleMeta>(State.toggleMeta);
    const [searchQuery, setSearchQuery] = React.useState<IAutoSuggest.SearchQuery>(State.searchQuery);

    const createSuggestions = (data: { [key: string]: any }[], mapTextTo: string, mapValueTo: string) => {
        const suggestions = data?.map((item) => {
            return {
                ...item,
                text: item[mapTextTo] || '',
                value: item[mapValueTo] || 0,
            }
        })
        return suggestions
    };

    const resetActiveInputValue = (searchQuery?: IAutoSuggest.SearchQuery) => {
        if (searchQuery) {
            if (searchQuery.query.length === 0) {

                Store.reset(Util.actions.udpateActiveInputValue);

                if (onSelectHandler) {
                    onSelectHandler({ text: "", value: 0 });
                }

            }
        } else {
            Store.reset(Util.actions.udpateActiveInputValue);
        }

    }

    const resetSearchQuery = () => {
        Store.reset(Util.actions.updateSearchQuery);
    }

    useNonInitialEffect(() => {
        if (inputSize) {
            Store.update(Util.actions.updateInputSize, inputSize);
        }
        if (placeHolderText) {
            Store.update(Util.actions.setPlaceHolderText, placeHolderText);
        }
        if (isDisabled !== undefined) {
            Store.update(Util.actions.updateIsDisabled, isDisabled);
        }
        if (inputClassNames) {
            Store.update(Util.actions.updateInputClassNames, inputClassNames);
        }
    }, [inputSize, placeHolderText, isDisabled, inputClassNames]);

    useNonInitialEffect(() => {
        if (activeInputValue) {
            Store.update(Util.actions.udpateActiveInputValue, activeInputValue);
        }
    }, [activeInputValue]);

    useNonInitialEffect(() => {
        if (defaultInputValue) {
            Store.update(Util.actions.updateDefaultInputValue, defaultInputValue);
        }
    }, [defaultInputValue]);

    useNonInitialEffect(() => {
        if (customSuggestionComp) {
            Store.update(Util.actions.setCustomSuggestionComp, customSuggestionComp);
        }
    }, [customSuggestionComp]);

    useNonInitialEffect(() => {

        Store.update(Util.actions.updatePrependIcon, prependIcon);

    }, [prependIcon]);

    useNonInitialEffect(() => {
        if (fetchStatusCode) {
            Store.update(Util.actions.updateFetchStatusCode, fetchStatusCode);
        }
    }, [fetchStatusCode]);

    useNonInitialEffect(() => {
        if (toggleMeta) {
            Store.update(Util.actions.updateToggleMeta, toggleMeta);
        }
    }, [toggleMeta]);

    useNonInitialEffect(() => {
        if (searchQuery) {
            Store.update(Util.actions.updateSearchQuery, searchQuery);
        }
    }, [searchQuery]);

    return {
        setInputSize,
        setActiveInputValue,
        setDefaultInputValue,
        setCustomSuggestionComp,
        setPlaceHolderText,
        setPrependIcon,
        setFetchStatusCode,
        setOnSelectHandler,
        setIsDisabled,
        setInputClassNames,
        setToggleMeta,
        resetActiveInputValue,
        setSearchQuery,
        resetSearchQuery,
        createSuggestions
    }

}

export default useAutoSuggest;