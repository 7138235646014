import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { IInputOption } from './interfaces';
import styles from './inputOption.module.scss';

interface Props {
    option: IInputOption.MenuItem;
    activeOption?: IInputOption.MenuItem;
    setActiveOption?: React.Dispatch<React.SetStateAction<IInputOption.MenuItem>>;
    customOption?: (props: { option: IInputOption.MenuItem, [key: string]: any }) => JSX.Element;
    onSelect?: (option: IInputOption.MenuItem) => void;
    onKeyDown?: (option: IInputOption.MenuItem, e: any) => void;
    index: number;
}

export default function InputOption({ option, activeOption, setActiveOption, index, customOption: CustomOption, onSelect, onKeyDown }: Props) {

    const isActive = (activeOption?.value === option.value) ? true : false;

    return (
        <>
            <Dropdown.Item
                eventKey={index.toString()}
                tabIndex={index}
                as={'div'}
                className={`py-2 cursor ${styles.compContainer}`}
                active={isActive}
                onClick={() => (setActiveOption) ? setActiveOption({ value: option.value, text: option.text }) : null}
                onSelect={(onSelect) ? () => { onSelect(option) } : () => null}
                onKeyDown={(e: any) => {
                    e.persist();
                    if (e.keyCode === 13) {
                        if (setActiveOption) {
                            setActiveOption({ value: option.value, text: option.text });
                        }
                        if (onKeyDown) {
                            onKeyDown(option, e);
                        }
                    }
                }}
            >
                {
                    (CustomOption) ? <CustomOption option={option} /> : option.text
                }
            </Dropdown.Item>
        </>
    )
}