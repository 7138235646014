import React from 'react';
import { ContentColumn } from '../../../atoms';

interface Props {
    fullwidth?: boolean;
    children: JSX.Element[] | JSX.Element;
}

export default function Column(props: Props) {

    return (
        <>
            <ContentColumn {...props} />
        </>
    )
}