import React from 'react';
import { IIcon } from '../interfaces';

function Count({ onClick, type, color, className, styles }: IIcon.Props) {

    return (
        <i className={`fa${type ? type?.substr(0,1) : "s"} fa-file-search ${className ?? ""} text-${color}`} onClick={onClick} style={styles}> </i>
    )
}

export default Count;