import React from "react";
import { usePageState, useCleanupEffect } from '../../../../hooks';
import { PageSidebar, PageSidebarMobile, PageSidebarMobileBtn } from '../../../atoms';
import useContent from '../use-content';

interface Props {
  children: JSX.Element | JSX.Element[];
}

function Widget({ children }: Props) {

  const page = usePageState();
  const content = useContent();

  //lets store know this page has a widget bar
  React.useEffect(() => {
    page.setHasWidgetBar(true);
  }, []);

  //resets widget bar state in store when page is navigated
  useCleanupEffect(() => {
    page.setHasWidgetBar(false);
  });

  return (
    <>
      {
        (page.isMobile) ?
          <PageSidebarMobile open={content.isWidgetAreaOpen}>
            <>
              {children}
              <PageSidebarMobileBtn
                onClick={() => content.toggleWidgetArea()}
              />
            </>
          </PageSidebarMobile> :
          <PageSidebar>
            {children}
          </PageSidebar>
      }
    </>
  );
}

export default Widget;
