import React from 'react';


interface ActionInterface {
    (
        dispatch: (
            object: {
                type: 'updateInfoCardData' | 'addToCheckedInfoCardData' | 'setInfoCardData', 
                updateInfoCardData?: object[],
                addToCheckedInfoCardData?: object[],
                setInfoCardData?: object[]
            }
        ) => object,
        setState: React.Dispatch<React.SetStateAction<{ 
            displayID: string,
            Id: number,
            name: string,
            country: string,
            street: string,
            city: string,
            state: string,
            zip: string,
            thumbnailURL?: string
        }[] | []>>,
        checkAllBtn: React.RefObject<HTMLInputElement>,
        infoCardData: {
            render: boolean,
            checked: boolean,
            data: {
                displayID: string,
                Id: number,
                name: string,
                country: string,
                street: string,
                city: string,
                state: string,
                zip: string,
                thumbnailURL?: string
            }
        }[]
    ): void
}

const handleCheckedAllBtn: ActionInterface = (dispatch, setState, checkAllBtn, infoCardData) => {
    if(checkAllBtn.current !== null && setState !== undefined && infoCardData !== undefined){

        let infoCardDataAllUnChecked = infoCardData.map((card) => {
            return {
                cardID: card.data,
                render: card.render,
                checked: false,
                data: {
                    displayID: card.data.displayID,
                    Id: card.data.Id,
                    name: card.data.name,
                    country: card.data.country,
                    street: card.data.street,
                    city: card.data.city,
                    state: card.data.state,
                    zip: card.data.zip,
                    thumbnailURL: card.data.thumbnailURL
                }
            }
        });

        let infoCardDataAllChecked = infoCardData.map((card) => {
            return {
                cardID: card.data,
                render: card.render,
                checked: true,
                data: {
                    displayID: card.data.displayID,
                    Id: card.data.Id,
                    name: card.data.name,
                    country: card.data.country,
                    street: card.data.street,
                    city: card.data.city,
                    state: card.data.state,
                    zip: card.data.zip,
                    thumbnailURL: card.data.thumbnailURL
                }
            }
        });

        if(checkAllBtn.current.checked === true){

            let infoCardDataIDs = infoCardData.map((card) => {
                return card.data;
            });

            setState(infoCardDataIDs as any);

            dispatch({
                type: 'addToCheckedInfoCardData',
                addToCheckedInfoCardData: infoCardDataIDs
            });

            dispatch({
                type: 'setInfoCardData',
                setInfoCardData: infoCardDataAllChecked 
            });

        }
        else{
            setState([]);

            dispatch({
                type: 'addToCheckedInfoCardData',
                addToCheckedInfoCardData: []
            });

            dispatch({
                type: 'setInfoCardData',
                setInfoCardData: infoCardDataAllUnChecked
            });
        }
    }
}

export default handleCheckedAllBtn;