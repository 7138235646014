import IDataBar from './interfaces';

const useDataBar = () => {

    const getColor = (value: IDataBar.Color) => {
        const textColor = {
            red: "text-danger",
            yellow: "text-warning",
            blue: "text-info",
            green: "text-success",
            white: "text-white",
        };
        if (value) {
            return textColor[value];
        }
        return textColor["white"];
    }

    return {
        getColor
    }

}

export default useDataBar;