/*
  Header component
*/

import React from "react";
import { AppLogo, Masthead } from '../../atoms';
import styles from './header.module.scss';
import { UserMenu, AppUtilities } from '../../molecules';

function Header() {


  return (
    <>
      <Masthead>
        <AppLogo />
        <div className={`d-flex align-items-center ${styles.userContainerHeight}`}>
          <AppUtilities />
          <div className={`${styles.vl} pr-3`}></div>
          <UserMenu />
        </div>
      </Masthead>
    </>
  );
}

export default Header;
