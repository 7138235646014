/*
  DataGrid
*/
import React from "react";
import { Content } from '..';
import { GridContainer } from '../../molecules';

export interface GridProps {
  children: JSX.Element | JSX.Element[];
  height?: string;
  className?: string;
  loading?: boolean;
  data: any[];
  as?: JSX.Element | object;
  pageSize?: number;
  fetchStatus?: any;
  totalDataCount?: number;
  columnTitles?: boolean;
  gridStyles?: React.CSSProperties;
  stickyColumns?: boolean;
}

export default function BasicGrid({
  children,
  height,
  className,
  loading,
  data,
  as,
  pageSize,
  fetchStatus,
  totalDataCount,
  columnTitles,
  gridStyles,
  stickyColumns
}: GridProps) {

  return (
    <>
      <Content.Panel bodyClassName={'p-0'}>
        <GridContainer
          height={height}
          className={className}
          loading={loading}
          data={data}
          as={as}
          pageSize={pageSize}
          fetchStatus={fetchStatus}
          totalDataCount={totalDataCount}
          columnTitles={columnTitles}
          gridStyles={gridStyles}
          stickyColumns={stickyColumns}
        >
          {children}
        </GridContainer>
      </Content.Panel>
    </>
  );
}

