import React from 'react';
import styles from './tableRowExpand.module.scss';

interface Props {
    isExpanded: boolean;
    customRow: ({ rowData }: { rowData: any }) => JSX.Element;
    rowData: any;
}

export default function TableRowExpand({ isExpanded, customRow: CustomRow, rowData }: Props) {

    return (
        <>
            {
                (isExpanded) ?
                    <tr>
                        <td className={`${styles.compContainer} ${styles.expanded} p-0`}>
                            <div className="p-0">
                                <CustomRow rowData={rowData} />
                            </div>
                        </td>
                    </tr> : null
            }
        </>
    )
}