import React from 'react';
import { PanelFooter as PanelFooterAtom } from '../../../atoms';

interface Props {
    className?: string;
    children?: JSX.Element | JSX.Element[]
}
export default function PanelFooter({ className, children }: Props) {

    return (
        <>
            <PanelFooterAtom className={className}>
                {children}
            </PanelFooterAtom>
        </>
    )
}