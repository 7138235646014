import React from 'react';
import { PanelHeader } from '../../../atoms';
import styles from './cardHeader.module.scss';
import { ICard } from '../interfaces';

export default function Header({ children, className }: ICard.Header) {
    return (
        <>
            <PanelHeader className={`${styles.content} p-2 ${className} `}>
                {children}
            </PanelHeader>
        </>
    )
}
