import React from 'react';
import { Content } from '../../organisms';
import styles from './loadingPage.module.scss';

function LoadingPage() {
    return (
        <Content>
            <Content.Column>
                <div className={styles.page}>
                    <div className="container-fluid d-flex align-items-center">
                        <div className="row d-flex justify-content-center align-items-center py-4 my-4">
                            <div className="col-5">
                                <svg id="Layer_1" width="100px" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 398.65 533.39"><defs><style>{`.cls-1{fill:#ABAEB0;}`}</style></defs><title>logo_protrans_A_white</title><path className="cls-1" d="M360,90.25,114.32,421.16H37.26l279.27-388H426.25l9.65,388H360.43Z" transform="translate(-37.26 -33.13)" /><polygon className="cls-1" points="279.9 202.47 280.29 533.39 34.25 533.39 279.9 202.47" /></svg>
                                {/* <img src={ProtransALogo} className="img-fluid" alt="Loading Page!"/> */}
                                <p className='pt-4 text-center'>Loading...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Content.Column>
        </Content>

    );
}

export default LoadingPage;