/*
    Table
    provides general table markup for columns and rows to be embedded
*/
import React, { useLayoutEffect, useRef } from "react";
import { Table } from "react-bootstrap";
import { TableHead, TableBody } from '../../../atoms';
import { GridLoadingSplash } from "../../../molecules";
import useDataGrid from '../use-data-grid';
import styles from './table.module.scss';

//lazy loads render data component
const RenderData = React.lazy(() => import("../render-data/render-data"));

interface Props {
  children: JSX.Element | JSX.Element[];
  className?: string;
}
function GridTable({ children, className }: Props) {

  const grid = useDataGrid();

  //Refs for Table and TBody elements
  const tableRef = useRef<any>(null);
  const tableBodyRef = useRef<any>(null);

  const LoadingFallback = () => (
    <GridLoadingSplash
      width={grid.width}
      height={grid.splashHeight}
    />
  )

  //updates tableRef in store so it can be shared to other components like the pdf export
  useLayoutEffect(() => {
    grid.setTableRef(tableRef);
  }, [tableRef]);

  //updates tBodyRef in store so it can be shared to other components like infiniteScroll settings
  useLayoutEffect(() => {
    grid.setTableBodyRef(tableBodyRef);
  }, [tableBodyRef]);

  return (
    <>
      <div
        className={styles.compContainer}
        style={{
          maxHeight: `${grid.height}`,
          minHeight: `${grid.height}`,
          overflowX: (grid.cachedTableData.length === 0 || grid.isLoading === true) ? 'hidden' : 'auto'
        }}
        ref={tableBodyRef}
      >
        <Table
          ref={tableRef}
          striped={grid.ExpandableRowComp ? false : true}
          bordered
          className={`${styles.table} ${className ? className : ""}`}
        >
          <TableHead sticky={grid.isStickyColumns} hide={grid.isColumnTitles} onMount={grid.setTheadRef}>
            {children}
          </TableHead>
          <TableBody isExpandable={grid.ExpandableRowComp}>
            <React.Suspense fallback={<LoadingFallback />}>
              <RenderData />
            </React.Suspense>
          </TableBody>
        </Table>
      </div>
    </>
  );
}

export default React.memo(GridTable);
