import React from 'react';
import './day-table.scss';

interface Props{
    weekNum: string,
    weekDate: string,
    days: number[]
}

function DayTable(props: Props) {

    //props
    // const weekNum = props.weekNum;
    // const weekDate = props.weekDate;
    const days = props.days;

    return (
        <>
            <td className='row-data-day-table p-0 m-0'>
                {/* <div className='d-flex justify-content-between align-items-center p-2'>
                    <p className='mb-0'>Week#</p>
    <p className='mb-0'>{weekNum}</p>
    <p className='mb-0'>{weekDate}</p>
                </div> */}
                <table className="table-responsive">
                    <thead>
                        <tr>
                            <th scope="col">S</th>
                            <th scope="col">M</th>
                            <th scope="col">T</th>
                            <th scope="col">W</th>
                            <th scope="col">T</th>
                            <th scope="col">F</th>
                            <th scope="col">S</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={`row-data-day-table-${(days[0] == 0) ? 'white' : 'red'}`}>{days[0]}</td>
                            <td className={`row-data-day-table-${(days[6] == 0) ? 'white' : 'purple'}`}>{days[6]}</td>
                            <td className={`row-data-day-table-${(days[1] == 0) ? 'white' : 'navy'}`}>{days[1]}</td>
                            <td className={`row-data-day-table-${(days[2] == 0) ? 'white' : 'blue'}`}>{days[2]}</td>
                            <td className={`row-data-day-table-${(days[3] == 0) ? 'white' : 'green'}`}>{days[3]}</td>
                            <td className={`row-data-day-table-${(days[4] == 0) ? 'white' : 'yellow'}`}>{days[4]}</td>
                            <td className={`row-data-day-table-${(days[5] == 0) ? 'white' : 'orange'}`}>{days[5]}</td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </>
    )
}

export default DayTable;