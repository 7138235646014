
const useTimePicker = () => {

    const extractTimePeriod = (defaultValue: { text: string, value: string }) => {
        return defaultValue.text.split(' ')[1] as 'PM' | 'AM';
    }

    const convertTimeBackTo24 = (time: string, timePeriod: 'PM' | 'AM') => {
        const timeArray = time.split(':');
        const timeInteger = parseInt(timeArray[0]);
        const newTimeInteger = (timePeriod === 'PM') ? (timeInteger === 12) ? timeInteger : timeInteger + 12 : (timeInteger === 12) ? 0 : timeInteger;
        const convertedTime = `${newTimeInteger}:${timeArray[1]}`;
        const formattedTime = (convertedTime.length === 4) ? `0${convertedTime}` : convertedTime;
        return formattedTime;
    };

    //outputs military time by default
    const outputTimeArray = (format?: 12 | 24, interval?: 15 | 30) => {
        let formatType = (format) ? format + 1 : 13;
        let array: number[] = [];
        for (let i = 0; formatType > i; i++) {
            array = [...array, i];
        }
        const processArray = () => {

            let formattedTimeArray: string[] = [];
            array.map((item: number) => {
                const stringItem = item.toString();
                const processedItem = (stringItem.length == 1 && format === 24) ? `0${stringItem}` : stringItem;
                if (interval) {
                    const itemIntervals =
                        (interval === 15) ? [`${processedItem}:00`, `${processedItem}:15`, `${processedItem}:30`, `${processedItem}:45`] :
                            (interval === 30) ? [`${processedItem}:00`, `${processedItem}:30`] : [];
                    if (item !== 25) {
                        formattedTimeArray = [...formattedTimeArray, ...itemIntervals];
                    }
                }
                else {
                    formattedTimeArray = [...formattedTimeArray, `${processedItem}:00`];
                }

            });

            const forbidden24Time = ['24:15', '24:30', '24:45'];
            const forbidden12Time = ['0:00', '0:15', '0:30', '0:45', '12:00', '12:15', '12:30', '12:45']
            const removedForbiddenTimes = formattedTimeArray.filter((item) => {
                if (format !== 24) {
                    if (!forbidden12Time.find((value) => value === item)) {
                        return item;
                    }
                } else {
                    if (!forbidden24Time.find((value) => value === item)) {
                        return item;
                    }
                }

            });
            const returnedArray = (format === 24) ? removedForbiddenTimes :
                (interval === 15) ? ['12:00', '12:15', '12:30', '12:45', ...removedForbiddenTimes] :
                    (interval === 30) ? ['12:00', '12:30', ...removedForbiddenTimes] : ['12:00', ...removedForbiddenTimes]

            return returnedArray
        }
        return processArray();
    }

    return ({
        extractTimePeriod,
        convertTimeBackTo24,
        outputTimeArray
    })
}

export default useTimePicker;