
const createInAppMessage = (type: 'success' | 'error' | 'warning' | 'info' | 'update' | 'loading' | 'default', messageText: string, dispatch: any, dismiss?: boolean | number) => {
  dispatch({
    type: 'ptrui_updateInAppMessages',
    ptrui_updateInAppMessages:
      [{
        type: type,
        dismiss: dismiss,
        messageText: messageText
      }]
  })
}

export default createInAppMessage;