import React from 'react';
import { Card } from 'react-bootstrap';
import styles from './cardFooter.module.scss';
import { ICard } from '../interfaces';

function Footer({ children, className }: ICard.Footer) {

    return (
        <>
            <Card.Footer className={`${styles.content} p-2 ${className}`}>
                {children}
            </Card.Footer>
        </>
    )
}

export default Footer;