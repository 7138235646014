import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { useCardGridStore } from '../../_Store/Store';
import styles from './dataBtn.module.scss';

interface IDataBtns {

}
export default function DataBtns({ }: IDataBtns) {

    const [{ queriedCardGridData, cardGridData, loadingState, checkedList, alphaFilter, cardCheckType }] = useCardGridStore();

    return (
        <>
            <ButtonGroup size="sm" className={`ml-2 ${styles.btnGroup}`}>
                {
                    (cardCheckType !== 'radio') ?
                        <Button variant="dark" className={styles.btn}>
                            <i className="fas fa-check-square pr-1"></i>{" "}
                            {checkedList.length}
                        </Button> : null
                }

                <Button variant="info" className={styles.btn}>
                    <i className="fas fa-filter pr-1"></i>{" "}
                    {
                        (alphaFilter.active === 'checked') ?
                            checkedList.length :
                            Object.entries(queriedCardGridData).length
                    }

                </Button>
                <Button
                    className={styles.btn}
                    variant={'primary'}
                    disabled={loadingState}
                >
                    <i className="fas fa-database pr-1"></i>{" "}
                    {
                        (loadingState) ?
                            "Fetching..." :
                            Object.entries(cardGridData).length
                    }

                </Button>

            </ButtonGroup>
        </>
    )
}