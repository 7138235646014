import React from 'react';

import {Provider, Reducer, State} from './_context';
import InfoCardSection from './info-card-section';

//interface
import PropsInterface from './props-interface';



function InfoCardSectionContainer(props: PropsInterface){
    return(
        <>
            <Provider data={State} reducer={Reducer}>
                {
                    (props.dataSet !== undefined) ?
                        <InfoCardSection {...props}/>
                        : <></>
                }
            </Provider>
        </>
    )
} 

export default InfoCardSectionContainer;