//format for date range selection
const formatDate = (date: Date | undefined | null) => {
    if (date !== undefined && date !== null) {
        let month = date?.getMonth() + 1;
        let day = date?.getDate();
        let year = date?.getUTCFullYear().toString();
        let formattedMonth = month < 10 ? `0${month}` : month;
        let formattedDay = day < 10 ? `0${day}` : day;
        let formattedDate = `${formattedMonth}/${formattedDay}/${year}`;
        return formattedDate;
    }
    return undefined
};

export default formatDate;