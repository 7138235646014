import React from "react";
import styles from './noResults.module.scss';

function NoResults() {

  return (
    <>

      <div className={styles.noResults}>
        <div
          className={`d-flex flex-column align-items-center justify-content-center`}
          style={{ height: "100%", width: `100%` }}
        >
          <div className={styles.icon}>
            <i className="fad fa-search text-info"></i>
            <i className="fad fa-sad-tear text-info"></i>
          </div>
          <h5 className="pt-3 text-info">Sorry, No Results...</h5>
        </div>
      </div>

    </>
  );
}

export default React.memo(NoResults);
