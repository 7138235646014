import React from "react";
import { withTreble } from 'treble-gsm';
import { Modal } from 'react-bootstrap';
import { Store } from './Store';
import { ModalHeader, ModalFooter } from '../../atoms';
import { useNonInitialEffect, useModal } from '../../../hooks';
import { IModalBasic } from './interfaces';
import styles from './modalBasic.module.scss';

function ModalBasicComp({ show, onHide, size, title, titleIcon, dialogClassName, backdropClassName, centered, children, ...props }: IModalBasic) {

  const modal = useModal();

  const [openAnimation, setOpenAnimation] = React.useState(false);

  useNonInitialEffect(() => {
    if (show === true) {
      setOpenAnimation(true);
    }
    else if (show === false) {
      setOpenAnimation(false);
    }
  }, [show]);

  return (
    <>
      <Modal
        centered={(centered !== undefined) ? centered : true}
        size={(size) ? size : 'xl' as any}//For some reason 'md' causes build to fail so had to make type generic
        animation={false}
        show={show}
        dialogClassName={`${styles.modal} ${styles.animation} ${(openAnimation) ? styles.open : ''} ${dialogClassName}`}
        backdropClassName={`${styles.backdrop} ${(openAnimation) ? '' : styles.close} ${backdropClassName}`}
        onHide={() => {
          setTimeout(() => {
            setOpenAnimation(false);
          }, 0)
          setTimeout(() => {
            onHide();
          }, 300)
        }}
        {...props}
      >
        {
          (title || titleIcon) ?
            <ModalHeader closeButton title={title} titleIcon={titleIcon} /> : null
        }
        {
          (modal.isSideBar) ?
            <div className={`${styles.sidebarMobile} d-flex justify-content-start align-content-center d-lg-none`}>
              <p className='mb-0 p-1 pl-4'><i className="fas fa-ellipsis-h cursor" onClick={() => modal.toggleSideBarCollapse()}></i></p>
            </div> : null
        }
        {
          React.Children.map(children, (child) => {
            return (child.type?.displayName === 'ModalHeader') ? child : null
          })
        }
        <div className={'d-flex'}>
          {
            React.Children.map(children, (child) => {
              return (child.type?.displayName !== 'ModalFooter' && child.type?.displayName !== 'ModalHeader') ? child : null
            })
          }
        </div>
        {
          React.Children.map(children, (child) => {
            return (child.type?.displayName === 'ModalFooter') ? child : null
          })
        }
        {
          (React.Children.toArray(children).find((child: any) => child.type?.displayName === 'ModalFooter')) ? null : <ModalFooter />
        }
      </Modal>
    </>
  )
}

const ModalBasic = withTreble(ModalBasicComp, { store: Store })
export default ModalBasic;
