import React from 'react';
import { Badge } from 'react-bootstrap';
import styles from './listItemLabel.module.scss';

interface Props {
    icon?: JSX.Element;
    text?: string;
    badge?: string | number;
    className?: string;
    badgeType?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
}

export default function ListItemLabel({ text, icon, badge, badgeType, className }: Props) {

    return (
        <>
            <div className={`d-flex justify-content-between ${className}`}>
                <div className={`d-flex align-items-center ${(text) ? styles.iconAndLabel : styles.iconOnly}`}>
                    {icon}
                    {
                        (text) ? <p className={`mb-0 ${(icon) ? 'pl-3' : ''}`}>{text}</p> : null
                    }
                </div>
                {
                    (badge !== undefined) ?
                        <p className='mb-0'><Badge variant={(badgeType) ? badgeType : 'primary'} className={(text) ? styles.badge : styles.badgeIconOnly}>{badge}</Badge></p> : null
                }
            </div>
        </>
    )
}