import React from 'react';
import LoadingData from '../../render-data/loading-data';
import styles from './tbody.module.scss';

//lazy loads render data component
const RenderData = React.lazy(() => import("../../render-data/render-data"));

interface Props {
    width: number;
    expandableRow?: boolean;
}
export default function TBody({ width, expandableRow }: Props) {

    return (
        <>
            <tbody className={`${styles.tbody} ${(expandableRow) ? styles.strippedRows : ''}`}>
                {/**Lazy loads RenderData component so it doesnt block stack (Not sure if this is working) */}
                <React.Suspense fallback={<LoadingData width={width} />}>
                    <RenderData />
                </React.Suspense>
            </tbody>
        </>
    )
}