import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import styles from './accordionContainer.module.scss';

interface Props {
    children?: JSX.Element | JSX.Element[];
    onClick?: () => void;
    className?: string;
    open?: boolean;
}

export default function AccordionContainer({ children, onClick, className, open }: Props) {

    return (
        <>
            <Accordion defaultActiveKey={(open) ? '1' : '0'} className={`${styles.compContainer} ${className}`} onClick={() => (onClick) ? onClick() : null}>
                <Card className={styles.card}>
                    {children}
                </Card>
            </Accordion>
        </>
    )
}