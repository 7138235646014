import React from 'react';
import styles from './expandableRowContainer.module.scss';

interface Props {
    expandRow: boolean;
    isRowExpandable: (item: any) => boolean;
    customRow: ({ rowData }: { rowData: any }) => JSX.Element;
    rowData: any;
}

export default function ExpandableRowContainer({ expandRow, isRowExpandable, customRow: CustomRow, rowData }: Props) {

    return (
        <>
            <tr>
                <td
                    className={`${styles.expandableRow} ${expandRow ? styles.expanded : ""
                        } p-0`}
                >
                    {
                        (isRowExpandable(rowData) !== false) ?
                            <div className="data-grid-expandable-row-content p-0">
                                {expandRow ? <CustomRow rowData={rowData} /> : null}
                            </div> : null
                    }

                </td>
            </tr>
        </>
    )
}