
import React from "react";
import styles from './noteBlock.module.scss';
import { INoteBlock } from "./interfaces";

export default function NoteBlock({ children, type }: INoteBlock) {
  //handlers
  const handleNoteBlockType = (
    type: "info" | "warning" | "update" | "danger" | undefined
  ) => {
    const noteBlockType = {
      info: `${styles.info} alert-info`,
      warning: `${styles.warning} note-block-warning alert-warning`,
      update: `${styles.update} note-block-update alert-primary`,
      danger: `${styles.danger} note-block-danger alert-danger`,
    };
    if (type) {
      return noteBlockType[type];
    }
    return noteBlockType["info"];
  };

  return (
    <blockquote className={`${styles.blockquote} p-3 ${handleNoteBlockType(type)}`}>
      {children}
    </blockquote>
  );
}
