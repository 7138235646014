const normalizeCardData = (dispatchValue: any[]) => {

    let normalizedData = {};
    dispatchValue?.map((item: any, index: number) => {
        normalizedData = {
            ...normalizedData,
            [item.Id || index]: {
                ...item,
                checkState: false
            }
        }
    });

    return normalizedData;
}

export default normalizeCardData;