import { GridContainer } from '../../molecules';

//hook used internally to update Grid Store
const useDataGrid = () => GridContainer.useStore();

//hook that can be used externally by downstream devs
const useGrid = () => {
    const {
        setLoading,
        setFetchStatus,
        updateTableData,
        updateCachedTableData,
        resetGridPosition,
        prependRow,
        appendRow,
        selectedRows,
        activePage,
        cachedTableData,
        tableData,
        isLoading,
        searchQueries: filterQueries,
        totalDataCount,
        resetSearchQueries: resetColumnFilters,
        onRefresh: refetchData,
        isRowSelected,
        customItemProps: customRowProps
    } = useDataGrid();


    return {
        setLoading,
        setFetchStatus,
        updateTableData,
        updateCachedTableData,
        resetGridPosition,
        resetColumnFilters,
        prependRow,
        appendRow,
        isRowSelected,
        selectedRows,
        activePage,
        cachedTableData,
        tableData,
        isLoading,
        searchQueries: filterQueries,
        totalDataCount,
        refetchData,
        customRowProps
    }
}

export { useGrid };
export default useDataGrid;