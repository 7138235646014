import { createStore } from "treble-gsm";
import { queryState, processState } from "../middleware";

export interface ITableDataStore {
  tableData: [];
  queriedTableData: [];
  renderedTableData: any;
  triggerTableDataFetch: boolean | any;
  fetchStatusCode: null | any;
  totalDataCount: boolean | number;
  shouldDataRefresh: boolean;
}

const actionKeys = {
  ['updateTableData']: 'updateTableData',
  ['updateQueriedTableData']: 'updateQueriedTableData',
  ['updateRenderedTableData']: 'updateRenderedTableData',
  ['triggerTableDataFetch']: 'triggerTableDataFetch',
  ['updateFetchStatusCode']: 'updateFetchStatusCode',
  ['updateTotalDataCount']: 'updateTotalDataCount',
  ['setShouldDataRefresh']: 'setShouldDataRefresh'
}

export type TTableDataActions = typeof actionKeys;

const TableDataStore = createStore([
  {
    action: actionKeys.updateTableData,
    state: {
      tableData: [],
    },
    features: {
      keys: true,
      check : (value : any) =>
      { 
        return (Array.isArray(value.dispatchValue) && value.dispatchAction.subscribeType === "update") || value.dispatchAction.subscribeType !== "update"; 
      }
    }
  },
  {
    action: actionKeys.updateQueriedTableData,
    state: {
      queriedTableData: [],
    },
    features: {
      process: (value) => queryState(value.dispatchValue),
    },
  },
  {
    action: actionKeys.updateRenderedTableData,
    state: {
      renderedTableData: [],
    },
    features: {
      process: (value) => processState(value.dispatchValue),
    },
  },
  {
    action: actionKeys.triggerTableDataFetch,
    state: {
      triggerTableDataFetch: false,
    },
  },
  {
    action: actionKeys.updateFetchStatusCode,
    state: {
      fetchStatusCode: 'No Errors Detected',
    },
  },
  {
    action: actionKeys.updateTotalDataCount,
    state: {
      totalDataCount: false
    }
  },
  {
    action: actionKeys.setShouldDataRefresh,
    state: {
      shouldDataRefresh: true
    }
  }
]);

export default TableDataStore;
