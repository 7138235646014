import React from 'react';


interface ActionInterface {
    (
        dispatch: (
            object: {
                type: 'setInfoCardData',
                setInfoCardData?: object[]
            }
        ) => object,
        infoCardData: {
            render: boolean,
            checked: boolean,
            data: {
                displayID: string,
                Id: number,
                name: string,
                country: string,
                street: string,
                city: string,
                state: string,
                zip: string,
                thumbnailURL?: string
            }
        }[],
        filterValue: 'a' | 'b' | 'c' | 'd' | 'e' | 
        'f' | 'g' | 'h' | 'i' | 'j' | 'k' | 
        'l' | 'm' | 'n' | 'o' | 'p' | 'q' | 
        'r' | 's' | 't' | 'u' | 'v' | 'w' | 
        'x' | 'y' | 'z' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '0' | 'all' | 'checked'
    ): void
}

const handleAlphabetSearch: ActionInterface = (dispatch, infoCardData, filterValue) => {

    let shouldCardRender = (filterValue: string, nameValue: string, checkedState: boolean) => {
        let cardRenderValue = 
        (filterValue !== 'all' && filterValue !== 'checked') 
        ? (nameValue.toLowerCase().startsWith(filterValue)) ?  true : false
        : (filterValue === 'checked') ? (checkedState === true) ? true : false 
        : (filterValue === 'all') ? true : false;

        return cardRenderValue;
    }

    let filteredInfoCardData = 
        infoCardData.map((card) => {
            let renderValue = shouldCardRender(filterValue, card.data.name, card.checked);
            return(
                {
                    render: renderValue,
                    checked: card.checked,
                    data: {
                        displayID: card.data.displayID,
                        Id: card.data.Id,
                        name: card.data.name,
                        street: card.data.street,
                        country: card.data.country,
                        city: card.data.city,
                        state: card.data.state,
                        zip: card.data.zip,
                        thumbnailURL: card.data.thumbnailURL
                    }
                }
            );
        });

   dispatch({
       type: 'setInfoCardData',
       setInfoCardData: filteredInfoCardData
   })
}

export default handleAlphabetSearch;