/*
 Footer
 - provides pagination and row counts
*/
import React from "react";
import Content from '../../content';
import useDataGrid from "../use-data-grid";

interface Props {
  children?: JSX.Element | JSX.Element[];
}
export default function Footer({ children }: Props) {

  const grid = useDataGrid();

  return (
    <>
      <Content.PanelFooter>
        <>
          {
            (children) ?
              <div className="flex-fill px-3">
                {children}
              </div> : null
          }
        </>
      </Content.PanelFooter>
    </>
  );
}
