import React from 'react';
import Treble from 'treble-gsm';
import { BrowserRouter as Router } from 'react-router-treble';
import { ProtransReactUIStore } from '../_Store';
import { IPTRUIApp } from './interface';
import PersistState from '../_components/atoms/persist-state';
import StartUp from './start-up';
import RenderPage from './render-page';
import '../styles/styles.global.scss';

export default function App({
    config: AppConfig,
    startup: AppStartup,
    navigation: AppNavigation,
    alerts: AppAlerts,
    routes: AppRoutes,
    store: AppStore,
    basename,
    children
}: IPTRUIApp) {

    interface IStoreArray {
        data: {
            action: string,
            state: { [key: string]: any },
            features?: any
        }[],
        modules?: any[],
        scope?: any
    }
    const mergeStores = (storeArray: IStoreArray[]) => {
        const storeData: { action: string, state: { [key: string]: any }, features?: any }[] = [...storeArray[0].data, ...storeArray[1].data];
        const storeModules: any = undefined;
        const storeScope: any = undefined;

        return {
            data: storeData,
            modules: storeModules,
            scope: storeScope
        };
    }

    const combinedStores = mergeStores([AppStore, ProtransReactUIStore]);

    return (
        <>
            <div id="PTRUIAppContainer" className="ptrui-app-container">
                <Treble store={combinedStores}>
                    {/**Persist State will be removed once TrebleGSM 4 is rolled out and Treble Persist is updated with Session Storage option*/}
                    <PersistState />

                    <Router basename={basename}>
                        <AppConfig />
                        <StartUp>
                            {
                                (AppStartup) ?
                                    <AppStartup /> : <></>
                            }
                        </StartUp>
                        <RenderPage
                            AppNavigation={AppNavigation}
                            AppAlerts={AppAlerts}
                            AppRoutes={AppRoutes}
                        >
                            {children}
                        </RenderPage>
                    </Router>
                </Treble>
            </div>
        </>
    )
}
