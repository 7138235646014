import React from 'react';
import { ListItemHeader, ListItemDivider, ListItemCheck } from '../../atoms';
import { BtnWithDropdown, IconWithDropdown } from '../../molecules';
import styles from './columnSelector.module.scss';

interface Props {
    iconOnly?: boolean;
    small?: boolean;
    large?: boolean;
    className?: string;
    classNameDropdown?: string;
    onChange?: (value: React.FormEvent<HTMLInputElement>) => void;
    onSelectAllChange?: (isChecked: boolean, value?: React.FormEvent<HTMLInputElement>) => void;
    fields: {
        field: string;
        title: string;
        enabled: boolean;
        trebleKey: number;
    }[]
}
export default function ColumnSelector({ iconOnly, small, large, className, classNameDropdown, fields, onChange, onSelectAllChange }: Props) {

    const size = (small) ? 'sm' : (large) ? 'lg' : undefined;
    const [fieldData, setFieldData] = React.useState(fields);
    const [selectAllChecked, setSelectAllChecked] = React.useState(false);

    const onSelectAll = (value: React.FormEvent<HTMLInputElement>) => {
        const isChecked = (value.target as any)?.checked || false;
        if (isChecked) {
            setSelectAllChecked(true);
            setFieldData(fields.map((column) => ({ ...column, enabled: true })));
            if (onSelectAllChange) {
                onSelectAllChange(isChecked, value);
            }
        }
        else {
            setSelectAllChecked(false);
            setFieldData(fields.map((column) => ({ ...column, enabled: false })));
            if (onSelectAllChange) {
                onSelectAllChange(isChecked, value);
            }
        }
    }

    React.useEffect(() => {
        setFieldData(fields);
    }, [fields]);

    const DropdownMenu = () => (
        <>
            <ListItemHeader text={'Select Columns'} />
            <ListItemDivider />
            <div className={styles.listContainer}>
                {
                    fieldData.map(({ field, title, enabled, trebleKey }) => (
                        <ListItemCheck
                            key={trebleKey}
                            label={title}
                            isChecked={enabled}
                            value={field}
                            onChange={onChange}
                        />
                    ))
                }
            </div>
            <ListItemDivider />
            <ListItemCheck
                label={'Select All'}
                isChecked={selectAllChecked}
                value={'select_all'}
                onChange={(value) => onSelectAll(value)}
            />
        </>
    )

    return (
        <>
            {
                (iconOnly) ?

                    <IconWithDropdown
                        icon={<i className={`fas fa-columns text-info ${styles.iconOnly}`}></i>}
                        className={`${className}`}
                        classNameToggle={'pt-0'}
                        caret
                        drop={'down'}
                        text={'Select Columns'}
                        toolTip={false}
                        classNameDropdown={classNameDropdown}
                    >
                        <DropdownMenu />
                    </IconWithDropdown>

                    :
                    <BtnWithDropdown
                        small={(size === 'sm') ? true : false}
                        large={(size === 'lg') ? true : false}
                        variant={'info'}
                        label={'Columns'}
                        caret
                        caretDir={'down'}
                        icon={<i className="fas fa-columns text-white"></i>}
                        className={className}
                        classNameDropdown={classNameDropdown}
                    >
                        <DropdownMenu />
                    </BtnWithDropdown>
            }
        </>
    )
}