import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { InputLoading, InputError, InputToolTip, InputFooter, ListItemChildren, ListItemDropdown } from '../../atoms';
import styles from './inputDropdown.module.scss';

interface Props {
    children?: JSX.Element | JSX.Element[];
    className?: string;
    childrenClassName?: string;
    withInputIcon?: boolean;
    loading?: boolean;
    error?: boolean | null | object | string;
    showChildren?: boolean;
    customFooter?: (props: any) => JSX.Element;
}
export default function InputDropdown({ children, className, withInputIcon, customFooter: CustomFooter, error, loading, showChildren, childrenClassName }: Props) {

    return (
        <>
            <ListItemDropdown className={`${(withInputIcon) ? styles.withIcon : ''} ${className} `}>
                {
                    (loading) ? <InputLoading /> :
                        (error) ? <InputError /> :
                            (showChildren !== false) ?
                                <>
                                    <ListItemChildren className={`${childrenClassName}`}>
                                        {children}
                                    </ListItemChildren>
                                    {
                                        (CustomFooter) ?
                                            <InputFooter>
                                                <CustomFooter />
                                            </InputFooter> : null
                                    }
                                </> :
                                <>
                                    <InputToolTip />
                                    {
                                        (CustomFooter) ?
                                            <InputFooter>
                                                <CustomFooter />
                                            </InputFooter> : null
                                    }
                                </>
                }
            </ListItemDropdown>
        </>
    )
}