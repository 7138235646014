import React from 'react';
import { Nav } from 'react-bootstrap';
import { useModalStore } from '../../Store';
import styles from './navItem.module.scss';

interface Props {
    eventKey: string;
    text: string;
    textKey?: string | number
}

export default function NavItem({ eventKey, text, textKey }: Props) {

    const [, Store, Util] = useModalStore();

    React.useEffect(() => {
        if (eventKey === 'link-0') {
            Store.update(Util.actions.ptrui_modalBasic_setActiveSideBarNavItem, { text: text, key: textKey });
        }
    }, [])

    return (
        <>
            <Nav.Link
                as={'div'}
                eventKey={eventKey}
                onSelect={() => {
                    Store.update(Util.actions.ptrui_modalBasic_setActiveSideBarNavItem, { text: text, key: textKey });
                    Store.update(Util.actions.ptrui_modalBasic_setActiveSideBarNavItemEventKey, eventKey);
                }}
                className={`${styles.navListItem} cursor text-muted`}>
                {text}
            </Nav.Link>
        </>
    )
}