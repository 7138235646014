import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useNonInitialEffect } from '../../../../hooks';
import { useCardGridStore } from '../_Store/Store';

export default function SelectAll() {

    const [{ queriedCardGridData, cardCheckType, checkedList, alphaFilter }, Store, Util] = useCardGridStore();
    const [checked, setChecked] = React.useState(false);

    useNonInitialEffect(() => {
        if (checked) {
            const allCardIds = Object.keys(queriedCardGridData).map(Number);
            Store.update(Util.actions.setSelectAll, true);
            Store.update(Util.actions.updateCheckedList, allCardIds);
        } else {
            Store.update(Util.actions.setSelectAll, false);
            Store.update(Util.actions.updateCheckedList, []);
        }

    }, [checked]);

    return (
        <>
            {
                (cardCheckType !== 'radio') ?
                    <Row>
                        <Col>
                            <Form.Group className='d-flex' style={{ height: '100%' }}>
                                <Form.Check
                                    disabled={(Object.values(queriedCardGridData).length === 0)}
                                    type="checkbox"
                                    checked={checked}
                                    onChange={() => (checked) ? setChecked(false) : setChecked(true)}
                                />
                                <Form.Label>Select All</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row> : null
            }

        </>
    )
}