/*
    Header
    - holds grid title or custom header actions
*/
import React from "react";
import { Row, Col } from 'react-bootstrap';
import { IHeader } from "../interfaces";
import styles from './header.module.scss';

function Header({ title, children, className }: IHeader) {
  return (
    <>
      <Row
        className={`${styles.header} d-flex align-items-center justify-content-between ${className}`}
      >
        {title ? <Col><h5 className="m-0">{title}</h5></Col> : null}
        {children ? (
          <Col className={styles.actions}>{children}</Col>
        ) : null}
      </Row>
    </>
  );
}

export default React.memo(Header);
