import React from 'react';
import { ContentRow } from '../../../atoms';

interface Props {
    half?: boolean;
    left?: boolean;
    right?: boolean;
    full?: boolean;
    className?: string;
    noBottomPadding?: boolean;
    children: JSX.Element[] | JSX.Element;
}

export default function Row(props: Props) {
    return (
        <>
            <ContentRow {...props} />
        </>
    )
}