import React from 'react';
import { Card, Form, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNonInitialEffect } from '../../../../hooks';
import { useCardGridStore } from '../_Store/Store';
import styles from './infoCard.module.scss';

interface Props {
  children?: JSX.Element | JSX.Element[];
  link?: string;
  checkType?: 'checkbox' | 'radio';
  controlID: number
}

function InfoCard({ children, link, controlID }: Props) {


  const [{ cardCheckType, checkedList, selectAll }, Store, Util] = useCardGridStore();
  const [checked, setChecked] = React.useState(checkedList.includes(controlID));

  const handleChecks = (checked: boolean, checkedList: number[]) => {
    if (!checked) {
      if (!checkedList.includes(controlID)) {
        if (cardCheckType === 'radio') {
          Store.update(Util.actions.updateCheckedList, [controlID])
        }
        else {
          Store.append(Util.actions.updateCheckedList, controlID)
        }
        setChecked(true);
      }
    }
    if (checked) {
      if (cardCheckType === 'radio') {
        Store.update(Util.actions.updateCheckedList, [controlID])
      }
      else {
        Store.remove(Util.actions.updateCheckedList, controlID)
      }
      setChecked(false);
    }
  }

  useNonInitialEffect(() => {
    if (selectAll) {

    }

  }, [checked]);

  useNonInitialEffect(() => {
    if (selectAll) {
      setChecked(true);
    }
    else {
      setChecked(false);
    }
  }, [selectAll])


  return (
    <Col md={6} lg={4} className={`pt-2 py-2 px-0 ${styles.infoCardContainer}`}>
      <Card className='p-0 m-2'>

        <div className={`d-flex ${styles.contentContainer}`}>
          <div className='pl-3 pt-3'>
            <Form.Check
              type={(cardCheckType) ? cardCheckType : 'checkbox'}
              name={'ptruiCardGridCheck'}
              id={`ptruiCardGridCheck${controlID}`}
              checked={checked}
              onChange={() => handleChecks(checked, checkedList)}
            />
          </div>
          {
            (link) ?
              <Link to={link}>
                <Card.Body className='pl-2 pt-3'>
                  {children}
                </Card.Body>
              </Link> :
              <Card.Body className='pl-2 pt-3'>
                {children}
              </Card.Body>
          }

        </div>
        {
          React.Children?.map(children, (child) => {
            return (child?.type.name === 'InfoCardFooter') ? child : null
          })
        }
      </Card>
    </Col>
  );
}

export default InfoCard;





