import React from 'react';
import { withTreble } from 'treble-gsm';
import { Nav } from 'react-bootstrap';
import { CollapseBtn } from '../../atoms';
import { ListGroup } from '../../molecules';
import { IPageNav } from './interfaces';
import Divider from './divider';
import { usePageState } from '../../../hooks';
import { Store } from './Store';
import styles from './pageNav.module.scss';

function PageNavComp({ children }: IPageNav) {

    const page = usePageState();

    return (
        <>
            <Nav>
                <ListGroup className={`pt-2 pb-0 ${styles.listGroup}`}>
                    <>
                        {children}
                    </>
                    <Divider className={'my-0'} />
                </ListGroup>
                <CollapseBtn isOpen={page.isCollapsed} onClick={() => { page.toggle(); page.setIsWidgetBarOpen((page.isWidgetBarOpen) ? false : true); }} />
            </Nav>
        </>
    )
}

const PageNav = withTreble(PageNavComp, { store: Store });
export default PageNav;