import React from 'react';
import styles from './gridBackToTop.module.scss';

interface Props {
    className?: string;
    onClick?: () => void;
    show?: boolean;
}
export default function GridBackToTop({ className, onClick, show }: Props) {

    return (
        <>
            <div className={`${styles.compContainer} d-flex justify-content-end ${className}`}>
                <div>
                    <i className={`fas fa-arrow-up text-white cursor px-2 pb-0 pt-2 ${styles.icon} ${(show) ? styles.show : styles.hide} `} onClick={(onClick) ? onClick : () => null}></i>
                </div>
            </div>
        </>
    )
}