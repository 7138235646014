import useDataGrid from '../use-data-grid';

const useRenderData = () => {

    const grid = useDataGrid();

    //provides animation to ScrollTo function
    const easeInOutQuad = (t: any, b: any, c: any, d: any) => {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };

    //scrolls scrollbar to specific point with animation
    const scrollTo = (element: any, to: any, duration: any) => {
        let start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        let animateScroll = function () {
            currentTime += increment;
            let val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }



    //fires scroll to when scrollbar reaches top
    const onScrollTop = (lastRowIndex: number) => {
        const element = document.getElementById(`ptrui_row_${lastRowIndex + 2}`);
        const prevPage = grid.activePage - 1;
        grid.setActivePage(prevPage);
        setTimeout(() => {
            element?.scrollIntoView();
        }, 0);
    }

    //fires scroll to when scrollbar reaches bottom
    const onScrollBottom = (lastRowIndex: number) => {

        const element = document.getElementById(`ptrui_row_${lastRowIndex - 2}`);
        const nextPage = (grid.activePage === 0) ? grid.activePage + 2 : grid.activePage + 1;
        grid.setActivePage(nextPage);
        setTimeout(() => {
            element?.scrollIntoView();
        }, 0);

    }

    const areAllColumnsDisabled = () => {
        const enabledColumns = grid.columnFields.find((column) => column.enabled === true);
        if (enabledColumns) {
            return false
        }
        return true
    }

    return {
        areAllColumnsDisabled,
        onScrollTop,
        onScrollBottom
    }
}

export default useRenderData;