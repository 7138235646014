

import {createContext} from 'react';


//Global App Context

let defaultValue:object = [];
const Context = createContext(defaultValue as any);

export default Context;