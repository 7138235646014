import React from 'react';
import { useAppStore } from '../../../_Store';
import IAppMessage from './interfaces';

const useAppMessage = (message: IAppMessage.Data) => {

    const [, Store, Util] = useAppStore();
    const [shouldAnimate, setShouldAnimate] = React.useState(false);

    const dismiss = () => {
        setShouldAnimate(true);
        //delay unmount until animation finishes
        setTimeout(() => {
            Store.remove(Util.actions.ptrui_updateInAppMessages, message);
        }, 250);
    }

    const startDismissTimer = () => {
        const dismiss = (message.dismiss === undefined || message.dismiss === true) ? 9000 : message.dismiss;
        if (dismiss !== false) {
            setTimeout(() => {
                Store.remove(Util.actions.ptrui_updateInAppMessages, message)
            }, dismiss);
        }
    }

    const alertType = () => {
        const alertColor: { [key: string]: { color: string, icon: string } } = {
            'success': {
                color: "success",
                icon: 'fa-check-circle'
            },
            'error': {
                color: "danger",
                icon: 'fa-minus-circle'
            },
            'warning': {
                color: "warning",
                icon: 'fa-exclamation-circle'
            },
            'info': {
                color: "info",
                icon: 'fa-info-circle',
            },
            'update': {
                color: "primary",
                icon: 'fa-arrow-circle-up',
            },
            'loading': {
                color: "info",
                icon: 'spinner',
            },
        };

        return alertColor[message.type || 'info'];
    };

    const text = message.messageText;

    const type = alertType();

    return {
        dismiss,
        startDismissTimer,
        shouldAnimate,
        type,
        text
    }

}

export default useAppMessage;