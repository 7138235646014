import React from 'react';
import { useNonInitialEffect } from '../../../hooks';
import { IAutoSuggest } from '../auto-suggest/interfaces';
import { useFilterListStore } from './Store';

const useFilterList = () => {

    const [State, Store, Util] = useFilterListStore();

    const [inputSize, setInputSize] = React.useState<IAutoSuggest.InputSize | undefined>(State.inputSize);
    const [activeInputValue, setActiveInputValue] = React.useState<IAutoSuggest.MenuItem | undefined>(State.activeInputValue);
    const [defaultInputValue, setDefaultInputValue] = React.useState<IAutoSuggest.MenuItem | undefined>(State.defaultInputValue);
    const [customDataComp, setCustomDataComp] = React.useState<IAutoSuggest.CustomSuggestionComp | undefined>(State.CustomDataComp);
    const [placeHolderText, setPlaceHolderText] = React.useState<string | undefined>(State.placeHolderText);
    const [prependIcon, setPrependIcon] = React.useState<JSX.Element | boolean | undefined>(State.prependIcon);
    const [onSelectHandler, setOnSelectHandler] = React.useState<IAutoSuggest.OnSelectHandler | undefined>(State.onSelectHandler);
    const [isDisabled, setIsDisabled] = React.useState<boolean | undefined>(State.isDisabled);
    const [inputClassNames, setInputClassNames] = React.useState<string | undefined>(State.inputClassNames);
    const [toggleMeta, setToggleMeta] = React.useState<IAutoSuggest.ToggleMeta>(State.toggleMeta);
    const [searchQuery, setSearchQuery] = React.useState<IAutoSuggest.SearchQuery>(State.searchQuery);

    const createData = (data: { [key: string]: any }[], mapTextTo: string, mapValueTo: string) => {
        let _data : any = [];
        if(Array.isArray(data))
            _data = data?.map((item) => {
                return {
                    ...item,
                    text: item[mapTextTo] || '',
                    value: item[mapValueTo] || 0,
                }
            })
        return _data;
    };

    const resetActiveInputValue = (searchQuery?: IAutoSuggest.SearchQuery) => {
        if (searchQuery) {
            if (searchQuery.query.length === 0) {

                Store.reset(Util.actions.udpateActiveInputValue);

                if (onSelectHandler) {
                    onSelectHandler({ text: "", value: 0 });
                }

            }
        } else {
            Store.reset(Util.actions.udpateActiveInputValue);
        }

    }

    const resetSearchQuery = () => {
        Store.reset(Util.actions.updateSearchQuery);
    }

    useNonInitialEffect(() => {
        if (inputSize) {
            Store.update(Util.actions.updateInputSize, inputSize);
        }
        if (placeHolderText) {
            Store.update(Util.actions.setPlaceHolderText, placeHolderText);
        }
        if (isDisabled !== undefined) {
            Store.update(Util.actions.updateIsDisabled, isDisabled);
        }
        if (inputClassNames) {
            Store.update(Util.actions.updateInputClassNames, inputClassNames);
        }
    }, [inputSize, placeHolderText, isDisabled, inputClassNames]);

    useNonInitialEffect(() => {
        if (activeInputValue) {
            Store.update(Util.actions.udpateActiveInputValue, activeInputValue);
        }
    }, [activeInputValue]);

    useNonInitialEffect(() => {
        if (defaultInputValue) {
            Store.update(Util.actions.updateDefaultInputValue, defaultInputValue);
        }
    }, [defaultInputValue]);

    useNonInitialEffect(() => {
        if (setCustomDataComp) {
            Store.update(Util.actions.setCustomDataComp, setCustomDataComp);
        }
    }, [setCustomDataComp]);

    useNonInitialEffect(() => {

        Store.update(Util.actions.updatePrependIcon, prependIcon);

    }, [prependIcon]);

    useNonInitialEffect(() => {
        if (toggleMeta) {
            Store.update(Util.actions.updateToggleMeta, toggleMeta);
        }
    }, [toggleMeta]);

    useNonInitialEffect(() => {
        if (searchQuery) {
            Store.update(Util.actions.updateSearchQuery, searchQuery);
        }
    }, [searchQuery]);

    return {
        setInputSize,
        setActiveInputValue,
        setDefaultInputValue,
        setCustomDataComp,
        setPlaceHolderText,
        setPrependIcon,
        setOnSelectHandler,
        setIsDisabled,
        setInputClassNames,
        setToggleMeta,
        resetActiveInputValue,
        setSearchQuery,
        resetSearchQuery,
        createData
    }

}

export default useFilterList;