import React from 'react';
import { Dropdown } from 'react-bootstrap';
import LoadingMenu from './loading-indicator';
import ErrorMenu from './error-message';
import DefaultItem from './default-item';
import { useAppStore } from '../../../../../_Store';
import styles from './menu.module.scss';

interface Props {
    loading: boolean,
    error: null | object,
    data: []
}

export default function Menu({ loading, error, data }: Props) {

    const [{ ptrui_appSearch: search }] = useAppStore();
    const CustomSearchItem = (search) ? search.customItem : () => <></>

    return (
        <>
            <Dropdown.Menu className={styles.menu}>
                {
                    (loading) ?
                        <LoadingMenu /> :
                        (error !== null) ?
                            <ErrorMenu /> :
                            (data.length > 0) ?
                                data?.map((item: { [key: string]: any }, index: number) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Dropdown.ItemText className={`${styles.searchItem} text-white p-3`}>
                                                <CustomSearchItem {...item} />
                                            </Dropdown.ItemText>
                                        </React.Fragment>
                                    )
                                }) : <DefaultItem />
                }
            </Dropdown.Menu>
        </>
    )
}