import React from 'react';
import { Col, Row } from 'react-bootstrap';
import AlphaCar from './alpha-char';
import { useCardGridStore } from '../_Store/Store';

export default function AlphaFilter() {

    const [{ alphaFilter }, Store, Util] = useCardGridStore();
    const [activeChar, setActiveChar] = React.useState('all');
    const alphabet = [...Array.from('abcdefghijklmnopqrstuvwxyz'), 'all', 'checked']

    React.useLayoutEffect(() => {
        Store.update(Util.actions.updateActivePage, 0);
        Store.update(Util.actions.updateAlphaFilter, { ...alphaFilter, active: activeChar });
    }, [activeChar]);

    return (
        <>
            <Row>
                <Col>
                    <div className={`d-flex pb-3`}>
                        {
                            alphabet.map((char) => {
                                return (
                                    <React.Fragment key={char}>
                                        <AlphaCar
                                            char={char}
                                            active={(activeChar === char) ? true : false}
                                            onClick={() => setActiveChar(char)}
                                        />
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
}