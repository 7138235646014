import React from 'react';
import { Dropdown } from 'react-bootstrap';
import styles from './listItemDropdown.module.scss';

interface Props {
    className?: string;
    children?: JSX.Element | JSX.Element[];
}

export default function ListItemDropdown({ className, children }: Props) {

    return (
        <>
            <Dropdown.Menu className={`${styles.compContainer} ${className}`}>
                {children}
            </Dropdown.Menu>
        </>
    )
}