import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { ListItem, ListItemDivider, ListItemHeader, ListItemDropdown, BtnToggle } from '../../atoms';
import BtnWithDropdown from '../btn-with-dropdown';
import IconWithDropdown from '../icon-with-dropdown';
import { useDataExports } from '../../../hooks';
import styles from './exportBtnPDF.module.scss';

interface Props {
    iconOnly?: boolean;
    small?: boolean;
    large?: boolean;
    className?: string;
    label?: string;
    data: any[];
    fields: {
        field: string;
        title: string;
        enabled: boolean;
        trebleKey: number;
    }[]
    options?: {
        type: 'pdf' | 'excel',
        label: string,
        onClick: () => void;
    }[]
}
export default function ExportBtnPDF({ iconOnly, small, large, className, label, data, fields, options }: Props) {

    const { exportToPDF } = useDataExports();
    const size = (small) ? 'sm' : (large) ? 'lg' : undefined;
    const btnLabel = (label) ? label : "Export PDF";


    return (
        <>
            {
                (iconOnly) ?
                    (options) ?
                        <IconWithDropdown
                            icon={<i className={`fas fa-file-pdf text-danger ${styles.iconOnly}`}></i>}
                            className={`${className}`}
                            classNameToggle={'pt-0'}
                            caret
                            drop={'down'}
                            text={btnLabel}
                            toolTip={false}
                        >
                            <>
                                <ListItemHeader text={btnLabel} />
                                <ListItemDivider />
                                {
                                    options.map(({ label, onClick }, index) => (
                                        <ListItem
                                            key={index}
                                            text={label}
                                            onClick={onClick}
                                        />
                                    ))
                                }
                            </>
                        </IconWithDropdown> :
                        <i
                            className={`fas fa-file-pdf text-danger cursor ${styles.iconOnly} ${className}`}
                            onClick={() => exportToPDF(data, fields)}
                        ></i> :
                    (options) ?
                        <BtnWithDropdown
                            small={(size === 'sm') ? true : false}
                            large={(size === 'lg') ? true : false}
                            variant={'danger'}
                            label={btnLabel}
                            className={className}
                            icon={<i className="fas fa-file-pdf text-white"></i>}
                            caret
                            caretDir={'down'}
                        >
                            <>
                                <ListItemHeader text={btnLabel} />
                                <ListItemDivider />
                                {
                                    options.map(({ label, onClick }, index) => (
                                        <ListItem
                                            key={index}
                                            text={label}
                                            onClick={onClick}
                                        />
                                    ))
                                }
                            </>
                        </BtnWithDropdown> :
                        <Button
                            variant={'danger'}
                            size={size}
                            className={className}
                            onClick={() => exportToPDF(data, fields)}
                        >
                            <i className="fas fa-file-pdf text-white pr-2"></i>
                            {btnLabel}
                        </Button>
            }
        </>
    )
}