import React from 'react';

interface Props {
    char: string,
    active: boolean,
    onClick: () => void
}
export default function AlphaChar({ char, active, onClick }: Props) {

    return (
        <>
            <a className={`pr-3 cursor ${(char === 'all') ? 'pl-3' : ''} ${(active) ? 'text-primary text-bold' : 'text-dark'}`} onClick={() => onClick()} style={{ textDecoration: 'none' }}>
                <strong className={`${(char === 'all' || char === 'checked') ? 'text-capitalize' : 'text-uppercase'}`}>
                    {char}
                </strong>
            </a>
        </>
    )
}