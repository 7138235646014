import React from "react";
import { useCardGridStore } from "../../_Store/Store";
import styles from './errorResults.module.scss';

function ErrorResults() {
  const [{ fetchStatusCode }] = useCardGridStore();

  return (
    <>

      <div
        className={styles.errorResults}
      >
        <div
          className={`d-flex flex-column align-items-center justify-content-center`}
          style={{ height: "100%" }}
        >
          <i className="fad fa-exclamation-triangle text-danger"></i>
          <h4 className="pt-3 text-danger">Something Went Wrong!</h4>
          <div className='d-flex justify-content-center' style={{ width: '75%' }}>
            <p className="text-danger text-center">Fetch Error: {fetchStatusCode?.toString()}</p>
          </div>
        </div>
      </div>

    </>
  );
}

export default React.memo(ErrorResults);
