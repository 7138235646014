import React from 'react';
import { Form } from 'react-bootstrap';
import { useNonInitialEffect } from '../../../../hooks';
import { useDataGridStore, TStore } from '../_Store';

interface Props {
    columnData: {
        field: string;
        title: string;
        enabled: boolean;
    };
    checkAll: boolean
}

export default function ColumnItem({ columnData, checkAll }: Props) {

    //subscribe to store
    const [{ columnFields }, Store] = useDataGridStore() as TStore;

    const isColumnEnabled = () => {
        let thisColumn = columnFields.find((column) => column.field === columnData.field);
        if (thisColumn?.enabled) {
            return true
        } else {
            return false;
        }
    }
    const [checked, setChecked] = React.useState(isColumnEnabled());

    const handleCheck = (columnData: { enabled: boolean }) => {
        if (checked) {
            Store.edit('createColumnFields', { ...columnData, enabled: false });
            setChecked(false);
        }
        else if (!checked) {
            Store.edit('createColumnFields', { ...columnData, enabled: true });
            setChecked(true);
        }
    }

    useNonInitialEffect(() => {
        if (checkAll) {
            Store.edit('createColumnFields', { ...columnData, enabled: true });
            setChecked(true);
        }
        else if (!checkAll) {
            Store.edit('createColumnFields', { ...columnData, enabled: false });
            setChecked(false);
        }
    }, [checkAll])

    return (
        <>
            <div className={'px-3 mb-2'}>
                <Form.Check type="checkbox" label={(columnData.title.length > 0) ? columnData.title : <em>(No Title)</em>} checked={checked} onChange={() => handleCheck(columnData)} />
            </div>
        </>
    )
}