/*
  RenderData
  - handles the different types of data rendering for the Grid.Table component
*/
import React from "react";
import { useDataGridStore, TStore } from "../_Store";
import { Spinner } from 'react-bootstrap';
import RenderGroups from "./render-groups";
import RenderRows from "./render-rows";
import NoResults from "./no-results";
import NoColumns from './no-columns';
import LoadingData from "./loading-data";
import ErrorResults from "./error-results";
import LoadMoreData from './load-more-data';
import { useResize } from '../../../../hooks';

export default function RenderData() {
  //subscribe to store
  const [
    {
      columnGroupTarget,
      queriedTableData,
      tableData,
      loadingState,
      fetchStatusCode,
      shouldDataRefresh,
      gridRef,
      columnFields
    },
  ] = useDataGridStore() as TStore;

  const areAllColumnsDisabled = () => {
    let enabledColumns = columnFields.find((column) => column.enabled === true);
    if (enabledColumns) {
      return false
    }
    return true
  }

  //makes sure width is set if window is resized
  const { width } = useResize(gridRef);

  return (
    <>
      {
        (areAllColumnsDisabled()) ? <NoColumns width={width} />
          : (loadingState && shouldDataRefresh) ? (
            <LoadingData width={width} />
          )
            : (fetchStatusCode !== 'No Errors Detected') ? (
              <ErrorResults width={width} />
            ) : tableData.length === 0 || queriedTableData.length === 0 ? (
              <NoResults width={width} />
            ) : columnGroupTarget.field !== "" && columnGroupTarget.title !== "" ? (
              <RenderGroups />
            ) :
              (
                <>
                  <RenderRows />
                  <LoadMoreData width={width} />
                </>
              )
      }
    </>
  );
}

