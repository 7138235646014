import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import uniqid from "uniqid";
import "./_setting-group-list.scss";

interface Props {
  listTitle?: string;
  settingsList: {
    value: string;
    text: string;
  }[];
  selectedSetting: React.Dispatch<React.SetStateAction<string>>;
}

function SettingGroupList(props: Props) {
  //props
  const listTitle = props.listTitle;
  const settingsList = props.settingsList;
  const setActiveSetting = props.selectedSetting;

  //state
  const [selectedItem, setSelectedItem] = useState<string>("link-1");

  //handlers
  const handleSelectedItem = (eventKey: string, settingValue: string) => {
    setSelectedItem(eventKey);
    setActiveSetting(settingValue);
  };
  const handleInitialSetting = () => {
    settingsList.forEach((item, index) => {
      let listItemEventKey = `link-${index + 1}`;
      if (listItemEventKey === "link-1") {
        setActiveSetting(item.value);
      }
    });
  };

  //set initial active setting
  useEffect(() => {
    handleInitialSetting();
  }, []);

  return (
    <Nav
      defaultActiveKey="link-1"
      className="settings-wizard-setting-group-list pt-2"
    >
      <div className="settings-wizard-setting-group-list-container">
        {listTitle !== undefined ? <p className="pl-3">{listTitle}</p> : null}

        {settingsList.map((item, index) => {
          let listItemEventKey = `link-${index + 1}`;
          return (
            <React.Fragment key={uniqid()}>
              <Nav.Link
                eventKey={listItemEventKey}
                className={`${
                  selectedItem === listItemEventKey
                    ? "settings-wizard-active-list-item"
                    : ""
                  } pl-3 `}
                onSelect={(eventKey: any) =>
                  handleSelectedItem(eventKey, item.value)
                }
              >
                {item.text}
              </Nav.Link>
            </React.Fragment>
          );
        })}
      </div>
    </Nav>
  );
}

export default SettingGroupList;
