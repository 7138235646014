import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { InputField } from "../../atoms";
import { IInput } from "../date-selector/interfaces";

export default function Input({
    name,
    caption,
    warningMsg,
    width,
    type,
    disabled,
    readOnly,
    className,
    size,
    value,
    checked,
    isHorizontal,
    colsLabel,
    colsInput,
    required, 
    onClick,
    onKeyDown,
    onChange,
    ...props
  }: IInput){

    const render = () => {
      const inputFn = () => { return isHorizontal ? getHorizontalInput() :  getVerticalInput(); }
      const checkboxFn = () => { return getCheckboxInput(); }
      const obj = {
        'text': inputFn,
        'email': inputFn,
        'number': inputFn,
        'url': inputFn,
        'textarea': inputFn,
        'checkbox': checkboxFn,
        'radio': checkboxFn,
        'switch': checkboxFn,
        'default': () =>{ return <></> }
      };
      return switchFn(obj, type ?? 'text');
    }

    const switchFn = (lookupObject : any, defaultCase = 'default') =>
                       { return lookupObject[defaultCase]() };
    
    const getCheckboxInput = () => {
      let _colsInput : number = colsInput ?? 12;
      return (
        <Form.Group as={Col} sm={_colsInput} md={_colsInput} lg={_colsInput} controlId={"validation" + name}>
          <Form.Check 
            required = {required}
            label = {caption}
            feedback = {warningMsg ?? 'Please provide a valid ' + name}
            type = { type as any }
            id = {"checkbox" + name}
            onClick = {onClick}
            onChange = {onChange}
            checked = {checked}
          />    
        </Form.Group>
      ); 
    }

    const getVerticalInput = () => {
      let _colsInput : number = colsInput ?? 12;
      let _type : string = (type ?? 'text') !== "textarea" ? 'input' : 'textarea';
      return (
        <Form.Group as={Col} sm={_colsInput} md={_colsInput} lg={_colsInput} controlId={"validation" + name}>
          <Form.Label>{caption}</Form.Label>
          <Form.Control
                    {...props}
                    size={size}
                    className={`${(!readOnly && !disabled) ? 'cursor' : ''} ${className}`}
                    as={_type as any}
                    type = {type ?? 'text'}
                    value={value}
                    readOnly={readOnly}
                    disabled={disabled}
                    onKeyDown={(e: React.KeyboardEvent) => {
                        if (onKeyDown) {
                            onKeyDown(e);
                        }
                    }}
                    onClick={(e: React.MouseEvent) => {
                        if (!readOnly) {
                            if (onClick) {
                                onClick(e);
                            }
                        }
                    }}
                    onChange={(value) => {
                        if (onChange) {
                            onChange(value);
                        }
                    }}
                    required={required}
                >
                </Form.Control>      
                <Form.Control.Feedback type="invalid">
                  { warningMsg ?? 'Please provide a valid ' + name }
                </Form.Control.Feedback>
        </Form.Group>
      ); 
    }

    const getHorizontalInput = () => {
      let _colsLabel : number = colsLabel ?? 2;
      let _colsInput : number = colsInput ?? 10;
      let _type : string = (type ?? 'text') !== "textarea" ? 'input' : 'textarea';
      return (
        <Form.Group as={Row} controlId={"validation" + name}>
          <Form.Label column sm={_colsLabel} md={_colsLabel} lg={_colsLabel}>{caption}</Form.Label>
          <Col sm={_colsInput} md={_colsInput} lg={_colsInput}>
          <Form.Control
                    {...props}
                    size={size}
                    className={`${(!readOnly && !disabled) ? 'cursor' : ''} ${className}`}
                    as={_type as any}
                    type = {type ?? 'text'}
                    value={value}
                    readOnly={readOnly}
                    disabled={disabled}
                    onKeyDown={(e: React.KeyboardEvent) => {
                        if (onKeyDown) {
                            onKeyDown(e);
                        }
                    }}
                    onClick={(e: React.MouseEvent) => {
                        if (!readOnly) {
                            if (onClick) {
                                onClick(e);
                            }
                        }
                    }}
                    onChange={(value) => {
                        if (onChange) {
                            onChange(value);
                        }
                    }}
                    required={required}
                >
            </Form.Control>      
            <Form.Control.Feedback type="invalid">
              { warningMsg ?? 'Please provide a valid ' + name }
            </Form.Control.Feedback>
          </Col>     
        </Form.Group>
      ); 
    }

    return render();
}