/*
    updateSearchQueries
    - updates Grid search query state object so the Grid knows how to filter rows and 
    also makes it accessible to outside components for api searches
*/

import IUpdateSearchQueries from "./interface";

const updateSearchQueries: IUpdateSearchQueries = {

  search: (searchQueries, field, inputValue, e, Store, gridTBodyRef) => {
    if (e?.keyCode !== 9 && e?.keyCode !== 16/*enter key*/) {
      let updatedSearchQueries = searchQueries.map((searchItem) => {
        if (searchItem.target === field) {
          return {
            target: field,
            query: inputValue.length > 0 ? inputValue.toLowerCase() : "",
            exact: searchItem.exact,
            type: "string",
            trebleKey: searchItem.trebleKey
          };
        }
        return {
          target: searchItem.target,
          query: searchItem.query,
          exact: searchItem.exact,
          type: searchItem.type,
          trebleKey: searchItem.trebleKey
        };
      });
      //gridTBodyRef.current.scrollTop = 0;
      const equals: any = (a: any, b: any) => {
        if (a === b) return true;
        if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();
        if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b;
        if (a === null || a === undefined || b === null || b === undefined) return false;
        if (a.prototype !== b.prototype) return false;
        let keys = Object.keys(a);
        if (keys.length !== Object.keys(b).length) return false;
        return keys.every(k => equals(a[k], b[k]));
      };


      if (!equals(updatedSearchQueries, searchQueries)) {
        console.log('foo')
        Store.update("updateSearchQueries", updatedSearchQueries);
      }

    }
  },
  select: (searchQueries, field, inputValue, Store) => {
    //selectRef null check
    let updatedSearchQueries = searchQueries.map((searchItem) => {
      if (searchItem.target === field) {
        return {
          target: field,
          query: inputValue.length > 0 ? inputValue.toLowerCase() : "",
          exact: searchItem.exact,
          type: "string",
        };
      }
      return {
        target: searchItem.target,
        query: searchItem.query,
        exact: searchItem.exact,
        type: searchItem.type,
      };
    });
    Store.update("updateSearchQueries", updatedSearchQueries);
  },
  dateRange: (searchQueries, field, dateRangeObject, Store) => {
    //updates dateRange queries
    let updatedSearchQueries = searchQueries.map((searchItem) => {
      if (searchItem.target === field) {
        return {
          target: field,
          query: dateRangeObject,
          exact: searchItem.exact,
          type: "dateRange",
        };
      }
      return {
        target: searchItem.target,
        query: searchItem.query,
        exact: searchItem.exact,
        type: searchItem.type,
      };
    });
    Store.update("updateSearchQueries", updatedSearchQueries);
  },
};

export default updateSearchQueries;
