import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from '../../../utils';
import styles from './listItem.module.scss';

interface Props {
    path?: string;
    onClick?: () => void;
    icon?: JSX.Element;
    children?: React.ReactChild | React.ReactChild[];
    text?: string;
    className?: string;
    as?: React.ElementType<any>;
    linkClassName?: string;
}

export default function ListItem({ path, icon, onClick, text, children, className, as, linkClassName }: Props) {
    return (
        <>
            <ListGroup.Item as={(as) ? as : 'li'} className={`${styles.compContainer} ${className} cursor`} onClick={onClick}>
                <Link to={path} className={linkClassName}>
                    <>
                        {
                            (icon) ?
                                <span className="pr-3">
                                    {icon}
                                </span> : null
                        }
                        {
                            (children) ? children : text
                        }
                    </>
                </Link>
            </ListGroup.Item>
        </>
    )
}