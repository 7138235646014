import { useAppStore } from '../_Store';

interface IuseSettingsModal {
    (): {
        open: () => void;
        close: () => void;
        toggle: () => void;
        isOpen: boolean;
    }
}
const useSettingsModal: IuseSettingsModal = () => {

    const [{ ptrui_settingsModalInfo }, Store, Util] = useAppStore();

    const open = () => Store.update(Util.actions.ptrui_setSettingsModalInfo, { show: true });
    const close = () => Store.update(Util.actions.ptrui_setSettingsModalInfo, { show: false });
    const toggle = () => Store.update(Util.actions.ptrui_setSettingsModalInfo, { show: (ptrui_settingsModalInfo.show) ? false : true });
    const isOpen = ptrui_settingsModalInfo.show;

    return {
        open,
        close,
        toggle,
        isOpen
    }

}

export default useSettingsModal;