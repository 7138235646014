import React from "react";
import styles from './noChecked.module.scss';

function NoChecked() {

  return (
    <>
      <div className={styles.noChecked}>
        <div
          className={`d-flex flex-column align-items-center justify-content-center`}
          style={{ height: "100%", width: `100%` }}
        >
          <div className={styles.icons}>
            <i className="fad fa-clipboard-list-check text-info"></i>
          </div>
          <h5 className="pt-3 text-info">No items are checked...</h5>
        </div>
      </div>
    </>
  );
}

export default React.memo(NoChecked);
