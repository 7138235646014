import React from 'react';
import styles from './notificationIcon.module.scss';
import { useAlertMenu } from '../../../hooks';
import { useAppStore } from '../../../_Store';

export default function NotificationIcon() {

    const [{ ptrui_alertMenuData: alerts }] = useAppStore();
    const menu = useAlertMenu();

    return (
        <>
            <div
                className={`${styles.compContainer} pr-4 align-items-center d-flex`}
                onClick={() => menu.open()}
            >
                <p
                    className={`mt-1 mb-0 pb-0 ${alerts.length > 0 ? "text-danger" : "text-muted"
                        }`}
                >
                    <i className={`fa fa-bell ${styles.iconBell}`}></i>
                    <span className="pl-2">{alerts.length || 0}</span>
                </p>
            </div>
        </>
    )
}