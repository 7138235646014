import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ToggleSort, FilterIndicator } from '../../atoms';
import styles from './gridColumn.module.scss';

interface Props {
    refProp?: React.LegacyRef<HTMLTableHeaderCellElement>;
    children?: JSX.Element | JSX.Element[];
    width?: string;
    hide?: boolean;
    title?: string | JSX.Element;
    isSort?: boolean;
    isFilter?: boolean;
    isFilterActive?: boolean;
    sortType?: 'asc' | 'desc' | 'none';
    disableSort?: boolean;
    topPadding?: boolean;
    onSortChange?: () => void;
}
export default function GridColumn({ refProp, width, hide, title, children, isSort, isFilter, isFilterActive, sortType, onSortChange, topPadding, disableSort }: Props) {

    return (
        <>
            <th ref={refProp} style={{ width: width }} className={`${styles.compContainer} ${(hide) ? 'd-none' : ''}`}>
                <Row style={{ height: '100%' }}>
                    <Col className={'d-flex flex-column'}>
                        <Row>
                            <Col className={'d-flex justify-content-between'}>
                                {
                                    (isFilter) ?
                                        <FilterIndicator size={.7} isActive={isFilterActive} /> :
                                        (topPadding) ?
                                            <FilterIndicator size={.7} isActive={false} className={'invisible'} /> : null
                                }
                                {
                                    (isSort) ?
                                        <ToggleSort size={.8} type={sortType} disabled={disableSort} onClick={onSortChange} /> :
                                        (topPadding) ? <ToggleSort size={.8} type={sortType} disabled className={'invisible'} /> : null
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className={`mb-0`}>{title}</p>
                            </Col>
                        </Row>
                        {
                            (children) ?
                                <Row>
                                    <Col>
                                        {children}
                                    </Col>
                                </Row> : null
                        }
                    </Col>
                </Row>
            </th>
        </>
    )
}