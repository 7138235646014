/*App Provider*/
import React, {useReducer} from 'react';
import Context from '../context';
import ProviderInterface from './provider-interface';

const Provider = ({reducer, data, children}: ProviderInterface) => (
    <Context.Provider value={useReducer(reducer, data)}>
        {children}
    </Context.Provider>
);

export default Provider;