import React from 'react';
import { Card as BTCard } from 'react-bootstrap';
import styles from './card.module.scss';
import { ICard } from './interfaces';

export default function Card({ children, className, minHeight }: ICard.ICardComp) {

    return (
        <>
            <BTCard className={`${styles.cardContainer} ${className}`} style={(minHeight) ? { minHeight: minHeight } : {}}>
                {children}
            </BTCard>
        </>
    )
}