import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Content from '../../content';
import styles from './utilityBar.module.scss';

interface Props {
    children?: JSX.Element | JSX.Element[];
}
export default function UilityBar({ children }: Props) {

    return (
        <>
            <Content.PanelHeader className={styles.compContainer}>
                <Col>
                    <Row>
                        <Col className='d-flex justify-content-end'>{children}</Col>
                    </Row>
                </Col>
            </Content.PanelHeader>
        </>
    )
}