import React from "react";
import { default as GridComp } from "./grid";
import Header from "./header";
import Table from "./table";
import Column from "./column";
import Footer from "./footer";
import Row from "./row";
import Cell from "./cell";
import ColumnSelector from './column-selector';
import { useDataGridStore } from "./_Store/Store";
import { IGrid, IDataGridComp } from "./interfaces";

//Grid sub components are added as methods to main Grid comp to allow for dot syntax initialization
const Grid: IDataGridComp<IGrid> = (props) => <GridComp {...props} />;
Grid.Header = Header;
Grid.Table = Table;
Grid.Column = Column;
Grid.ColumnSelector = ColumnSelector;
Grid.Row = Row;
Grid.Cell = Cell;
Grid.Footer = Footer;
Grid.useState = useDataGridStore;

export default Grid;
