import React from 'react';
import { Dropdown } from 'react-bootstrap';
import CustomToggle from './custom-toggle';
import { InputField, TimePicker } from '../../atoms';
import InputDropdown from '../input-dropdown';
import styles from './timeSelector.module.scss';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import { ITimeSelector } from './interfaces';
import { useNonInitialEffect } from '../../..';

interface Props {
    defaultValue?: { value: string, text: string };
    disabled?: boolean;
    format?: 12 | 24;
    interval?: 15 | 30;
    placeHolder?: string;
    readOnly?: boolean;
    className?: string;
    onSelect?: React.Dispatch<React.SetStateAction<{
        text: string;
        value: string;
    }>>;
    width?: string;
    minWidth?: string;
    size?: 'sm' | 'lg';
    hideIcon?: boolean;
}

export default function TimeSelector({
    defaultValue,
    placeHolder,
    disabled,
    readOnly,
    format,
    interval,
    onSelect,
    width,
    minWidth,
    size,
    className,
    hideIcon
}: Props) {

    const [activeOption, setActiveOption] = React.useState<{ value: string, text: string }>((defaultValue) ? defaultValue : { text: '', value: '' });

    useNonInitialEffect(() => {        
        if(defaultValue)
        {
            if(defaultValue.value !== activeOption.value && defaultValue.text !== activeOption.text){
                let _activeOption : ITimeSelector.MenuItem = { value : defaultValue.value, text : defaultValue.text  } ;
                setActiveOption(_activeOption);
            }
        }
    }, [defaultValue]);

    return (
        <>
            <Dropdown className={className} style={{ width: (width) ? width : 'auto', minWidth: (minWidth) ? minWidth : 'none' }}>
                <Dropdown.Toggle as="div">
                    <InputField
                        icon={(!hideIcon) ? <i className="fas fa-clock"></i> : undefined}
                        size={size}
                        value={activeOption?.text}
                        as={'select'}
                        readOnly={readOnly}
                        disabled={disabled}
                    >
                        <option hidden>{activeOption?.text || placeHolder || 'Choose a Time'}</option>
                    </InputField>
                </Dropdown.Toggle>
                <InputDropdown className={styles.compContainer} withInputIcon={(hideIcon) ? false : true}>
                    <TimePicker
                        defaultValue={defaultValue}
                        interval={interval}
                        format={format}
                        onChange={(option: { value: string, text: string }) => {
                            setActiveOption(option);
                            if (onSelect) {
                                onSelect(option);
                            }
                        }}
                    />
                </InputDropdown>
            </Dropdown>
        </>
    )
}