/*
    Table
    provides general table markup for columns and rows to be embedded
*/
import React, { useLayoutEffect, useRef } from "react";
import { Table } from "react-bootstrap";
import { useDataGridStore } from "../_Store";
import { ITable } from "../interfaces";
import { useNonInitialEffect, useResize } from '../../../../hooks';
import THead from './thead';
import TBody from './tbody';
import styles from './table.module.scss';

function GridTable({ children, className }: ITable) {

  //Refs for Table and TBody elements
  const tableRef = useRef<any>(null);
  const tableBodyRef = useRef<any>(null);

  //Store Items and Subscribe Methods
  const [
    { gridHeight, activeGridPage, ExpandableRow, scrollTrigger, liveRender, renderedTableData, tableData, totalDataCount, columnTitleState, loadingState, gridRef, loadMoreDataRef, stickyColumns },
    Store, Util
  ] = useDataGridStore();

  //get width for LoadingData
  const { width } = useResize(gridRef);

  function isElementInViewport(el: any) {
    if (el !== null) {
      var rect = el.getBoundingClientRect();
      return rect.bottom < window.innerHeight;
    }
    return false
  }

  //handles scroll events when grid tbody is scrolled (used for infinite scrolling feature)
  const handleScroll = (e: any) => {
    if (!(loadingState)) {
      let element = e.target;
      let isScrollingSpinnerInViewPort = (loadMoreDataRef !== null) ? isElementInViewport(loadMoreDataRef.current) : false;
      let currentRowCount = tableData.length;
      let pageAmount = renderedTableData.length - 1;
      if (isScrollingSpinnerInViewPort) {
        if (liveRender) {
          if (!(currentRowCount === totalDataCount || currentRowCount > totalDataCount)) {
            if (activeGridPage < pageAmount) {
              Store.update(Util.actions.updateActiveGridPage, activeGridPage + 1);
            }
            if (activeGridPage === pageAmount) {
              //makes sure grid doesnt refresh when infinite scrolling
              Store.update(Util.actions.setShouldDataRefresh, false);
              scrollTrigger();
            }
          }
        }
      }
      else if (element.scrollTop === 0) {
        if (activeGridPage !== 0) {
          tableBodyRef.current.scrollBy(0, 200);
          Store.update(Util.actions.updateActiveGridPage, activeGridPage - 1);
        }
      }
    }
  }

  //updates tableRef in store so it can be shared to other components like the pdf export
  useLayoutEffect(() => {
    Store.update(Util.actions.updateGridTableRef, tableRef);
  }, [tableRef]);

  //updates tBodyRef in store so it can be shared to other components like infiniteScroll settings
  useLayoutEffect(() => {
    Store.update(Util.actions.updateGridTBodyRef, tableBodyRef);
  }, [tableBodyRef]);

  //make sure the activeGridPage is incremented when the renderTableData lengh changes
  useNonInitialEffect(() => {
    if (liveRender) {
      if (activeGridPage < (renderedTableData.length - 1)) {
        Store.update(Util.actions.updateActiveGridPage, activeGridPage + 1);
      }
    }
  }, [renderedTableData.length]);


  return (
    <>
      <div
        className={styles.tableContainer}
        style={{
          maxHeight: `${gridHeight}`,
          overflowX: (renderedTableData.length === 0 || tableData.length === 0 || loadingState === true) ? 'hidden' : 'auto'
        }}
        ref={tableBodyRef}
        onScroll={handleScroll}
      >
        <Table
          ref={tableRef}
          striped={ExpandableRow ? false : true}
          bordered
          className={`${styles.table} ${className ? className : ""}`}
        >
          <THead
            stickyColumns={stickyColumns}
            columnTitleState={columnTitleState}
          >
            {children}
          </THead>
          <TBody
            width={width}
            expandableRow={ExpandableRow}
          />
        </Table>
      </div>
    </>
  );
}

export default React.memo(GridTable);
