import React, { useEffect, useRef } from "react";
import "./_modal-basic.scss";

interface Props {
  id?: string;
  small?: boolean;
  titleIcon?: JSX.Element;
  title?: string;
  submitAction?: any;
  modalState?: any;
  submitBtn?: boolean;
  children?: JSX.Element | JSX.Element[];
}

function Modal(props: Props) {
  //prop variables
  const id = props.id;
  const small = props.small;
  const titleIcon = props.titleIcon;
  const title = props.title;
  const submitAction = props.submitAction;
  const modalState = props.modalState;
  const submitBtn = props.submitBtn;

  //ref
  const modal = useRef<HTMLDivElement>(null);
  const modalBackdrop = useRef<HTMLDivElement>(null);
  const saveBtn = useRef<HTMLButtonElement>(null);

  //handlers
  const getScrollbarWidth = () => {
    // Creating invisible container
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll"; // forcing scrollbar to appear
    (outer.style as any).msOverflowStyle = "scrollbar"; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement("div");
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    (outer.parentNode as HTMLDivElement).removeChild(outer);

    return scrollbarWidth;
  };
  const handleFadeOut = () => {
    (modal.current as HTMLDivElement).classList.remove("modal-fadeIn");
    (modalBackdrop.current as HTMLDivElement).classList.remove("modal-fadeIn");
  };

  const handleCloseModal = () => {
    modalState.handler("close", modalState.setState, modal.current);
    // document.body.classList.remove("modal-open");
    // document.body.classList.remove("pr-3");
    // document.body.style.paddingRight = "0px";
  };

  const handleSaveBtnActions = () => {
    let btn = saveBtn.current;
    let btnIcon = (btn as HTMLButtonElement).childNodes[0];
    let shouldSubmit = submitAction();

    if (shouldSubmit !== false) {
      (btnIcon as HTMLElement).classList.remove("fa-save");
      (btnIcon as HTMLElement).classList.add("fa-check");
      (btn as HTMLButtonElement).classList.remove("btn-primary");
      (btn as HTMLButtonElement).classList.add("btn-success");

      setTimeout(() => {
        handleFadeOut();
      }, 300);
      setTimeout(() => {
        //submitAction();
        handleCloseModal();
      }, 350);
    }
  };

  const handleCloseBtnActions = () => {
    handleFadeOut();
    setTimeout(() => {
      handleCloseModal();
    }, 100);
  };

  useEffect(() => {
    if (modal.current !== null) {
      if (modalState.state === "open") {
        modal.current.classList.add("modal-fadeIn");
        (modalBackdrop.current as HTMLDivElement).classList.add("modal-fadeIn");
        // document.body.classList.add("modal-open");
        // document.body.style.paddingRight = getScrollbarWidth() + "px";
      }
    }
  }, [modalState]);

  //conditional component render
  if (modalState.state === "close") {
    return null;
  } else {
    return (
      <div className="modal-basic">
        <div ref={modalBackdrop} className={`modal-backdrop`}>
          <div
            ref={modal}
            id={id}
            className={`modal-basic-container pr-0 d-block`}
            onClick={() => {
              handleCloseBtnActions();
            }}
          >
            <div
              className={
                small
                  ? "modal-dialog modal modal-dialog-centered"
                  : "modal-dialog modal-lg modal-dialog-centered"
              }
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    {titleIcon}&nbsp;&nbsp;{title}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => {
                      handleCloseBtnActions();
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body pb-4">{props.children}</div>
                <div className="modal-footer">
                  {submitBtn !== false ? (
                    <button
                      ref={saveBtn}
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        handleSaveBtnActions();
                      }}
                    >
                      <i className="fas fa-save"></i>&nbsp;&nbsp;Save
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
