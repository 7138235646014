/*
    Protrans React UI Store
    Store extension for Treble-GSM store, so the Protrans React UI can use the library's store items.
*/

import { createStore, TUseTreble, useTreble } from "treble-gsm";
import StoreConfig from "./extend-store/store-config";
import StoreCore from "./extend-store/store-core";
import StoreComps from './extend-store/store-comps';
import StoreLogin from './extend-store/store-login';
import { TrebleFetchStore } from 'treble-fetch';
import { ReactRouterStore } from 'react-router-treble';
import { TStore, IStoreItems, IStoreActions } from './interfaces';

const Store = createStore([], {
  extendStore: [ReactRouterStore, TrebleFetchStore, StoreConfig, StoreCore, StoreComps, StoreLogin]
});

const useAppStore = () => useTreble() as TUseTreble<IStoreItems, IStoreActions>;

export type { TStore };
export { useAppStore };
export default Store;
