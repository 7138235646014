import React from 'react';
import { ModalBasic } from '../../molecules';
import Sections from './sections';
import { useSettingsModal } from '../../../hooks';
import styles from './settingsModal.module.scss';

export default function SettingsModal() {

    const modal = useSettingsModal();

    const list = [
        {
            text: 'Preferences',
            key: 'settings1'
        }
    ]

    return (
        <>
            <ModalBasic
                centered
                show={modal.isOpen}
                title={<h5 className='mb-0'><i className="fas fa-cogs pr-2"></i>Settings</h5>}
                onHide={() => modal.close()}
            >
                <ModalBasic.SideBar>
                    <ModalBasic.SideBarNav navItems={list} />
                </ModalBasic.SideBar>
                <ModalBasic.Body className={styles.body}>
                    <Sections />
                </ModalBasic.Body>
            </ModalBasic>
        </>
    )
}