import React from "react";
import { useDataGridStore, TStore } from "../../_Store";
import styles from './errorResults.module.scss';

interface Props {
  width: number
}

function ErrorResults({ width }: Props) {
  const [
    { fallBackGridHeight, fetchStatusCode },
  ] = useDataGridStore() as TStore;


  return (
    <>
      <tr>
        <td
          className={styles.errorResults}
          style={{ height: fallBackGridHeight }}
        >
          <div
            className={`${styles.icons} ${(width > 0) ? 'd-flex' : 'd-none'} flex-column align-items-center justify-content-center`}
            style={{ height: "100%", width: `${width + 'px'}` }}
          >
            <i className="fad fa-exclamation-triangle text-danger"></i>
            <h4 className="pt-3 text-danger">Something Went Wrong!</h4>
            <div className='d-flex justify-content-center' style={{ width: '75%' }}>
              <p className="text-danger text-center">Fetch Error: {fetchStatusCode?.toString()}</p>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}

export default React.memo(ErrorResults);
