import React from 'react';
import RemoveGridItem from '../../../molecules/remove-grid-item';
import useDataGrid from '../../data-grid/use-data-grid';

export default function RemoveRow() {

    const grid = useDataGrid();
    const onRemove = (itemsToBeRemoved: { ptrui_cacheKey: number, [key: string]: any }[]) => {
        grid.removeCachedTableData(itemsToBeRemoved);
        grid.unSelectAllRows();
        grid.resetActiveColumnSort();
        grid.resetGridPosition();
        grid.resetSearchQueries();
    }

    return (
        <>
            <RemoveGridItem
                small
                label={'Delete'}
                modalSize={'md'}
                itemsToRemove={grid.selectedRows || []}
                onRemove={onRemove}
                modalText={
                    <>
                        Are you sure you want to <strong>delete {grid.selectedRows?.length} {(grid.selectedRows?.length > 1) ? 'rows' : 'row'}</strong> ?
                    </>
                }
            />
        </>
    )
}