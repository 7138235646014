import React from 'react';
import styles from './pageSidebarMobile.module.scss';

interface Props {
    open: boolean;
    children: JSX.Element | JSX.Element[];
}

export default function PageSidebarMobile({ open, children }: Props) {

    return (
        <>
            <div className={`${styles.compContainer} ${(open) ? styles.open : ''}`}>
                {children}
            </div>
        </>
    )
}