import React from 'react';
import { IIcon } from '../interfaces';

function FilterReset({ onClick, type, color, className, styles }: IIcon.Props) {

    return (
        <span>
            <i className={`fa${type ? type?.substr(0, 1) : "s"} fa-filter ${className ?? ""} text-${color}`} onClick={onClick} style={styles}> </i>
            <i className={`fa${type ? type?.substr(0, 1) : "s"} fa-redo-alt ${className ?? ""} text-${color}`} onClick={onClick} style={{ ...styles }}> </i>
        </span>

    )
}

export default FilterReset;