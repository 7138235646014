import React from 'react';
import { Card } from 'react-bootstrap';

interface Props {
    children?: JSX.Element | JSX.Element[],
}

function InfoCardFooter({ children }: Props) {

    return (

        <Card.Footer>
            {children}
        </Card.Footer>
    );
}

export default InfoCardFooter;





