import { createStore } from "treble-gsm";
import { calcGridHeight } from "../middleware";

export interface IUIStore {
  gridRef: any;
  gridTableRef: any;
  gridTBodyRef: any;
  gridHeight: string;
  fallBackGridHeight: string;
  loadingState: boolean;
  columnWidths: {
    target: string;
    width: string | "auto";
  }[];
  gridPagingOptions: {
    page: boolean;
    size: number;
    groupSize: number;
  };
  activeGridPage: number;
  CustomRow: null | any;
  customRowProps: { [key: string]: any };
  columnFields: {
    field: string;
    title: string;
    dataType: 'string' | 'number' | 'boolean' | undefined;
    enabled: boolean;
    trebleKey: number;
  }[];
  ExpandableRow: any;
  expandableCheck: (item: any) => boolean;
  exports: {
    pdf: boolean;
    excel: boolean;
    options: boolean;
    customOptions: {
      type: 'excel' | 'pdf',
      label: string,
      onClick: () => void
    }[] | boolean
  };
  scrollTrigger: () => void | boolean;
  placeholderRowState: boolean;
  columnTitleState: boolean;
  loadMoreDataRef: any;
  renderMoreDataRef: any;
  footerColumnSelector: boolean;
  stickyColumns: boolean;
  theadRef: any;
  selectedRows: any[];
  isClientRenderDisabled: boolean;
  isPageEnd: boolean;
  onBottomScroll: (meta: {
    activePage: number,
    isPageEnd: boolean
  }) => void | null;
  onRefresh?: () => void;
  customItemProps: {
    [key: string]: any
  }
}

const actionKeys = {
  ['updateGridRef']: 'updateGridRef',
  ['updateGridTableRef']: 'updateGridTableRef',
  ['updateGridTBodyRef']: 'updateGridTBodyRef',
  ['updateGridHeight']: 'updateGridHeight',
  ['calculateFallBackGridHeight']: 'calculateFallBackGridHeight',
  ['updateLoadingState']: 'updateLoadingState',
  ['setColumnWidths']: 'setColumnWidths',
  ['updateGridPagingOptions']: 'updateGridPagingOptions',
  ['updateActiveGridPage']: 'updateActiveGridPage',
  ['createCustomRow']: 'createCustomRow',
  ['updateCustomRowProps']: 'updateCustomRowProps',
  ['createColumnFields']: 'createColumnFields',
  ['createExpandableRow']: 'createExpandableRow',
  ['setExports']: 'setExports',
  ['setScrollTriggerFunction']: 'setScrollTriggerFunction',
  ['setPlaceholderRowState']: 'setPlaceholderRowState',
  ['setColumnTitleState']: 'setColumnTitleState',
  ['setColumnState']: 'setColumnState',
  ['setExpandableCheck']: 'setExpandableCheck',
  ['setDebounceFilterTime']: 'setDebounceFilterTime',
  ['updateLoadMoreDataRef']: 'updateLoadMoreDataRef',
  ['setRenderMoreDataRef']: 'setRenderMoreDataRef',
  ['updateTheadRef']: 'updateTheadRef',
  ['showFooterColumnSelector']: 'showFooterColumnSelector',
  ['setStickyColumnState']: 'setStickyColumnState',
  ['setIsOnRowSelect']: 'setIsOnRowSelect',
  ['setSelectedRows']: 'setSelectedRows',
  ['setIsClientRenderDisabled']: 'setIsClientRenderDisabled',
  ['updateIsPageEnd']: 'updateIsPageEnd',
  ['setOnBottomScroll']: 'setOnBottomScroll',
  ['setOnRefresh']: 'setOnRefresh',
  ['setCustomItemProps']: 'setCustomItemProps'
}

export type TUIActions = typeof actionKeys;

const UIStore = createStore([
  {
    action: actionKeys.updateGridRef,
    state: {
      gridRef: null,
    },
  },
  {
    action: actionKeys.updateGridTableRef,
    state: {
      gridTableRef: null,
    },
  },
  {
    action: actionKeys.updateGridTBodyRef,
    state: {
      gridTBodyRef: null
    }
  },
  {
    action: actionKeys.updateGridHeight,
    state: {
      gridHeight: "400px",
    },
  },
  {
    action: actionKeys.calculateFallBackGridHeight,
    state: {
      fallBackGridHeight: "",
    },
    features: {
      process: (data) => calcGridHeight(data),
    },
  },
  {
    action: actionKeys.updateLoadingState,
    state: {
      loadingState: true,
    },
  },
  {
    action: actionKeys.setColumnWidths,
    state: {
      columnWidths: [],
    },
  },
  {
    action: actionKeys.updateGridPagingOptions,
    state: {
      gridPagingOptions: {
        page: false,
        size: 100,
        groupSize: 15,
      },
    },
  },
  {
    action: actionKeys.updateActiveGridPage,
    state: {
      activeGridPage: 0,
    },
  },
  {
    action: actionKeys.createCustomRow,
    state: {
      CustomRow: null,
    },
  },
  {
    action: actionKeys.updateCustomRowProps,
    state: {
      customRowProps: {},
    },
  },
  {
    action: actionKeys.createColumnFields,
    state: {
      columnFields: [],
    },
    features: {
      keys: true,
    },
  },
  {
    action: actionKeys.createExpandableRow,
    state: {
      ExpandableRow: false,
    },
  },
  {
    action: actionKeys.setExports,
    state: {
      exports: {
        pdf: false,
        excel: false,
        options: false,
        customOptions: false
      },
    },
  },
  {
    action: actionKeys.setScrollTriggerFunction,
    state: {
      scrollTrigger: false
    }
  },
  {
    action: actionKeys.setPlaceholderRowState,
    state: {
      placeholderRowState: false
    }
  }, {
    action: actionKeys.setColumnTitleState,
    state: {
      columnTitleState: true
    }
  },
  {
    action: actionKeys.setColumnState,
    state: {
      columnState: []
    }
  },
  {
    action: actionKeys.setExpandableCheck,
    state: {
      expandableCheck: (value: any) => value
    }
  },
  {
    action: actionKeys.setDebounceFilterTime,
    state: {
      debounceFilterTime: 400
    }
  },
  {
    action: actionKeys.updateLoadMoreDataRef,
    state: {
      loadMoreDataRef: null
    }
  },
  {
    action: actionKeys.setRenderMoreDataRef,
    state: {
      renderMoreDataRef: null
    }
  },
  {
    action: actionKeys.updateTheadRef,
    state: {
      theadRef: null
    }
  },
  {
    action: actionKeys.showFooterColumnSelector,
    state: {
      footerColumnSelector: false
    }
  },
  {
    action: actionKeys.setStickyColumnState,
    state: {
      stickyColumns: true
    }
  },
  {
    action: actionKeys.setIsOnRowSelect,
    state: {
      isOnRowSelect: false
    }
  },
  {
    action: actionKeys.setSelectedRows,
    state: {
      selectedRows: []
    }
  },
  {
    action: actionKeys.setIsClientRenderDisabled,
    state: {
      isClientRenderDisabled: false
    }
  },
  {
    action: actionKeys.updateIsPageEnd,
    state: {
      isPageEnd: false
    }
  },
  {
    action: actionKeys.setOnBottomScroll,
    state: {
      onBottomScroll: undefined
    }
  },
  {
    action: actionKeys.setOnRefresh,
    state: {
      onRefresh: undefined
    }
  },
  {
    action: actionKeys.setCustomItemProps,
    state: {
      customItemProps: undefined
    }
  }
]);

export default UIStore;
