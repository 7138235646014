import { useAppStore } from '../_Store';
import useResponsive from './use-responsive'

const usePageState = () => {

    const [{ ptrui_collapsedPage, ptrui_hasWidgetBar, ptrui_isWidgetBarOpen }, Store, Utils] = useAppStore();
    const respond = useResponsive();
    const collapse = () => Store.update(Utils.actions.ptrui_updateCollapsedPageState, true);
    const expand = () => Store.update(Utils.actions.ptrui_updateCollapsedPageState, false);
    const toggle = () => Store.toggle(Utils.actions.ptrui_updateCollapsedPageState, ptrui_collapsedPage);
    const setHasWidgetBar = (hasWidgetBar: boolean) => Store.update(Utils.actions.ptrui_setHasWidgetbar, hasWidgetBar);
    const setIsWidgetBarOpen = (isOpen: boolean) => Store.update(Utils.actions.ptrui_setIsWidgetBarOpen, isOpen);
    const isCollapsed = ptrui_collapsedPage;
    const isMobile = (!respond.lgDown) ? true : false;
    const hasWidgetBar = ptrui_hasWidgetBar;
    const isWidgetBarOpen = ptrui_isWidgetBarOpen;

    return {
        collapse,
        expand,
        toggle,
        isCollapsed,
        isMobile,
        setHasWidgetBar,
        hasWidgetBar,
        isWidgetBarOpen,
        setIsWidgetBarOpen
    }
}

export default usePageState;