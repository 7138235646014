/*
    Protrans React Core UI Store
    - Holds state for Core UI features
*/
import { createStore } from "treble-gsm";
import { TMessageType } from '../../_components/organisms'

export interface IStoreCore {
  ptrui_signedInState: boolean;
  ptrui_mainMenuState: boolean;
  ptrui_alertMenuData: {
    alertID: number;
    shipmentID: number;
    type: "warning" | "update";
    message: string;
  }[];
  ptrui_alertMenuState: boolean;
  ptrui_inAppMessages: {
    type?: TMessageType;
    messageText: string;
    dismiss?: boolean | number
    trebleKey: number;
  }[];
  ptrui_collapsedPage: boolean;
  ptrui_collapsedPageWidth: string;
  ptrui_appResize: { width: number, height: number };
  ptrui_logoutModalInfo: {
    confirmBtnText: string,
    confirm: () => void,
    cancel: () => void,
    show: boolean,
    redirectTo?: string | boolean
  };
  ptrui_settingsModalInfo: {
    show: boolean;
  };
  ptrui_LanguageModalInfo: {
    show: boolean;
  };
  ptrui_loginPageInfo: {
    form?: () => JSX.Element;
    title?: string | (() => JSX.Element);
  };
  ptrui_isAppMobile: boolean;
  ptrui_globalPageCollapse: boolean;
  ptrui_hasWidgetBar: boolean;
  ptrui_isWidgetBarOpen: boolean;
}

const actionKeys = {
  ['ptrui_updateSignInState']: 'ptrui_updateSignInState',
  ['ptrui_updateMainMenuState']: 'ptrui_updateMainMenuState',
  ['ptrui_updateAlertMenuData']: 'ptrui_updateAlertMenuData',
  ['ptrui_updateAlertMenuState']: 'ptrui_updateAlertMenuState',
  ['ptrui_updateInAppMessages']: 'ptrui_updateInAppMessages',
  ['ptrui_updateCollapsedPageState']: 'ptrui_updateCollapsedPageState',
  ['ptrui_updateCollapsedPageWidth']: 'ptrui_updateCollapsedPageWidth',
  ['ptrui_setAppResize']: 'ptrui_setAppResize',
  ['ptrui_setLogoutModalInfo']: 'ptrui_setLogoutModalInfo',
  ['ptrui_updateIsAppMobile']: 'ptrui_updateIsAppMobile',
  ['ptrui_setloginPageInfo']: 'ptrui_setloginPageInfo',
  ['ptrui_setSettingsModalInfo']: 'ptrui_setSettingsModalInfo',
  ['ptrui_setLanguageModalInfo']: 'ptrui_setLanguageModalInfo',
  ['ptrui_setGlobalPageCollapse']: 'ptrui_setGlobalPageCollapse',
  ['ptrui_setHasWidgetbar']: 'ptrui_setHasWidgetbar',
  ['ptrui_setIsWidgetBarOpen']: 'ptrui_setIsWidgetBarOpen'
}

export type TStoreCoreActions = typeof actionKeys;

const StoreCore = createStore([
  //sign in state
  {
    action: actionKeys.ptrui_updateSignInState,
    state: {
      ptrui_signedInState: false,
    },
  },

  //main menu
  {
    action: actionKeys.ptrui_updateMainMenuState,
    state: {
      ptrui_mainMenuState: false, //handles open and close state
    },
  },

  //alert menu
  {
    action: actionKeys.ptrui_updateAlertMenuData,
    state: {
      ptrui_alertMenuData: [], //populates data cards
    },
  },

  {
    action: actionKeys.ptrui_updateAlertMenuState,
    state: {
      ptrui_alertMenuState: false, //handles open and close state
    },
  },

  //in-app-messages
  {
    action: actionKeys.ptrui_updateInAppMessages,
    state: {
      ptrui_inAppMessages: [], //handles in app messages
    },
    features: {
      keys: true,
    },
  },

  //Sets the collapse state for pages
  {
    action: actionKeys.ptrui_updateCollapsedPageState,
    state: {
      ptrui_collapsedPage: true,
    }
  },

  //Sets the collapse state width for menu bar menu icon and widget width for pages (so they match)
  {
    action: actionKeys.ptrui_updateCollapsedPageWidth,
    state: {
      ptrui_collapsedPageWidth: 250,
    }
  },

  //watches dimensions for app (until I create a module)
  //the watcher for this is found in the Header component
  {
    action: actionKeys.ptrui_setAppResize,
    state: {
      ptrui_appResize: {
        width: 0,
        height: 0
      },
    }
  },

  //logout modal
  {
    action: actionKeys.ptrui_setLogoutModalInfo,
    state: {
      ptrui_logoutModalInfo: {
        confirmBtnText: 'Log Out',
        confirm: () => null,
        cancel: () => null,
        show: false,
        redirectTo: false
      }
    }
  },

  //login page
  {
    action: actionKeys.ptrui_setloginPageInfo,
    state: {
      ptrui_loginPageInfo: {
        form: () => null,
        title: ''
      }
    }
  },

  //settings modal
  {
    action: actionKeys.ptrui_setSettingsModalInfo,
    state: {
      ptrui_settingsModalInfo: {
        show: false
      }
    }
  },

  //settings modal
  {
    action: actionKeys.ptrui_setLanguageModalInfo,
    state: {
      ptrui_LanguageModalInfo: {
        show: false
      }
    }
  },

  {
    action: actionKeys.ptrui_updateIsAppMobile,
    state: {
      ptrui_isAppMobile: false
    }
  },

  //detects if page has widget bar
  {
    action: actionKeys.ptrui_setHasWidgetbar,
    state: {
      ptrui_hasWidgetBar: false
    }
  },

  {
    action: actionKeys.ptrui_setIsWidgetBarOpen,
    state: {
      ptrui_isWidgetBarOpen: true
    }
  }
]);

export default StoreCore;
