import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from '../../../utils';

interface Props {
    className?: string;
    text?: string;
    children?: JSX.Element | JSX.Element[];
    path?: string;
}

export default function ListItemHeader({ className, text, children, path }: Props) {

    return (
        <>
            <Dropdown.Header className={className}>{
                (path) ?
                    <Link to={path}>{(children) ? children : (text) ? text : ''}</Link> :
                    (children) ? children : text
            }</Dropdown.Header>
        </>
    )
}