import React from 'react';
import { Nav } from 'react-bootstrap';
import SideBarNavItem from './nav-item';
import { useModal } from '../../../../hooks';
import styles from './sideBar.module.scss';

interface Props {
    navItems: {
        text: string,
        key: number | string
    }[]
}
export default function SideBarNav({ navItems: modalNavItems }: Props) {

    const { isSideBarCollapsed, updateNavItems, navItems, activeNavIndex } = useModal();

    React.useEffect(() => {
        updateNavItems(modalNavItems);
    }, [modalNavItems]);

    return (
        <>
            <div className={`pt-3 ${styles.sideBarNavContainer} ${(isSideBarCollapsed) ? 'd-none' : ''}`}>
                <Nav activeKey={`link-${activeNavIndex}`} className="flex-column">
                    {
                        navItems?.map((item: { text: string, key?: string | number }, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <SideBarNavItem
                                        eventKey={`link-${index}`}
                                        text={item.text}
                                        textKey={item.key}
                                    />
                                </React.Fragment>
                            )
                        })
                    }
                </Nav>
            </div>
        </>
    )
}