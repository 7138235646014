import React from 'react';
import { Link as RRTLink } from 'react-router-treble';
import styles from './link.module.scss';
interface Props {
    children: React.ReactChild | React.ReactChild[];
    to?: string;
    target?: string;
    external?: boolean;
    className?: string;
    component?: React.ComponentType<any>;
    replace?: boolean;
    innerRef?: React.Ref<HTMLAnchorElement>;
    onClick?: () => void;
    [key: string]: any;
}
export default function Link({ children, to, external, className, onClick, ...props }: Props) {


    return (
        <>
            {
                (to) ?
                    (external) ?
                        <a
                            {...props}
                            onClick={() => (onClick) ? onClick() : null}
                            href={(to) ? to : '/#'}
                            rel="noopener noreferrer"
                            className={`${styles.compContainer} ${(to) ? '' : styles.disabled} ${className}`}

                        >
                            {children}
                        </a> :
                        <RRTLink
                            {...props}
                            onClick={() => (onClick) ? onClick() : null}
                            to={(to) ? to : '/#'}
                            className={`${styles.compContainer} ${(to) ? '' : styles.disabled} ${className}`}
                        >
                            {children}
                        </RRTLink> :
                    <div
                        onClick={() => (onClick) ? onClick() : null}
                        className={`${styles.compContainer} ${(to) ? '' : styles.disabled} ${className}`}
                    >
                        {children}
                    </div>
            }
        </>
    )
}



