import React, { useEffect, useRef } from "react";
import "./_settings-wizard.scss";

interface Props {
  id?: string;
  titleIcon?: JSX.Element;
  title?: string;
  modalState?: any;
  className?: string;
  width?: string;
  children?: JSX.Element | JSX.Element[];
}

function SettingsWizard(props: Props) {
  //prop variables
  const id = props.id;
  const titleIcon = props.titleIcon;
  const title = props.title;
  const modalState = props.modalState;

  //ref
  const modal = useRef<HTMLDivElement>(null);
  const modalBackdrop = useRef<HTMLDivElement>(null);
  // const saveBtn = useRef<HTMLButtonElement>(null);

  //handlers
  const getScrollbarWidth = () => {
    // Creating invisible container
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll"; // forcing scrollbar to appear
    (outer.style as any).msOverflowStyle = "scrollbar"; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement("div");
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    (outer.parentNode as HTMLDivElement).removeChild(outer);

    return scrollbarWidth;
  };
  const handleFadeOut = () => {
    (modal.current as HTMLDivElement).classList.remove("modal-fadeIn");
    (modalBackdrop.current as HTMLDivElement).classList.remove("modal-fadeIn");
  };

  const handleCloseModal = () => {
    modalState.handler("close", modalState.setState, modal.current);
    document.body.classList.remove("modal-open");
    document.body.classList.remove("pr-3");
    document.body.style.paddingRight = "0px";
  };

  const handleCloseBtnActions = () => {
    handleFadeOut();
    setTimeout(() => {
      handleCloseModal();
    }, 100);
  };

  useEffect(() => {
    if (modal.current !== null) {
      if (modalState.state === "open") {
        modal.current.classList.add("modal-fadeIn");
        (modalBackdrop.current as HTMLDivElement).classList.add("modal-fadeIn");
        document.body.classList.add("modal-open");
        document.body.style.paddingRight = getScrollbarWidth() + "px";
      }
    }
  }, [modalState]);

  //conditional component render
  if (modalState.state === "close") {
    return null;
  } else {
    return (
      <div
        className={`settings-wizard ${props.className ? props.className : ""}`}
      >
        <div ref={modalBackdrop} className={`modal-backdrop`}>
          <div
            ref={modal}
            id={id}
            className={`settings-wizard-container pr-0 d-block`}
            onClick={() => {
              handleCloseBtnActions();
            }}
          >
            <div
              className={`modal-dialog modal-dialog-centered`}
              onClick={(e) => e.stopPropagation()}
              style={{ maxWidth: props.width ? props.width : "75vw" }}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {titleIcon}&nbsp;&nbsp;{title}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => {
                      handleCloseBtnActions();
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body p-0 d-flex justify-content-between">
                  {props.children}
                </div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SettingsWizard;
