import React from 'react';
import { Button } from 'react-bootstrap';
import { ModalBasic } from '../../molecules';
import { useLogoutModal } from '../../../hooks';
import styles from './logoutModal.module.scss';

export default function LogoutModal() {

    const logout = useLogoutModal();

    return (
        <>
            <ModalBasic animation={true} backdrop={'static'} show={logout.isOpen} onHide={() => logout.close()} size={'sm'} centered={false}>
                <ModalBasic.Body>
                    <div className={`${styles.bodyIcon} d-flex justify-content-center align-items-center p-4`}>
                        <i className="fad fa-sign-out text-info"></i>
                    </div>
                    <div className='text-center'>
                        <p className='text-center'>Are you sure you want to Log Out?</p>
                    </div>
                </ModalBasic.Body>
                <ModalBasic.Footer className='d-flex justify-content-center'>
                    <div className='d-flex'>
                        <Button variant={'link'} className={'mr-2 text-muted'} onClick={() => { logout.cancel(); logout.close() }}>
                            No Thanks
                        </Button>
                        <Button variant={'info'} onClick={() => logout.confirm()}>
                            {logout.confirmBtnLabel}
                        </Button>
                    </div>
                </ModalBasic.Footer>
            </ModalBasic>
        </>
    )
}