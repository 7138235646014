/*
 Footer
 - provides pagination and row counts
*/
import React from "react";
import Content from '../../content';
import { BtnCounters } from '../../../atoms';
import { ExportBtnExcel, ExportBtnPDF } from "../../../molecules";
import ColumnSelector from '../column-selector';
import useDataGrid from "../use-data-grid";

interface Props {
  children?: JSX.Element | JSX.Element[];
}
export default function Footer({ children }: Props) {

  const grid = useDataGrid();

  return (
    <>
      <Content.PanelFooter>
        <>
          <p className="mb-0"></p>
          {
            (children) ?
              <div className="flex-fill px-3">
                {children}
              </div> : null
          }
          <div className="d-flex align-items-center">

            {
              (grid.exports.pdf) ?
                <ExportBtnPDF
                  className={'mr-3'}
                  iconOnly
                  data={grid.cachedTableData}
                  fields={grid.columnFields}
                  options={(typeof grid.exports.customOptions === 'object') ? grid.exports.customOptions.filter(
                    (item) => {
                      if (item.type === 'pdf') {
                        return item;
                      }
                    }) : undefined
                  }
                /> : null
            }
            {
              (grid.exports.excel) ?
                <ExportBtnExcel
                  className={'mr-3'}
                  iconOnly
                  data={grid.cachedTableData}
                  fields={grid.columnFields}
                  options={(typeof grid.exports.customOptions === 'object') ? grid.exports.customOptions.filter(
                    (item) => {
                      if (item.type === 'excel') {
                        return item;
                      }
                    }) : undefined
                  }
                /> : null
            }
            {
              (grid.isColumnSelector) ?
                <ColumnSelector
                  iconOnly
                  className={'mr-3'}
                /> : null
            }
            <BtnCounters
              isLoading={grid.isLoading}
              error={grid.fetchStatusCode}
              selectedCount={(grid.isOnRowSelect) ? grid.selectedRows.length : undefined}
              fetchedCount={(grid.cachedTableData?.length) ? grid.cachedTableData?.length : undefined}
              totalCount={(typeof grid.totalDataCount === 'number') ? grid.totalDataCount : 0}
            />
          </div>
        </>
      </Content.PanelFooter>
    </>
  );
}
