import React from 'react';
import { useLocation } from 'react-router-treble';
import { useAppStore } from '../_Store';

const useStartUp = () => {

    const [State, Store, Util] = useAppStore();
    const location = useLocation();
    const initialPath = State.ptrui_initialPath;
    const setInitialPath = (path: string) => Store.update(Util.actions.ptrui_setInitialPath, path);

    // //sets initial page that will be navigated to after Auth
    React.useEffect(() => {
        setInitialPath(location.pathname);
    }, []);

    return {
        initialPath,
        setInitialPath
    }

}

export default useStartUp;