import React from 'react';
import { Button } from 'react-bootstrap';
import ModalBasic from '../modal-basic';
import { useNonInitialEffect } from '../../../hooks';
import styles from './createGridItem.module.scss';

interface Props {
    iconOnly?: boolean;
    small?: boolean;
    large?: boolean;
    className?: string;
    label?: string;
    onClick?: () => void;
    disabled?: boolean;
    customForm?: ({ closeModal }: { closeModal: () => void }) => JSX.Element;
    modalSize?: "sm" | "lg" | "md" | "xl";
    onModalOpen?: () => void;
    onModalClose?: () => void;
}
export default function CreateGridItem({ iconOnly, small, large, className, onClick, label, disabled, customForm: CustomFormProp, modalSize, onModalOpen, onModalClose }: Props) {

    const size = (small) ? 'sm' : (large) ? 'lg' : undefined;
    const [show, setShow] = React.useState(false);
    const closeModal = () => setShow(false);
    const CustomForm = (CustomFormProp) ? () => CustomFormProp({ closeModal }) : () => null;
    const CustomFormMemo = React.useMemo(() => CustomForm, []);
    const onHide = () => {
        setShow(false);
        if (onModalClose) {
            onModalClose();
        }
    };
    useNonInitialEffect(() => {
        if (show) {
            if (onModalOpen) {
                onModalOpen();
            }
        }
    }, [show]);

    return (
        <>
            <ModalBasic
                size={(modalSize) ? modalSize : undefined}
                title={(label) ? label : 'Create Item'}
                show={show}
                onHide={() => onHide()}
                centered={false}
            >
                <ModalBasic.Body>
                    <>
                        {
                            (CustomFormProp) ?
                                <CustomFormMemo /> : <p>No Form has been added yet.</p>
                        }
                    </>
                </ModalBasic.Body>
            </ModalBasic>
            {
                (iconOnly) ?
                    <i onClick={onClick} className={`fas cursor fa-filter text-info ${styles.iconOnly} ${className}`}></i> :
                    <Button
                        size={(size === 'sm') ? 'sm' : (size === 'lg') ? 'lg' : undefined}
                        variant={'success'}
                        className={className}
                        onClick={() => setShow(true)}
                        disabled={disabled}
                    >
                        <>
                            <span className={'pr-2'}>{<i className="fas fa-plus-circle text-white"></i>}</span>
                            <span className={'pr-1'}>{(label) ? label : 'Create Item'}</span>
                        </>
                    </Button>
            }
        </>
    )
}