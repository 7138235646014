import React from 'react';
import { useAppStore } from '../_Store';

const useLanguageModal = () => {
    const [{ ptrui_LanguageModalInfo: modalInfo, ptrui_appSettings }, Store, Util] = useAppStore();
    const [renderForm, setRenderForm] = React.useState<JSX.Element>(React.createElement('p', null, 'No Language Setting'));

    const close = () => {
        Store.update(Util.actions.ptrui_setLanguageModalInfo, { ...modalInfo, show: false });
    }

    const open = () => {
        Store.update(Util.actions.ptrui_setLanguageModalInfo, { ...modalInfo, show: true });
    }

    const isOpen = modalInfo?.show;

    React.useEffect(() => {
        ptrui_appSettings?.find((item) => {
            if (item.section === 'language') {
                setRenderForm(item.form);
            }
        });
    }, [ptrui_appSettings]);

    return {
        close,
        open,
        isOpen,
        renderForm
    }
}

export default useLanguageModal;