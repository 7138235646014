import React from 'react';
import IconWithDropdown from '../icon-with-dropdown';
import { useLanguageModal } from '../../../hooks';
import styles from './languageSwitcher.module.scss';

interface Props {
    className?: string;
}

export default function LanguageSwitcher({ className }: Props) {

    const language = useLanguageModal();

    return (
        <>
            <IconWithDropdown icon={<i className={`far fa-language`}></i>} caret={false} toolTipPlacement={'left'} className={`${className}`} text={<>Change Language <br /> Cambiar idioma</>}>
                {language.renderForm}
            </IconWithDropdown>
        </>
    )
}