import React, {useContext} from 'react';
import SideNavContext from '../side-nav-context';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

interface Props{
    children: JSX.Element[] | JSX.Element
}

function SubMenu(props: Props){

    //context
    const sideNavState:{collapsed: boolean} = useContext(SideNavContext);

        return(
            (sideNavState.collapsed !== true) ?
            <Accordion.Collapse eventKey="0" className='side-nav-submenu'>
                <Card.Body>
                    {props.children}
                </Card.Body>
            </Accordion.Collapse>
            :
            <div className='side-nav-submenu py-2'>
                {/* <Dropdown.Item>hi</Dropdown.Item> */}
                {props.children}
            </div>
        );
    }

export default SubMenu;




