import React from 'react';
import { SelectedActions as SelectedActionsMol } from '../../../molecules';
import useDataGrid from '../use-data-grid';

interface Props {
    iconOnly?: boolean;
    className?: string;
}
export default function SelectedActions({ iconOnly, className }: Props) {

    const grid = useDataGrid();
    const count = grid.selectedRows.length || 0;
    const totalCount = (typeof grid.totalDataCount === 'number') ? grid.totalDataCount : 0;

    return (
        <>
            <SelectedActionsMol
                small
                count={count}
                totalCount={totalCount}
                onSelectAllChange={(isChecked: boolean) => {
                    if (isChecked) {
                        grid.selectAllRows();
                        grid.setDisableColumnFields(true);
                    }
                    else {
                        grid.unSelectAllRows();
                        grid.setDisableColumnFields(false);
                    }
                }}
                onShowAllChange={(isChecked: boolean) => {
                    if (isChecked) {
                        grid.resetSearchQueries();
                        grid.resetActiveColumnSort();
                        grid.setDisableColumnFields(true);
                        setTimeout(() => grid.showSelectedRowsOnly(), 0);

                    } else {
                        grid.showAllRows();
                        grid.setDisableColumnFields(false);
                    }
                }}
                onUnselectClick={() => {
                    grid.unSelectAllRows();
                    grid.resetSearchQueries();
                    grid.setDisableColumnFields(false);
                    grid.showAllRows();
                }}
                className={className}
                iconOnly={iconOnly}

            />
        </>
    )
}