import React from 'react';
import { useFetch as useFetchTF } from 'treble-fetch';
import { useAppStore } from '../../_Store';
import { UseFetch } from './interfaces';
import { useNonInitialEffect, useLogin, useInAppMessage } from '../../hooks';
import { isObjectLiteral } from '../../utils';

const useFetch: UseFetch.Fetch = (url, options) => {

    const Message = useInAppMessage();
    const [{ ptrui_baseHeader: baseHeader, ptrui_baseURLTypes: baseURLTypes, ptrui_noAuthRedirect: noAuthRedirect, ptrui_apiFetchCredentials: fetchCredentials }] = useAppStore();
    const { token, isAuth: isAuthUL } = useLogin();
    const baseURL = (options?.baseURLType) ? baseURLTypes[options.baseURLType] : baseURLTypes?.default;
    const apiURL = (options?.baseURLType !== false) ? `${baseURL}${url}` : url;
    const headers = (options?.headers) ? (typeof options?.headers === 'object') ? { ...options?.headers, ...baseHeader, 'Authorization': `Bearer ${token}` } : { ...baseHeader, 'Authorization': `Bearer ${token}` } : undefined;
    const credentialType = fetchCredentials[`${(options?.baseURLType) ? options?.baseURLType : 'default'}`];

    //cancel fetch (this needs to be built into treble fetch)
    // const abortController = new AbortController();
    // const signal = abortController.signal;
    // const cancel = () => abortController.abort.bind(abortController);

    const { response: responseTF, loading, error: errorTF, refresh, isAuth: isFetchAuth } = useFetchTF(apiURL, {
        ...options,
        method: (options?.method) ? options?.method : 'GET',
        headers: headers,
        credentials: credentialType
        //signal: signal
    });

    const [response, setResponse] = React.useState<UseFetch.Response>({ data: { Result: [] } });
    const [responseData, setResponseData] = React.useState<{ Result: any[], mappedResult: any[] }>({ Result: [], mappedResult: [] });
    // const [refreshTrigger, setRefreshTrigger] = React.useState({});
    const error: null | object | string = (response.data?.Error) ? response.data.Error : errorTF;//This needs to check response.error to
    const successAction = options?.successAction;
    const errorAction = options?.errorAction;
    const noAuthAction = options?.noAuthAction;
    const isAuthAction = options?.isAuthAction;
    const loadingMessage = options?.loadingMessage;
    const responseModel = options?.responseModel;

    const createResultFromModel = (result?: any[] | null) => {

        if (responseModel && result) {
            const mappedResult = (Array.isArray(result))
                ? result.map(responseModel)
                : (isObjectLiteral(result)) ? [result].map(responseModel) : undefined;
            return mappedResult;
        }
        return undefined;
    }

    //handles authentication
    const handleIsAuth = (isAuth?: boolean) => {

        //handles no Auth
        if (isAuth === false) {

            if (noAuthAction) {
                noAuthAction();
            }

            else if (noAuthRedirect) {
                window.location.replace(noAuthRedirect);
            }
        }

        //handles post true Auth
        else if (isAuth === true) {
            if (isAuthAction) {
                isAuthAction();
            }
        }
    }

    const handleSideEffects = (loading: boolean, response: { data: { [key: string]: any } }) => {
        //create loading message
        if (loadingMessage) {
            Message.createLoading(loadingMessage, { dismiss: false });
        }

        if (!loading) {

            Message.dismissAll();

            //create new responseData object
            const mapResultTo = (options?.mapResultFrom) ? options?.mapResultFrom : 'Result';
            const resultData = (response.data as any)[mapResultTo] || [];
            setResponseData({
                Result: (resultData) ? (Array.isArray(resultData)) ? resultData : (isObjectLiteral(resultData)) ? [resultData] : [] : [],
                mappedResult: (options?.responseModel) ? createResultFromModel(resultData) || [] : []
            });

            setResponse(response as any);

            //Do successAction if no errors
            if (error === null && successAction !== undefined) {
                successAction();
            }

            //Do errorAction if an error
            else if (error !== null && errorAction !== undefined) {
                errorAction(error);
            }
        }
    }

    //Response actions
    React.useEffect(() => {

        if (options?.initialMount !== false) {
            handleIsAuth(isFetchAuth);
            handleSideEffects(loading, responseTF);
        }

    }, [loading, isFetchAuth, responseTF]);

    //Do not fire on initial mount
    useNonInitialEffect(() => {
        if (options?.initialMount == false) {
            handleIsAuth(isFetchAuth);
            handleSideEffects(loading, responseTF);
        }

    }, [loading, isFetchAuth, responseTF]);

    return {
        loading,
        error,
        response,
        responseData,
        refresh,
        isFetchAuth
    }

}

export default useFetch;