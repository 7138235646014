/*
  Code Block component
  - V2: Cleaned up some of the code
*/
import React, { useEffect, useRef, useState } from "react";
import Prism from "prismjs";
import "prismjs/themes/prism-twilight.css";
import "prismjs/plugins/line-numbers/prism-line-numbers";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-typescript";
import "prismjs/components/prism-css";
import "prismjs/components/prism-scss";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-aspnet";
import styles from './codeBlock.module.scss';
import { ICodeBlock } from "./interfaces";

function CodeBlock({ type, toolBar, children }: ICodeBlock) {
  //refs
  const codeBlockContents = useRef<HTMLElement>(null);

  //state
  const [copyState, setCopyState] = useState("");

  //handlers
  const handleCopyToClipboard = () => {
    let code = (codeBlockContents.current as HTMLElement).innerText;
    let newInput = document.createElement("textarea");
    newInput.value = code;
    document.body.appendChild(newInput);
    newInput.select();
    document.execCommand("copy");
    document.body.removeChild(newInput);
    setCopyState("Copied!");
  };

  const handleCodeColor = (
    type: "Javascript" | "JSX" | "SCSS" | "HTML" | "ASP.Net" | "Typescript"
  ) => {
    const codeColor = {
      Javascript: "language-javascript",
      JSX: "language-javascript",
      SCSS: "language-scss",
      HTML: "language-markup",
      "ASP.Net": "language-aspnet",
      Typescript: "language-typescript",
    };
    return codeColor[type];
  };

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  return (
    <div className="pb-2">
      {toolBar !== false ? (
        <div className={`${styles.title} d-flex justify-content-between align-items-center`}>
          <p className="mb-0 pl-3 py-1">{type}</p>
          <p
            onClick={() => {
              handleCopyToClipboard();
            }}
            className={`${styles.copyCode} mb-0 pr-3 py-1`}
          >
            <i
              title="copy to clipboard"
              className="far fa-copy text-white pr-2"
            ></i>
            {copyState}
          </p>
        </div>
      ) : null}

      <pre
        className={`px-4 py-3 mt-0 ${styles.preContainer} ${toolBar === false ? styles.noToolbar : ""}`}
      >
        <code
          ref={codeBlockContents}
          className={`${type !== undefined ? handleCodeColor(type) : ""}`}
        >
          {children}
        </code>
      </pre>
    </div>
  );
}

export default CodeBlock;
