import React from 'react';
import { Content } from '../../organisms';
import styles from './sectionDropdown.module.scss';
import { AccordionContainer, AccordionToggle, AccordionDropdown } from '../../atoms';
import { useNonInitialEffect } from '../../../hooks';
interface Props {
  sectionTitle?: string,
  open?: boolean,
  counter?: string | number,
  onClickActions?: () => void,
  onClick?: () => void,
  small?: boolean,
  children?: JSX.Element[] | JSX.Element
}

export default function SectionDropdown({ sectionTitle, open, onClickActions, onClick, counter, small, children }: Props) {

  const [isOpen, setIsOpen] = React.useState((open) ? true : false);

  useNonInitialEffect(() => {
    if (isOpen) {
      if (onClickActions) {
        onClickActions();
      }
      if (onClick) {
        onClick();
      }
    }
  }, [isOpen]);

  return (
    <>
      <Content.Panel bodyClassName={`p-0 ${styles.compContainer} ${(isOpen) ? styles.openDropdownBK : ''}`}>
        <AccordionContainer open={open} onClick={() => null}>
          <AccordionToggle eventKey={'1'} className={'bg-none py-3'} caret={false} callback={(eventKey:any)=>{setIsOpen(n=>!n)}}>
            <>
              <h4 className={`mb-0 ${(small) ? styles.smallText : ''} ${(isOpen) ? styles.openDropdownText : ''}`}><span><i className="fas fa-caret-down pr-4"></i>{sectionTitle}</span></h4>
              {
                (counter) ?
                  <h5 className={`mb-0 ${(small) ? styles.smallText : ''} ${(isOpen) ? styles.openDropdownText : ''}`}>
                    <div>
                      <span>Count: {counter}</span>
                    </div>
                  </h5>
                  : null
              }
            </>
          </AccordionToggle>

          <AccordionDropdown eventKey={'1'} >
            <div className='px-3 pb-3'>
              {
                (isOpen) ? children : null
              }
            </div>
          </AccordionDropdown>

        </AccordionContainer>
      </Content.Panel>
    </>
  );
}