import React, {useEffect, useState} from 'react';
import SideNavContext from './side-nav-context';
import Sticky from 'react-stickynode';
import './_side-nav.scss';

interface Props{
    sticky?: boolean,
    children: JSX.Element[] | JSX.Element,
    collapsed?: boolean,
    collapsedBtn?: {
        state: boolean,
        setState: (arg: boolean) => void
    } | boolean
}

function SideNav(props: Props){

    //prop vars
    const sticky = props.sticky;
    const collapsed = props.collapsed;
    const collapsedBtn = props.collapsedBtn;

    //state
    const sideNavState = {
        collapsed: collapsed as boolean
    }
    const [isMobile, setIsMobile] = useState<boolean>(false);//lets componenent know when to hide collapse button for mobile menu

    //handlers
    const handleCollapseState = (collapsedState: boolean, setCollapsedState: (value: boolean) => boolean) => {
        if(collapsedState === true) {
            setCollapsedState(false);
        }
        else{
            setCollapsedState(true);
        }
    }
    //un collapses mobile menu and hides collapse button
    const handleCollapsedOnMobile = (setCollapsedState: (value: boolean) => boolean) => {
        // media query change
        const WidthChange = (mq: any) => {
            if (mq.matches) {
            // window width is at least 991.98px
                setCollapsedState(false);
                setIsMobile(true);
            } else {
            // window width is less than 991.98px
                setIsMobile(false);
            }
        }
        // media query event handler
        // if (matchMedia) {
            let mq = window.matchMedia("(max-width: 991.98px)");
            mq.addListener(WidthChange);
                WidthChange(mq);
        // }
    }
    
    useEffect(() => {
        if(collapsedBtn !== undefined){
            handleCollapsedOnMobile((collapsedBtn as {setState: any}).setState)
        }
    },[collapsedBtn])

    return(
        <div className={`side-nav ${(sideNavState.collapsed) ? 'side-nav-collapsed' : ''}`}>
            {
                (sticky) ? 
                <Sticky>
                    <ul className="pt-2">
                        <div>
                        <SideNavContext.Provider value={sideNavState}>
                            {props.children}
                        </SideNavContext.Provider>
                        </div>
                    </ul>
                    {
                        (isMobile !== true) ?
                            (collapsedBtn) ?
                            <div className='side-nav-collapse-btn'>
                                <div className='side-nav-collapse-btn-container pt-2'>
                                    <p onClick={() => {handleCollapseState((collapsedBtn as {state: boolean}).state, (collapsedBtn as any).setState)}}>
                                        {
                                            (collapsed !== true) ?
                                            <span className='px-3'>
                                                <i className="fas fa-chevron-circle-left"></i>
                                            </span> :
                                            <span className='px-3'>
                                                <i className="fas fa-chevron-circle-right"></i>
                                            </span>
                                        }
                                        {
                                            (collapsed !== true) ?
                                            <span>
                                                Collapse Menu
                                            </span> : null
                                        }
                                    </p>
                                </div>
                            </div> : null : null
                    }
                </Sticky> :
                <div>
                <ul className="pt-2">
                    <div style={{maxHeight: 'calc(100vh - 250px)'}}>
                    <SideNavContext.Provider value={sideNavState}>
                        {props.children}
                    </SideNavContext.Provider>
                    </div>
                </ul>
                    {(isMobile !== true) ?
                    (collapsedBtn) ?
                    <div className='side-nav-collapse-btn'>
                        <div className='side-nav-collapse-btn-container pt-2'>
                            <p onClick={() => {handleCollapseState((collapsedBtn as {state: boolean}).state, (collapsedBtn as any).setState)}}>
                                {
                                    (collapsed !== true) ?
                                    <span className='px-3'>
                                        <i className="fas fa-chevron-circle-left"></i>
                                    </span> :
                                    <span className='px-3'>
                                        <i className="fas fa-chevron-circle-right"></i>
                                    </span>
                                }
                                {
                                    (collapsed !== true) ?
                                    <span>
                                        Collapse Menu
                                    </span> : null
                                }
                            </p>
                        </div>
                    </div> : null : null}
                    </div>
            }            
        </div>
    );
}

export default SideNav;




