/*
    data-grid-context.js
    provides data that needs to be acssessible to entire DataGrid component
*/

import React, {createContext, useContext, useReducer} from 'react';

//DataGrid Context
let defaultValue: object[] = [];
const DataGridContext = createContext(defaultValue as any);

//DataGrid Provider
interface AppProviderInterface{
  reducer: any,
  initialState: object,
  children: object
}
const DataGridProvider = ({reducer, initialState, children}:AppProviderInterface) => {
    return(
        <DataGridContext.Provider value={useReducer(DataGridReducer, DataGridState)}>
            {children}
        </DataGridContext.Provider>
    )
}

//useDataGridContext Hook
const useDataGridContext = () => useContext(DataGridContext);

//DataGrid Reducer Function
interface ActionInterface{
  type: 'setRowData' | 'setNoResults' | 'setFlags' | 'setHeaderFilter' | 'setColumns',
  setRowData: object,
  setNoResults: object,
  setFlags: object,
  setHeaderFilter: object,
  setColumns: object
}
const DataGridReducer = (state: object, action: ActionInterface) => {
    switch (action.type) {
        case 'setRowData':
        return {
          ...state,
          rowData: action.setRowData
        };
        case 'setNoResults':
          return {
            ...state,
            noResults: action.setNoResults
          };
          case 'setFlags':
          return {
            ...state,
            flags: action.setFlags
          };
          case 'setHeaderFilter':
            return {
              ...state,
              headerFilter: action.setHeaderFilter
            };
          case 'setColumns':
            return {
              ...state,
              columns: action.setColumns
            };
      default:
        return state;
    }
  };

//DataGrid state object
interface DataGridStateInterface{
  rowData: [{
    render: boolean,
    flags: {
      status: string
    },
    data: object[]
  }] | object[],
  noResults: boolean,
  flags: boolean,
  headerFilter: boolean,
  columns: [{field: string}][]
}
const DataGridState: DataGridStateInterface = {
  rowData: [],
  noResults: false,
  flags: true,
  headerFilter: false, //RGY filter in data-grid header,
  columns: []
}


export {DataGridProvider, useDataGridContext, DataGridReducer, DataGridState}
export default DataGridContext;