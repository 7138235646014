/*
    In App Messageing Hook
*/
import { useAppStore } from '../_Store';

type TMessageType = "success" | "error" | "warning" | "info" | "update" | "loading" | "default"

export interface IMessageMethodOptions {
    append?: boolean
    dismiss?: boolean | number
}

export interface IMessageHandler {
    (
        action: TMessageType,
        message: string | JSX.Element,
        options?: IMessageMethodOptions
    ): void
}

export interface IMessageMethod {
    (
        message: string | JSX.Element,
        options?: IMessageMethodOptions
    ): void
}



const useInAppMessage = () => {

    const [{ ptrui_inAppMessages }, Store, Util] = useAppStore();

    const handleMessages: IMessageHandler = (action, message, options) => {
        let newMessage = {
            type: action,
            messageText: message,
            dismiss: (action !== 'loading' && options?.dismiss === undefined) ? 5000 : options?.dismiss,
        }
        if (options?.append) {
            Store.append(Util.actions.ptrui_updateInAppMessages, newMessage);
        } else {
            Store.update(Util.actions.ptrui_updateInAppMessages, [newMessage]);
        }
        return null
    }

    const createSuccess: IMessageMethod = (message, options) => handleMessages('success', message, options);
    const createError: IMessageMethod = (message, options) => handleMessages('error', message, options);
    const createWarning: IMessageMethod = (message, options) => handleMessages('warning', message, options);
    const createInfo: IMessageMethod = (message, options) => handleMessages('info', message, options);
    const createUpdate: IMessageMethod = (message, options) => handleMessages('update', message, options);
    const createLoading: IMessageMethod = (message, options) => handleMessages('loading', message, options);
    const dismissAll = () => Store.reset(Util.actions.ptrui_updateInAppMessages);
    const messageData = ptrui_inAppMessages;

    return {
        createSuccess,
        createError,
        createWarning,
        createInfo,
        createUpdate,
        createLoading,
        dismissAll,
        messageData
    }

}

export default useInAppMessage