import React from 'react';

interface Props {
    open: boolean;
    onClick: () => void;
    isDisabled?: boolean;
    className?: string;
}
export default function CaretToggle({ open, onClick, isDisabled, className }: Props) {

    return (
        <>
            <div
                style={{ height: "100%", opacity: (!isDisabled) ? 1 : 0 }}
                className={`cursor ${className}`}
                onClick={onClick}
            >
                <i
                    className={`fas fa-caret-${(open) ? "down" : "right"
                        } pr-3`}
                    style={{ fontSize: ".9rem" }}
                ></i>
            </div>
        </>
    )
}