import React from 'react';

interface ActionInterface {
    (
        e: React.KeyboardEvent<HTMLInputElement>,
        dispatch: (
            object: {
                type: 'updateInfoCardData', 
                updateInfoCardData: object[]
            }
        ) => object,
        infoCardData: {
            render: boolean,
            checked: boolean,
            data: {
                displayID: string,
                Id: number,
                name: string,
                country: string,
                street: string,
                city: string,
                state: string,
                zip: string,
                thumbnailURL?: string
            }
        }[]
    ): void
}

const handleTypedSearch: ActionInterface = (e, dispatch, infoCardData) => {
    let inputString: string = e.currentTarget.value.toLowerCase();
    let infoCardDataArray: object[] = [];

    infoCardData.map((infoCard) => {

        //parameters to search for
        let searchParameters = [
            infoCard.data.displayID,
            infoCard.data.name.toLowerCase(),
            infoCard.data.street.toLowerCase(),
            infoCard.data.city.toLowerCase(),
            infoCard.data.state.toLowerCase(),
            infoCard.data.zip
        ].join('');

        let renderState = () => {
            return searchParameters.includes(inputString)
        }

        let updatedInfoCard = {
            cardID: infoCard.data.displayID,
            data: infoCard.data,
            render: renderState(),
            checked: infoCard.checked
        }
        
        infoCardDataArray.push(updatedInfoCard);
    })
    dispatch({
        type: "updateInfoCardData",
        updateInfoCardData: infoCardDataArray
    })
}

export default handleTypedSearch;