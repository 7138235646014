import React from 'react';
import { useIsInViewport } from '../../../hooks';
import styles from './tableRow.module.scss';

//the class ptrui-grid-row is needed for this so Grids know which rows to style 
//(allows for proper row stripping if expanded rows are present)

interface Props {
    data?: {
        ptrui_cacheKey: number,
        ptrui_sortKey?: number,
        ptrui_isSelected?: boolean,
        [key: string]: any
    };
    children?: JSX.Element | JSX.Element[];
    className?: string;
    isActive?: boolean;
    isStripped?: boolean;
    selectable?: boolean;
    noTextHighlight?: boolean;
    onSelect?: (data: {
        ptrui_cacheKey: number,
        ptrui_sortKey?: number,
        ptrui_isSelected?: boolean,
        [key: string]: any
    }, isActive: boolean, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
}
export default function TableRow({ children, className, isActive, isStripped, data, onSelect, selectable, noTextHighlight }: Props) {

    const [active, setActive] = React.useState(false);
    const [hover, setHover] = React.useState(false);

    React.useLayoutEffect(() => {
        setActive((isActive) ? true : false);
        setHover((isActive) ? false : true);
    }, [isActive]);

    return (
        <>
            <tr
                id={`ptrui_row_${data?.ptrui_cacheKey}`}
                onClick={(e) => {
                    if (selectable && data && onSelect) {
                        onSelect(data, active, e);
                    }
                }}
                className={`${styles.compContainer} ${(noTextHighlight) ? styles.noTextHighlight : ''} ${(isStripped) ? styles.stripped : styles.notStripped} ${(selectable) ? `${(hover) ? styles.hover : ''} ${(active) ? styles.active : ''}` : ''} ${className}`}>
                {children}
            </tr>
        </>
    );
}