/*
    global variables for PTRUI library
*/

//Global PTRUI object
const globals = {

    //Error Message Boilerplate
    errorMessages: {
        title: 'PTRUI Error'
    },

    //Global breakpoints (based off of bootstrap 4 breakpoints)
    breakpoints: {
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200
    }
}

export default globals


