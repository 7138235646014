/*
 Footer
 - provides pagination and row counts
*/
import React from "react";
import ExportBtns from './export-btns';
import DataBtns from './data-btns';
import ColumnSelector from '../column-selector';
import { useDataGridStore } from '../_Store';
import { IFooter } from "../interfaces";
import styles from './footer.module.scss';

export default function Footer({ children, hideFilterCount }: IFooter) {

  const [{ footerColumnSelector }] = useDataGridStore();

  return (
    <>
      <div className={styles.footer}>
        <div className="d-flex justify-content-between align-items-center p-2">
          <p className="mb-0"></p>
          {children ? (
            <div className="flex-fill px-3">
              {children}
            </div>
          ) : null}

          <div className="d-flex align-items-center">
            <ExportBtns />
            {
              (footerColumnSelector) ?
                <ColumnSelector iconOnly /> : null
            }

            <DataBtns hideFilterCount={hideFilterCount} />
          </div>
        </div>
      </div>
    </>
  );
}
