import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { useDataGridStore, TStore } from "../_Store";

interface IDataBtns {
    hideFilterCount?: boolean
}
export default function DataBtns({ hideFilterCount }: IDataBtns) {

    const [
        {
            tableData,
            queriedTableData,
            liveRender,
            loadingState,
            fetchStatusCode,
            totalDataCount,
            searchQueries,
            triggerTableDataFetch,
            resetFilterInputs
        },
        Store,
    ] = useDataGridStore() as TStore;

    //handles filter reset
    const handleFilterReset = () => {
        if (window.confirm("Reset Grid Filters?")) {
            let resetSearchQueries = searchQueries.map(
                (query: {
                    target: string;
                    query: string | { from?: string; to?: string } | null;
                    exact: boolean;
                    type: 'string' | 'dateRange' | 'dateTime';
                }) => {
                    return {
                        target: query.target,
                        query: "",
                        exact: query.exact,
                        type: query.type,
                    };
                }
            );
            Store.update("updateSearchQueries", resetSearchQueries);
            Store.toggle("toggleResetFilterInputs", resetFilterInputs);
        }
    };

    const handleRefreshTableData = () => {
        if (triggerTableDataFetch !== false) {

            triggerTableDataFetch();
        }
    };

    return (
        <>
            <ButtonGroup size="sm" className="ml-2">
                {
                    (liveRender) ?
                        <Button variant="info" onClick={() => null}>
                            <i className="fas fa-file-search pr-1"></i>{" "}
                            {queriedTableData.length}
                        </Button> :
                        (!(hideFilterCount === true)) ?
                            !(searchQueries.length === 0) ?
                                <Button variant="info" onClick={handleFilterReset}>
                                    <i className="fas fa-filter pr-1"></i>{" "}
                                    {queriedTableData.length}
                                </Button> : null : null
                }
                <Button
                    disabled={loadingState}
                    onClick={loadingState ? () => null : () => handleRefreshTableData()}
                    className={`${(fetchStatusCode === 'No Errors Detected')
                        ? "btn-primary"
                        : "btn-danger"
                        }`}
                >
                    <i className="fas fa-database pr-1"></i>
                    {(fetchStatusCode === 'No Errors Detected')
                        ? loadingState
                            ? "Fetching..."
                            : (totalDataCount !== undefined) ? totalDataCount : tableData.length
                        : "Error"}
                </Button>
            </ButtonGroup>
        </>
    )
}