/*
    Protrans React Login
    - Holds state for app authentication
*/

import { createStore } from "treble-gsm";

export interface IStoreLogin {

    ptrui_userInfo:
    | {
        id: number;
        userName: string;
        firstName: string;
        lastName: string;
        email: string;
        profilePic: string;
    } | boolean;
    ptrui_userToken: string;
    ptrui_loginLoadingState: boolean;
    ptrui_isAuth: boolean;
    ptrui_initialPath: string;
}

const actionKeys = {
    ['updateUserInfo']: 'updateUserInfo',
    ['updateUserToken']: 'updateUserToken',
    ['ptrui_updateIsAuth']: 'ptrui_updateIsAuth',
    ['ptrui_updateloginLoadingState']: 'ptrui_updateloginLoadingState',
    ['ptrui_setInitialPath']: 'ptrui_setInitialPath'
}
export type TStoreLoginActions = typeof actionKeys;

const StoreLogin = createStore([

    //userInfo
    {
        action: actionKeys.updateUserInfo,
        state: {
            ptrui_userInfo: false,
        },
        features: {
            //replace with session storage
            //persist: true
        }
    },

    //login token
    {
        action: actionKeys.updateUserToken,
        state: {
            ptrui_userToken: '',
        },
        features: {
            //replace with session storage
            //persist: true
        }
    },

    //login loading state
    {
        action: actionKeys.ptrui_updateloginLoadingState,
        state: {
            ptrui_loginLoadingState: false
        }
    },

    //sets Auth to true or false based on if user authenticates
    {
        action: actionKeys.ptrui_updateIsAuth,
        state: {
            ptrui_isAuth: false
        },
        features: {
            //replace with session storage
            //persist: true 
        }
    },

    //saves initial path if redirected to login so user can be redirected back to page when logged in
    {
        action: actionKeys.ptrui_setInitialPath,
        state: {
            ptrui_initialPath: '/'
        },
        features: {
            check: (data) => {
                if (data.dispatchValue !== '/login') {
                    return true
                } else {
                    return false
                }
            }

        }
    }
]);

export default StoreLogin;
