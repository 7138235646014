import React from "react";
import { TableRow, TableRowExpand, TableCell, TableCellContent } from '../../../atoms';
import useRow from './use-row';
import useDataGrid from "../use-data-grid";

interface Props {
  children?: JSX.Element | JSX.Element[];
  rowData?: {
    [key: string]: any;
    ptrui_cacheKey: number;
    ptrui_sortKey: number;
    ptrui_isSelected: boolean;
  };
}
function Row({ children, rowData }: Props) {

  const grid = useDataGrid();
  const row = useRow();
  //checks to see if row is active (selected)
  const isActive = (grid.selectedRows.find((row) => row.ptrui_cacheKey === rowData?.ptrui_cacheKey)) ? true : false;
  const isRowExpandable = (grid.ExpandableRowComp) ? grid.isRowExpandable(rowData) : false;
  const isStripped = (rowData) ? (rowData?.ptrui_cacheKey / 2 || rowData?.ptrui_cacheKey === 0) ? false : true : false;

  return (
    <>
      {
        (rowData) ?
          <>
            <TableRow
              noTextHighlight={row.disableTextHighlight}
              isActive={isActive}
              isStripped={isStripped}
              selectable={grid.isOnRowSelect}
              onSelect={row.onSelect}
              data={rowData}
            >
              {
                grid.columnFields?.map(
                  ({ field, enabled, trebleKey }: { field: string, enabled: boolean, trebleKey: number }) => {
                    return (
                      <React.Fragment key={trebleKey}>
                        <TableCell
                          width={grid.columnWidths.find((item: any) => item.target === field)?.width}
                          hide={(!enabled) ? true : false}
                          className={row.createCustomCell(field, rowData).className}
                        >
                          <TableCellContent
                            isExpandable={isRowExpandable}
                            isExpanded={row.isExpanded}
                            index={trebleKey}
                            onClick={row.toggleIsExpanded}
                          //data={rowData[field]}
                          //as={row.createCustomCell(field, rowData).Component}
                          >
                          </TableCellContent>
                        </TableCell>
                      </React.Fragment>
                    );
                  }
                )
              }
            </TableRow>

            {
              (grid.ExpandableRowComp) ?
                <TableRowExpand
                  isExpanded={row.isExpanded}
                  customRow={grid.ExpandableRowComp}
                  rowData={rowData}
                /> : null
            }
          </> : null
      }
    </>
  );
}
export default React.memo(Row);
