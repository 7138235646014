/*
 Footer
 - provides pagination and row counts
*/
import React from "react";
import Content from '../../content';
import { BtnCounters } from '../../../atoms';
import { ExportBtnExcel, ExportBtnPDF } from "../../../molecules";
import ExportToPDF from "../export-to-pdf";
import ExportToExcel from "../export-to-excel";
import ColumnSelector from '../column-selector';
import useDataGrid from "../use-data-grid";

interface Props {
  children?: JSX.Element | JSX.Element[];
}
export default function Footer({ children }: Props) {

  const grid = useDataGrid();

  return (
    <>
      <Content.PanelFooter>
        <>
          <p className="mb-0"></p>
          {
            (children) ?
              <div className="flex-fill px-3">
                {children}
              </div> : null
          }
          <div className="d-flex align-items-center">

            {
              (grid.exports.pdf) ? <ExportToPDF iconOnly className={'mr-3'} /> : null
            }
            {
              (grid.exports.excel) ? <ExportToExcel iconOnly className={'mr-3'} /> : null
            }
            {
              (grid.isColumnSelector) ? <ColumnSelector iconOnly className={'mr-3'} /> : null
            }
            <BtnCounters
              isLoading={grid.isLoading}
              error={grid.fetchStatusCode}
              selectedCount={(grid.isOnRowSelect) ? grid.selectedRows.length : undefined}
              filterCount={(grid.tableData?.length) ? grid.tableData?.length : undefined}
              totalCount={(typeof grid.totalDataCount === 'number') ? grid.totalDataCount : (grid.tableData?.length) ? grid.tableData?.length : 0}
            />
          </div>
        </>
      </Content.PanelFooter>
    </>
  );
}
