import React from 'react';
import { default as CardGridComp } from './card-grid';
import InfoCard from './info-card';
import InfoCardFooter from './info-card-footer';
import CardGridHeader from './header';
import CardGridSearch from './search';
import SelectAll from './select-all';
import AlphaFilter from './alpha-filter';
import { CardGridFooter, DataBtns } from './footer';
import { ICardGrid } from './interfaces';

const CardGrid: ICardGrid = (props) => <CardGridComp {...props} />;
CardGrid.Header = CardGridHeader;
CardGrid.Search = CardGridSearch;
CardGrid.Card = InfoCard;
CardGrid.CardFooter = InfoCardFooter;
CardGrid.SelectAll = SelectAll;
CardGrid.AlphaFilter = AlphaFilter;
CardGrid.Footer = CardGridFooter;
CardGrid.Counters = DataBtns;

export default CardGrid;