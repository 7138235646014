import React from "react";
import { default as ClientGridComp } from "./client-grid";
import Header from "./header";
import Table from "./table";
import Column from "./column";
import Footer from "./footer";
import Row from './row';
import Cell from "./cell";
import ColumnSelector from './column-selector';
import ResetFilters from './reset-filters';
import RefreshData from "./refresh-data";
import SelectedActions from './selected-actions';
import AddRow from './add-row';
import CreateRow from './create-row';
import RemoveRow from './remove-row';
import UtilityBar from './utility-bar';
import ExportToExcel from './export-to-excel';
import ExportToPDF from './export-to-pdf';
import { useGrid } from './use-data-grid';
import { GridProps } from './client-grid';

interface IClientGridComp<P> {
    (props: P): JSX.Element;
    Header: typeof Header;
    Table: typeof Table;
    Column: typeof Column;
    ColumnSelector: typeof ColumnSelector;
    ResetFilters: typeof ResetFilters;
    RefreshData: typeof RefreshData;
    SelectedActions: typeof SelectedActions;
    CreateRow: typeof CreateRow;
    RemoveRow: typeof RemoveRow;
    UtilBar: typeof UtilityBar;
    AddRow: typeof AddRow;
    ExportToExcel: typeof ExportToExcel;
    ExportToPDF: typeof ExportToPDF;
    Row: typeof Row;
    Cell: typeof Cell;
    Footer: typeof Footer;
    useGrid: typeof useGrid;
}

//ClientGrid sub components are added as methods to main ClientGrid comp to allow for dot syntax initialization
const ClientGrid: IClientGridComp<GridProps> = (props) => <ClientGridComp {...props} />;
ClientGrid.Header = Header;
ClientGrid.Table = Table;
ClientGrid.Column = Column;
ClientGrid.ColumnSelector = ColumnSelector;
ClientGrid.ResetFilters = ResetFilters;
ClientGrid.RefreshData = RefreshData;
ClientGrid.SelectedActions = SelectedActions;
ClientGrid.AddRow = AddRow;
ClientGrid.CreateRow = CreateRow;
ClientGrid.RemoveRow = RemoveRow;
ClientGrid.ExportToExcel = ExportToExcel;
ClientGrid.ExportToPDF = ExportToPDF;
ClientGrid.UtilBar = UtilityBar;
ClientGrid.Row = Row;
ClientGrid.Cell = Cell;
ClientGrid.Footer = Footer;
ClientGrid.useGrid = useGrid;

export default ClientGrid;
