/*Component Reducer*/

import ReducerInterface from './reducer-interface';

const Reducer: ReducerInterface = (state, action) => {
    switch (action.type) {

      case 'setInfoCardData':
        return {
          ...state,
          infoCardData: action.setInfoCardData
        };

        case 'updateInfoCardData':
          return {
            ...state,
            infoCardData: action.updateInfoCardData
          };

        case 'addToCheckedInfoCardData':
          return {
            ...state,
            checkedInfoCardData: action.addToCheckedInfoCardData
          };

        case 'removeFromCheckedInfoCardData':
          return {
            ...state,
            checkedInfoCardData: action.removeFromCheckedInfoCardData
          };
      
      default:
        return state;
    }
  };

  export default Reducer;