import React from 'react';
import { default as ModalBasicComp } from "./modal";
import { ModalHeader, ModalFooter, ModalBody } from '../../atoms';
import SideBar from './side-bar';
import SideBarNav from './side-bar-nav';
import { useModalStore } from './Store';
import { IModalBasicComp, IModalBasic } from './interfaces';

const ModalBasic: IModalBasicComp<IModalBasic> = (props) => <ModalBasicComp {...props} />;
ModalBasic.Header = ModalHeader;
ModalBasic.Body = ModalBody;
ModalBasic.Footer = ModalFooter;
ModalBasic.SideBar = SideBar;
ModalBasic.SideBarNav = SideBarNav;
ModalBasic.useState = () => useModalStore();

export default ModalBasic;
