import React from 'react';
import { Dropdown } from 'react-bootstrap';
import uniqid from 'uniqid';
import { exportExcel } from '../../../../../../exports';
import { useDataGridStore } from '../../../_Store';
import styles from './excelBtn.module.scss';

export default function ExcelBtn() {

    const [{ tableData, queriedTableData, renderedTableData, exports, columnFields, activeGridPage, liveRender }] = useDataGridStore();

    return (
        <>
            {//Excel exports
                exports?.excel && exports?.options ? (
                    <Dropdown className={`${styles.excelDropdown} pr-0`}>
                        <Dropdown.Toggle variant="link" id={uniqid()} size="sm">
                            <i className="fas fa-file-excel text-success cursor"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Header>Export to Excel</Dropdown.Header>
                            {
                                !liveRender ?
                                    <Dropdown.Item
                                        //href="#/"
                                        onClick={() => setTimeout(() => { exportExcel(tableData, columnFields) }, 0)}
                                    >
                                        ({tableData.length}) All
                                </Dropdown.Item>
                                    : null
                            }

                            {
                                (typeof exports.customOptions !== 'boolean') ?
                                    <>
                                        {
                                            exports.customOptions?.map((option, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {
                                                            (option.type === 'excel') ?
                                                                <Dropdown.Item
                                                                    //href="#/"
                                                                    onClick={() => {
                                                                        setTimeout(() => {
                                                                            option.onClick();
                                                                        }, 0)
                                                                    }}
                                                                >
                                                                    {option.label}
                                                                </Dropdown.Item> : null
                                                        }
                                                    </React.Fragment>
                                                )

                                            })
                                        }
                                    </> : null
                            }

                            {!liveRender ? (
                                <Dropdown.Item
                                    href="#/"
                                    onClick={() =>
                                        setTimeout(() => {
                                            exportExcel(queriedTableData, columnFields);
                                        }, 0)
                                    }
                                >
                                    ({queriedTableData.length}) Filtered
                                </Dropdown.Item>
                            ) : null}
                            {
                                (liveRender && !(exports.customOptions)) ?
                                    <Dropdown.Item
                                        href="#/"
                                        onClick={() =>
                                            exportExcel(
                                                renderedTableData[activeGridPage],
                                                columnFields
                                            )
                                        }
                                    >
                                        ({queriedTableData.length}) Current
                                </Dropdown.Item>
                                    : null
                            }


                        </Dropdown.Menu>
                    </Dropdown>
                ) : exports?.excel ? (
                    <i
                        className="fas fa-file-excel text-success mr-3 cursor"
                        onClick={() => exportExcel(tableData, columnFields)}
                    ></i>
                ) : null
            }
        </>
    )
}