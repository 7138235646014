import React from 'react';
import { TableRowPlaceholder } from '../../atoms';
import { useNonInitialEffect } from '../../../hooks';
import useGridRenderData from './use-grid-render-data';

interface Props {
    as?: any;
    rowProps?: {
        [key: string]: any;
    };
    data?: any[];
    pagedData?: any[][];
    activePage: number;
    pageSize?: number;
    totalDataCount?: number;
    windowing?: boolean;
    onScrollTop?: (firstRowIndex: number) => void;
    onScrollBottom?: (lastRowIndex: number, isPageEnd: boolean) => void;
    isFetchRendering?: boolean;
    onPageEnd?: (isPageEnd?: boolean) => void;
}
export default function GridRenderData({ as, rowProps, data: dataProp, pageSize, activePage, onScrollTop, onScrollBottom, isFetchRendering, totalDataCount: totalDataCountProp, onPageEnd }: Props) {

    const data = (dataProp) ? dataProp : [];
    const totalDataCount = (totalDataCountProp) ? totalDataCountProp : 0;
    const render = useGridRenderData();
    const [renderData, setRenderData] = React.useState((data) ? render.chunkArray(data, pageSize || 15) : []);
    const RenderComp = (as) ? as : null;
    const isPageStart = (activePage === 0) ? true : false
    const isPageEnd = (activePage < (renderData.length - 1)) ? false : true;
    const isTotalCountReached = (data.length < totalDataCount) ? false : true;
    const isFetchingPlaceHolderHidden = (isTotalCountReached && isPageEnd) ? true : false;

    useNonInitialEffect(() => {
        setRenderData((data && pageSize) ? render.chunkArray(data, pageSize) : (data) ? data : [])
    }, [data, pageSize]);

    const scrollTop = () => {
        if (onScrollTop) {
            const current = render.windowData(renderData, activePage || 0);
            const firstRowIndex = current[0]?.ptrui_cacheKey;
            onScrollTop(firstRowIndex);
        }
    }

    const scrollBottom = () => {
        if (onScrollBottom) {
            const current = render.windowData(renderData, activePage || 0);
            const lastRowIndex = current[current.length - 1]?.ptrui_cacheKey;
            onScrollBottom(lastRowIndex, isPageEnd);
        }
    }

    React.useEffect(() => {
        if (onPageEnd) {
            onPageEnd(isPageEnd);
        }
    }, [isPageEnd]);

    return (
        <>
            <TableRowPlaceholder
                hide={isPageStart}
                count={1}
                onView={() => scrollTop()}
            />
            {
                render.windowData(renderData, activePage || 0)?.map((row: { [key: string]: any }) => {
                    return (
                        <React.Fragment key={row.ptrui_cacheKey}>
                            {
                                (RenderComp !== null) ?
                                    <RenderComp rowData={row} /> : null
                            }
                        </React.Fragment>
                    );
                })
            }
            {
                (isFetchRendering) ?
                    <TableRowPlaceholder
                        hide={isFetchingPlaceHolderHidden}
                        label={(isPageEnd) ? 'Fetching...' : 'loading...'}
                        count={1}
                        onView={() => scrollBottom()}
                    /> :
                    <TableRowPlaceholder
                        hide={isPageEnd}
                        label={'loading...'}
                        count={1}
                        onView={() => scrollBottom()}
                    />
            }
        </>
    )
}