import React from 'react';
import useDataGrid from '../use-data-grid';

const useRow = () => {

    const grid = useDataGrid();

    //uses custom cells (these are the Cell comp's children) to rows instead of default cells
    const createCustomCell = (field: string, data: { [key: string]: any }) => {
        const cells = (typeof grid.CustomRowComp === 'function') ? React.Children.map(grid.CustomRowComp({ rowData: data }), (child) => child?.props?.children?.props?.children) : [];
        const matchedCell = cells.filter((item: any) => {
            if (item.props.targetField === field) {
                return item;
            }
        })[0];

        const cellClassNames = matchedCell?.props?.className;

        return {
            Component: matchedCell,
            className: cellClassNames
        };
    };



    return {
        createCustomCell
    }

}

export default useRow;