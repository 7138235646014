import React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-treble';
import { useConfig, useLogin, useStartUp, useResize, usePageState, useResponsive } from '../../hooks';

interface Props {
    children: JSX.Element;
}

export default function StartUp({ children }: Props) {

    const history = useHistory();
    const location = useLocation();
    const Ref = React.useRef(null);
    const config = useConfig();
    const login = useLogin();
    const startup = useStartUp();
    const respond = useResponsive();
    const page = usePageState();
    const dimensions = useResize(Ref);
    const shouldCompRender = (config.isFrontEndLogin) ? (login.isAuth) ? true : false : true;
    const loginURL = (startup.initialPath.length > 1) ? `/login?redirect:${startup.initialPath}` : '/login';

    // watches app resolution
    React.useEffect(() => {
        respond.setRes(dimensions);
    }, [dimensions]);

    //resets page states when navigating
    React.useLayoutEffect(() => {
        if (!page.hasWidgetBar) {
            page.collapse();
        }

        else if (page.hasWidgetBar) {
            if (page.isWidgetBarOpen) {
                page.expand();
            }
            else {
                page.collapse();
            }

        }
    }, [location.pathname, page.hasWidgetBar]);

    //makes sure app redirects to login screen if not authenticated (only for front end login)
    React.useEffect(() => {
        if (!shouldCompRender) {
            history.push(loginURL);
        }
    }, [location.pathname]);

    return (
        <>

            {/** Redirects to login page if not Auth. When Auth it redirects to intial navigated page*/}
            {
                (config.isFrontEndLogin) ?
                    (login.isAuth) ? <Redirect to={startup.initialPath} /> : <Redirect to={loginURL} /> : <Redirect to={startup.initialPath} />
            }

            <div ref={Ref} className={'ptrui-startup-container'}>
                {children}
            </div>
        </>
    )
}