import { useAppStore } from '../_Store';

const useAlertMenu = () => {

    const [{ ptrui_alertMenuState: isMenuOpen, ptrui_mainMenuState: mainMenuState }, Store, Util] = useAppStore();

    const open = () => {
        Store.update(Util.actions.ptrui_updateAlertMenuState, true);
        Store.update(Util.actions.ptrui_updateMainMenuState, false);
    }

    const close = () => {
        Store.update(Util.actions.ptrui_updateAlertMenuState, false);
    }

    const toggle = () => {
        Store.toggle(Util.actions.ptrui_updateAlertMenuState, isMenuOpen);
        if (mainMenuState) {
            Store.update(Util.actions.ptrui_updateAlertMenuState, false);
        }
    }

    return {
        open,
        close,
        toggle,
        isMenuOpen
    }

}

export default useAlertMenu;