import React from 'react';
import { Card } from 'react-bootstrap';
import styles from './panelHeader.module.scss';

interface Props {
    children?: JSX.Element | JSX.Element[];
    className?: string;
}

export default function PanelHeader({ children, className }: Props) {

    return (
        <>
            <Card.Header className={`${styles.header} ${className} d-flex justify-content-between align-items-center`}>
                {children}
            </Card.Header>
        </>
    )
}