import React from 'react';
import { useAppStore } from '../../../_Store';
import styles from './pageSidebar.module.scss';

interface Props {
    children?: JSX.Element | JSX.Element[];
}

export default function PageSidebar({ children }: Props) {


    const [{ ptrui_collapsedPage }] = useAppStore();
    const isPageCollapsed = (ptrui_collapsedPage) ? true : false;

    return (
        <>
            <div className={`${(isPageCollapsed) ? styles.compContainerCollapsed : styles.compContainer}`}>
                {children}
            </div>
        </>
    )
}