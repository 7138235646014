import React, { useState, useEffect } from "react";
import "./_setting-groups.scss";
import Nav from "react-bootstrap/Nav";

//components
import SettingGroupList from "./setting-group-list";

interface Props {
  scm: {
    value: string;
    text: string;
  }[];
  accounting: {
    value: string;
    text: string;
  }[];
  engineering: {
    value: string;
    text: string;
  }[];
  activeSetting: React.Dispatch<React.SetStateAction<string>>;
  userRole: "admin" | "scm" | "engineering" | "accounting";
}

function SettingGroups(props: Props) {
  //props
  const scmSettings = props.scm;
  const accountingSettings = props.accounting;
  const engineeringSettings = props.engineering;
  const setActiveSetting = props.activeSetting;
  const userRole = props.userRole;

  //state
  const [selectedGroup, setSelectedGroup] = useState<string>("");
  const [selectedSetting, setSelectedSetting] = useState<string>("");

  //handlers
  const handleUserRoles = (
    userRole: "scm" | "engineering" | "accounting" | "admin"
  ) => {
    if (userRole === "admin") {
      setSelectedGroup("scm");
    } else {
      setSelectedGroup(userRole);
    }
  };

  const handleDefaultActiveKey = (
    userRole: "scm" | "engineering" | "accounting" | "admin"
  ) => {
    let activeKey = {
      scm: "link-1",
      accounting: "link-2",
      engineering: "link-3",
      admin: "link-1",
    };
    return activeKey[userRole];
  };

  //set the active setting
  useEffect(() => {
    if (selectedSetting !== undefined) {
      setActiveSetting(selectedSetting);
    }
  }, [selectedSetting]);

  useEffect(() => {
    handleUserRoles(userRole);
  }, []);

  return (
    <>
      <div className="settings-wizard-setting-groups">
        <Nav variant="tabs" defaultActiveKey={handleDefaultActiveKey(userRole)}>
          <Nav.Item>
            <Nav.Link
              eventKey={`link-1`}
              onSelect={() => setSelectedGroup("scm")}
              disabled={
                userRole === "scm" || userRole === "admin" ? false : true
              }
            >
              SCM
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey={`link-2`}
              onSelect={() => setSelectedGroup("accounting")}
              disabled={
                userRole === "accounting" || userRole === "admin" ? false : true
              }
            >
              Accounting
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey={`link-3`}
              onSelect={() => setSelectedGroup("engineering")}
              disabled={
                userRole === "engineering" || userRole === "admin"
                  ? false
                  : true
              }
            >
              Engineering
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="settings-wizard-content-nav-group pb-3 pl-0">
          {selectedGroup === "scm" ? (
            <SettingGroupList
              settingsList={scmSettings}
              selectedSetting={setSelectedSetting}
            />
          ) : null}
          {selectedGroup === "accounting" ? (
            <SettingGroupList
              settingsList={accountingSettings}
              selectedSetting={setSelectedSetting}
            />
          ) : null}
          {selectedGroup === "engineering" ? (
            <SettingGroupList
              settingsList={engineeringSettings}
              selectedSetting={setSelectedSetting}
            />
          ) : null}
        </div>
      </div>
      {/*  */}
    </>
  );
}

export default SettingGroups;
