import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import uniqid from 'uniqid';
//import {ClapSpinner} from 'react-spinners-kit';
import { useDataGridContext } from '../data-grid-context';
import { exportTableToExcel, exportTableToPDF } from '../tools';
// import DatePicker from "../../../protrans-react-ui/date-picker";



interface Props {
    header: string,
    actions?: [
        {
            export?: 'excel' | 'pdf'
            icon?: JSX.Element,
            color?: 'red' | 'yellow' | 'blue' | 'green' | 'default',
            link?: string
        }
    ],
    filter?: 'RGY'
    children: JSX.Element | JSX.Element[]
}

function DataGridContainer(props: Props) {
    //context
    type AppContextType = [
        {
            rowData: {
                render: boolean,
                flags: { status: string }
            }[],
            noResults: boolean,
            headerFilter: boolean
        },
        ({ }) => object
    ];
    const [{ rowData, noResults, headerFilter }, dispatch]: AppContextType = useDataGridContext();

    //state
    const [updateRowCount, setUpdateRowCount] = useState<string | number>('--');
    const [lateRowCount, setLateRowCount] = useState<string | number>('--');
    const [onTimeRowCount, setOnTimeRowCount] = useState<string | number>('--');
    const [filterRowCount, setFilteredRowCount] = useState<string | number>('--');

    //props
    const header = props.header;
    const actions = props.actions;
    const filter = props.filter;

    //refs
    const tableRef = useRef<HTMLTableElement>(null);


    //handlers
    const handleIconColor = (value: string) => {
        switch (value) {
            case 'red': {
                return 'text-danger';
            }
            case 'yellow': {
                return 'text-warning';
            }
            case 'blue': {
                return 'text-info';
            }
            case 'green': {
                return 'text-success';
            }
            default: {
                return '';
            }
        }
    }


    const handleCounts = (dataArray: { render: boolean, flags: { status: string } }[]) => {
        //filter count
        let filterArray: object[] = [];
        let rowDataArray = dataArray;


        rowDataArray.map((row) => {
            if (row.render === true) {
                return (
                    filterArray.push(row)
                )
            }
            else {
                return false
            }
        });
        let filterCount: number = filterArray.length;
        setFilteredRowCount(filterCount);

        //other counts
        let updateArray = [];
        let lateArray = [];
        let onTimeArray = [];
        rowDataArray.map((row) => {
            let status = (row.flags !== undefined) ? row.flags.status : '';
            switch (status) {
                case 'update':
                    return updateArray.push(row);
                case 'late':
                    return lateArray.push(row);
                case 'on-time':
                    return onTimeArray.push(row);
                default:
                    return false;
            }
        });
        let updateCount: number = updateArray.length;
        let lateCount: number = lateArray.length;
        let onTimeCount: number = onTimeArray.length;
        setUpdateRowCount(updateCount);
        setLateRowCount(lateCount);
        setOnTimeRowCount(onTimeCount);
    }

    const handleFilterBar = (sortByInput: string | null) => {
        let sortBy = sortByInput;

        let sortRowData = () => {
            let rowDataArraySorted: object[] = [];
            rowData.map((row: any) => {
                if (sortBy === null) {
                    row.render = true;
                    return rowDataArraySorted.push(row);
                }
                else if (row.flags.status === sortBy as string) {
                    row.render = true;
                    return rowDataArraySorted.push(row);
                }
                else {
                    row.render = false;
                    return rowDataArraySorted.push(row);
                }
            })
            return rowDataArraySorted;
        }

        dispatch({
            type: 'setRowData',
            setRowData: sortRowData()
        })
    }

    useEffect(() => {
        handleCounts(rowData);
    }, [rowData]);

    // const [selectedDate, setSelectedDate] = useState(new Date().toString());



    return (
        <div className='col-12 mt-3 pt-3'>
            <div className="data-grid-container">
                {
                    (header) ?
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">{header}</h5>
                            <div className="d-flex">
                                {/* <div className='pr-2'>
                                <DatePicker value={selectedDate} selectedDate={setSelectedDate} />
                            </div> */}
                                {
                                    (actions) ?
                                        <div className="d-flex pr-2">
                                            <div className='pr-2'></div>
                                            {
                                                actions.map(data =>
                                                    (data.export === 'excel') ?
                                                        <p className={`d-flex align-items-center mb-0 px-2 text-success`} key={uniqid()} style={{ cursor: 'pointer' }} onClick={() => exportTableToExcel((tableRef.current as HTMLElement), header)} title='export to excel'><i className="fas fa-file-excel"></i></p> :
                                                        (data.export === 'pdf') ?
                                                            <p className={`d-flex align-items-center mb-0 px-2 text-danger`} key={uniqid()} style={{ cursor: 'pointer' }} onClick={() => exportTableToPDF((tableRef.current as HTMLElement), header)} title='export to pdf'><i className="fas fa-file-pdf"></i></p> :
                                                            <p className={`d-flex align-items-center d-flex align-items-center mb-0 px-2  ${handleIconColor(data.color as string)}`} key={uniqid()}><Link to={data.link as string}>{data.icon as object}</Link></p>
                                                )
                                            }
                                            {
                                                (headerFilter === true) ?
                                                    <div className='border-left ml-2 d-flex align-items-center pl-2'>
                                                        <div className="btn-group pl-2" role="group">
                                                            {
                                                                (filter === 'RGY') ?
                                                                    <>
                                                                        {/* RGY Filter */}
                                                                        <button type="button" className="btn btn-warning" onClick={() => handleFilterBar('update')}><small className='mb-0'>Update:<span className='pl-2'>{updateRowCount}</span></small></button>
                                                                        <button type="button" className="btn btn-danger" onClick={() => handleFilterBar('late')}><small className='mb-0'>Late:<span className='pl-2'>{lateRowCount}</span></small></button>
                                                                        <button type="button" className="btn btn-success" onClick={() => handleFilterBar('on-time')}><small className='mb-0'>On Time:<span className='pl-2'>{onTimeRowCount}</span></small></button>
                                                                    </> : null
                                                            }
                                                            {/* Show All Filter and Filter Count */}
                                                            <button type="button" className="btn btn-secondary" onClick={() => handleFilterBar(null)}><small className='mb-0'>All:<span className='pl-2'>{rowData.length}</span></small></button>
                                                            <button type="button" className="btn btn-info count-btn"><small><i className="fas fa-filter"></i> <span className='pl-2'>{filterRowCount}</span></small></button>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                        </div> : null
                                }
                                {/* <div className='border-left ml-2 d-flex align-items-center pl-3'>
                                <button type="button" className="btn btn-secondary count-btn">
                                    <small>Results: {filterRowCount} of {rowData.length}</small>
                                </button>
                            </div> */}

                            </div>

                        </div> : null
                }
                <div className="data-grid-responsive">
                    <table ref={tableRef} className="table table-hover table-striped table-bordered">
                        {props.children}
                    </table>
                    {
                        (noResults === false) ?
                            null :
                            <div className='data-grid-no-results justify-content-center align-items-center'>
                                <div className='py-4'>
                                    <p className='text-center'><i className="fas fa-frown"></i></p>
                                    <p className='text-center'>no results</p>
                                </div>
                            </div>
                    }
                    {
                        (rowData.length > 0) ?
                            null :
                            <div className='data-grid-spinner d-flex justify-content-center align-items-center'>
                                <div className='py-4'>
                                    {/* <ClapSpinner 
                                size={45}
                                /> */}
                                </div>
                            </div>
                    }
                </div>
                {/* <div className='ml-2 d-flex align-items-center justify-content-end p-2 pr-3'>
                     <div className='pr-4'><small>Filter Results: {filterRowCount}</small></div>
                     <div><small>Total: {rowData.length}</small></div>
                </div> */}
            </div>
        </div>

    )
}

export default DataGridContainer;

