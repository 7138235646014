import React from "react";
import { useDataGridStore, TStore } from "../../_Store";
import styles from './noColumns.module.scss';

interface Props {
  width: number
}

function NoColumns({ width }: Props) {
  const [{ fallBackGridHeight }] = useDataGridStore() as TStore;
  return (
    <>
      <tr>
        <td
          className={styles.noColumns}
          style={{ height: fallBackGridHeight }}
        >
          <div
            className={`${(width > 0) ? 'd-flex' : 'd-none'} flex-column align-items-center justify-content-center`}
            style={{ height: "100%", width: `100%` }}
          >
            <div className={styles.icons}>
              <i className="fad fa-columns text-info"></i>
              {/* <i className="fad fa-meh text-info"></i> */}
            </div>
            <h5 className="pt-3 text-info">No Columns Selected...</h5>
          </div>
        </td>
      </tr>
    </>
  );
}

export default React.memo(NoColumns);
