import React, {useRef, useContext} from 'react';
import {Link} from 'react-router-dom';
import SideNavContext from '../side-nav-context';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import uniqid from 'uniqid';

interface Props{
    link?: string,
    submenu?: boolean | 'open',
    collapsed?: boolean,
    icon?: JSX.Element,
    text: string,
    action?: () => void,
    children?: JSX.Element | JSX.Element[]
}

function ListItem(props: Props){

    //props variables
    const link = props.link;
    const submenu = props.submenu;
    const icon = props.icon;
    const text = props.text;
    const action = props.action;

    //context
    const sideNavState:{collapsed: boolean} = useContext(SideNavContext);

    //refs
    const caret = useRef<HTMLElement>(null);

    //handlers
    const handleCaretAnimation = () => {
        let caretIcon = caret.current as HTMLElement;
        if(caretIcon.classList.contains('side-menu-open-state-caret')){
            caretIcon.classList.remove('side-menu-open-state-caret');
        }else{
            caretIcon.classList.add('side-menu-open-state-caret');
        }
    }

    return(
        <div className="list-item">
            {
                //expanded side nav
                (sideNavState.collapsed !== true) ?
                (submenu) ?
                <Accordion defaultActiveKey={(submenu === 'open') ? '0' : '1'}>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => {handleCaretAnimation()}}className='d-flex justify-content-between'>
                            <div className='list-item-content-container py-3'>
                                <span className='side-nav-list-item-icon pr-3'>{icon}</span>
                                <span className='side-nav-list-item-text'>{text}</span>
                            </div>
                            <div className='d-flex align-items-center'>
                                <i ref={caret} className={`fa fa-fw fa-caret-right mr-1 ${(submenu === 'open') ? 'side-menu-open-state-caret' : ''}`}></i>
                            </div>
                        </Accordion.Toggle>
                        {props.children}
                    </Card>
                </Accordion> :
                <Link to={(link) ? link : '#/'} className={'d-flex justify-content-between py-3'} onClick={(action) ? action : () => {return false}}>
                    <div className='list-item-content-container d-flex'><span className='side-nav-list-item-icon pr-3'>{icon}</span><span className='side-nav-list-item-text'>{text}</span></div>
                </Link>
                :
                //collapsed sidenav
                (submenu) ?
                <DropdownButton
                    drop={'right'}
                    variant="secondary"
                    title={<div title={text}>{icon}</div>}
                    id={`dropdown-button-drop-${uniqid()}`}
                    key={'right'}
                >
                    <Dropdown.Header>{text}</Dropdown.Header>
                    {props.children}
                </DropdownButton> : 
                <Link to={(link) ? link : '#/'} className={'d-flex justify-content-between'} onClick={(action) ? action : () => {return false}}>
                    <div title={text} className='list-item-content-container'>
                        <span className='side-nav-list-item-icon'>{icon}</span>
                        <span className='side-nav-list-item-text'>{text}</span>
                    </div>
                </Link>
            }  
        </div>
    )
}

export default ListItem;