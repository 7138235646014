//jsPDF-AutoTable html table to pdf exporter 
//url: https://github.com/simonbengtsson/jsPDF-AutoTable

// import jsPDF from 'jspdf';
// import 'jspdf-autotable';

const exportTableToPDF = (tableRef: HTMLElement, filename: string) => {

    // let table = tableRef;
    // let fileName = filename.replace(' ', '-').toLowerCase();

    // let doc = new jsPDF();

    
    // (doc as any).autoTable({
    //     html: table,
    //     headStyles: {fillColor: [40, 40, 40], fontSize: 8},
    //     bodyStyles: {fontSize: 8}
        
    // })
    // doc.save(`${fileName}.pdf`);

}

 export default exportTableToPDF;



// @ts-ignore before jsPDF.autoTable
    //not sure why autoTable is not passing type check.  Need to investigate.
