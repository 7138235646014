import React from 'react';
import { Card } from 'react-bootstrap';
import uniqid from 'uniqid';
import styles from './actionBar.module.scss';

interface Props {
    dataSet: {
        icon: JSX.Element;
        label: string;
        action?: () => void;
        link?: string;
    }[];
}

export default function ActionBar({ dataSet }: Props) {

    return (
        <>
            <Card.Header className={`d-flex justify-content-end ${styles.compHeader}`}>
                {dataSet.map((item) => {
                    return (
                        <div
                            key={uniqid()}
                            title={item.label}
                            className="px-2 d-flex align-content-center"
                            onClick={item.action}
                        >
                            {item.icon}
                        </div>
                    );
                })}
            </Card.Header>
        </>
    )
}