import React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-treble';
import protransLogo from '../../../_assets/images/logo-protrans-colored.svg';
import protransWhiteLogo from '../../../_assets/images/logo-protrans-white.svg';
import optimizLogo from '../../../_assets/images/logo-optimiz-colored.svg';
import { Content } from '../../organisms';
import styles from './login.module.scss';
import { useConfig, useLogin, useStartUp } from '../../../hooks';

export default function LoginPage() {

    const config = useConfig();
    const login = useLogin();
    const startup = useStartUp();
    const history = useHistory();
    const location = useLocation();
    const title = config.loginPageInfo.title;
    const TitleComp = () => {
        return (
            <>
                {
                    (typeof title === 'function') ? title() : null
                }
            </>
        )
    };
    const LoginForm = () => (config.loginPageInfo.form) ? config.loginPageInfo.form() : null;

    return (
        <>
            {
                (login.isAuth) ?
                    <Redirect to={startup.initialPath} /> :
                    <div className={styles.sceneContainer}>
                        <div className={`d-flex align-items-center justify-content-center ${styles.formContainer}`} >
                            <div className={`${styles.formPanel}`}>
                                <div className={'d-flex justify-content-center'}>
                                    {config.appLogo === "protrans" ? (

                                        <img src={protransLogo} className={styles.logo} alt="Protrans" />

                                    ) : config.appLogo === "optimiz" ? (

                                        <img src={optimizLogo} className={styles.logo} alt="Optimiz" />

                                    ) : null}
                                </div>

                                <Content.Panel>
                                    <div className={'d-flex justify-content-center'}>
                                        {
                                            (config.loginPageInfo.title) ?
                                                (typeof title === 'string') ?
                                                    <h3>{title}</h3> :
                                                    <TitleComp /> : <></>
                                        }
                                    </div>
                                    <LoginForm />
                                </Content.Panel>
                            </div>
                        </div>
                        <div className={`d-flex justify-content-end align-items-center ${styles.loginFooter}`}>
                            <img src={protransWhiteLogo} className={styles.logo} alt="Protrans" />
                        </div>
                    </div>
            }
        </>
    )
}