import React from 'react';
import { LoadingIndicator } from '../../atoms';

interface Props {
    type?: 'table' | 'card'
}
export default function GridLoadMore({ type }: Props) {

    const ref = React.useRef(null);

    return (
        <>
            {
                (type === undefined || type === 'card') ?
                    <div ref={ref} style={{ height: '100px' }}>
                        <div
                            className={`d-flex flex-column align-items-center justify-content-center`}
                            style={{ height: "100%" }}
                        >
                            <h5 className='p-0 mb-0'>
                                <LoadingIndicator size={3} />
                            </h5>
                        </div>
                    </div> :
                    (type === 'table') ?
                        <tr ref={ref} >
                            <td style={{ height: '100px', backgroundColor: 'white' }}>
                                <div
                                    className={`d-flex flex-column align-items-center justify-content-center`}
                                    style={{ height: "100%" }}
                                >
                                    <h5 className='p-0 mb-0'>
                                        <LoadingIndicator size={3} />
                                    </h5>
                                </div>
                            </td>
                        </tr> : null
            }
        </>
    )
}