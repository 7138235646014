import React from 'react';
import { Form, CardDeck } from 'react-bootstrap';
import { useCardGridStore } from '../../_Store/Store';

export default function RenderCards() {

    const [{ CustomCard, checkedList, alphaFilter, renderedCardData, activePage }] = useCardGridStore();

    const handleRenderedCards = (activeGridPage: number, chunkedData: { [key: string]: any }[][]) => {
        const pageAmount = (chunkedData.length !== 0) ? chunkedData.length - 1 : 0;
        const previousPage = chunkedData[activeGridPage - 1];
        const currentPage = chunkedData[activeGridPage];
        const nextpage = chunkedData[activeGridPage + 1];

        if (!(activeGridPage > pageAmount)) {
            if (activeGridPage > 0) {
                const combinedRenderedRows = [...previousPage, ...currentPage];
                return combinedRenderedRows;
            }
            if (activeGridPage === 0 && chunkedData.length > 1) {
                const combinedRenderedRows = [...currentPage, ...nextpage];
                return combinedRenderedRows;
            }
        }
        return currentPage;
    }
    return (
        <>
            <Form>
                <Form.Group controlId="ptruiCardGridForm">
                    <CardDeck>
                        {
                            handleRenderedCards(activePage, renderedCardData)?.map((cardData, index) => {


                                return (
                                    <React.Fragment key={cardData?.Id || index}>
                                        <CustomCard cardData={cardData} />
                                    </React.Fragment>
                                )
                            })
                        }
                    </CardDeck>
                </Form.Group>
            </Form>
        </>
    )
}