import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { withTreble } from 'treble-gsm';
import { Store, useAutoSuggestStore } from './Store';
import { InputOption } from '../../atoms';
import InputDropdown from '../input-dropdown';
import * as customToggle from './custom-toggle';
import uniqid from 'uniqid';
import { IAutoSuggest } from './interfaces';
import { useNonInitialEffect } from '../../../hooks';
import useAutoSuggest from './use-auto-suggest';

interface Props {
    mapValueTo: string;
    mapTextTo: string;
    loading?: boolean;
    fetchStatus?: null | object;
    suggestions: IAutoSuggest.MenuItem[];
    defaultValue?: IAutoSuggest.MenuItem;
    customSuggestion?: (props: { option: IAutoSuggest.MenuItem, [key: string]: any }) => JSX.Element;
    placeHolder?: string;
    className?: string;
    icon?: JSX.Element | boolean;
    onSelect?: (suggestion?: IAutoSuggest.MenuItem) => void;
    width?: string;
    minWidth?: string;
    size?: 'sm' | 'lg';
    disabled?: boolean;
    onChange?: (query: { query: string }) => React.SetStateAction<{
        query: string;
    }>;
    menuFooter?: () => JSX.Element;
}

function AutoSuggestComp({
    suggestions,
    defaultValue,
    fetchStatus,
    customSuggestion,
    placeHolder,
    className,
    icon,
    onSelect,
    width,
    minWidth,
    size,
    disabled,
    onChange,
    mapTextTo,
    mapValueTo,
    loading,
    menuFooter
}: Props) {


    const [{ searchQuery, fetchStatusCode, activeInputValue }] = useAutoSuggestStore();
    const autoSuggest = useAutoSuggest();
    const mappedSuggestions = autoSuggest.createSuggestions(suggestions, mapTextTo, mapValueTo);

    // //add static props to store
    React.useEffect(() => {

        autoSuggest.setPrependIcon((icon) ? icon : false);
        autoSuggest.setInputSize(size);
        autoSuggest.setActiveInputValue(defaultValue);
        autoSuggest.setDefaultInputValue(defaultValue);
        autoSuggest.setCustomSuggestionComp(customSuggestion);
        autoSuggest.setPlaceHolderText(placeHolder);
        autoSuggest.setPrependIcon(icon);

        if (onSelect) {
            onSelect(defaultValue);
        }

    }, []);

    React.useEffect(() => {

        autoSuggest.setFetchStatusCode(fetchStatus);
        autoSuggest.setIsDisabled(disabled);

    }, [fetchStatus, disabled]);


    // //update default value to store and sets active value to default value
    useNonInitialEffect(() => {

        autoSuggest.setDefaultInputValue(defaultValue);
        if (onSelect) {
            onSelect(defaultValue);
        }

    }, [defaultValue]);

    // //update onChange handler
    useNonInitialEffect(() => {

        if (onChange) {
            onChange(searchQuery)
        }
        autoSuggest.resetActiveInputValue(searchQuery);

    }, [searchQuery]);

    useNonInitialEffect(() => {
        if (onSelect) {
            onSelect(activeInputValue);
        }
    }, [activeInputValue]);

    return (
        <>
            <Dropdown
                className={className}
                style={{ width: (width) ? width : 'auto', minWidth: (minWidth) ? minWidth : 'none' }}
                onToggle={(isOpen, event, metadata) => {
                    autoSuggest.setToggleMeta({ isOpen: isOpen, event: event, metadata: metadata });
                }}
            >
                <Dropdown.Toggle as={customToggle as any} id={`ptrui-auto-suggest-${uniqid()}`} />
                <InputDropdown
                    withInputIcon={(icon) ? true : false}
                    showChildren={(mappedSuggestions.length > 0) ? true : false}
                    loading={loading}
                    error={fetchStatusCode}
                    customFooter={menuFooter}
                >
                    {
                        mappedSuggestions?.map((suggestion : any, index : number) => (
                            <React.Fragment key={suggestion.value}>
                                <InputOption
                                    option={suggestion}
                                    customOption={customSuggestion}
                                    index={index}
                                    onSelect={(option) => {
                                        if (option) {
                                            autoSuggest.setActiveInputValue(option);
                                            if (onSelect) {
                                                onSelect(option);
                                            }
                                        }
                                    }}
                                    onKeyDown={(option, e) => { (onSelect) ? onSelect(option) : null; autoSuggest.setActiveInputValue(option); }}
                                />
                            </React.Fragment>
                        ))
                    }
                </InputDropdown>
            </Dropdown>
        </>
    )
}

const AutoSuggest = withTreble(AutoSuggestComp, { store: Store });
export default AutoSuggest;