import React from 'react';
import { GridSplash } from '../../atoms';

interface Props {
    width?: number;
    height?: number;
    text?: string;
    smallText?: string;
}
export default function GridNoResultsSplash({ width, height, text, smallText }: Props) {

    return (
        <>
            <tr>
                <td className={'p-0 border-0'}>
                    <GridSplash
                        width={width}
                        height={height}
                        icon={
                            <>
                                <i className="fad fa-search text-info"></i>
                                <i className="fad fa-sad-tear text-info"></i>
                            </>
                        }
                        text={`${(text) ? text : 'Sorry, No Results...'}`}
                        textColor={'text-info'}
                        smallText={smallText}
                    />
                </td>
            </tr>
        </>
    )
}