/*
    This is a temporary persist state comp that replaces Treble's persist feature.
    This will be deprecated once Treble 4 is rolled out and 
    Treble-Persist module is updated with session storage option.
*/
import React from 'react';
import { useSessionStorage, useLogin } from '../../../hooks';

export default function PersistState() {

    const ss = useSessionStorage();
    const login = useLogin();

    //session storage keys
    const isAuthKey = 'ptrui_isAuth';
    const tokenKey = 'ptrui_userToken';
    const userKey = 'ptrui_userInfo';

    //initial session storage values
    const ssIsAuth = (ss.get(isAuthKey)) ? true : false;
    const ssTokenKey = (typeof ss.get(tokenKey) === 'string') ? ss.get(tokenKey) : '';
    const ssUser = ss.get(userKey);

    //hydrates login state based on session storage
    React.useEffect(() => {

        //sets isAuth in Store
        login.setIsAuth(ssIsAuth);

        //sets token in Store
        login.setToken(ssTokenKey);

        //sets user in Store
        login.setUser(ssUser);

    }, []);

    //saves login credentials to session storage
    React.useEffect(() => {

        //save isAuth
        ss.set(isAuthKey, login.isAuth);

        //save token
        ss.set(tokenKey, login.token);

        //save user
        ss.set(userKey, login.user);

    }, [login.isAuth, login.token, login.user]);


    return null;
}