import React from 'react';
import { AccordionContainer, AccordionToggle, AccordionDropdown, ListItemLabel } from '../../../atoms';
import { IconWithDropdown } from '../../../molecules';
import { usePageState } from '../../../../hooks';
import styles from './subMenuItem.module.scss';

interface Props {
    className?: string;
    onClick?: () => void;
    icon?: JSX.Element;
    text?: string;
    children?: JSX.Element | JSX.Element[];
    open?: boolean;
}

export default function SubMenuItem({ className, onClick, icon, text, children, open }: Props) {

    const page = usePageState();

    return (
        <>
            {
                (page.isCollapsed && !page.isMobile) ?
                    <IconWithDropdown drop={'right'} className={`pb-3 ${className}`} classNameToggle={`${styles.iconOnly}`} classNameDropdown={`pt-0 ${styles.dropdownBK} ${styles.dropdownMenu}`} onClick={onClick} icon={icon} text={text} caret={true} toolTipPlacement={'right'}>
                        <>
                            <ListItemLabel text={text} className={`pl-3 pt-2 pb-2 ${styles.dropdownLabel}`} />
                            {
                                React.Children.map(children, (child) => {
                                    if (child?.type.compName === 'PageNavItem') {
                                        return { ...child, props: { ...child?.props, ignorePageState: true } };
                                    } else {
                                        return child;
                                    }

                                })
                            }
                        </>
                    </IconWithDropdown> :
                    <AccordionContainer open={open} onClick={onClick} className={className}>
                        <AccordionToggle eventKey={'1'} className={'pb-3 pt-2 text-white'}>
                            <ListItemLabel text={text} icon={icon} />
                        </AccordionToggle>
                        <AccordionDropdown eventKey={'1'} className={styles.dropdownBK}>
                            {children}
                        </AccordionDropdown>
                    </AccordionContainer>
            }
        </>
    )
}