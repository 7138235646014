/*
Treble middleware that processes data state
*/

import { IMiddlewareData } from 'treble-gsm';

const processState = (middlewareData: IMiddlewareData) => {
  const { page, data, size } = middlewareData.dispatchValue;
  const chunk = (arr: any, size: number) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );
  if (page) {
    const pagefiedState = chunk(data, size);
    return pagefiedState;
  }

  return [data];
};

export default processState;
