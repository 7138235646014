import React, {useRef, useEffect, useState} from 'react';
import './_info-card-section-search.scss';

//context
import useInfoCardSectionContext from '../_context';

//actions
import {handleTypedSearch, handleCheckedAllBtn, handleAlphabetSearch} from './actions';


interface Props{
    placeHolder?: string,
    darkMode?: boolean,
    inputType?: 'radio' | 'checkbox',
    inputState?: {
        displayID: string,
        Id: number,
        name: string,
        country: string,
        street: string,
        city: string,
        state: string,
        zip: string,
        thumbnailURL?: string
    }[],
    setCheckedCards?: React.Dispatch<React.SetStateAction<{ 
        displayID: string,
        Id: number,
        name: string,
        country: string,
        street: string,
        city: string,
        state: string,
        zip: string,
        thumbnailURL?: string
    }[] | []>>
}

function Search(props: Props){

    //props
    const sectionTitle = props.placeHolder;
    const darkMode = props.darkMode;
    const inputType = props.inputType;
    const inputState = props.inputState;
    const setCheckedCards = props.setCheckedCards;

    //state
    const [filterColor, setFilterColor] = useState('all');

    //refs
    const checkAllBtn = useRef<HTMLInputElement>(null);

    //context
    type ContextType = [
        {
            infoCardData: {
                render: boolean,
                checked: boolean, 
                data: {
                    displayID: string,
                    Id: number,
                    name: string,
                    country: string,
                    street: string,
                    city: string,
                    state: string,
                    zip: string
                }
            }[],
            checkedInfoCardData: {
                displayID: string,
                Id: number,
                name: string,
                country: string,
                street: string,
                city: string,
                state: string,
                zip: string
            }[]
        },
        (object: {
            type: 'updateInfoCardData' | 'addToCheckedInfoCardData' | 'setInfoCardData', 
            updateInfoCardData?: object[],
            addToCheckedInfoCardData?: object[],
            setInfoCardData?: object[]
        }) => object 
    ];

    //context
    const [{infoCardData}, dispatch]: ContextType = useInfoCardSectionContext();

    return(
        <>
            <div className="info-card-section-search input-group pl-3">
                <input type="text" className="form-control" placeholder={`Search ${sectionTitle}`} onKeyUp={(e) => handleTypedSearch(e, dispatch, infoCardData)} />
                <div className="input-group-append">
                    <button className="btn btn-secondary" type="button">
                        <i className="fa fa-search"></i>
                    </button>
                </div>
                {
                    (inputType !== 'radio') ?
                    <div className="form-check ml-4 mt-2">
                        <input ref={checkAllBtn} type="checkbox" className="form-check-input" onClick={() => (setCheckedCards) ? handleCheckedAllBtn(dispatch, setCheckedCards as any, checkAllBtn, infoCardData as any) : alert('Something went wrong!')}/>
                        <label className="form-check-label mt-0" >Select All</label>
                    </div> : null
                }

            </div>
            <div className={`info-card-section-alphabet-search pl-3 py-2 ${(darkMode) ? 'text-white' : ''}`}> 
                <span className={`p-1 ${(filterColor === 'a') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'a'); setFilterColor('a')}}>A</span> 
                <span className={`p-1 ${(filterColor === 'b') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'b'); setFilterColor('b')}}>B</span> 
                <span className={`p-1 ${(filterColor === 'c') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'c'); setFilterColor('c')}}>C</span> 
                <span className={`p-1 ${(filterColor === 'd') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'd'); setFilterColor('d')}}>D</span> 
                <span className={`p-1 ${(filterColor === 'e') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'e'); setFilterColor('e')}}>E</span> 
                <span className={`p-1 ${(filterColor === 'f') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'f'); setFilterColor('f')}}>F</span> 
                <span className={`p-1 ${(filterColor === 'g') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'g'); setFilterColor('g')}}>G</span> 
                <span className={`p-1 ${(filterColor === 'h') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'h'); setFilterColor('h')}}>H</span> 
                <span className={`p-1 ${(filterColor === 'i') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'i'); setFilterColor('i')}}>I</span> 
                <span className={`p-1 ${(filterColor === 'j') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'j'); setFilterColor('j')}}>J</span> 
                <span className={`p-1 ${(filterColor === 'k') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'k'); setFilterColor('k')}}>K</span> 
                <span className={`p-1 ${(filterColor === 'l') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'l'); setFilterColor('l')}}>L</span> 
                <span className={`p-1 ${(filterColor === 'm') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'm'); setFilterColor('m')}}>M</span> 
                <span className={`p-1 ${(filterColor === 'n') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'n'); setFilterColor('n')}}>N</span> 
                <span className={`p-1 ${(filterColor === 'o') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'o'); setFilterColor('o')}}>O</span> 
                <span className={`p-1 ${(filterColor === 'p') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'p'); setFilterColor('p')}}>P</span> 
                <span className={`p-1 ${(filterColor === 'q') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'q'); setFilterColor('q')}}>Q</span> 
                <span className={`p-1 ${(filterColor === 'r') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'r'); setFilterColor('r')}}>R</span> 
                <span className={`p-1 ${(filterColor === 's') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 's'); setFilterColor('s')}}>S</span> 
                <span className={`p-1 ${(filterColor === 't') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 't'); setFilterColor('t')}}>T</span> 
                <span className={`p-1 ${(filterColor === 'u') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'u'); setFilterColor('u')}}>U</span> 
                <span className={`p-1 ${(filterColor === 'v') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'v'); setFilterColor('v')}}>V</span> 
                <span className={`p-1 ${(filterColor === 'w') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'w'); setFilterColor('w')}}>W</span> 
                <span className={`p-1 ${(filterColor === 'x') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'x'); setFilterColor('x')}}>X</span> 
                <span className={`p-1 ${(filterColor === 'y') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'y'); setFilterColor('y')}}>Y</span> 
                <span className={`p-1 ${(filterColor === 'z') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'z'); setFilterColor('z')}}>Z</span> 
                <span className={`p-1 ${(filterColor === '1') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, '1'); setFilterColor('1')}}>1</span> 
                <span className={`p-1 ${(filterColor === '2') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, '2'); setFilterColor('2')}}>2</span> 
                <span className={`p-1 ${(filterColor === '3') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, '3'); setFilterColor('3')}}>3</span> 
                <span className={`p-1 ${(filterColor === '4') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, '4'); setFilterColor('4')}}>4</span> 
                <span className={`p-1 ${(filterColor === '5') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, '5'); setFilterColor('5')}}>5</span> 
                <span className={`p-1 ${(filterColor === '6') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, '6'); setFilterColor('6')}}>6</span> 
                <span className={`p-1 ${(filterColor === '7') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, '7'); setFilterColor('7')}}>7</span> 
                <span className={`p-1 ${(filterColor === '8') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, '8'); setFilterColor('8')}}>8</span> 
                <span className={`p-1 ${(filterColor === '9') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, '9'); setFilterColor('9')}}>9</span> 
                <span className={`p-1 ${(filterColor === '0') ? 'text-success' : ''}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, '0'); setFilterColor('0')}}>0</span> 
                <span className={`ml-4 ${(filterColor === 'all') ? 'text-success' : 'text-primary'}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'all'); setFilterColor('all')}}>All</span> 
                <span className={`ml-4 ${(filterColor === 'checked') ? 'text-success' : 'text-primary'}`} onClick={()=> {handleAlphabetSearch(dispatch, infoCardData, 'checked'); setFilterColor('checked')}}><i className="fas fa-check-circle"></i> Checked</span>
            </div>
        </>
    )

}

export default Search;