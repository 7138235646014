import React from 'react';
import { Col, Card } from 'react-bootstrap';
import { PanelHeader } from '../../atoms';
import styles from './panel.module.scss';

interface Props {
    header?: string,
    style?: any,
    className?: string,
    bodyClassName?: string,
    half?: boolean,
    noPadding?: boolean,
    children: JSX.Element[] | JSX.Element
}

export default function Panel({ header, children, className, bodyClassName, style, noPadding }: Props) {

    return (
        <>
            <Col className={`${(noPadding) ? 'p-0' : 'pt-3'} ${className}`} xs={'12'} style={(style) ? style : {}}>
                <div className={`${styles.panelContainer}`} style={{ height: 100 + '%' }}>
                    {
                        (header) ?
                            <PanelHeader className={styles.header}>
                                <h5 className="mb-0">{header}</h5>
                            </PanelHeader> : null
                    }
                    {
                        React.Children.map(children, (child, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {
                                        (child.type.displayName === 'PanelHeader') ?
                                            child : null
                                    }
                                </React.Fragment>
                            )

                        })
                    }
                    <Card.Body style={{ height: 100 + '%' }} className={`${bodyClassName}`}>
                        {
                            React.Children.map(children, (child, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {
                                            (child.type.displayName !== 'PanelHeader') ?
                                                child : null
                                        }
                                    </React.Fragment>
                                )

                            })
                        }
                    </Card.Body>
                </div>
            </Col>
        </>
    )
}