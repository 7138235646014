import React from 'react';
import Button from 'react-bootstrap/Button';
import ModalBasic from '../modal-basic';
import Badge from 'react-bootstrap/Badge';
import { useNonInitialEffect } from '../../../hooks';
import styles from './removeGridItem.module.scss';

interface Props {
    iconOnly?: boolean;
    small?: boolean;
    large?: boolean;
    className?: string;
    label?: string;
    onClick?: () => void;
    disabled?: boolean;
    modalSize?: "sm" | "lg" | "md" | "xl";
    modalText?: JSX.Element | string;
    onModalOpen?: () => void;
    onModalClose?: () => void;
    itemsToRemove?: { ptrui_cacheKey: number, [key: string]: any }[];
    onRemove?: (itemsToRemove: { ptrui_cacheKey: number, [key: string]: any }[]) => void;
}
export default function RemoveGridItem({ iconOnly, small, large, className, label, disabled, modalSize, onModalOpen, onModalClose, itemsToRemove, onRemove, modalText }: Props) {

    const size = (small) ? 'sm' : (large) ? 'lg' : undefined;
    const [show, setShow] = React.useState(false);
    const isDisabled = (disabled) ? true : (itemsToRemove) ? (itemsToRemove.length === 0) ? true : false : false;
    const onHide = () => {
        setShow(false);
        if (onModalClose) {
            onModalClose();
        }
    };
    useNonInitialEffect(() => {
        if (show) {
            if (onModalOpen) {
                onModalOpen();
            }
        }
    }, [show]);
    const onDelete = () => {
        if (onRemove && itemsToRemove) {
            onRemove(itemsToRemove);
        }
        setShow(false);
    }
    const onCancel = () => {
        setShow(false);
    }

    return (
        <>
            <ModalBasic
                size={(modalSize) ? modalSize : undefined}
                show={show}
                onHide={() => onHide()}
                centered={false}
                animation={true}
                backdrop={'static'}
            >
                <ModalBasic.Body>
                    <>
                        <div className='d-flex justify-content-center align-items-center p-4'>
                            <i className={`${styles.icon} fad fa-exclamation-triangle text-danger`}></i>
                        </div>
                        <div className='text-center'>
                            <p className='text-center'>
                                {
                                    (modalText) ? modalText :
                                        <>
                                            Are you sure you want to <strong>delete {itemsToRemove?.length}</strong> ?
                                        </>
                                }
                            </p>
                        </div>
                    </>
                </ModalBasic.Body>
                <ModalBasic.Footer className={'d-flex justify-content-between'}>
                    <Button variant={'danger'} onClick={onDelete}>
                        <i className="fas fa-trash-alt pr-2"></i>
                        Delete
                    </Button>
                    <Button variant={'primary'} onClick={onCancel}>
                        Cancel
                    </Button>
                </ModalBasic.Footer>
            </ModalBasic>
            {
                (iconOnly) ?
                    <i onClick={() => (isDisabled) ? null : setShow(true)} className={`fas fa-trash-alt ${(!isDisabled) ? 'cursor text-danger' : 'text-muted'} ${styles.iconOnly} ${className}`}></i> :
                    <Button
                        size={(size === 'sm') ? 'sm' : (size === 'lg') ? 'lg' : undefined}
                        variant={'danger'}
                        className={className}
                        onClick={() => setShow(true)}
                        disabled={isDisabled}
                    >

                        <>
                            <span className={'pr-2'}>{<i className="fas fa-trash-alt text-white"></i>}</span>
                            <span className={'pr-1'}>{(label) ? label : 'Remove Item'}</span>
                        </>
                        <Badge variant="light">{itemsToRemove?.length || 0}</Badge>
                    </Button>
            }
        </>
    )
}