/*
  DataGrid
  LEGACY GRID - This will need to be deprecated in favor of the 3 atomic grids in a later PTRUI version
*/
import React, { useLayoutEffect, useEffect } from "react";
import { withTreble } from "treble-gsm";
import { Store, useDataGridStore } from "./_Store";
import { useNonInitialLayoutEffect } from '../../../hooks';
import { IGrid } from "./interfaces";
import { Content } from "../../organisms";
import styles from './dataGrid.module.scss';

export function GridComp({
  children,
  height,
  className,
  loading,
  data,
  as,
  pageSize,
  pageGroupSize,
  rowProps,
  expandable,
  exportPDF,
  exportExcel,
  exportOptions,
  liveRender,
  groupMeta,
  fetchStatus,
  scrollTrigger,
  totalDataCount,
  fetchTrigger,
  onQueryChange,
  onSortChange,
  customExportOptions,
  columnTitles,
  gridStyles,
  expandableCheck,
  debounceFilters,
  resetData: resetDataProp,
  resetFilters: resetFiltersProp,
  columnSelector,
  stickyColumns,
  noPadding,
  onSelect
}: IGrid) {

  //Store Items and Methods
  const [
    {
      tableData,
      queriedTableData,
      gridPagingOptions,
      searchQueries,
      gridHeight,
      activeColumnSort,
      shouldDataRefresh,
      gridTBodyRef,
      loadingState
    },
    Store, Util
  ] = useDataGridStore();

  const gridRef = React.useRef(null);

  //makes sure resetData doesn't cause to many rerenders
  const resetData = React.useCallback(resetDataProp, [resetDataProp]);

  //listens for fetch status' (200, 404)
  useLayoutEffect(() => {

    if (fetchStatus !== undefined) {
      if (fetchStatus === null) {
        Store.update(Util.actions.updateFetchStatusCode, 'No Errors Detected');
      }
      else {
        Store.update(Util.actions.updateFetchStatusCode, fetchStatus);
      }
    }
  }, [fetchStatus]);

  //listens for loading state changes
  useLayoutEffect(() => {
    if (loading !== undefined) {
      Store.update(Util.actions.updateLoadingState, loading);
      if (loading) {
        Store.update(Util.actions.updateFetchStatusCode, 'No Errors Detected');
      }
    }
  }, [loading]);

  //sets gridRef
  React.useEffect(() => {
    Store.update(Util.actions.updateGridRef, gridRef);
  }, [gridRef]);

  //update static store state on render
  useLayoutEffect(() => {

    //sets grid height
    Store.update(Util.actions.updateGridHeight, height || '400px');

    //activates live filters and live row rendering if liveRender prop is set to true
    if (liveRender) {
      Store.update(Util.actions.activateLiveRender, liveRender);
      Store.update(Util.actions.setPlaceholderRowState, liveRender);
    }

    //sets paging options
    Store.update(Util.actions.updateGridPagingOptions, {
      page: true,
      size: pageSize || 15,
      groupSize: pageGroupSize,
    });

    //creates custom rows
    if (as !== undefined) {
      Store.update(Util.actions.createCustomRow, as);
    }

    //makes rows expandable if set to true
    if (expandable) {
      Store.update(Util.actions.createExpandableRow, expandable);
    }

    //sets a conditional for expandable rows
    if (expandableCheck) {
      Store.update(Util.actions.setExpandableCheck, expandableCheck);
    }

    //add custom meta to groups if jsx passed to prop
    if (groupMeta) {
      Store.update(Util.actions.createGroupMeta, groupMeta);
    }

    //removes column table header cells from grid (can be used for nested tables)
    if (!columnTitles && columnTitles !== undefined) {
      Store.update(Util.actions.setColumnTitleState, columnTitles);

      //also sets sticky column to false to prevent weird overlap of nested tables
      Store.update(Util.actions.setStickyColumnState, false);
    }

    //set column filter debounce time
    if (debounceFilters) {
      Store.update(Util.actions.setDebounceFilterTime, debounceFilters)
    }

    //shows footer columnSelector icon if set to true
    if (columnSelector) {
      Store.update(Util.actions.showFooterColumnSelector, true)
    }

    //sets column header to sticky on scroll if set to true
    if (stickyColumns !== undefined && typeof stickyColumns === 'boolean') {
      Store.update(Util.actions.setStickyColumnState, stickyColumns)
    }

    //set onSelect
    if (onSelect) {
      Store.update(Util.actions.setOnSelect, onSelect)
    }
  }, []);

  useEffect(() => {
    //sets export options
    Store.update(Util.actions.setExports, {
      pdf: exportPDF,
      excel: exportExcel,
      options: exportOptions,
      customOptions: customExportOptions
    });
  }, [customExportOptions]);

  //calculates fallback grid height based on gridHeight prop
  useLayoutEffect(() => {
    Store.update(Util.actions.calculateFallBackGridHeight, gridHeight);
  }, [gridHeight]);

  //listens for grid data changes and then updates fetchData
  useLayoutEffect(() => {
    if (!loadingState && data) {
      if (liveRender) {
        if (!shouldDataRefresh) {
          Store.update(Util.actions.updateTableData, [...tableData, ...data]);
          return
        }
        //makes sure page number is reset
        Store.update(Util.actions.updateActiveGridPage, 0);

        //makes sure scroll is reset to top
        if (gridTBodyRef) {
          gridTBodyRef?.current.scrollTop == 0;
        }

        Store.update(Util.actions.updateTableData, data);

        return
      }
      Store.update(Util.actions.updateTableData, data);
    }
  }, [data, loadingState]);

  //listens for the total data count from the server
  useLayoutEffect(() => {
    if (totalDataCount !== undefined) {
      Store.update(Util.actions.updateTotalDataCount, totalDataCount);
    }
  }, [totalDataCount]);

  //listens for rowProp changes and then updates store
  useLayoutEffect(() => {
    Store.update(Util.actions.updateCustomRowProps, rowProps);
  }, [rowProps]);

  //listens for column sort state changes and updates onSortChange setState
  useNonInitialLayoutEffect(() => {
    if (onSortChange && liveRender) {
      Store.update(Util.actions.setShouldDataRefresh, true);
      onSortChange(activeColumnSort);
    }
  }, [activeColumnSort]);

  //listens for column filter data changes
  useNonInitialLayoutEffect(() => {

    if (!loadingState) {
      //makes sure grid can reset data and page position when column filters fire
      Store.update(Util.actions.updateActiveGridPage, 0);
      Store.update(Util.actions.setShouldDataRefresh, true);

      //passes updated query to outside components
      if (onQueryChange) {
        // if (searchQueries.find((item) => item?.query !== '')) {
        onQueryChange(searchQueries);
        // }
      }
    }
  }, [searchQueries]);

  //listens for column search query changes, and filters tableData based on query strings
  //disables client side search based on liveFilter prop
  useLayoutEffect(() => {
    Store.update(Util.actions.updateQueriedTableData, {
      state: tableData,
      searchQueries: (liveRender) ? '' : searchQueries,
    });
  }, [searchQueries, tableData]);

  //listens for queriedDataState store item changes and then updates renderedTableData
  useLayoutEffect(() => {
    Store.update(Util.actions.updateRenderedTableData, {
      tableData: queriedTableData,
      page: gridPagingOptions.page,
      size: gridPagingOptions.size,
    });
  }, [queriedTableData]);

  //listens for resetData props (used by external components to reset grid data)
  useNonInitialLayoutEffect(() => {
    //makes sure grid can reset data and page position when column filters fire
    if (resetData) {
      Store.update(Util.actions.updateActiveGridPage, 0);
      Store.update(Util.actions.setShouldDataRefresh, true);
    }
  }, [...resetData || []]);

  //sets scrollTrigger function and updates it if values change
  useNonInitialLayoutEffect(() => {
    if (scrollTrigger) {
      Store.update(Util.actions.setScrollTriggerFunction, scrollTrigger);
    }
  }, [scrollTrigger]);

  return (
    <>
      <Content.Panel bodyClassName={'p-0'} noPadding={noPadding}>
        <div ref={gridRef} className={`${styles.dataGrid} ${className}`} style={(gridStyles) ? gridStyles : {}}>{children}</div>
      </Content.Panel>
    </>
  );
}

//Grid and Store is passed to trebleGSM higher order component to pass state to
//Grid component with out using Provider comp
const Grid = withTreble(GridComp, { store: Store });
export default Grid;
