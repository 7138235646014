/*
    hook for setting local storage in the browser
*/
import React from 'react';

const useSessionStorage = () => {

    const handleValue = (value: any) => {

        //makes sure boolean values are not returned as strings
        //This might cause issues down the road.  If it becomes an issue, will seek alternative
        if (value === 'true' || value === 'false') {
            return (value === 'true') ? true : (value === 'false') ? false : value
        }

        //checks to see if the localstorage string is a valid json string
        let isJsonString = (value: any) => {
            try {
                JSON.parse(value)
            } catch (e) {
                return false
            }
            return true
        }
        //if string is valid it parses back to object
        if (isJsonString(value)) {
            return JSON.parse(value);
        }
        return value
    }

    const isEnabled = (typeof (Storage) !== "undefined") ? true : false;

    const set = (key: string, value: any) => {
        const processedValue = (typeof value === 'object') ? JSON.stringify(value) : value;
        if (isEnabled) {
            sessionStorage.setItem(key, processedValue);
        }
    }

    const get = (key: string) => {
        if (isEnabled) {
            return handleValue(sessionStorage.getItem(key));
        }
    }

    const remove = (key: string) => {
        if (isEnabled) {
            sessionStorage.removeItem(key);
        }
    }

    return {
        isEnabled,
        set,
        get,
        remove
    }

}

export default useSessionStorage;