import React, { useEffect } from "react";
import { useLocation } from 'react-router-treble';
import { AppMessage } from '../../atoms';
import { useInAppMessage } from '../../../hooks';
import styles from './inAppMessage.module.scss';

function InAppMessage() {
  const { pathname } = useLocation();
  const messages = useInAppMessage();

  //listens for route change and then dismisses all alerts
  useEffect(() => {
    messages.dismissAll();
  }, [pathname]);

  return messages.messageData !== undefined ? (
    messages.messageData.length > 0 ? (
      <div className={`${styles.compContainer} d-flex justify-content-center`}>
        <div className={styles.inAppMessageContainer}>
          {messages.messageData.map((message) => {
            return (
              <React.Fragment key={message.trebleKey}>
                <AppMessage message={message} />
              </React.Fragment>
            );
          })}
        </div>
        {messages.messageData.length > 1 ? (
          <p
            className="pl-3 pt-3 cursor"
            onClick={() => messages.dismissAll()}
            style={{ textDecoration: "underline" }}
          >
            Clear All
          </p>
        ) : null}
      </div>
    ) : null
  ) : null;
}

export default InAppMessage;
