import { createStore, useScopedTreble, useTreble, TUseTreble } from 'treble-gsm';

interface IState {
    ptrui_content_openMobileWidgetArea: boolean,
    ptrui_content_collapsedWidgetArea: boolean,
    ptrui_content_isWidgetAreaMobile: boolean
}

const actions = {
    ['ptrui_content_setIsWidgetAreaMobile']: 'ptrui_content_setIsWidgetAreaMobile',
    ['ptrui_content_setOpenMobileWidgetArea']: 'ptrui_content_setOpenMobileWidgetArea',
    ['ptrui_content_showCollapsedWidgetArea']: 'ptrui_content_showCollapsedWidgetArea'
}

type TActions = typeof actions;

const Context = useScopedTreble();
export const useContentStore = () => useTreble(Context) as TUseTreble<IState, TActions>;

export const Store = createStore([
    {
        action: actions.ptrui_content_setIsWidgetAreaMobile,
        state: {
            ptrui_content_isWidgetAreaMobile: false
        }
    },
    {
        action: actions.ptrui_content_setOpenMobileWidgetArea,
        state: {
            ptrui_content_openMobileWidgetArea: false
        }
    }
], {
    context: Context
});