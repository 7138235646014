import React from 'react';
import styles from './tableHeader.module.scss';

interface Props {
    children?: JSX.Element | JSX.Element[];
    sticky?: boolean;
    hide?: boolean;
    className?: string;
    onMount?: (ref: React.MutableRefObject<any>) => void;
}

export default function TableHead({ children, sticky, hide, className, onMount }: Props) {

    const ref = React.useRef<any>(null);
    const memoChildren = React.useMemo(() => children, []);

    //Allows for the comp ref to be saved to a scoped store or other actions dependent on the component mounting
    React.useLayoutEffect(() => {
        if (onMount) {
            onMount(ref);
        }
    }, [ref]);

    return (
        <>
            <thead ref={ref} className={`${styles.compContainer} ${(!hide) ? 'd-none' : ''} ${(sticky) ? styles.sticky : ''} ${className}`}>
                {/**Generates column table headers */}
                <tr className={styles.row}>
                    {React.Children.map(memoChildren, (child, index) => {
                        return <>{
                            (index !== 0) ? <>{child}</> : child
                        }</>;
                    })}
                </tr>
            </thead>
        </>
    )
}