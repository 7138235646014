import React from 'react';
import Link from '../../../../utils/link';
import { Card } from 'react-bootstrap';
import styles from './cardBody.module.scss';
import { ICard } from '../interfaces';

function Body({ children, className, onClick, link, redirectLink, linkTarget }: ICard.Body) {

    return (
        <>
            <Card.Body className={`${styles.content} p-2 ${className} ${(link || redirectLink) ? 'cursor' : ''}`} onClick={(onClick) ? () => onClick() : () => null}>
                {
                    (!link && !redirectLink) ? children :
                        (link) ? <Link to={link} className={styles.link} target={linkTarget}><>{children}</></Link> :
                            (redirectLink) ? <a href={redirectLink} target={linkTarget} className={styles.link}>{children}</a> : null
                }
            </Card.Body>
        </>
    )
}

export default Body;