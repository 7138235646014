import React, { useEffect, useRef, Ref } from 'react';
import { Link } from 'react-router-dom';
import './_info-card.scss';

//context
import useInfoCardSectionContext from '../_context';

import {addToCheckedInfoCardData, removeFromCheckedInfoCardData} from './_actions';


interface Props {
  inputType: 'radio' | 'checkbox' | null,
  inputState?: {
      displayID: string,
      Id: number,
      name: string,
      country: string,
      street: string,
      city: string,
      state: string,
      zip: string,
      thumbnailURL?: string
  }[],
  url?: string,
  Id: number,
  displayID: string,
  name: string,
  country: string, 
  street: string,
  city: string,
  state: string,
  zip: string,
  checked: boolean,
  thumbnailURL?: string
}

function InfoCard(props: Props) {

  //prop variables
  const inputType = props.inputType;
  const url = props.url;
  const Id = props.Id;
  const displayID = props.displayID;
  const name = props.name;
  const country = props.country
  const street = props.street;
  const city = props.city;
  const state = props.state;
  const zip = props.zip;
  const checked = props.checked;
  const thumbnailURL = props.thumbnailURL;
  const inputState = props.inputState;


  //context
  type ContextType = [
    {
      infoCardData: {
        cardID: string,
        render: boolean,
        checked: boolean,
        data: {
            displayID: string,
            Id: number,
            name: string,
            country: string,
            street: string,
            city: string,
            state: string,
            zip: string,
            thumbnailURL?: string
        }
    }[],
      checkedInfoCardData: {
          displayID: string,
          Id: number,
          name: string,
          country: string,
          street: string,
          city: string,
          state: string,
          zip: string,
          thumbnailURL?: string
      }[]
    },
    () => {
      type: 'addToCheckedInfoCardData',
      addToInfoCardData: object
    }
  ];
  const [{checkedInfoCardData }, dispatch]: ContextType = useInfoCardSectionContext();

  //refs
  const checkInput = useRef<HTMLInputElement>(null);

  //handlers
  const resetBodyStyles = () => {
    let body = document.querySelector('body') as HTMLBodyElement;
    body.classList.remove('modal-open');
    body.style.paddingRight = '0px';
  }

  const handleCheckedInfoCard = () => {
    if(checkInput.current !== null){
      if(checkInput.current.checked === true){
        //set checked attribute in InfoCardData object
        
        if(inputType === 'radio'){
          //add card to checkedCard object
          addToCheckedInfoCardData(dispatch, [
          {
            Id: Id,
            displayID: displayID,
            name: name,
            country: country,
            street: street,
            city: city,
            state: state,
            zip: zip,
            thumbnailURL: thumbnailURL
          }]);
        }
        else{
          //add card to checkedCard object
          addToCheckedInfoCardData(dispatch, [
            ...inputState as any, {
            Id: Id,
            displayID: displayID,
            name: name,
            street: street,
            city: city,
            state: state,
            zip: zip,
            thumbnailURL: thumbnailURL
          }]);
        }
      }
      else{
        //remove card from checkedCard object
        removeFromCheckedInfoCardData(dispatch, inputState as any,
          {
            Id: Id,
            displayID: displayID,
              name: name,
            country: country,
            street: street,
            city: city,
            state: state,
            zip: zip,
            thumbnailURL: thumbnailURL
          }
        )
      }
    }
  }

  const handleInputType = () => {
    switch (inputType) {
      case 'radio':
        return (
          <div className="form-check ml-3 pb-3 pt-2">
            <input ref={checkInput} className="form-check-input" type="radio" name="choose1" value="" onClick={() => {handleCheckedInfoCard()}}/>
          </div>
        );
      case 'checkbox':
        return (
          <div className="form-check ml-3 pb-3 pt-2">
            <input ref={checkInput} className="form-check-input" type="checkbox" value="" onClick={() => {handleCheckedInfoCard()}}/>
          </div>
        );
      default: return false;
    }
  }
  //check card based off of checked prop
  useEffect(() => {
    if(checkInput.current !== undefined && checkInput.current !== null){
      if(checked === true){
        checkInput.current.checked = true;
      }
      else{
        checkInput.current.checked = false;
      }
    }
  },[checkInput.current, checked])

  useEffect(() => {
    handleCheckedInfoCard()
  },[])

  return (

    <div className='section-dropdown-info-card col-12 col-sm-6 col-md-4 pt-2 py-2 px-2'>
      <div className='card'>
        {handleInputType()}
        <Link to={`/${url}/${Id}/details`} onClick={() => resetBodyStyles()}>
          <div className="card-body pt-3">
            <p className="card-text"><small>{displayID}</small></p>
            <p className="card-text"><strong>{name}</strong></p>
            <p className="card-text"><small>{street}</small></p>
            <p className="card-text"><small>{city}, {state} {zip}</small></p>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default InfoCard;





