import React from 'react';
import { RefreshData as RefreshDataMol } from '../../../molecules';
import useDataGrid from '../use-data-grid';

interface Props {
    iconOnly?: boolean;
    className?: string;
}

export default function RefreshData({ iconOnly, className }: Props) {

    const grid = useDataGrid();
    return (
        <>
            <RefreshDataMol
                small
                onClick={() => (grid.onRefresh) ? grid.onRefresh() : null}
                className={className}
                iconOnly={iconOnly}
                loading={grid.isLoading}
                error={grid.fetchStatusCode}
            />
        </>
    )
}