import React from 'react';
import { Content } from '../../organisms';
import { Row, Col } from 'react-bootstrap';
import styles from './notFound.module.scss';

//component imports
import { MenuBar } from '../../organisms';

//import images
import PageNotFoundGraphic from '../../../_assets/images/graphic-page-not-found.svg';

function NotFound() {
    return (
        <>
            <MenuBar
                landingIcon={<i className="fas fa-frown-open"></i>}
                paths={[{ name: 'You Lost Bro?', url: '/' }]}
            />
            <Content>
                <Content.Column>
                    <Content.Row>
                        <Col>
                            <Row className={'d-flex justify-content-center align-items-center py-4 my-4'}>
                                <Col xs={5}>
                                    <img src={PageNotFoundGraphic} className="img-fluid" alt="404, Sorry Page Not Found!" />
                                </Col>
                                <Col xs={12} className={`d-flex justify-content-center pt-4`}>
                                    <small className={styles.waterMark}>
                                        <a href="https://www.freepik.com/free-photos-vectors/background" target='_blank' rel='noopener noreferrer'>Background vector created by freepik - www.freepik.com</a>
                                    </small>
                                </Col>
                            </Row>
                        </Col>

                    </Content.Row>
                </Content.Column>
            </Content>
        </>
    );
}

export default NotFound;