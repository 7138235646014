import React from 'react';
import { ContentLanding } from '../../../atoms';

interface Props {
    children: JSX.Element[] | JSX.Element;
}

export default function Landing(props: Props) {

    return (
        <>
            <ContentLanding {...props} />
        </>
    )
}