import React from "react";
import IConfig from './interfaces';
import { useConfig } from '../hooks';


export default function Config({
  alerts,
  languageSwitcher,
  search,
  appLogo,
  appVersion,
  appLogout,
  appLogin,
  apiBaseURLTypes,
  apiFetchCredentials,
  apiBaseHeaders,
  noAuthRedirect,
  mainMenu,
  settings,
  checkAuthBeforeRender,
  collapseWidgetBar,
  children
}: IConfig.Props) {

  const config = useConfig();


  React.useEffect(() => {

    //enables are disables alert menu
    config.setAlerts(alerts);

    //enables global search ui
    config.setSearch(search);

    //enables main menu ui
    config.setMainMenu(mainMenu);

    //enable settings modal
    config.setSettings(settings);

    //set app logo
    config.setAppLogo(appLogo);

    //sets api base headers
    config.setBaseHeaders(apiBaseHeaders);

    //sets api base url types
    config.setAPIBaseURLTypes(apiBaseURLTypes);

    //sets global api fetch credentials for useFetch
    config.setAPIFetchCredentials(apiFetchCredentials);

    //set logout specs
    config.setAppLogout(appLogout);

    //sets noAuthRedirect
    config.setNoAuthRedirect(noAuthRedirect);

    //sets checkAuthBeforeRender state
    config.setCheckAuthBeforeRender(checkAuthBeforeRender);

    //set language switcher header icon
    config.setLanguageSwitcher(languageSwitcher);

    //sets default widget bar collapse state
    config.setCollapseWidgetBar(collapseWidgetBar);

    //sets login page specs
    config.setAppLogin(appLogin);

  }, []);

  React.useEffect(() => {
    //set app version
    config.setAppVersion(appVersion);
  }, [appVersion]);

  return (
    <>
      {children}
    </>
  )
}
