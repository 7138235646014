import React from 'react';
import { GridSplash, LoadingIndicator } from '../../atoms';

interface Props {
    width?: number;
    height?: number;
}
export default function GridNoColumnsSplash({ width, height }: Props) {

    return (
        <>
            <tr>
                <td className={'p-0 border-0'}>
                    <GridSplash
                        width={width}
                        height={height}
                        icon={<i className="fad fa-columns text-info"></i>}
                        text={'No Columns Selected...'}
                    />
                </td>
            </tr>
        </>
    )
}