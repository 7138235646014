import React from 'react';
import { Dropdown, Badge } from 'react-bootstrap';

interface Props {
    small?: boolean;
    large?: boolean;
    children?: JSX.Element | JSX.Element[];
    label?: string;
    icon?: JSX.Element;
    className?: string;
    count?: number;
    caret?: boolean;
    caretDir?: 'right' | 'left' | 'down' | 'up';
    variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | string;
}
export default function BtnToggle({ small, large, children, label, icon, className, variant, count, caretDir, caret }: Props) {

    const isCounter = (typeof count === 'number') ? true : false;

    return (
        <>
            <Dropdown.Toggle variant={variant} size={(small) ? 'sm' : (large) ? 'lg' : undefined} className={className}>
                {
                    (children) ?
                        children :
                        <>
                            <span className={'pr-2'}>{icon}</span>
                            <span className={'pr-1'}>{label}</span>
                            {
                                <Badge variant="light" className={'mr-0'}>{count}</Badge>
                            }
                            {
                                (caret) ?
                                    <i className={`fas fa-caret-${(caretDir) ? caretDir : 'right'} pl-2 pt-1`}></i> : null
                            }

                        </>
                }
            </Dropdown.Toggle>
        </>
    )
}