/*
    hook for setting local storage in the browser
*/
import React from 'react';

const useLocalStorage = () => {

    const isEnabled = (typeof (Storage) !== "undefined") ? true : false;

    const set = (key: string, value: any) => {
        const processedValue = (typeof value === 'object') ? JSON.stringify(value) : value;
        if (isEnabled) {
            localStorage.setItem(key, processedValue);
        }
    }

    const get = (key: string) => {
        if (isEnabled) {
            return localStorage.getItem(key);
        }
    }

    const remove = (key: string) => {
        if (isEnabled) {
            localStorage.removeItem(key);
        }
    }

    return {
        isEnabled,
        set,
        get,
        remove
    }

}

export default useLocalStorage;