import React from "react";
import { TableRow, TableRowExpand, TableCell, TableCellContent } from '../../../atoms';
import useRow from './use-row';
import useDataGrid from "../use-data-grid";

interface Props {
  children?: JSX.Element | JSX.Element[];
  rowData?: {
    [key: string]: any;
    ptrui_cacheKey: number;
  };
}
function Row({ children, rowData }: Props) {

  const grid = useDataGrid();
  const row = useRow();
  const isStripped = (rowData) ? (rowData?.ptrui_cacheKey / 2 || rowData?.ptrui_cacheKey === 0) ? false : true : false;

  return (
    <>
      {
        (rowData) ?
          <>
            <TableRow
              isStripped={isStripped}
              data={rowData}
            >
              {
                grid.columnFields?.map(
                  ({ field, trebleKey }: { field: string, trebleKey: number }) => {
                    return (
                      <React.Fragment key={trebleKey}>
                        <TableCell
                          width={grid.columnWidths.find((item: any) => item.target === field)?.width}
                          className={row.createCustomCell(field, rowData).className}
                        >
                          <TableCellContent
                            index={trebleKey}
                          //data={rowData[field]}
                          // as={row.createCustomCell(field, rowData).Component}
                          >
                          </TableCellContent>
                        </TableCell>
                      </React.Fragment>
                    );
                  }
                )
              }
            </TableRow>
          </> : null
      }
    </>
  );
}
export default React.memo(Row);
