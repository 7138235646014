import { useAppStore } from '../_Store';

const useMainMenu = () => {

    const [{ ptrui_mainMenuState }, Store, Utils] = useAppStore();

    const open = () => Store.update(Utils.actions.ptrui_updateMainMenuState, true);
    const close = () => Store.update(Utils.actions.ptrui_updateMainMenuState, false);
    const toggle = () => Store.toggle(Utils.actions.ptrui_updateMainMenuState, ptrui_mainMenuState);
    const isOpen = ptrui_mainMenuState;

    return {
        close,
        open,
        toggle,
        isOpen
    }
}

export default useMainMenu;