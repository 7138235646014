import React from 'react';

interface Props {
    width?: string;
    hide?: boolean;
    className?: string;
    children?: JSX.Element | JSX.Element[];
}
export default function TableCell({ width, hide, className, children }: Props) {

    return (
        <>
            <td style={{ width: width }} className={`${(hide) ? 'd-none' : ''} ${className}`}>
                {children}
            </td>
        </>
    )
}