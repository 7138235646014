interface InfoCardInterface{
    displayID: string,
    Id: number,
    name: string,
    country: string,
    street: string,
    city: string,
    state: string,
    zip: string,
    thumbnailURL?: string
}
interface RemoveFromCheckedInfoCardDataInterface {
    (
        dispatch: (
            object: {
                type: 'removeFromCheckedInfoCardData', 
                removeFromCheckedInfoCardData: InfoCardInterface[]
            }
        ) => object,
        currentCheckedCards: InfoCardInterface[],
        unCheckedCard: InfoCardInterface
    ): void
}

const removeFromCheckedInfoCardData:RemoveFromCheckedInfoCardDataInterface  = (dispatch, currentCheckedCards, unCheckedCard) => {
    
    let newCheckedCardList: InfoCardInterface[] = []
    currentCheckedCards.forEach((card: InfoCardInterface) => {
        if(unCheckedCard.displayID !== card.displayID){
            newCheckedCardList.push(card);
        }
    })
    dispatch({
        type: 'removeFromCheckedInfoCardData',
        removeFromCheckedInfoCardData: newCheckedCardList
    });
    return false;
}
export default removeFromCheckedInfoCardData;