import React from 'react';
import { ListItemDivider } from '../../../atoms';

interface Props {
    className?: string;
}
export default function Divider({ className }: Props) {

    return (
        <>
            <ListItemDivider className={className} style={{ borderColor: 'black' }} />
        </>
    )
}