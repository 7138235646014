import React from 'react';
import { Row, Col } from 'react-bootstrap';
import RenderCards from './render-cards';
import LoadingData from './loading-data';
import NoResults from './no-results';
import NoChecked from './no-checked';
import ErrorResults from './error-results';
import { useCardGridStore } from '../_Store/Store';
import styles from './renderData.module.scss';

interface Props {
    className?: string;
}
export default function RenderData({ className }: Props) {

    const renderDataBottomRef = React.useRef<any>(null);
    const cardGridContainerRef = React.useRef<any>(null);
    const [{ loadingState, activePage, renderedCardData, queriedCardGridData, alphaFilter, checkedList, fetchStatusCode }, Store, Util] = useCardGridStore();
    const pageCount = renderedCardData.length - 1;

    function isInViewport(offset: number, el: any) {
        if (!el) return false;
        const top = el.getBoundingClientRect().top;
        return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
    }

    const handleScroll = () => {

        const el = renderDataBottomRef.current;
        if (el !== null) {
            if (isInViewport(0, el)) {
                if (activePage < pageCount) {
                    Store.update(Util.actions.updateActivePage, activePage + 1);
                }
            }
            else if (cardGridContainerRef.current.scrollTop === 0) {
                if (activePage !== 0) {
                    cardGridContainerRef.current.scrollBy(0, 200);
                    Store.update(Util.actions.updateActivePage, activePage - 1);
                }
            }
        }
    }

    return (
        <>
            <Row ref={cardGridContainerRef} className={`${styles.renderData} pt-2 ${className}`} onScroll={handleScroll}>
                <Col>
                    {
                        (loadingState) ?
                            <LoadingData /> :
                            (fetchStatusCode !== null && fetchStatusCode !== undefined) ?
                                <ErrorResults /> :
                                (alphaFilter.active === 'checked' && checkedList.length === 0) ?
                                    <NoChecked /> :
                                    (Object.values(queriedCardGridData).length === 0) ?
                                        <NoResults /> :
                                        <RenderCards />
                    }
                    <div ref={renderDataBottomRef}></div>
                </Col>
            </Row>
        </>
    )
}