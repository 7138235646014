import React from 'react';
import { useNonInitialEffect } from '../../../../hooks';
import { InputField } from '../../../atoms';
import useDataGrid from '../use-data-grid';
import useColumnSearchFilter from './use-column-search-filter';

interface Props {
    onChange?: (value?: string) => void;
    value?: string;
    field: string;
}
export default function ColumnSearchFilter({ field, onChange, value: valueProp }: Props) {

    const grid = useDataGrid();
    const search = useColumnSearchFilter(field, onChange);
    const isDisabled = (grid.disableColumnFilters) ? true : grid.isLoading;

    //listens for value change and then updates search query
    useNonInitialEffect(() => {
        search.updateQuery(search.inputValue, grid.searchQueries);
    }, [search.inputValue]);

    //addes default search query object
    React.useEffect(() => {
        grid.addSearchQuery({
            target: field,
            query: '',
            exact: true,
            type: "string"
        });
    }, []);

    //watches for external reset
    useNonInitialEffect(() => {
        search.onReset(valueProp);
    }, [valueProp]);

    return (
        <>
            <InputField
                {...search.bindToInput}
                className={'pt-2'}
                placeHolder={'Search'}
                size={'sm'}
                type={'text'}
                disabled={isDisabled}
            >

            </InputField>
        </>
    )
}