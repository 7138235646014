import StateInterface from './state-interface';

//Component State Object

const State:StateInterface = {

    infoCardData: [],
    checkedInfoCardData: []
   
}

export default State;