//Sheetjs html table to excel exporter 
//url: https://github.com/sheetjs/js-xlsx

//Note: Visual Studio IDE does not like the XLSX library.  Need to find an alternative

// import {saveAs} from 'file-saver';
//import XLSX from 'xlsx';

const exportTableToExcel = (tableRef: HTMLElement, filename: string) => {
  // let table = tableRef;
  // let cleanedFileName = filename.replace(/ /g, '-').toLowerCase().substring(0, 30);
  //let wb = XLSX.utils.table_to_book(table, {sheet:cleanedFileName} as object);
  //let wbout = XLSX.write(wb, {bookType:'xlsx', bookSST:true, type: 'binary'});

  //let s2ab = (s: any) => {
  //    let buf = new ArrayBuffer(s.length);
  //    let view = new Uint8Array(buf);
  //    for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
  //    return buf;
  //}
  //saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `${cleanedFileName}.xlsx`);
  
}

 export default exportTableToExcel;




