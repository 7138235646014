import React, {useRef, useEffect} from 'react';
import {useDataGridContext} from '../../data-grid-context';

interface Props{
    column: string,
    disabled?: boolean
}

function RowSearch(props: Props){

    //context
    type AppContextType = [
        {
          rowData: {
            render: boolean,
            data: string[],
            flags: object[]
          }[]
        }, 
        ({}) => object
      ];
    const [{rowData}, dispatch]: AppContextType = useDataGridContext();

    //props variables
    const columnName = props.column;
    const disabled = props.disabled;

    //refs
    const searchFilter = useRef<HTMLInputElement>(null);

    //handler functions
    const handleSearch = () => {
        let newRowDataArray: object[] = [];
        let searchArray: string[] = [];
        let searchFilterRef = searchFilter.current as HTMLInputElement;
        let searchInputs: HTMLInputElement[] = [...(searchFilterRef as any).closest('.data-grid-header').getElementsByTagName('input')];
        searchInputs.map((input) => {
            return(
                searchArray.push(input.value.toLowerCase())
            )
        });
        rowData.map((item) => {
            let renderValue = () => {
                let renderArray: boolean[] = [];
                item.data.map((value, index) => {
                    if(typeof value === 'string'){//checks to see if value is an object
                        if(value.toLowerCase().indexOf(searchArray[index]) > -1){
                            return(
                                renderArray.push(true)
                            )
                        }
                        else{
                            return(
                                renderArray.push(false)
                            )
                        };
                    }

                });
                let render = !renderArray.includes(false);
                return render;
            };
            let object = {
                render: renderValue(),
                flags: item.flags,
                data: item.data
            };
            return(
                newRowDataArray.push(object)
            )
        });
        dispatch({
            type: 'setRowData',
            setRowData: newRowDataArray
        });
    }

    //sees if there are any search results and if there is none displays the "not results" icon
    
    const handleNoResults = (value: {render: boolean}[]) => {
      if (value.length > 0) {
            let renderValue = () => {
                let renderArray: boolean[] = [];
                value.map((rowObject) => {
                    if (rowObject.render === true) {
                        return(
                            renderArray.push(true)
                        )
                    }
                    else {
                        return(
                            renderArray.push(false)
                        )
                    }
                });
                let render = !renderArray.includes(true);
                return render;
            }
            dispatch({
                type: 'setNoResults',
                setNoResults: renderValue()
            });
      }
    }

    //runs the handleResults function everytime rowData changes
    useEffect(() => {
        handleNoResults(rowData);
     // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [rowData])
   
  
    return(
        <div className='data-grid-search-cell p-2'>
            <div className="input-group p-0 m-0">
                <input ref={searchFilter} type="text" className={`form-control form-control-sm ${(disabled) ? 'invisible' : ''}`} placeholder={`Filter ${columnName}...`} onKeyUp={handleSearch}/>
            </div>
        </div>
    )
}

export default RowSearch;
