import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { default as DayPickerComp, Modifiers, Modifier, DayModifiers, LocaleUtils } from "react-day-picker";

import 'react-day-picker/lib/style.css';
import styles from './dayPicker.module.scss';

interface Props {
    range: any;
    dateRange: any;
    selectedDay: any;
    modifiers?: Modifiers;
    onDayClick?: any;
    handleReset: any;
    handleToday: any;
}

function YearMonthForm(props : any) {
  const date : Date = props.date;
  const localeUtils = props.localeUtils;
  const onChange = props.onChange;
  const currentYear = new Date().getFullYear();
  const fromMonth = new Date((date ? date.getFullYear() : currentYear) - 100, 0);
  const toMonth = new Date(currentYear + 100, 11);

  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = (e : any) => {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  const getMonhAbreviation = (month : string) => {
    switch(month) {
      case 'January':
        return 'Jan';
      case 'February':
        return 'Feb';
      case 'March':
        return 'Mar';
      case 'April':
        return 'Apr';
      case 'May':
        return 'May';
      case 'June':
        return 'Jun';
      case 'July':
        return 'Jul';
      case 'August':
        return 'Aug';
      case 'September':
        return 'Sep';
      case 'October':
        return 'Oct';
      case 'November':
        return 'Nov';
      case 'December':
        return 'Dec';
      default:
        return '';
    }
  };

  return (
    <form className="DayPicker-Caption">
      <Form.Control
                            as="select"
                            size={'sm'}
                            name="month"  
                            value={date.getMonth()}
                            onChange={(e: any) => handleChange(e)}
                        >
        {months.map((month : any, i : number) => (
          <option key={month} value={i} selected={date.getMonth() == month}>
            { getMonhAbreviation(month) }
          </option>
        ))}
      </Form.Control>
      <Form.Control
                            as="select"
                            size={'sm'}
                            name="year"  
                            value={date.getFullYear()}
                            onChange={(e: any) => handleChange(e)}
                        >
        {years.map(year => (
          <option key={year} value={year} selected={date.getUTCFullYear() == year}>
            {year}
          </option>
        ))}
      </Form.Control>
    </form>
  );
}

export default function DayPicker({ range, dateRange, selectedDay, modifiers, onDayClick, handleReset, handleToday }: Props) {

    const [month, setMonth ] = useState((range) ? dateRange.from : selectedDay);
    const handleYearMonthChange = (month : any)  => {
        setMonth(month);
    }

    return (
        <>
            <div className={`d-flex justify-content-center`}>
                <div>
                    <DayPickerComp
                        month={month}
                        className={styles.compContainer}
                        numberOfMonths={1}
                        selectedDays={(range) ? [dateRange.from, { ...dateRange }] : selectedDay}
                        modifiers={modifiers}
                        onDayClick={(e: any) => onDayClick(e)}
                        captionElement={({ date, localeUtils }) => { 
                          return <YearMonthForm
                              date={date}
                              localeUtils={localeUtils}
                              onChange={handleYearMonthChange}
                          />
                        }}
                    />
                    <div className="d-flex justify-content-start pb-2 px-2">
                        <Button
                            variant="link"
                            size="sm"
                            className="text-danger mr-1"
                            onClick={()=>{setMonth(handleReset());}}
                        >
                            reset
                        </Button>
                        <Button
                            variant="link"
                            size="sm"
                            className="text-info mr-1"
                            onClick={()=>{setMonth(handleToday());}}
                        >
                            today
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}