import React from "react";
import { useFetch } from 'treble-fetch';
import { useAppStore } from '../../../../_Store';
import { Dropdown } from "react-bootstrap";
import Input from './input';
import Menu from './menu';
import { useInput, useDebounce, useNonInitialEffect } from '../../../../hooks';
import styles from './siteSearch.module.scss';

function SiteSearch() {

  const [{ ptrui_appSearch: search }] = useAppStore();

  const defaultSearchProps = { url: '', baseURL: '', params: undefined, responseProp: '', customItem: () => (<></>) };

  const { url, baseURL, params, responseProp, customItem: CustomItem } = (search) ? search : defaultSearchProps;
  const { bind, value } = useInput('');
  const [paramsState, setParamsState] = React.useState({});

  const { response, error, loading } = useFetch(`${baseURL}${url}`, {
    trigger: [paramsState],
    method: 'POST',
    initialMount: false,
    headers: { 'Content-Type': 'application/json' },
    body: paramsState,
    credentials: (search) ? search?.credentials : 'omit'
  });

  const handleParams = useDebounce((value: string) => {
    setParamsState((typeof params === 'function') ? params(value) : {});
  }, 300);

  useNonInitialEffect(() => {
    if (value.length !== 0) {
      handleParams(value);
    }
    else {
      handleParams(null);
    }
  }, [value]);

  const data = (response.data as any)[responseProp] || [];

  return (
    <>
      <Dropdown className={styles.dropdown}>
        <Dropdown.Toggle className={styles.toggle} as={'div'}>
          <Input bind={bind} />
        </Dropdown.Toggle>
        <Menu loading={loading} error={error} data={data} />
      </Dropdown>
    </>
  );
}

export default SiteSearch;
