import React from 'react';
import { Dropdown } from 'react-bootstrap';
import styles from './errorMessage.module.scss';

export default function ErrorMessage() {

    return (
        <>
            <Dropdown.ItemText className={styles.errorMessage}>
                <p className='mb-0 text-danger'>
                    <i className="fas fa-exclamation-triangle pr-2"></i>
                    Fetch Error
                </p>
            </Dropdown.ItemText>
        </>
    )
}