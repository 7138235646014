import React from 'react';
import { Button } from 'react-bootstrap';
import { ListItemHeader, ListItemDivider, ListItemCheck } from '../../atoms';
import { BtnWithDropdown, IconWithDropdown } from '..';
import styles from './selectedActions.module.scss';

interface Props {
    iconOnly?: boolean;
    count?: number;
    small?: boolean;
    large?: boolean;
    className?: string;
    classNameDropdown?: string;
    totalCount?: number;
    onChange?: (value: React.FormEvent<HTMLInputElement>) => void;
    onShowAllChange?: (isChecked: boolean) => void;
    onSelectAllChange?: (isChecked: boolean) => void;
    onUnselectClick?: () => void;
}
export default function SelectedActions({ iconOnly, small, large, className, classNameDropdown, count, onSelectAllChange, onShowAllChange, totalCount, onUnselectClick }: Props) {

    const size = (small) ? 'sm' : (large) ? 'lg' : undefined;
    const [selectAllChecked, setSelectAllChecked] = React.useState(false);
    const [showAllChecked, setShowAllChecked] = React.useState(false);

    const onSelectAll = (value: React.FormEvent<HTMLInputElement>) => {
        const isChecked = (value.target as any)?.checked || false;
        if (isChecked) {
            setSelectAllChecked(true);
            if (onSelectAllChange) {
                onSelectAllChange(isChecked);
            }
        }
        else {
            setSelectAllChecked(false);
            if (onSelectAllChange) {
                onSelectAllChange(isChecked);
            }
        }
    }

    const onShowAll = (value: React.FormEvent<HTMLInputElement>) => {
        const isChecked = (value.target as any)?.checked || false;
        if (isChecked) {
            setShowAllChecked(true);
            if (onShowAllChange) {
                onShowAllChange(isChecked);
            }
        }
        else {
            setShowAllChecked(false);
            if (onShowAllChange) {
                onShowAllChange(isChecked);
            }
        }
    }

    const onUnselect = () => {
        if (onUnselectClick) {
            onUnselectClick();
        }
    }

    //checks to make sure select all is false when at least one row is selected
    React.useEffect(() => {
        if (count !== undefined && totalCount) {
            if (count < totalCount || count === 0) {
                setSelectAllChecked(false);
            }
        }
    }, [count, totalCount]);

    //checks to make sure show all is false when no rows are selected
    React.useEffect(() => {
        if (count !== undefined) {
            if (count === 0) {
                setShowAllChecked(false);
            }
        }
    }, [count]);

    const DropdownMenu = () => (
        <>
            <ListItemHeader text={`Selected: ${count}`} />
            <ListItemDivider />
            {
                (onShowAllChange) ?
                    <ListItemCheck
                        label={'Show Selected Only'}
                        isChecked={showAllChecked}
                        value={'show_selected_only'}
                        onChange={(value) => onShowAll(value)}
                    /> : null
            }
            {
                (onSelectAllChange) ?
                    <ListItemCheck
                        label={'Select All'}
                        isChecked={selectAllChecked}
                        value={'select_all'}
                        onChange={(value) => onSelectAll(value)}
                    /> : null
            }
            <ListItemDivider />
            <div className='d-flex justify-content-end pr-3'>
                <Button size={'sm'} variant="link" onClick={onUnselect}>Clear All</Button>
            </div>
        </>
    )

    return (
        <>
            {
                (iconOnly) ?

                    <IconWithDropdown
                        icon={<i className={`fas fa-check-square text-info ${styles.iconOnly}`}></i>}
                        count={count}
                        className={`${className}`}
                        classNameToggle={'pt-0'}
                        caret
                        drop={'down'}
                        text={'Selected'}
                        toolTip={false}
                        classNameDropdown={classNameDropdown}
                    >
                        <DropdownMenu />
                    </IconWithDropdown>

                    :
                    <BtnWithDropdown
                        counter={count}
                        small={(size === 'sm') ? true : false}
                        large={(size === 'lg') ? true : false}
                        variant={'info'}
                        label={'Selected'}
                        icon={<i className="fas fa-check-square text-white"></i>}
                        className={className}
                        caret
                        caretDir={'down'}
                        classNameDropdown={`${styles.dropdown} ${classNameDropdown}`}
                    >
                        <DropdownMenu />
                    </BtnWithDropdown>
            }
        </>
    )
}