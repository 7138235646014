import React from 'react';
import { Button } from 'react-bootstrap';
import LoadingIndicator from '../loading-indicator';

interface Props {
    label?: string;
    fetchingLabel?: string;
    loading?: boolean;
    onClick?: (toggle: boolean) => void;
}
export default function RefetchDataBtn({ label, loading, fetchingLabel }: Props) {

    return (
        <>
            <Button variant={'info'} disabled={loading}>
                {
                    (!loading) ?
                        <>
                            <i className="fas fa-database pr-2"></i>
                            {
                                (label) ? label : 'Refetch Data'
                            }
                        </> :
                        <>
                            <span className={'pr-2'}>
                                <LoadingIndicator size={1} color={'white'} />
                            </span>
                            {
                                (fetchingLabel) ? fetchingLabel : '...Fetching'
                            }
                        </>
                }

            </Button>
        </>
    )
}