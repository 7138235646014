import React, { useEffect, useState } from 'react';
import InfoCard from './info-card';
//import {ClapSpinner} from 'react-spinners-kit';
import uniqid from 'uniqid';
import './_info-card-section.scss';

//components
import Search from './search';

//context
import useInfoCardSectionContext from './_context';

//actions
import { setInfoCardData } from './_actions';

//interface
import InfoCardSectionPropsInterface from './props-interface';
import { set } from 'local-storage';


function InfoCardSection(props: InfoCardSectionPropsInterface) {

    //props
    const dataSet = props.dataSet;
    const inputType = props.inputType;
    const url = props.url;
    const settingsAction = props.settingsAction;
    const setCheckedCards = props.checkedCards;
    const inputState = props.inputState;
    const search = props.search;
    const darkMode = props.darkMode;

    //state
    const [areThereResults, setAreThereResults] = useState<boolean>(true);

    //context
    type ContextType = [
        {
            infoCardData: {
                cardID: string,
                render: boolean,
                checked: boolean,
                data: {
                    displayID: string,
                    Id: number,
                    name: string,
                    country: string,
                    street: string,
                    city: string,
                    state: string,
                    zip: string,
                    thumbnailURL?: string
                }
            }[],
            checkedInfoCardData: {
                displayID: string,
                Id: number,
                name: string,
                country: string,
                street: string,
                city: string,
                state: string,
                zip: string,
                thumbnailURL?: string
            }[]
        },
        ({ }: any) => void
    ];

    const [{ infoCardData, checkedInfoCardData }, dispatch]: ContextType = useInfoCardSectionContext();

    //handlers
    const handleNoResults = () => {
        let areThereRenderedCardsValue = true;
        for (var i = 0; i < infoCardData.length; i++) {
            if (infoCardData[i].render === true) {
                areThereRenderedCardsValue = true;
                break;
            }
            else {
                areThereRenderedCardsValue = false;
                continue;
            }
        }

        return areThereRenderedCardsValue;
    }

    //save checkedInfoCardData to checkedCards state object (this will be returned)
    useEffect(() => {
        if (dataSet !== undefined && setCheckedCards !== undefined && inputState !== undefined) {
            setCheckedCards(
                (checkedInfoCardData.length > 0) ? checkedInfoCardData : inputState
            );
        }
    }, [dataSet, checkedInfoCardData, setCheckedCards, inputState]);


    //sets default checked cards on component mount (reads inputState object to do this)
    useEffect(() => {
        if (dataSet !== undefined && inputState !== undefined) {
            setInfoCardData(dispatch as any, dataSet, true, inputState);
        }
    }, [dataSet, inputState]);

    //checks to see if a render true exists in infoCardData array.
    useEffect(() => {
        if (infoCardData !== undefined) {
            setAreThereResults(() => handleNoResults());
        }
    }, [infoCardData]);


    return (
        <>
            <div className='col-12 info-card-section pt-2'>
                {
                    (search !== false) ?
                        <div className="row d-flex info-card-section-search" style={{ height: '75px' }}>
                            <div className="col-10 col-lg-10">
                                <Search placeHolder={'Cards'} darkMode={darkMode} inputType={inputType} inputState={inputState} setCheckedCards={setCheckedCards} />
                            </div>
                            {
                                (settingsAction && checkedInfoCardData.length > 0) ?
                                    <div className="col-2 col-lg-2 d-flex justify-content-end align-items-center">
                                        <i className="fas fa-cog pr-4 text-white" style={{ cursor: 'pointer' }} onClick={() => settingsAction()}></i>
                                    </div> : null
                            }

                        </div> : null
                }
                <div className="row d-flex justify-content-center pt-4 info-card-section-card-group-container">
                    <form name="info-card-section-card-group" className="info-card-section-card-group row px-4 pb-3">
                        {
                            (infoCardData.length > 0) ?
                                (areThereResults !== false) ?
                                    infoCardData.map((card, index) => {
                                        return (
                                            <React.Fragment key={Math.random.toString() + index + uniqid}>
                                                {
                                                    (card.render !== false) ?
                                                        <InfoCard
                                                            inputType={(!inputType) ? null : (inputType === 'radio') ? 'radio' : (inputType === 'checkbox') ? 'checkbox' : null}
                                                            inputState={inputState}
                                                            Id={card.data.Id}
                                                            displayID={card.data.displayID}
                                                            name={card.data.name}
                                                            country={card.data.country}
                                                            street={card.data.street}
                                                            city={card.data.city}
                                                            state={card.data.state}
                                                            zip={card.data.zip}
                                                            url={url}
                                                            checked={card.checked}
                                                            thumbnailURL={card.data.thumbnailURL}
                                                        /> : null
                                                }
                                            </React.Fragment>
                                        )
                                    }) :
                                    <div className='info-section-no-results d-flex justify-content-center align-items-center'>
                                        <div className='py-4'>
                                            <p className='text-center'><i className="fas fa-frown"></i></p>
                                            <p className='text-center'>no results</p>
                                        </div>
                                    </div>
                                : <div className='info-card-section-spinner d-flex justify-content-center align-items-center'>
                                    <div className='py-4'>
                                        {/* <ClapSpinner 
                                        size={45}
                                        /> */}
                                    </div>
                                </div>
                        }
                    </form>
                </div>
            </div>
        </>
    )
}

export default InfoCardSection;

