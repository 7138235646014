import React from 'react';
import styles from './gridSplash.module.scss';

interface Props {
    width?: number;
    height?: number;
    icon?: JSX.Element;
    text?: string;
    smallText?: string | JSX.Element;
    className?: string;
    textColor?: 'text-danger' | 'text-info' | 'text-warning' | 'text-primary' | 'text-secondary' | 'text-success' | 'text-light' | 'text-dark' | 'text-muted' | 'text-white';
    smallTextColor?: 'text-danger' | 'text-info' | 'text-warning' | 'text-primary' | 'text-secondary' | 'text-success' | 'text-light' | 'text-dark' | 'text-muted' | 'text-white';
}
export default function GridSplash({ width, icon, text, smallText, height, className, smallTextColor, textColor }: Props) {

    return (
        <>
            <div className={`${styles.compContainer} ${className}`} style={{ height: height }}>
                <div
                    className={`${(width) ? (width > 0) ? 'd-flex' : 'd-none' : 'd-none'} flex-column align-items-center justify-content-center`}
                    style={{ height: "100%", width: `100%` }}
                >
                    <div className={styles.icon}>
                        {icon}
                    </div>
                    <h5 className={`pt-3 ${(textColor) ? textColor : 'text-dark'}`}>{text}</h5>
                    {
                        (smallText) ?
                            <p className={`${(smallText) ? smallTextColor : 'text-dark'}`}>{smallText}</p> : null
                    }
                </div>
            </div>
        </>
    )
}