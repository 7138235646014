import React from "react";

interface Props {
  children: JSX.Element | JSX.Element[];
  targetField: string;
  className?: string;
}
export default function Cell(props: Props) {
  return <>{props.children}</>;
}
