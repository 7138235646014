import React from 'react';
import { IIcon } from '../interfaces';
import icoHazmat from '../../../../_assets/images/ico_hazmat.svg';

function Hazmat({ onClick, type, color, className, styles }: IIcon.Props) {
    return (
        <img src={icoHazmat} alt='' onClick={onClick} />
    )
}

export default Hazmat;