import React from "react";
import { Card } from 'react-bootstrap';
import { Link } from '../../../utils';
import IDataBar from './interfaces';
import useDataBar from './use-data-bar';
import uniqid from "uniqid";
import styles from './dataBar.module.scss';

export default function DataBar({ dataSet }: IDataBar.Props) {

    const dataBar = useDataBar();

    return (
        <Card.Footer className={`${styles.compContainer} d-flex justify-content-center`}>
            {
                dataSet.map((data) => (
                    <div
                        key={uniqid()}
                        className={`${styles.value} text-center px-3`}
                    >
                        <Link to={(data.link) ? data.link : ''}>
                            <p className={`pt-4 pb-2 ${(data?.color) ? dataBar.getColor(data.color) : ''}`}>{data.value}</p>
                            <p className="pt-4 pb-2 text-white">{data.title}</p>
                        </Link>
                    </div>
                ))
            }
        </Card.Footer>
    );
}
