import React from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { useNonInitialEffect } from '../../../../hooks';
import { useDataGridStore, TStore } from '../_Store';
import ColumnItem from './column-item';
import styles from './columnSelector.module.scss';

interface Props {
    iconOnly?: boolean;
    size?: 'sm' | 'lg';
}

export default function ColumnSelector({ size, iconOnly }: Props) {

    //subscribe to store
    const [{ columnFields, searchQueries }, Store] = useDataGridStore() as TStore;
    const [checkAll, setCheckAll] = React.useState(true);

    useNonInitialEffect(() => {
        if (checkAll) {
            let disabledFields = columnFields.filter((column) => column.enabled === false);
            disabledFields.map((column) => {
                let matchedQuery = searchQueries.find((query) => query.target === column.field);
                if (matchedQuery !== undefined && matchedQuery?.query !== "") {
                    Store.edit('updateSearchQueries', { ...matchedQuery, query: "" });
                }
            });
        }

    }, [columnFields])

    useNonInitialEffect(() => {
        if (checkAll) {
            let areThereQueries = (searchQueries.find((query) => query.query !== "")) ? true : false;
            if (areThereQueries) {
                let clearedSearchQueries = searchQueries.map((item) => {
                    return {
                        ...item,
                        query: ""
                    }
                })
                Store.update('updateSearchQueries', clearedSearchQueries);
            }

        }
    }, [checkAll])

    return (
        <>
            <DropdownButton
                className={`${styles.columnSelector} ${(iconOnly) ? styles.iconOnly : ''}`}
                variant={(iconOnly) ? 'link' : 'info'}
                size={(size) ? size : 'sm'}
                title={
                    (iconOnly) ? <i className={`fas fa-columns text-info`}></i>
                        : <span className='pr-2'><i className="fas fa-columns pr-2"></i>Columns</span>
                }
            >
                <Dropdown.Header className={'pt-0'}>Select Columns</Dropdown.Header>
                <Dropdown.Divider className={'mb-0 mt-0'} />
                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                    {
                        columnFields?.map((column) => {
                            return (
                                <React.Fragment key={column.trebleKey}>
                                    <ColumnItem columnData={column} checkAll={checkAll} />
                                </React.Fragment>
                            )
                        })
                    }
                </div>

                <Dropdown.Divider className={'mt-0'} />
                <div className={'px-3 mb-0'} >
                    <Form.Check type="checkbox" label={'Select All'} defaultChecked={checkAll} onChange={() => {
                        setCheckAll((checkAll) ? false : true);
                    }} />
                </div>
            </DropdownButton>
        </>
    )
}