/*
    Protrans React Component Store
    - Allows certain UI components to use global app state for functionality
    - example: CardGrid is able to persist check state
*/

import { createStore } from "treble-gsm";

const actionKeys = {
    ['ptrui_updateCardGridCheckList']: 'ptrui_updateCardGridCheckList'
}
export interface IStoreComps {
    ptrui_CardGridCheckList: {
        [key: string]: number[];
    };
}

export type TStoreCompActions = typeof actionKeys;

const StoreComps = createStore([
    {
        action: actionKeys.ptrui_updateCardGridCheckList,
        state: {
            ptrui_CardGridCheckList: {},
        },
    }
]);

export default StoreComps;
