import React from 'react';
import { useModalStore } from '../Store';
import { useModal } from '../../../../hooks';
import styles from './sideBar.module.scss';
interface Props {
    children?: JSX.Element | JSX.Element[];
    width?: string;
    collapse?: boolean;
    className?: string;
    onClick?: () => void
}

export default function ModalSideBar({ children, width, collapse, className, onClick }: Props) {

    const [, Store, Util] = useModalStore();

    const { isSideBarCollapsed, toggleSideBarCollapse, collapseSideBar, unCollapseSideBar } = useModal();

    const handleSideBarWidth = (width?: string) => {
        return (isSideBarCollapsed) ? '50px' : (width) ? width : '200px'
    }

    const sideBarWidth = handleSideBarWidth(width);

    //sets sideBar status
    React.useEffect(() => {
        Store.update(Util.actions.ptrui_modalBasic_setIsSidebarStatus, true);
    }, [])

    React.useEffect(() => {
        if (collapse) {
            collapseSideBar();
        } else {
            unCollapseSideBar();
        }
    }, [collapse]);

    return (
        <>
            <div className={`${styles.sidebar}
            ${(isSideBarCollapsed) ? 'd-none d-lg-flex' : 'd-flex'}
             flex-column justify-content-between ${className}`}
                style={{ width: sideBarWidth, minWidth: sideBarWidth, maxWidth: sideBarWidth }}>

                <div className={styles.content} onClick={() => (onClick) ? onClick() : null}>
                    {children}
                </div>

                <div className={`${styles.collapseBtn} d-none d-lg-flex justify-content-${(isSideBarCollapsed) ? 'center' : 'end'} align-content-center`}>
                    <i className={`fas fa-chevron-double-${(isSideBarCollapsed) ? 'right' : 'left'} p-2 cursor`} onClick={() => toggleSideBarCollapse()}></i>
                </div>
            </div>
        </>
    )
}