import React from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import styles from './loadingIndicator.module.scss';

export default function LoadingIndicator() {

    return (
        <>
            <Dropdown.ItemText className={`${styles.loadingIndicator} d-flex`}>
                <Spinner className={`${styles.spinner} mr-2`} animation={'border'} variant={'info'} />
                <p className='mb-0 text-info'>
                    Loading...
                </p>
            </Dropdown.ItemText>
        </>
    )
}