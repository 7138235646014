
interface ActionInterface {
    (
        dispatch: (
            object: {
                type: 'setInfoCardData', 
                setInfoCardData: object[]
            }
        ) => object,
        dataSet: DataInterface[],
        render: boolean,
        checkedCards?: {
            displayID: string
        }[]
    ): void
}

interface DataInterface{
    displayID: string,
    Id: number,
    name: string,
    country: string,
    street: string,
    city: string,
    state: string,
    zip: string,
    thumbnailURL?: string
}

const setInfoCardData: ActionInterface = (dispatch, dataSet, render, checkedCards ) => {

    //maps an array of dataSet displayIDs
    let dataSetIDArray = dataSet.map((data) => {
        return(data.displayID);
    });

    //maps an array of checkedCard displayIDs
    let checkedCardsIDArray = (checkedCards) ? checkedCards.map((card) => {
        return(card.displayID);
    }) : null;

    //compares both arrays and finds duplicate values and returns a value only array
    let checkedCardsFlattened = (checkedCardsIDArray) ? checkedCardsIDArray.filter(element => dataSetIDArray.includes(element)) : [];


    //
    let dataSetArray = (dataSet).map((data: DataInterface) => {
        return {
            cardID: data.displayID,
            render: render,
            checked: (checkedCardsFlattened.includes(data.displayID)) ? true : false,
            data: {
                displayID: data.displayID,
                Id: data.Id,
                name: data.name,
                street: data.street,
                city: data.city,
                state: data.state,
                zip: data.zip,
                thumbnailURL: data.thumbnailURL
            }
        }
    });
    dispatch({
        type: 'setInfoCardData',
        setInfoCardData: dataSetArray
    })
}

export default setInfoCardData;

