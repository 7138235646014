import React, { useLayoutEffect } from "react";
import Row from "../../row";
import Group from "../../group";
import { useDataGridStore, TStore } from "../../_Store";

export default function RenderGroups() {
  //subscribe to store
  const [
    {
      renderedTableData,
      renderedRowGroups,
      columnGroupTarget,
      CustomRow,
      customRowProps,
      activeGridPage,
      queriedTableData,
      gridPagingOptions,
    },
    Store,
  ] = useDataGridStore() as TStore;

  const uniqueGroupList = React.useMemo(
    () =>
      Array.from(
        new Set(
          queriedTableData?.map(
            (item: any) =>
              item[
                typeof columnGroupTarget !== "boolean"
                  ? columnGroupTarget.field
                  : ""
              ]
          )
        )
      ),
    [queriedTableData, columnGroupTarget]
  );

  const flattenedRenderedTableData = renderedTableData?.flat();

  const handleRenderedRowGroups = () => {
    Store.update("updateRenderedRowGroups", {
      tableData: uniqueGroupList,
      page: gridPagingOptions.page,
      size: gridPagingOptions.groupSize,
    });
  };

  const generateKey = (group: string, index: number) => {
    let key = group?.replace(/ /g, "-") || index;
    return key;
  };

  useLayoutEffect(() => {
    handleRenderedRowGroups();
  }, [queriedTableData, columnGroupTarget]);

  return (
    <>
      {renderedRowGroups[activeGridPage]?.map(
        (group: string, index: number) => (
          //index is used for keys. this will need to change if whole groups are allowed to be removed at a time
          <Group name={group} key={generateKey(group, index)}>
            {
              //renderedTableData is a multidimensional array. It needs to be flattened so all values can be mapped in its target group
              flattenedRenderedTableData.map((row: { [key: string]: any }) =>
                group === row[columnGroupTarget.field] ? (
                  <React.Fragment key={row.trebleKey}>
                    {CustomRow !== null ? (
                      <CustomRow rowData={row} {...customRowProps} />
                    ) : (
                      <Row rowData={row} />
                    )}
                  </React.Fragment>
                ) : null
              )
            }
          </Group>
        )
      )}
    </>
  );
}
