/*
    Column
    - component for table colums
*/
import React, { useState, useLayoutEffect, useRef } from "react";
import DatePicker from "../date-picker";
import { useDataGridStore } from "../_Store/Store";
import { Form } from "react-bootstrap";
import updateSearchQueries from "./update-search-queries";
import { useDebounce, useNonInitialEffect, useInput } from '../../../../hooks';
import styles from './column.module.scss';

interface Props {
  title: string | JSX.Element;
  sortable?: boolean;
  groupable?: boolean | "default";
  menu?: boolean;
  defaultSort?: string;
  search?: boolean;
  select?: boolean;
  field: string | boolean;
  width?: string;
  dataType?: "string" | "dateRange";
  dateRange?: boolean;
  children?: JSX.Element | JSX.Element[];
  hide?: boolean
}
export default function Column(props: Props) {

  //props
  const { title,
    sortable,
    groupable,
    menu,
    field,
    search,
    select,
    width,
    dataType,
    dateRange,
    children,
    hide
  } = props;

  //refs for column and column searches
  const isInitialMount = React.useRef(true);
  const columnRef = useRef(null);
  const columnProps = useRef();

  const {
    value: searchInput,
    bind: bindSearchInput,
    reset: resetSearchInput,
  } = useInput("");
  const {
    value: selectInput,
    bind: bindSelectInput,
    reset: resetSelectInput,
  } = useInput("");

  //subscribe to store
  const [
    {
      columnSortState,
      searchQueries,
      columnGroupTarget,
      tableData,
      resetFilterInputs,
      gridTBodyRef,
      liveRender,
      columnFields,
      columnWidths,
      debounceFilterTime,
      resetFilterColumns,
      loadingState
    },
    Store,
  ] = useDataGridStore();

  //sorting type state
  const [sortState, setSortState] = useState<
    [string, "desc" | "asc" | "reset"]
  >(["d-none", "desc"]);

  //select input list state
  const [selectState, setSelectState] = useState<string[]>([]);

  //handles unique lists
  const uniqueSelectList: string[] | boolean = React.useMemo(
    () =>
      typeof field === "string"
        ? Array.from(new Set(tableData?.map((item: any) => item[field])))
        : false,
    [tableData]
  );
  //debounced searchFilter
  const searchFilter = useDebounce((searchQueries: any, field: any, searchInput: any, e: any, Store: any, gridTBodyRef: any) => updateSearchQueries.search(searchQueries, field, searchInput, e, Store, gridTBodyRef), debounceFilterTime);

  //controls the ui and sorting type for columns.
  const changeSortState = (sortState: string) => {
    let sortType: { [key: string]: string[] } = {
      ["d-none"]: ["fa-long-arrow-alt-down", "asc"],
      ["fa-long-arrow-alt-down"]: ["fa-long-arrow-alt-up", "reset"],
      ["fa-long-arrow-alt-up"]: ["d-none", "desc"],
    };
    return sortType[sortState];
  };

  //updates the column group target state when group icon is clicked in column
  const handleColumnGroupStore = () => {
    if (!(columnGroupTarget.field === field)) {
      Store.update("changeColumnGroupTarget", { field: field, title: title });
      Store.update("updateActiveGridPage", 0);
    } else {
      Store.update("changeColumnGroupTarget", { field: "", title: "" });
      Store.update("updateActiveGridPage", 0);
    }
  };

  //handles default onload column settings
  const handleDefaultColumnSettings = () => {
    if (groupable === "default") {
      Store.update("changeColumnGroupTarget", { field: field, title: title });
    }
  };

  //toggles column group sort
  const toggleColumnSort = () => {
    //updates column sort state on click
    Store.update("changeColumnSortState", columnRef);
    if (!(liveRender)) {
      if (sortState[1]) {
        if (sortState[1] !== "reset") {
          Store.orderBy("updateTableData", field, sortState[1]);
        } else {
          //targets trebleKey prop to get original order, feature.keys must be set to true in Store
          Store.orderBy("updateTableData", "trebleKey", "asc");
        }
      }
    }

    //resets active grid page and scroll position
    Store.update('updateActiveGridPage', 0);
    gridTBodyRef.current.scrollTop = 0;

    if (liveRender) {
      Store.update('setActiveColumnSort', {
        target: field,
        sortType: sortState[1]
      })

      //resets tableData so the newly fetched data does not get concatinated to the old data
      Store.update('updateTableData', []);
    }
    setSortState(changeSortState(sortState[0]) as any);
  };

  //handles select state
  const handleSelectState = () => {
    if (uniqueSelectList && tableData?.length !== 0) {
      setSelectState(uniqueSelectList);
    }
  };

  //creates static state in the store out of column props on render
  useLayoutEffect(() => {
    Store.append("createColumnFields", { field: field, title: title, enabled: (hide) ? false : true });
    Store.append("setColumnWidths", {
      target: field,
      width: width ? width : "auto",
    });
    Store.append("createColumnSearch", searchInput);

    if (search || select || dateRange) {
      let exactMatch = select ? true : false;
      Store.append("updateSearchQueries", {
        target: field,
        query:
          dataType === "dateRange" ? { from: undefined, to: undefined } : "",
        exact: exactMatch,
        type: dataType === "dateRange" ? "dateRange" : "string",
      });
    }

    handleDefaultColumnSettings();

  }, []);

  //listens for column sort state and then updates column sorting based on that
  useLayoutEffect(() => {
    if (typeof columnSortState !== "boolean") {
      if (columnSortState?.current !== columnRef?.current) {
        setSortState(["d-none", "desc"]);
      }
    }
  }, [columnSortState]);

  //generates select option list
  useLayoutEffect(() => {
    handleSelectState();
  }, [tableData]);

  //updates searchqueries when select value changes
  useLayoutEffect(() => {
    if (searchQueries.length > 0) {
      updateSearchQueries.select(
        searchQueries,
        field,
        selectInput.length > 0 ? selectInput : "",
        Store
      );
    }
  }, [selectInput]);

  useNonInitialEffect(() => {
    let thisColumn = columnFields.find((item) => item.field === field);
    Store.edit("createColumnFields", { ...thisColumn, enabled: (hide) ? false : true, });
  }, [hide]);

  //sees if column is enabled and if not it will reset the filter (search only)
  useNonInitialEffect(() => {
    // console.log(columnFields);
    let isThereADisabledField = (columnFields.find((column) => column.enabled === false)) ? true : false;
    if (isThereADisabledField) {
      let allDisabledFields = columnFields.filter((column) => column.enabled === false);
      let isThisColumnIsDisabled = (allDisabledFields.find((column) => column.field === field)) ? true : false;
      if (isThisColumnIsDisabled) {
        resetSearchInput();
      }
    }
  }, [columnFields])

  //makes sure columnRef is set to props
  React.useEffect(() => {
    if (columnProps.current) {
      columnProps.current = props as any;
    }
  }, [props]);

  //checks to see if this is the initial mount
  React.useLayoutEffect(() => {
    isInitialMount.current = false;
  }, []);

  //checks to see if column needs to be hidden
  const shouldColumnBeHidden = (columnFields: { field: string, enabled: boolean }[]) => {
    let thisColumn = columnFields.find((item) => item.field === field);
    if (!thisColumn?.enabled) {
      return true;
    }
    return false
  }

  return (
    <>
      <th ref={columnRef} style={{ width: width }} className={`${(shouldColumnBeHidden(columnFields)) ? 'd-none' : ''}`}>
        <div className={styles.column}>
          <div className="d-flex justify-content-between">
            <div
              className={`d-flex ${sortable ? "cursor" : null}`}
              onClick={(!loadingState) ? sortable ? toggleColumnSort : () => null : () => null}
            >
              {sortable ? (
                <i className={`far ${sortState[0]} ${styles.sortIcon} text-success pr-2`}></i>
              ) : null}
              <p className={`mb-0`}>{title}</p>
            </div>
            {groupable ? (
              <div
                className="cursor"
                onClick={handleColumnGroupStore}
              >
                <i
                  className={`fas fa-layer-group ${typeof columnGroupTarget !== "boolean"
                    ? columnGroupTarget.field === field
                      ? "text-success"
                      : ""
                    : ""
                    } pl-2 pb-0 mb-0`}
                ></i>
              </div>
            ) : null}
            {
              //adds sorting menu if menu prop is set to true
              menu ? (
                <div className={styles.fieldMenu}>
                  <i className="fal fa-ellipsis-v pl-2 pb-0 mb-0"></i>
                </div>
              ) : null
            }
          </div>
          {children}
          {
            //adds inline column search if prop is set to true
            search ? (
              <Form.Group className={`mb-0 pt-2 column-search-filter ${styles.searchFilter}`}>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder={`Search`}
                  {...bindSearchInput}
                  onKeyUp={(e: any) => { e.persist(); searchFilter(searchQueries, field, searchInput, e, Store, gridTBodyRef) }}
                />
              </Form.Group>
            ) : //adds inline column option input when prop is set to true
              select ? (
                <Form.Group className="mb-0 pt-2">
                  <Form.Control as="select" size="sm" {...bindSelectInput}>
                    <option value="">All</option>
                    {selectState?.map((item) => {
                      return (
                        <React.Fragment key={item}>
                          {
                            (item !== null) ?
                              (item?.length !== 0) ? (
                                <option value={item?.toString()}>{item}</option>
                              ) : null : null
                          }
                        </React.Fragment>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              ) : //adds date range picker to column when option is checked
                dateRange ? (
                  <DatePicker
                    updateDateRangeSelection={(dateRangeObject: any) =>
                      updateSearchQueries.dateRange(
                        searchQueries,
                        field,
                        dateRangeObject,
                        Store
                      )
                    }
                    currentDateRange={
                      { from: undefined, to: undefined }
                    }
                  />
                ) : null
          }
        </div>
      </th>
    </>
  );
}
