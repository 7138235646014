import React from 'react';
import { Nav } from 'react-bootstrap';
import styles from './collapseBtn.module.scss';

interface Props {
    onClick?: () => void;
    isOpen?: boolean;
    className?: string;
}

export default function CollapseBtn({ onClick, isOpen, className }: Props) {

    return (
        <>

            <Nav.Link
                as={'div'}
                className={`${styles.compContainer} d-none d-lg-flex justify-content-${(isOpen) ? 'center' : 'end'} text-white ${className}`}
            >
                <i className={`fas fa-chevron-double-${(isOpen) ? 'right' : 'left'} py-2 cursor ${styles.iconSize}`} onClick={() => { (onClick) ? onClick() : null }}></i>
            </Nav.Link>
        </>
    )
}