import React from 'react';
import { useNonInitialLayoutEffect } from '../../../hooks';
import useStore from './use-store';
import { Grid } from './interfaces';
import { withTreble } from 'treble-gsm';
import { Store } from './_Store';
import styles from './gridContainer.module.scss';

function GridContainerComp({
    children,
    height,
    className,
    loading,
    data,
    as,
    pageSize,
    disablePaging,
    rowProps,
    expandable,
    exportPDF,
    exportExcel,
    fetchStatus,
    totalDataCount,
    customExportOptions,
    columnTitles,
    gridStyles,
    expandableCheck,
    debounceFilters,
    columnSelector,
    stickyColumns,
    onFilterChange,
    onSortChange,
    onBottomScroll,
    disableClientRendering,
    onSelect,
    onRefresh,
    customItemProps
}: Grid.Props) {

    const gridRef = React.useRef(null);
    const grid = useStore();

    //listens for fetch status' (200, 404)
    React.useLayoutEffect(() => {
        grid.setFetchStatus(fetchStatus);
    }, [fetchStatus]);

    //listens for loading state changes
    React.useLayoutEffect(() => {
        grid.setLoading(loading);
    }, [loading]);

    //sets gridRef
    React.useEffect(() => {
        if (gridRef) {
            grid.setRef(gridRef);
        }
    }, [gridRef]);

    //update static store state on render
    React.useLayoutEffect(() => {

        //sets whether or client rendering is disabled
        if (disableClientRendering) {
            grid.disableClientRendering();
        }

        //sets grid height
        grid.setHeight(height);

        //sets paging options
        grid.setPagingOptions(pageSize, 0);

        //creates custom rows
        grid.setCustomRow(as);

        //makes rows expandable if set to true and sets check logic
        grid.setExpandableRows(expandable, expandableCheck);

        //removes column table header cells from grid (can be used for nested tables)
        grid.disableTableHeaders(columnTitles);

        //set column filter debounce time
        grid.setFilterDebounce(debounceFilters);

        //shows footer columnSelector icon if set to true
        grid.enableColumnSelector(columnSelector);

        //sets column header to sticky on scroll if set to true
        grid.enableStickyColumns(stickyColumns);

        //set onSelect enabled flag
        grid.setIsOnRowSelect((onSelect) ? true : false);

        //sets custom rows
        grid.setCustomRow(as);

        //sets onRefresh function
        grid.setOnRefresh(onRefresh);

    }, []);

    React.useEffect(() => {
        if (disablePaging) {
            grid.setPagingOptions(grid.cachedTableData.length, 0);
        }
    }, [grid.cachedTableData]);

    React.useEffect(() => {
        //sets export options
        grid.setExportOptions(exportPDF, exportExcel, customExportOptions);
    }, [customExportOptions]);

    //calculates fallback grid height based on gridHeight prop
    React.useLayoutEffect(() => {
        grid.setFallbackHeight(grid.height);
    }, [grid.height]);

    //listens for grid data changes and then updates fetchData
    React.useLayoutEffect(() => {
        if (grid.shouldCachedDataRefresh) {
            if (!grid.isLoading && data?.length) {
                grid.updateCachedTableData(data);
            }
        }
        else {
            if (!grid.isLoading && data?.length) {
                grid.appendCachedTableData(data);
                grid.setShouldCachedDataRefresh(true);
            }
        }
    }, [data, grid.isLoading]);

    //listens for the total data count from the server
    React.useLayoutEffect(() => {
        grid.updateDataCount(totalDataCount);
    }, [totalDataCount]);

    //listens for rowProp changes and then updates store
    React.useLayoutEffect(() => {
        grid.updateRowProps(rowProps);
    }, [rowProps]);

    //listens for column search query changes, and filters tableData based on query strings
    //disables client side search based on liveFilter prop
    React.useLayoutEffect(() => {
        grid.updateTableData(grid.cachedTableData);
    }, [grid.cachedTableData]);

    //sets selected rows
    useNonInitialLayoutEffect(() => {
        if (onSelect) {
            onSelect(grid.selectedRows);
        }
    }, [grid.selectedRows]);

    //updates client table data based on search queries
    useNonInitialLayoutEffect(() => {
        if (!grid.isClientRenderDisabled) {
            grid.filterTableData();
        }
        if (onFilterChange) {
            onFilterChange(grid.searchQueries);
        }
    }, [grid.searchQueries]);

    //updates tableData based on active column sort
    React.useEffect(() => {
        if (onSortChange) {
            onSortChange(grid.activeColumnSort);
        }
        if (!grid.isClientRenderDisabled) {
            grid.sortTableData(grid.activeColumnSort.field, grid.activeColumnSort.type);
        }
        grid.resetGridPosition();
    }, [grid.activeColumnSort]);

    //sets onBottomScroll function
    React.useEffect(() => {
        grid.setOnBottomScroll(onBottomScroll);
    }, [onBottomScroll]);

    //updates custom row props (this can be accessed from a custom row through the grid hook)
    React.useEffect(() => {
        grid.setCustomItemProps(customItemProps);
    }, [customItemProps])

    return (
        <>
            <div ref={gridRef} className={`${styles.containerComp} ${className}`} style={(gridStyles) ? gridStyles : {}}>
                {children}
            </div>
        </>
    )
}

const GridContainer = withTreble(GridContainerComp, { store: Store });
export default GridContainer;