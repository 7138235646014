import { createStore, TUseTreble, useScopedTreble, useTreble } from 'treble-gsm';

//interface for state items (used to describe StoreItems returned by useTreble)
interface IStoreItems {
    isSideBar: boolean,
    sideBarCollapse: boolean,
    sideBarNavItems: {
        text: string,
        key?: string | number
    }[],
    activeSideBarNavItem: {
        text: string,
        key?: string | number
    }
    activeSideBarNavItemEventKey: string;
    testState: any[]
}

const actionKeys = {
    ['ptrui_modalBasic_setIsSidebarStatus']: 'ptrui_modalBasic_setIsSidebarStatus',
    ['ptrui_modalBasic_collapseModalSideBar']: 'ptrui_modalBasic_collapseModalSideBar',
    ['ptrui_modalBasic_setActiveSideBarNavItem']: 'ptrui_modalBasic_setActiveSideBarNavItem',
    ['ptrui_modalBasic_setActiveSideBarNavItemEventKey']: 'ptrui_modalBasic_setActiveSideBarNavItemEventKey',
    ['ptrui_modalBasic_udpateSideBarNavItems']: 'ptrui_modalBasic_udpateSideBarNavItems'
}

type TStoreActions = typeof actionKeys;

//Store Type for useTreble hook
type TStore = TUseTreble<IStoreItems, TStoreActions>;

//create scoped store context
const modalContext = useScopedTreble();

//create custom treble hook
export const useModalStore = () => useTreble(modalContext) as TStore;

export const Store = createStore([
    {
        action: actionKeys.ptrui_modalBasic_setIsSidebarStatus,
        state: {
            isSideBar: false
        }
    },
    {
        action: actionKeys.ptrui_modalBasic_collapseModalSideBar,
        state: {
            sideBarCollapse: false
        }
    },
    {
        action: actionKeys.ptrui_modalBasic_setActiveSideBarNavItem,
        state: {
            activeSideBarNavItem: {
                text: '',
                key: undefined
            }
        },
    },
    {
        action: actionKeys.ptrui_modalBasic_udpateSideBarNavItems,
        state: {
            sideBarNavItems: []
        },
        features: {
            check: (data) => {

                if (data.dispatchValue.length === 0 && data.currentState.length === 0) {
                    return false
                }
                return true
            }
        }
    },
    {
        action: actionKeys.ptrui_modalBasic_setActiveSideBarNavItemEventKey,
        state: {
            activeSideBarNavItemEventKey: 'link-0'
        }
    }
], {
    context: modalContext
})