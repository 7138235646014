import React from 'react';
import { Spinner } from 'react-bootstrap';
import styles from './loadingData.module.scss';

export default function LoadingData() {

    return (
        <>
            <div className={`${styles.loadingData} d-flex justify-content-center align-items-center`}>
                <Spinner animation="border" variant={"info"} className={styles.spinner} />
            </div>
        </>
    )
}