/*
  Alert Menu Card component
*/

import React from "react";
import { Card } from '../../../molecules';
import { Link } from "react-router-treble";
import { IAlertCard } from "../interfaces";
import styles from './alertCard.module.scss';

export default function AlertCard({ shipmentID, type, message }: IAlertCard) {
  return (
    <>
      <Card className={'mt-3'}>
        <Card.Header>
          <p className="mb-0">
            {type === "warning" ? (
              <i className="fas fa-clock text-danger"></i>
            ) : type === "update" ? (
              <i className="fas fa-exclamation-circle text-info"></i>
            ) : null}
          </p>
        </Card.Header>
        <Card.Body>
          <div>
            <div className="d-flex justify-content-between">
              <h5 className="card-title mb-0">{shipmentID}</h5>
              <p className="card-text pt-2">
                <small className="text-muted">14 minutes ago</small>
              </p>
            </div>
            <h6 className="card-subtitle">
              <small className="text-muted">is now Late</small>
            </h6>
          </div>
          <p className="card-text pt-2">{message}</p>
        </Card.Body>
        <Card.Footer>
          <Link to={""} className={styles.footerLink}>
            Complete
          </Link>
          <Link to={""} className={styles.footerLink}>
            Shipment Details
          </Link>
        </Card.Footer>
      </Card>
    </>
  );
}
