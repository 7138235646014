import React from 'react';
import { Dropdown } from 'react-bootstrap';

interface Props {
    message?: string;
    children?: JSX.Element;
}

export default function InputToolTip({ message, children }: Props) {

    return (
        <>
            <Dropdown.ItemText className={`pb-2 d-flex align-items-center `}>
                {
                    (children) ?
                        children :
                        <span className='text-muted'>
                            {(message) ? message : 'Type to Search'}
                        </span>
                }
            </Dropdown.ItemText>
        </>
    )
}