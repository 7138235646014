import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "../../../utils";
import { ActionBar } from '../../atoms';
import styles from './landingCard.module.scss';
import { ILandingCard } from "./interfaces";

export default function LandingCard({
  cardColor,
  readonly,
  icon,
  imgContainerWidth,
  alt,
  data,
  title,
  onClickRoute,
  onClickAction,
  onClickURL,
  actionBar,
  children,
}: ILandingCard) {

  return (
    <>
      <Card
        className={`${styles.compContainer} m-4 ${cardColor === "white" ? styles.cardWhite : null
          } ${readonly ? styles.readonly : null}`}
      >
        {
          (actionBar) ? <ActionBar dataSet={actionBar} /> : null
        }
        <Link
          to={onClickRoute ? onClickRoute : onClickURL ? onClickURL : "#/"}
          onClick={onClickAction}
          className="d-flex"
          external={(onClickURL) ? true : false}
          target={(onClickURL) ? '_blank' : ''}
        >
          <Card.Body className={`${styles.body} d-flex align-items-center justify-content-center my-4 my-lg-0`}>
            <div>
              <div className="row d-flex justify-content-center align-items-center">
                {
                  (icon?.type === "img") ?
                    <div className={imgContainerWidth ? imgContainerWidth + " pb-4" : "col-7 pb-4"}>
                      <img
                        src={icon?.props.src}
                        alt={alt}
                        width="100%"
                        className="img-fluid"
                      />
                    </div> :
                    <div className={`${styles.cardIcon} col-7 d-flex justify-content-center pb-4`}>
                      {icon}
                    </div>
                }
              </div>
              <h5
                className={
                  icon ? "card-text text-center" : "card-text text-center"
                }
              >
                {title}
              </h5>
              {
                data ? (
                  <h1 className={`card-title text-center mb-0 pt-3`}>{data}</h1>
                ) : null
              }
            </div>
          </Card.Body>
        </Link>
        {children}
      </Card>
    </>
  );
}
