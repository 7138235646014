import React, {useContext} from 'react';
import SceneContainerContext from '../scene-container-context';

interface Props{
    fullwidth?: boolean,
    children: JSX.Element[] | JSX.Element
}

function ContentColumn(props: Props){
    //props variables
    const fullwidth = props.fullwidth;

    //context
    const sceneContext:{collapsed?:boolean} = useContext(SceneContainerContext);

    return(
        <div className={
            `scene-container-content-column col-12 pb-4
            ${
                (fullwidth) ? 'col-lg-12' : 
                (sceneContext !== undefined) ? 
                (sceneContext.collapsed === false) ? 'col-lg-10 mb-4' : 
                'col-lg-11 mb-4' : 'col-lg-10'
            }
        `}>
            <div className={`row d-flex ${
                (sceneContext !== undefined) ? 
                (sceneContext.collapsed === false) ? 'justify-content-center' : 'justify-content-start' :
                'justify-content-center'
                } no-gutters`}>
                <div className="col-12">
                    <div className={`row pt-3 mt-4 d-flex justify-content-center ${
                        (sceneContext !== undefined) ?
                        (sceneContext.collapsed === false) ? '' : 'pr-lg-4 mr-lg-4' :
                        ''
                        } no-gutters`}>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentColumn;

