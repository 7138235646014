import React from 'react';
import { withRouter } from 'react-router-dom';

//scrolls view to top when client side routing routes to new page

function ScrollToTop({ history }: { history: { listen: any } }) {
    React.useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }
    }, [history]);

    return (null);
}

export default withRouter(ScrollToTop);