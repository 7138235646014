import React from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { useAppStore } from '../../../../../_Store';
import styles from './input.module.scss';

interface Props {
    bind: {
        value: any;
        onChange: (event: any) => void;
    }
}

export default function Input({ bind }: Props) {

    const [{ ptrui_appSearch: search }] = useAppStore();
    const placeHolder = (search) ? search.placeHolder : false;

    return (
        <>
            <InputGroup className={styles.inputGroup}>
                <InputGroup.Prepend className={styles.inputPrepend}>
                    <InputGroup.Text className={`${styles.inputGroupText} py-3 py-lg-0`}>
                        <i className={`fas fa-search `} style={{ fontSize: '1.3rem' }}></i>
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                    className={styles.formControl}
                    placeholder={(placeHolder) ? placeHolder : 'Search'}
                    aria-label="App-Search"
                    aria-describedby="Search the app."
                    {...bind}
                />
            </InputGroup>
        </>
    )
}