import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import MainMenuBtn from './main-menu-btn';
import SiteSearch from "./site-search";
import SitePath from './site-path';
import { IMenuBar } from "./interfaces";
import { useConfig, usePageState } from '../../../hooks';
import styles from './menuBar.module.scss';

function MenuBar({ landingIcon, paths }: IMenuBar) {

  const { isCollapsed } = usePageState();
  const config = useConfig();


  return (
    <Container fluid className={`${styles.menuBarContainer} px-0`}>
      <Row className={`no-gutters ${(isCollapsed) ? '' : 'd-flex justify-content-between'}`}>
        <Col sm={12} md={9} lg={9} className={`flex-fill d-flex align-items-center justify-content-start`}>
          {
            (config.isMainMenu) ? <MainMenuBtn /> : <div style={{ minHeight: '60px' }}></div>
          }

          <SitePath paths={paths} icon={landingIcon} />
        </Col>
        <Col sm={12} md={3} lg={3}>
          {
            (config.isSearch) ?
              <SiteSearch />
              : null
          }
        </Col>
      </Row>
    </Container>
  );
}

export default MenuBar;
