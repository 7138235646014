/*
This was written as a class component because react-date-picker 
component throws error with functional component.
*/

import React, {Component} from 'react';



class CustomInput extends Component<any>{
    constructor(props: any){
        super(props);
    }
    render(){
        return(
            <>
                <div className="input-group" style={this.props.style}>
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-calendar-alt"></i></span>
                    </div>
                    <input type="text" className="form-control" placeholder="Username" aria-label="Username" value={this.props.value} onChange={() => this.props.value} aria-describedby="basic-addon1" onClick={this.props.onClick}/>
                </div>
            </>
        )
    }
}
export default CustomInput;