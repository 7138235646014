/*
 Footer
 - provides pagination and row counts
*/
import React from "react";
import DataBtns from './data-btns';
import { IFooter } from "../interfaces";
import styles from "./footer.module.scss";

function CardGridFooter({ children }: IFooter) {



  return (
    <>
      <div className={styles.footer}>
        <div className="d-flex justify-content-between align-items-center p-2">
          <p className="mb-0"></p>
          {children ? (
            <div className="flex-fill px-3">
              {children}
            </div>
          ) : null}

          <div className={`d-flex align-items-center ${styles.utilities}`}>
            <DataBtns />
          </div>
        </div>
      </div>
    </>
  );
}

CardGridFooter.displayName = 'CardGridFooter';
export default CardGridFooter;
