import React from 'react';
import { PanelHeader as PanelHeaderAtom } from '../../../atoms';

interface Props {
    className?: string;
    children?: JSX.Element | JSX.Element[]
}

function PanelHeader({ children, className }: Props) {

    return (
        <>
            <PanelHeaderAtom className={className}>
                {children}
            </PanelHeaderAtom>
        </>
    )
}

PanelHeader.displayName = 'PanelHeader';

export default PanelHeader;