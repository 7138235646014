import React from 'react';
import { Spinner } from 'react-bootstrap';
import styles from './icon-provider.module.scss';
import { IIcon } from './interfaces';


export default function IconComp({ children }: IIcon.IIconComp) {

    return (<>
            {children}
            </>
           )

}