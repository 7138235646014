import { IMiddlewareData } from 'treble-gsm';
const queryCardData = (middlewareData: IMiddlewareData) => {
    const dispatchValue: { [key: number]: { [key: string]: any } } = middlewareData.dispatchValue;
    const activeAlphaFilter: string = middlewareData.storeState.alphaFilter.active;
    const checkedList: number[] = middlewareData.storeState.checkedList;
    const dispatchValueArray = Object.values(dispatchValue);
    const query: string = middlewareData.storeState.searchQuery.query.toLowerCase();
    const queryTargets: string[] = middlewareData.storeState.searchQuery.targets;
    const activeAlphaCharFilter = middlewareData.storeState.alphaFilter.active;
    let filteredDispatchValue = {};

    dispatchValueArray.filter((item, index: number) => {
        //console.log(item)
        const searchParameters: string[] = queryTargets.filter((target) => {
            if (item[target] !== null) {
                return item[target].toString().toLowerCase();
            }

        });

        const isSearchMatch = searchParameters.some((target) => {
            if (item[target]) {
                const matchQuery = (query.length > 0) ? item[target].toString().toLowerCase().startsWith(query) : true;
                return matchQuery;
            }

            return true;
        });
        const isAlphaFilterMatch = () => {
            const target = middlewareData.storeState.alphaFilter.target;
            if (activeAlphaCharFilter === 'all') {
                return true;
            }
            if (activeAlphaCharFilter === 'checked') {
                if (checkedList.includes(item.Id)) {
                    return true
                }
            }
            if (item[target] !== null) {
                if (item[target].toString().toLowerCase().startsWith(activeAlphaCharFilter)) {
                    return true;
                }
                return false
            }

            return false;
        };

        if (isSearchMatch && isAlphaFilterMatch()) {
            filteredDispatchValue = {
                ...filteredDispatchValue,
                [item?.Id || index]: { ...item }
            }
        }
    });

    return filteredDispatchValue;
}

export default queryCardData;