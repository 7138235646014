import React from 'react';
import { ModalBasic } from '../../molecules';
import { useLanguageModal } from '../../../hooks';

export default function LanguageModal() {

    const modal = useLanguageModal();

    return (
        <>
            <ModalBasic
                animation={true}
                centered={false}
                show={modal.isOpen}
                onHide={() => modal.close()}
                size={'md'}
                titleIcon={<i className={`far fa-language`}></i>}
                title={'Change Language'}
            >
                <ModalBasic.Body>
                    {modal.renderForm}
                </ModalBasic.Body>

            </ModalBasic>
        </>
    )
}