import React, { useState, useEffect } from "react";
import {
  Dropdown,
  Form,
  Col,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import DayPicker, { DateUtils } from "react-day-picker";
import { useDataGridStore, TStore } from "../_Store";
//https://react-day-picker.js.org/

import uniqid from "uniqid";
import "react-day-picker/lib/style.css";
import styles from './datePicker.module.scss';
//import "./_date-picker.scss";

interface Props {
  //this is how the date keeper outputs the date range to parent components
  updateDateRangeSelection: (dateRangeValue: {
    from?: string;
    to?: string;
  }) => any;
  currentDateRange: { from: Date | undefined; to: Date | undefined };
  onSave?: (value?: any) => void;
}
export default function DatePicker({
  updateDateRangeSelection,
  currentDateRange,
  onSave,
}: Props) {
  const [{ resetFilterInputs, searchQueries }] = useDataGridStore() as TStore;

  const [dateRange, setDateRange] = useState<{ from?: Date; to?: Date }>({
    from: undefined,
    to: undefined,
  });
  const [formattedDateRange, setFormattedDateRange] = useState<{
    from?: string;
    to?: string;
  }>({ from: undefined, to: undefined });
  const { from, to } = dateRange;
  const modifiers = { start: from, end: to };

  const handleDayClick = (day: any) => {
    let range = DateUtils.addDayToRange(
      day,
      dateRange as { from: Date; to: Date }
    );
    setDateRange(range);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }: any, ref) => (
    <Form.Group
      ref={ref as any}
      onClick={(e: any) => {
        e.preventDefault();
        onClick(e);
      }}
      className={`${styles.datePickerInput} mb-0 pt-2 cursor`}
    >
      <InputGroup.Prepend>
        <InputGroup.Text>
          <i className="fas fa-calendar-alt"></i>
        </InputGroup.Text>
      </InputGroup.Prepend>
      <Form.Control
        type="text"
        size="sm"
        placeholder={
          formattedDateRange.from !== undefined &&
            formattedDateRange.to !== undefined
            ? `${formattedDateRange?.from} - ${formattedDateRange?.to}`
            : "No Date Range"
        }
        readOnly
      />
    </Form.Group>
  ));

  const CloseOnSaveBtn = React.forwardRef(({ children, onClick }: any, ref) => (
    <div
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));

  //format for date range selection
  const formatDateRange = {
    from: (fromDate: Date) => {
      let month = fromDate.getMonth() + 1;
      let day = fromDate.getDate();
      let year = fromDate.getUTCFullYear().toString().substr(2);
      let formattedMonth = month < 10 ? `0${month}` : month;
      let formattedDay = day < 10 ? `0${day}` : day;
      let date = `${formattedMonth}/${formattedDay}/${year}`;

      return date;
    },
    to: (toDate: Date) => {
      let month = toDate.getMonth() + 1;
      let day = toDate.getDate();
      let year = toDate.getUTCFullYear().toString().substr(2);
      let formattedMonth = month < 10 ? `0${month}` : month;
      let formattedDay = day < 10 ? `0${day}` : day;
      let date = `${formattedMonth}/${formattedDay}/${year}`;

      return date;
    },
  };

  const handleCloseOnSaveBtn = () => {
    // onSave({
    //     from: (dateRange.from) ? formatDateRange.from(dateRange.from) : undefined,
    //     to: (dateRange.to) ? formatDateRange.from(dateRange.to) : undefined
    // });
    updateDateRangeSelection({
      from: dateRange.from ? formatDateRange.from(dateRange.from) : undefined,
      to: dateRange.to ? formatDateRange.from(dateRange.to) : undefined,
    });
    setFormattedDateRange({
      from: dateRange.from ? formatDateRange.from(dateRange.from) : undefined,
      to: dateRange.to ? formatDateRange.from(dateRange.to) : undefined,
    });
  };

  const handleReset = () => {
    // onSave({
    //     from: undefined,
    //     to: undefined
    // });
    updateDateRangeSelection({
      from: undefined,
      to: undefined,
    });
    setFormattedDateRange({
      from: undefined,
      to: undefined,
    });
    setDateRange({ from: undefined, to: undefined });
  };
  React.useEffect(() => {
    if (searchQueries.length > 0) {
      handleReset();
    }
  }, [resetFilterInputs]);

  return (
    <>
      <Dropdown className={styles.datePickerContainer}>
        <Dropdown.Toggle as={CustomToggle}>
          {/* <i className="fas fa-calendar-alt pl-2 pb-0 cursor"></i> */}
        </Dropdown.Toggle>

        <Dropdown.Menu className="p-2">
          <Form>
            <Form.Row>
              <Col>
                <InputGroup className="pt-2 pl-2" size={"sm"}>
                  <FormControl
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={`${from !== null && from !== undefined
                      ? `${(from as any).getMonth() + 1
                      }/${(from as any).getDate()}/${(from as any).getUTCFullYear()}`
                      : "From Date"
                      }`}
                    readOnly
                  />
                </InputGroup>
              </Col>
              <Col xs="1" className="d-flex align-items-center">
                <i className="fas fa-long-arrow-alt-right m-0 p-0 mt-2"></i>
              </Col>
              <Col>
                <InputGroup className="pt-2 pr-2" size={"sm"}>
                  <FormControl
                    placeholder="Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={`${to !== null && to !== undefined
                      ? `${(to as any).getMonth() + 1
                      }/${(to as any).getDate()}/${(to as any).getUTCFullYear()}`
                      : "To Date"
                      }`}
                    readOnly
                  />
                </InputGroup>
              </Col>
            </Form.Row>
          </Form>
          <DayPicker
            className={styles.columnDayPicker}
            numberOfMonths={1}
            selectedDays={[from, { from, to }] as any[]}
            modifiers={modifiers}
            onDayClick={(e: any) => handleDayClick(e)}
          />
          <div className="d-flex justify-content-end pb-2 px-2">
            <Dropdown.Toggle as={CloseOnSaveBtn}>
              <Button
                variant="link"
                size="sm"
                className="text-danger mr-1"
                onClick={handleReset}
              >
                reset
              </Button>
            </Dropdown.Toggle>
            <Dropdown.Toggle as={CloseOnSaveBtn}>
              <Button
                variant="success"
                size="sm"
                onClick={handleCloseOnSaveBtn}
              >
                <i className="fas fa-save pr-2"></i>Save
              </Button>
            </Dropdown.Toggle>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
