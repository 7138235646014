import React from 'react';
import { useDataGridStore } from '../../_Store';
import { Spinner } from 'react-bootstrap';
import styles from './loadMoreData.module.scss';

interface Props {
    width: number
}

export default function LoadMoreData({ width }: Props) {

    const loadMoreDataRef = React.useRef(null);

    const [{ tableData, totalDataCount, placeholderRowState }, Store] = useDataGridStore();

    React.useEffect(() => {
        Store.update('updateLoadMoreDataRef', loadMoreDataRef);
    }, [loadMoreDataRef]);

    return (
        <>
            {
                (placeholderRowState) ?
                    (!(tableData.length === totalDataCount || tableData.length > totalDataCount)) ?
                        <tr className={styles.loadMoreData} ref={loadMoreDataRef}>
                            <td style={{ height: '100px' }}>
                                <div
                                    className={`${(width > 0) ? 'd-flex' : 'd-none'} flex-column align-items-center justify-content-center`}
                                    style={{ height: "100%" }}
                                >
                                    <h5 className='p-0 mb-0'>
                                        <Spinner animation="border" variant="info" />
                                    </h5>
                                </div>
                            </td>
                        </tr>
                        : null
                    : null
            }
        </>
    )
}