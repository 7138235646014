import React from "react";
import { default as DataGridComp } from "./data-grid";
import Header from "./header";
import Table from "./table";
import Column from "./column";
import Footer from "./footer";
import Row from "./row";
import Cell from "./cell";
import ColumnSelector from './column-selector';
import ResetFilters from './reset-filters';
import RefreshData from './refresh-data';
import UtilBar from './utility-bar';
import { useGrid } from './use-data-grid';
import { GridProps } from './data-grid';

interface IDataGridComp<P> {
    (props: P): JSX.Element;
    Header: typeof Header;
    Table: typeof Table;
    Column: typeof Column;
    ColumnSelector: typeof ColumnSelector;
    ResetFilters: typeof ResetFilters;
    RefreshData: typeof RefreshData;
    Row: typeof Row;
    UtilBar: typeof UtilBar;
    Cell: typeof Cell;
    Footer: typeof Footer;
    useGrid: typeof useGrid;
}

//ClientGrid sub components are added as methods to main ClientGrid comp to allow for dot syntax initialization
const DataGrid: IDataGridComp<GridProps> = (props) => <DataGridComp {...props} />;
DataGrid.Header = Header;
DataGrid.Table = Table;
DataGrid.Column = Column;
DataGrid.ColumnSelector = ColumnSelector;
DataGrid.ResetFilters = ResetFilters;
DataGrid.RefreshData = RefreshData;
DataGrid.Row = Row;
DataGrid.Cell = Cell;
DataGrid.Footer = Footer;
DataGrid.UtilBar = UtilBar;
DataGrid.useGrid = useGrid;

export default DataGrid;
