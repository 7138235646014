import React from 'react';
import { useConfig } from '../../../hooks';
import { NotificationIcon } from '../../atoms';
import { LanguageSwitcher } from '../../molecules';
import styles from './appUtilities.module.scss';

interface Props {
    className?: string;
}

export default function AppUtilities({ className }: Props) {

    const config = useConfig();

    return (
        <>
            <div className={`d-flex ${className}`}>
                {
                    (config.isLanguageSwitcher) ?
                        <LanguageSwitcher className={`pr-3 ${styles.languageSwitcher}`} /> : null
                }
                {
                    (config.isAlerts) ?
                        <NotificationIcon /> : null
                }
            </div>
        </>
    )
}