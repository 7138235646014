/*
  RenderData
  - handles the different types of data rendering for the Grid.Table component
*/
import React from "react";
import Row from '../row';
import { GridNoResultsSplash, GridErrorSplash, GridLoadingSplash, GridNoColumnsSplash, GridRenderData } from '../../../molecules';
import useDataGrid from "../use-data-grid";
import useRenderData from './use-render-data';

export default function RenderData() {

  const grid = useDataGrid();
  const render = useRenderData();
  const shouldGridRefresh = (grid.shouldCachedDataRefresh) ? grid.isLoading : false;

  return (
    <>
      {
        (render.areAllColumnsDisabled()) ?
          <GridNoColumnsSplash
            width={grid.width}
            height={grid.splashHeight}
          />

          : (shouldGridRefresh) ?
            <GridLoadingSplash
              width={grid.width}
              height={grid.splashHeight}
            />

            : (grid.fetchStatusCode !== 'No Errors Detected') ?
              <GridErrorSplash
                width={grid.width}
                height={grid.splashHeight}
                error={grid.fetchStatusCode}
              />

              : (grid.cachedTableData.length === 0) ?
                <GridNoResultsSplash
                  width={grid.width}
                  height={grid.splashHeight}
                />

                : <>
                  <GridRenderData
                    as={Row}
                    activePage={grid.activePage}
                    pageSize={grid.pagingOptions.size}
                    data={grid.cachedTableData}
                    onScrollBottom={render.onScrollBottom as any}
                    onScrollTop={render.onScrollTop}
                    totalDataCount={(typeof grid.totalDataCount === 'number') ? grid.totalDataCount : 0}
                    isFetchRendering
                  />
                </>
      }
    </>
  );
}

