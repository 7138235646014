import React from 'react';
import { Route as RRTRoute } from 'react-router-treble';

interface Props {
    children: JSX.Element | JSX.Element[];
    exact?: boolean;
    path?: string;
    [key: string]: any;
}
export default function Route({ children, ...props }: Props) {

    return (
        <>
            <RRTRoute {...props}>
                {children}
            </RRTRoute>
        </>
    )
}