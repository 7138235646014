import React from 'react';
import {iconList} from './icon-list';
import uniqid from 'uniqid';

interface Props{
    issue: string[],
    exception: string[]
}

function StatusIcons(props: Props){

    //props
    const issueProp = props.issue;
    const exceptionProp = props.exception;

    return(
        <div className='d-flex'>
           {
                iconList.issues.map((iconObject) => {
                    return(
                        (issueProp.includes(iconObject.type)) ?
                        <div key={uniqid()}>{iconObject.icon}</div> : 
                        null
                    )
                })
            }
            {
                iconList.exceptions.map((iconObject) => {
                    return(
                        (exceptionProp.includes(iconObject.type)) ?
                        <div key={uniqid()}>{iconObject.icon}</div> : 
                        null
                    )
                })
            }
        </div>
    )
}
 
export default StatusIcons;