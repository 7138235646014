import React from 'react';
import styles from './pageSidebarMobileBtn.module.scss';

interface Props {
    onClick?: () => void;
}

export default function PageSidebarMobileBtn({ onClick }: Props) {

    return (
        <>
            <div
                onClick={() => (onClick) ? onClick() : null}
                className={`cursor ${styles.compContainer} d-flex justify-content-center align-items-center`}
            >
                <i className="far fa-ellipsis-v"></i>
            </div>
        </>
    )
}