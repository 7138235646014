import React from 'react';
import useDataGrid from '../use-data-grid';

const useRow = () => {

    const grid = useDataGrid();
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [disableTextHighlight, setDisableTextHighlight] = React.useState(false);

    React.useEffect(() => {
        setIsExpanded(false);
    }, [grid.cachedTableData]);

    //uses custom cells (these are the Cell comp's children) to rows instead of default cells
    const createCustomCell = (field: string, data: { [key: string]: any }) => {
        const cells = (grid.CustomRowComp) ? React.Children.map(grid.CustomRowComp({ rowData: data }), (child) => child?.props?.children?.props?.children) : [];
        const matchedCell = cells.filter((item: any) => {
            if (item.props.targetField === field) {
                return item;
            }
        })[0];

        const cellClassNames = matchedCell?.props?.className;

        return {
            Component: matchedCell,
            className: cellClassNames
        };
    };

    //alows selecting of Rows and saves row data to state
    const onSelect = (data: { [key: string]: any }, isActive: boolean, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        setDisableTextHighlight(false);

        //allows for multi select on ctrl(windows) or cmd(macOS)
        if (e.metaKey || e.ctrlKey) {
            if (!isActive) {
                grid.addSelectedRow(data);
            }
            else if (isActive) {
                grid.removeSelectedRow(data);
            }
        }
        //allows for batch select when shift key is pressed
        else if (e.shiftKey) {
            setDisableTextHighlight(true);
            const start = grid.selectedRows[grid.selectedRows.length - 1]?.ptrui_sortKey;
            const end = data?.ptrui_sortKey;
            if (typeof start === 'number' && typeof end === 'number') {
                const range = grid.cachedTableData.filter((row) => {
                    if (row.ptrui_sortKey >= start && row.ptrui_sortKey <= end) {
                        return row;
                    }
                });
                grid.updateSelectedRows(range);
            }
        }
        //default is single select row
        else {
            if (!isActive) {
                grid.selectRow(data);
            }
            else if (isActive) {
                grid.unSelectAllRows();
            }
        }
    }


    //toggles expandable row if row expands
    const toggleIsExpanded = () => {
        isExpanded ? setIsExpanded(false) : setIsExpanded(true);
    }

    return {
        createCustomCell,
        toggleIsExpanded,
        onSelect,
        isExpanded,
        disableTextHighlight
    }

}

export default useRow;