import React from 'react';
import { Spinner } from 'react-bootstrap';
import styles from './loadingIndicator.module.scss';

interface Props {
    className?: string;
    size?: number;
    color?: 'white';
}

export default function LoadingIndicator({ className, size, color }: Props) {

    const handleSpinnerSize = () => {
        const strokeWidth = size || 1.5;
        return {
            height: `${strokeWidth}rem`,
            width: `${strokeWidth}rem`,
            borderWidth: `${strokeWidth * .075}rem`
        }
    }

    return (
        <>
            <Spinner
                animation="border"
                variant={(color === 'white') ? 'light' : 'info'}
                role="status"
                className={`${styles.loadingIndicator} ${className}`}
                style={{ ...handleSpinnerSize() }}
            >
                <span className="sr-only">Loading...</span>
            </Spinner>
        </>
    )
}