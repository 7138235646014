import React from "react";
import { default as Card } from "./landing-card";
import { DataBar } from "./../../atoms";
import { ILandingCardComp, ILandingCard } from "./interfaces";

const LandingCard: ILandingCardComp<ILandingCard> = (props) => (
  <Card {...props} />
);
LandingCard.DataBar = DataBar;


export { DataBar }

export default LandingCard;
