import React, {useEffect} from 'react';
import RowSearch from './row-search';
import {useDataGridContext} from '../data-grid-context';

interface Props{
    columns: {
        name: string,
        dataField?: string,
        filter?: 'search',
        headerWidth?: string,
    }[],
    flags?: boolean
}

function DataGridHeader(props:Props){
   
    //context
    type AppContextType = [
        {
          flags: boolean,
          headerFilter: boolean
        }, 
        ({}) => object
      ];
    const [{flags, headerFilter}, dispatch]: AppContextType = useDataGridContext();

    //props variables
    const columns = props.columns;
    const flagProp = props.flags;

    //handlers
   
    const hasFilterProperty = () => {
        let filterValues = () => {
            return(
                columns.map((item) => {
                    if('filter' in item){
                        return true;
                    }
                    return false;
                })
            )
        };
        let filter = filterValues().includes(true);
        return filter;
    }
    //set columns in global component state
    useEffect(() => {
        dispatch({
            type: 'setColumns',
            setColumns: columns
        });
    },[])

    //sets flag column state
    useEffect(() => {
        let flagValue = (flagProp) ? true : false;
        dispatch({
            type: 'setFlags',
            setFlags: flagValue
        });
    },[flagProp, dispatch])

    //sets filter button group state 'RGY' in the data-grid header
    useEffect(() => {
        if(hasFilterProperty() === true){
            dispatch({
                type: 'setHeaderFilter',
                setHeaderFilter: true
            })
        }
    },[headerFilter, dispatch])

    return(
        <thead className='data-grid-header'>
             <tr>
                {
                    (flags) ?
                    <th scope='col' className="text-center">
                        <p className='mt-3'>
                            <i className="fas fa-exclamation-circle"></i>
                        </p>
                        {
                            (hasFilterProperty() === true) ?
                            <div className='data-grid-search-cell p-2'>
                                <div className="input-group p-0 m-0">
                                    <select className="form-control form-control-sm invisible">
                                        <option>&nbsp;</option>
                                    </select>
                                </div>
                            </div> : null
                        }
                        
                    </th> : null
                }
                {
                    
                    columns.map((item, index) => {
                        return(
                            <th scope='col' key={index}>
                                <p className='pt-3 px-2' style={{width: `${(item.headerWidth) ? item.headerWidth : 'auto'}`}}>{item.name}</p>
                                {
                                    (hasFilterProperty() === true) ?
                                        (item.filter === 'search') ?
                                        <RowSearch key={index} column={item.name}/> :
                                        <RowSearch key={index} column={item.name} disabled/>  
                                    : null
                                }
                            </th>
                        )
                    })
                }
            </tr>
        </thead>
    )
}

export default DataGridHeader;
