/*
    Protrans React UI Exports
*/
///<reference path="index.d.ts" />

//molecules
import {
    Input,
    Card,
    ModalBasic,
    AutoSuggest,
    FilterList,
    ButtonLock,
    DateSelector,
    FileUpload,
    OptionSelector,
    TimeSelector
} from './_components/molecules';

//organisms
import {
    LandingCard,
    //#deprecated - DataBar export will be removed in favor of dot syntax 
    DataBar,
    AlertMenu,
    Footer,
    Header,
    MainMenu,
    InAppMessage,
    MenuBar,
    CodeBlock,
    NoteBlock,
    SectionDropdown,
    CardGrid,
    ClientGrid,
    //BasicGrid,
    //DataGrid,
    Grid,
    Content,
    PageNav,
    ConfirmDialog
} from './_components/organisms';

//templates
import {
    NotFound,
    LoadingPage,
    LoginForm
} from './_components/templates';

//App
import App from './app';

//Config settings
import Config from "./config";

//Store
import { ProtransReactUIStore, TStore } from './_Store';

//Assets
import imgLogoProtransAWhite from './_assets/images/logo-protrans-A-white.svg';

//Exports
import { exportExcel, exportPDF } from './exports';

//Utils
import { lazyImport, Route, Link } from './utils';

//Hooks
import {
    useInput,
    useDebounce,
    useNonInitialLayoutEffect,
    useNonInitialEffect,
    useLogin,
    useMainMenu,
    usePageState,
    useCleanupEffect,
    useFetch,
    useNonInitialFetch,
    useInAppMessage,
    usePublicConfig as useConfig
} from './hooks';

//Legacy - these components are kept for compatibility but will eventually be deprecated
import DatePicker from './_legacy/date-picker';
import { Switch } from './_legacy/frag-switch';
import { createInAppMessage, clearAllInAppMessages } from './_legacy/in-app-messages';
import AutoComplete from './_legacy/autocomplete';
import Modal, { handleModalState, } from './_legacy/modal-old';
import SceneContainer from './_legacy/scene-container';
import ContentColumn from './_legacy/scene-container/content-column';
import WidgetColumn from './_legacy/scene-container/widget-column';
import LandingColumn from './_legacy/scene-container/landing-column';
import ContentRow from './_legacy/scene-container/content-row';
import { DataGridHeaderOld, RowDataOld, DataGridOld } from './_legacy/data-grid-OLD';
import SettingsWizard, { handleSettingsWizardState, SelectedListItems, SettingGroups, FormContainer } from './_legacy/settings-wizard';
import SideNav, { ListItem, SubMenu } from './_legacy/side-nav';
import ContentPanel from './_legacy/content-panel';
import InfoCardSection from './_legacy/info-card-section';
import { Icon } from './_components/atoms';



const PTRUI = {
    useInput,
    useDebounce,
    useNonInitialLayoutEffect,
    useNonInitialEffect,
    useInAppMessage,
    useLogin,
    useConfig,
    useMainMenu,
    usePageState,
    useCleanupEffect,
    useFetch,
    useNonInitialFetch,
    exportExcel,
    exportPDF,
    lazyImport,
    LoginForm,
    App
}

export default PTRUI;

export {
    Input,
    Card,
    App,
    LoginForm,
    Route,
    Link,
    lazyImport,
    TimeSelector,
    Content,
    ProtransReactUIStore,
    TStore,
    useLogin,
    AutoSuggest,
    FilterList,
    Config,
    AutoComplete,
    AlertMenu,
    ButtonLock,
    CodeBlock,
    ContentPanel,
    Grid,
    DataGridHeaderOld,
    RowDataOld,
    DataGridOld,
    PageNav,
    OptionSelector,
    ClientGrid,
    //BasicGrid,
    //DataGrid,
    CardGrid,
    DatePicker,
    DateSelector,
    Footer,
    Switch,
    Header,
    createInAppMessage,
    clearAllInAppMessages,
    InAppMessage,
    useInAppMessage,
    InfoCardSection,
    DataBar,
    LandingCard,
    MainMenu,
    MenuBar,
    ModalBasic,
    ConfirmDialog,
    Modal,
    handleModalState,
    NoteBlock,
    SceneContainer,
    ContentColumn,
    WidgetColumn,
    LandingColumn,
    ContentRow,
    NotFound,
    LoadingPage,
    SectionDropdown,
    exportExcel,
    exportPDF,
    FileUpload,
    SideNav,
    ListItem,
    SubMenu,
    useInput,
    useDebounce,
    useNonInitialLayoutEffect,
    useNonInitialEffect,
    imgLogoProtransAWhite,
    handleSettingsWizardState,
    SelectedListItems,
    SettingGroups,
    FormContainer,
    SettingsWizard,
    useMainMenu,
    usePageState,
    useCleanupEffect,
    useFetch,
    useNonInitialFetch,
    Icon
}