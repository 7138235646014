import React from 'react';
import { IOptionSelector } from './interfaces';
import { InputField } from '../../atoms';
import styles from './optionSelector.module.scss';

interface Props {
    onClick?: (e: any) => void;
    icon?: JSX.Element;
    activeOption?: IOptionSelector.MenuItem;
    size?: 'sm' | 'lg';
    disabled?: boolean;
    readOnly?: boolean;
}

export default function CustomToggle({ icon, activeOption, size, readOnly, disabled }: Props) {

    const renderComp = React.forwardRef(({ onClick }: Props, ref: React.Ref<HTMLSelectElement>) => {

        return (
            <>
                <InputField
                    className={styles.customToggle}
                    icon={icon}
                    size={size}
                    value={activeOption?.text}
                    as={'select'}
                    readOnly={readOnly}
                    disabled={disabled}
                    onClick={onClick}
                    compRef={ref}
                >
                    <option hidden>{activeOption?.text}</option>
                </InputField>
            </>
        )
    });

    return renderComp
}

