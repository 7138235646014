import React from "react";
import styles from './contentLanding.module.scss';

interface Props {
  children: JSX.Element[] | JSX.Element;
}

function ContentLanding(props: Props) {
  return (
    <div className={`${styles.compContainer}`}>
      <div className={`d-lg-flex justify-content-lg-center card-deck col-12 pt-4 ${styles.cardContainer} `}>
        {props.children}
      </div>
    </div>

  );
}

export default ContentLanding;
