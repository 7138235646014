import React from 'react';
import { useNonInitialEffect } from '../../../../hooks';
import { OptionSelector } from '../../../molecules';
import useDataGrid from '../use-data-grid';
import useColumnSelectFilter from './use-column-select-filter';

interface Props {
    field: string;
    onChange: (value?: string) => void;
    options: {
        value: string | number,
        text: string
    }[];
}
export default function ColumnSelectFilter({ field, options, onChange }: Props) {

    const grid = useDataGrid();
    const select = useColumnSelectFilter(field, onChange, options);
    const isDisabled = (grid.disableColumnFilters) ? true : grid.isLoading;

    //addes default search query object
    React.useEffect(() => {
        grid.addSearchQuery({
            target: field,
            query: '',
            exact: true,
            type: "string"
        });
    }, []);

    //listens for search query reset
    useNonInitialEffect(() => {
        select.onReset();
    }, [grid.searchQueries]);

    return (
        <>
            <OptionSelector
                className={'pt-2'}
                size={'sm'}
                placeHolder={'Choose'}
                options={select.listItems}
                onSelect={select.onSelect}
                disabled={isDisabled}
                activeOption={select.activeOption}
                defaultOption={select.activeOption}
            />
        </>
    )
}