import React from 'react';
import styles from './tableBody.module.scss';

interface Props {
    children?: JSX.Element | JSX.Element[];
    isExpandable?: boolean;
}

export default function TableBody({ children, isExpandable }: Props) {

    const memoChildren = React.useMemo(() => children, []);

    return (
        <>
            <tbody className={`${styles.compContainer} ${styles.strippedRows}`}>
                {memoChildren}
            </tbody>
        </>
    )
}