import React from "react";
import { default as BasicGridComp } from "./basic-grid";
import Table from "./table";
import Column from "./column";
import Row from "./row";
import Cell from "./cell";
import Footer from './footer';
import { useGrid } from './use-data-grid';
import { GridProps } from './basic-grid';

interface IDataGridComp<P> {
    (props: P): JSX.Element;
    Table: typeof Table;
    Column: typeof Column;
    Row: typeof Row;
    Cell: typeof Cell;
    Footer: typeof Footer;
    useGrid: typeof useGrid;
}

//BasicGrid sub components are added as methods to main DataGrid comp to allow for dot syntax initialization
const BasicGrid: IDataGridComp<GridProps> = (props) => <BasicGridComp {...props} />;
BasicGrid.Table = Table;
BasicGrid.Column = Column;
BasicGrid.Row = Row;
BasicGrid.Cell = Cell;
BasicGrid.Footer = Footer;
BasicGrid.useGrid = useGrid;

export default BasicGrid;
