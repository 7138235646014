
interface AddToCheckedInfoCardDataInterface {
    (
        dispatch: (
            object: {
                type: 'addToCheckedInfoCardData', 
                addToCheckedInfoCardData: {
                    displayID: string,
                    Id: number,
                    name: string,
                    country: string,
                    street: string,
                    city: string,
                    state: string,
                    zip: string,
                    thumbnailURL?: string
                }[]
            }
        ) => object,
        checkedCard: {
            displayID: string,
            Id: number,
            name: string,
            country: string,
            street: string,
            city: string,
            state: string,
            zip: string,
            thumbnailURL?: string
        }[]
    ): void
}

const addToCheckedInfoCardData:AddToCheckedInfoCardDataInterface  = (dispatch, checkedCard) => {
    dispatch({
        type: 'addToCheckedInfoCardData',
        addToCheckedInfoCardData: checkedCard
    });
    return false;
}
export default addToCheckedInfoCardData;