import React from 'react';
import { default as ContentContainer } from "./content";
import { IContent, IContentComp } from './interfaces';
import Widget from "./widget";
import Column from './column';
import Row from './row';
import Panel from "./panel";
import Landing from './landing';
import PanelHeader from './panel-header';
import PanelFooter from './panel-footer';
import useContent from './use-content';

const Content: IContentComp<IContent> = (props) => <ContentContainer {...props} />;
Content.Column = Column;
Content.Widget = Widget;
Content.Landing = Landing;
Content.Row = Row;
Content.Panel = Panel;
Content.PanelHeader = PanelHeader;
Content.PanelFooter = PanelFooter;
Content.useContent = useContent;

export default Content;
