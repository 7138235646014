import React, { useState } from "react";
import ExpandableRowContainer from './expandable-row-container';
import { useDataGridStore } from "../_Store";
import { IRow } from "../interfaces";
import styles from './row.module.scss';

function Row({ children, rowData }: IRow) {

  //Store Items and Methods
  const [
    { tableData, columnFields, columnWidths, ExpandableRow, expandableCheck, onSelect, gridTBodyRef },
    Store, Util
  ] = useDataGridStore();

  //sets expandRow state
  const [expandRow, setExpandRow] = useState(false);

  //uses custom cells (these are the Cell comp's children) to rows instead of default cells
  const handleCustomCells = (key: string) => {
    let customChildren: any = {};
    let classNames: { [key: string]: string } = {};
    let i = 0;
    React.Children.map(children, (child, i) => {
      customChildren = {
        ...customChildren,
        [child.props.targetField]: React.cloneElement(child)
      };
      classNames = {
        ...classNames,
        [child.props.targetField]: children[i].props.className || ''
      }
    });
    return {
      component: customChildren[key],
      className: classNames[key]
    };
  };

  //toggles expandable row if row expands
  const toggleExpandableRow = () => {
    expandRow ? setExpandRow(false) : setExpandRow(true);
  };

  const onSelecting = (rowData : any, shiftKey : boolean, ctrlKey : boolean) => {
    let clonedTableData : any[] = [...tableData];
    let previousSelectedRowsInDom : any =  Array.from(gridTBodyRef.current.children[0].childNodes[1].childNodes).filter((x:any) => { return x.className === 'selectedRow' })
    let selectedTrebleKeys = previousSelectedRowsInDom?.map((item:any)=>{
      return item.dataset.key;
    });
    let previousSelectedRows : any = clonedTableData.filter(x => selectedTrebleKeys.includes(x.trebleKey));
    
    //Update rows previously selected
    for(let i = 0 ; i<= previousSelectedRows.length-1;i++){
      clonedTableData[previousSelectedRows[i].trebleKey].isSelected = true;
    }
    
    //Logic to select rows, ctrl and shift combination included
    if(!shiftKey && !ctrlKey)
    {
      for(let i = 0; i <= clonedTableData.length - 1; i++){
        let iRow : any = clonedTableData[i];
        iRow.isSelected = false;
        clonedTableData[i] = iRow;
      }
      let indexRowToBeSelectedInTable = clonedTableData.findIndex((x : any) => x.trebleKey === rowData.trebleKey);
      let rowToBeSelectedInTable : any = clonedTableData[indexRowToBeSelectedInTable]
      rowToBeSelectedInTable.isSelected = true;
      clonedTableData[indexRowToBeSelectedInTable] = rowToBeSelectedInTable;
    }

    if(!shiftKey && ctrlKey)
    {
      let indexRowToBeSelectedInTable = clonedTableData.findIndex((x : any) => x.trebleKey === rowData.trebleKey);
      let rowToBeSelectedInTable : any = clonedTableData[indexRowToBeSelectedInTable]
      rowToBeSelectedInTable.isSelected = !rowData.isSelected;
      clonedTableData[indexRowToBeSelectedInTable] = rowToBeSelectedInTable;
    }
    
    if(shiftKey && !ctrlKey)
    {
      let indexRowToBeSelectedInTable = clonedTableData.findIndex((x : any) => x.trebleKey === rowData.trebleKey);
      let rowsSelectedInTable = clonedTableData.filter((x : any) => x.isSelected === true);
      
      if(rowsSelectedInTable.length == 1){
        let indexRowSelectedInTable = clonedTableData.findIndex((x : any) => x.isSelected === true);
        let i : number = 0;
        let j : number = 0;
        if(indexRowSelectedInTable < indexRowToBeSelectedInTable)
        {
          i = indexRowSelectedInTable;
          j = indexRowToBeSelectedInTable;
        }
        else {
          j = indexRowSelectedInTable;
          i = indexRowToBeSelectedInTable;
        }

        for(;i <= j;i++){
          let rowToBeSelectedInTable : any = clonedTableData[i]
          rowToBeSelectedInTable.isSelected = true;
          clonedTableData[i] = rowToBeSelectedInTable;
        }
      }
      else{
        for(let i = 0; i <= clonedTableData.length - 1; i++){
          let iRow : any = clonedTableData[i];
          iRow.isSelected = false;
          clonedTableData[i] = iRow;
        }
        let rowToBeSelectedInTable : any = clonedTableData[indexRowToBeSelectedInTable];
        rowToBeSelectedInTable.isSelected = true;
        clonedTableData[indexRowToBeSelectedInTable] = rowToBeSelectedInTable;
      }  
    }
    //End Logic to select rows, ctrl and shift combination included
    
    //Clean up all rows previuosly selected
    previousSelectedRowsInDom.forEach((element:any) => {
      element.className = "";
      element.style.backgroundColor = "";
    });

    //Refresh all set of selected rows
    let rowsSelectedInTable = clonedTableData.filter((x : any) => x.isSelected == true);
    rowsSelectedInTable.forEach((element:any) => {
      gridTBodyRef.current.children[0].childNodes[1].children[element.trebleKey].className = "selectedRow";
      gridTBodyRef.current.children[0].childNodes[1].children[element.trebleKey].style.backgroundColor = "grey";
    });

    //Send selected rows
    onSelect(rowsSelectedInTable);
  } 
  
  return (
     <>
      <tr data-key = {rowData.trebleKey} onClick={(e:any)=>{
                          if(onSelect) 
                          {
                            onSelecting(rowData, e.shiftKey, e.ctrlKey);
                          }
                        }}>
        {columnFields?.map(
          ({ field, enabled, trebleKey }: { field: string, enabled: boolean, trebleKey: number }) => {
            return (
              <React.Fragment key={trebleKey}>
                <td
                  style={{
                    width: columnWidths.find(
                      (item: any) => item.target === field
                    )?.width
                  }}
                  className={`${(!enabled) ? 'd-none' : ''} ${handleCustomCells(field).className}`}
                >
                  <div
                    className={`${(ExpandableRow) ? "d-flex justify-content-start" : ""
                      }`}
                  >

                    {(ExpandableRow) ? (
                      trebleKey === 0 ? (
                        (expandableCheck(rowData) === false) ?
                          <div
                            style={{ height: "100%" }}
                            className={"d-flex align-items-center"}
                          >
                            <i
                              className={`fas fa-caret-right pr-3`}
                              style={{ fontSize: ".9rem", opacity: '0' }}
                            ></i>
                          </div> :
                          <div
                            style={{ height: "100%" }}
                            className={"d-flex align-items-center cursor"}
                            onClick={toggleExpandableRow}
                          >
                            <i
                              className={`fas fa-caret-${expandRow ? "down" : "right"
                                } pr-3`}
                              style={{ fontSize: ".9rem" }}
                            ></i>
                          </div>
                      ) : null
                    ) : null}

                    {handleCustomCells(field).component !== undefined
                      ? handleCustomCells(field).component
                      : Object.keys(rowData).map((key, index) => {
                        return (
                          <React.Fragment key={index}>
                            {field === key ? (
                              <p className={"p-0 m-0"}>{(rowData[key] !== null && typeof rowData[key] !== 'object') ? rowData[key] : null}</p>
                            ) : null}
                          </React.Fragment>
                        );
                      })}

                  </div>
                </td>


              </React.Fragment>
            );
          }
        )}
      </tr>
      {

        (ExpandableRow) ?
          <ExpandableRowContainer
            expandRow={expandRow}
            isRowExpandable={expandableCheck}
            customRow={ExpandableRow}
            rowData={rowData}
          />
          : null
      }

    </>
  );
}
export default React.memo(Row);
