import React from 'react';
import { Dropdown } from 'react-bootstrap';
import styles from './defaultItem.module.scss';

export default function DefaultItem() {

    return (
        <>
            <Dropdown.ItemText className={styles.defaultItem}>
                <p className='mb-0'>Type to Search...</p>
            </Dropdown.ItemText>
        </>
    )
}