import React, { useState } from "react";
import { Accordion, useAccordionToggle, Badge, Table } from "react-bootstrap";
import { useDataGridStore, TStore } from "../_Store";
import { IGroup, IGroupCustomToggle } from "../interfaces";
import styles from './group.module.scss';

function CustomToggle({ children, eventKey, onClick }: IGroupCustomToggle) {
  //@ts-ignore
  const toggleAccordian = (eventKey: any) => useAccordionToggle(eventKey, () => null);

  return (
    <div
      onClick={() => {
        toggleAccordian(eventKey);
        onClick();
      }}
      className="cursor"
    >
      <div className="d-flex p-3 justify-content-between">{children}</div>
    </div>
  );
}

export default function Group({ children, name }: IGroup) {
  const [
    { columnGroupTarget, renderedTableData, GroupMeta },
  ] = useDataGridStore() as TStore;
  const [toggleCaret, setToggleCaret] = useState(false);

  //multidimensional renderedTableData state is converted to a flattened array
  const flattenedRenderedTableData = renderedTableData?.flat();

  //row data specific to this group
  const groupRowData = GroupMeta
    ? flattenedRenderedTableData.filter((item: any) => {
      if (item[columnGroupTarget.field] === name) {
        return item;
      }
    })
    : false;

  const handleToggleCaret = () => {
    if (toggleCaret) {
      setToggleCaret(false);
    } else {
      setToggleCaret(true);
    }
  };

  const groupCount = React.Children.toArray(children).length;

  return (
    <>
      <tr>
        <td className="p-0">
          <Accordion
            defaultActiveKey="1"
            className="d-flex justify-content-start"
          >
            <div className={styles.clickableGroup}>
              <CustomToggle eventKey="0" onClick={handleToggleCaret}>
                <>
                  <h6 className="mb-0">
                    {toggleCaret === false ? (
                      <i
                        className="fas fa-caret-right pr-3"
                        style={{ fontSize: "1rem" }}
                      ></i>
                    ) : (
                        <i
                          className="fas fa-caret-down pr-3"
                          style={{ fontSize: "1rem" }}
                        ></i>
                      )}
                    <Badge variant="secondary" className="p-2">
                      {columnGroupTarget.title}: {groupCount}
                    </Badge>
                    <span className="pl-3">{name ? name : "No Data"}</span>
                  </h6>
                  {GroupMeta ? <GroupMeta groupRowData={groupRowData} /> : null}
                </>
              </CustomToggle>
              <Accordion.Collapse eventKey="0">
                <>
                  <Table>
                    <tbody style={{ overflow: "hidden" }}>{children}</tbody>
                  </Table>
                </>
              </Accordion.Collapse>
            </div>
          </Accordion>
        </td>
      </tr>
    </>
  );
}
