import React from "react";
import "./_form-container.scss";

interface Props {
  children: JSX.Element | JSX.Element[];
}

function FormContainer(props: Props) {
  return (
    <>
      <div className="settings-wizard-form-container flex-fill p-4">
        {props.children}
      </div>
    </>
  );
}

export default FormContainer;
