import React from 'react';
import { GridSplash, LoadingIndicator } from '../../atoms';

interface Props {
    width?: number;
    height?: number;
}
export default function GridLoadingSplash({ width, height }: Props) {

    return (
        <>
            <tr>
                <td className={'p-0 border-0'}>
                    <GridSplash
                        width={width}
                        height={height}
                        icon={<LoadingIndicator size={5} />}
                    />
                </td>
            </tr>
        </>
    )
}