import React from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-treble';
import { InAppMessage } from '../../_components/organisms';
import { ScrollToTop } from '../../utils';
import { Header, Footer, LogoutModal, SettingsModal } from '../../_components/organisms';
import Routes from './../routes';
import { useAppStore } from '../../_Store';
import { CheckAuthPage } from '../../_components/templates';
import { useLogin, useConfig, useStartUp } from '../../hooks';

interface Props {
    AppNavigation: () => JSX.Element;
    AppAlerts?: () => JSX.Element;
    AppRoutes: () => JSX.Element;
    children?: JSX.Element | JSX.Element[];
}

export default function RenderPage({ AppNavigation, AppAlerts, AppRoutes, children }: Props) {


    const login = useLogin();
    const startup = useStartUp();
    const config = useConfig();
    const location = useLocation();
    const history = useHistory();
    const shouldCompRender = (config.isFrontEndLogin) ? (login.isAuth) ? true : false : true;

    // React.useEffect(() => {
    //     if (!shouldCompRender) {
    //         history.push('/login');
    //     }
    // }, [location.pathname]);

    return (
        <>
            {
                (!login.isAuth && config.isCheckAuthBeforeRender) ?
                    <CheckAuthPage /> :
                    <>
                        <ScrollToTop />
                        <InAppMessage />
                        <AppNavigation />
                        <LogoutModal />
                        {/* <LanguageModal /> */}
                        <SettingsModal />

                        {/** Children props can render other components set by down stream developers */}
                        {children}

                        {
                            (shouldCompRender) ? <Header /> : null
                        }
                        <Routes routes={AppRoutes} />
                        {
                            (shouldCompRender) ? <Footer /> : null
                        }

                    </>
            }
        </>
    )
}