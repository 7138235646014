import React from 'react';

interface Props {
    onClick?: () => void;
    className?: string;
    size?: number;
    isActive?: boolean;
    type?: 'asc' | 'desc' | 'none';
    disabled?: boolean;
}
export default function ToggleSort({ onClick, className, size, type, disabled }: Props) {

    const [iconType, setIconType] = React.useState<'asc' | 'desc' | 'none'>('none');
    const iconSize = (size) ? `${(1 * size)}rem` : '1rem';

    React.useEffect(() => {
        if (type) {
            setIconType(type);
        }
    }, [type]);

    return (
        <>
            <div onClick={(disabled) ? () => null : onClick} className={`${(!disabled) ? 'cursor' : ''} ${className}`} style={{ fontSize: iconSize }}>
                {
                    (disabled) ?
                        <i className="fas fa-sort-size-up-alt text-muted"></i> :
                        (iconType === 'asc') ?
                            <i className="fas fa-sort-size-up-alt text-info"></i> :
                            (iconType === 'desc') ?
                                <i className="fas fa-sort-size-down-alt text-info"></i> :
                                (iconType === 'none') ? <i className="fas fa-sort-size-up-alt text-muted"></i> : null
                }
            </div>
        </>
    )
}