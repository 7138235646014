import React from 'react';
import { Card, AccordionContext, useAccordionToggle } from 'react-bootstrap';
import styles from './accordionToggle.module.scss';

interface Props {
    children: JSX.Element | JSX.Element[];
    eventKey: any;
    callback?: any;
    className?: string;
    caret?: boolean;
}

export default function AccordionToggle({ children, eventKey, callback, className, caret }: Props) {

    const currentEventKey = React.useContext(AccordionContext);

    const toggle = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey)
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <>
            <Card.Header className={`${styles.compContainer} d-flex justify-content-between align-content-center pr-3 py-1 cursor ${className}`} onClick={toggle}>
                {children}
                {
                    (caret !== false) ?
                        (!isCurrentEventKey) ?
                            <i className='fas fa-caret-right py-2'></i> :
                            <i className='fas fa-caret-down py-2'></i> : null
                }
            </Card.Header>
        </>
    )
}