import React from 'react';
import { GridSplash } from '../../atoms';

interface Props {
    width?: number;
    height?: number;
    text?: string;
    smallText?: string;
    error?: null | object | string;
}
export default function GridErrorSplash({ width, height, text, error }: Props) {

    return (
        <>
            <tr>
                <td className={'p-0 border-0'}>
                    <GridSplash
                        width={width}
                        height={height}
                        icon={<i className="fad fa-exclamation-triangle text-danger"></i>}
                        text={`${(text) ? text : 'Something Went Wrong!'}`}
                        textColor={'text-danger'}
                        smallTextColor={'text-danger'}
                        smallText={`Fetch Error: ${(error) ? error.toString() : ''}`}
                    />
                </td>
            </tr>
        </>
    )
}