import React, { useState, useEffect } from "react";
import "./_selected-list-items.scss";
import Nav from "react-bootstrap/Nav";
import uniqid from "uniqid";

interface Props {
  listGroupName: string;
  selectedListItems: {
    displayID: string;
    Id: number;
    name: string;
  }[];
  activeListItem: React.Dispatch<
    React.SetStateAction<{
      displayID: string;
      Id: number;
      name: string;
    }>
  >;
}

function SelectedListItems(props: Props) {
  //props
  const listGroupName = props.listGroupName;
  const listItems = props.selectedListItems;
  const setActiveListItem = props.activeListItem;

  const [selectedItem, setSelectedItem] = useState<string>("link-1");

  const handleSelectedItem = (eventKey: string) => {
    setSelectedItem(eventKey);
  };
  const handleInitialSelectedItem = () => {
    listItems.forEach(
      (item: { displayID: string; Id: number; name: string }, index) => {
        let listItemEventKey = `link-${index + 1}`;
        if (listItemEventKey === "link-1") {
          setActiveListItem({
            displayID: item.displayID,
            Id: item.Id,
            name: item.name,
          });
        }
      }
    );
  };

  //sets initial selected item
  useEffect(() => {
    handleInitialSelectedItem();
  }, []);
  return (
    <>
      <div className="settings-wizard-list-items">
        <p className="pl-3 pt-3">
          <strong>{listGroupName}</strong>
        </p>
        <Nav defaultActiveKey="link-1" className="flex-column">
          {listItems.map(
            (item: { displayID: string; Id: number; name: string }, index) => {
              let listItemEventKey = `link-${index + 1}`;
              return (
                <React.Fragment key={uniqid()}>
                  <Nav.Link
                    eventKey={listItemEventKey}
                    className={`${
                      selectedItem === listItemEventKey
                        ? "settings-wizard-active-list-item"
                        : ""
                      }`}
                    onSelect={(eventKey: any) => {
                      handleSelectedItem(eventKey);
                      setActiveListItem({
                        displayID: item.displayID,
                        Id: item.Id,
                        name: item.name,
                      });
                    }}
                  >
                    {/* <i className="fas fa-file-edit pr-3"></i>  */}
                    <div>
                      <p className="mb-0">
                        <strong>ID: {item.displayID}</strong>
                      </p>
                      <p className="mb-0">
                        <small>
                          <em>{item.name}</em>
                        </small>
                      </p>{" "}
                    </div>
                  </Nav.Link>
                </React.Fragment>
              );
            }
          )}
        </Nav>
      </div>
    </>
  );
}

export default SelectedListItems;
