import React from 'react';
import { ListGroup as BSListGroup } from 'react-bootstrap';
import uniqid from 'uniqid';
import { ListItem } from '../../atoms';

interface Props {
    className?: string;
    itemClassName?: string;
    onClickItems?: () => void;
    items?: {
        icon?: JSX.Element;
        text: string;
        path: string;
    }[];
    children?: JSX.Element | JSX.Element[];
    as?: React.ElementType<any>;
}

export default function ListGroup({ items, className, itemClassName, onClickItems, children, as }: Props) {

    return (
        <>
            <BSListGroup as={(as) ? as : 'ul'} className={`${className}`}>
                {
                    (children) ? children :
                        (items) ? items.map((item) => (
                            <React.Fragment key={uniqid()}>
                                <ListItem path={item.path} icon={item.icon} text={item.text} className={itemClassName} onClick={onClickItems} />
                            </React.Fragment>
                        )) : null
                }
            </BSListGroup>
        </>
    )
}