import React from 'react';
import { ModalBasic } from '../../../molecules';
import Preferences from './preferences';

export default function Sections() {

    const [{ sideBarCollapse: collapse, activeSideBarNavItemEventKey }, ModalStore, Util] = ModalBasic.useState();

    return (
        <>
            {
                (activeSideBarNavItemEventKey === 'link-0') ? <Preferences /> : <p>Choose a Settings</p>
            }
        </>
    )
}