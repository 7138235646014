import React, {useContext} from 'react';
import SceneContainerContext from '../scene-container-context';

interface Props{
    children: JSX.Element[] | JSX.Element
}

function WidgetColumn(props: Props){

    //props
    

    //context
    const sceneContext:{collapsed: boolean} = useContext(SceneContainerContext);

    //handler functions
    const handleMenuState = () => {
        let widgetarea: HTMLElement | null = document.querySelector('.scene-container-widget-area');
        ((widgetarea as HTMLElement).classList.contains('scene-container-open-widget-area')) ?
        (widgetarea as HTMLElement).classList.remove('scene-container-open-widget-area') :
        (widgetarea as HTMLElement).classList.add('scene-container-open-widget-area');
    }

    return(
        <div className={`scene-container-widget-column d-lg-block ${
            (sceneContext as {collapsed?: boolean}) ?
            (sceneContext.collapsed === false) ? 'col-lg-2' : 'col-lg-1' : 'col-lg-2'
        }`}>
            
            <div className='scene-container-widget-area'>
                {props.children}
                <div className="scene-container-widget-btn" onClick={handleMenuState}>
                    <i className="fas fa-ellipsis-v p-2 py-3"></i>
                </div>
            </div>
                
            
        </div>
        
        
    )
}

export default WidgetColumn;