import React from 'react';
import { ResetFilters as ResetFiltersMol } from '../../../molecules';
import useDataGrid from '../use-data-grid';

interface Props {
    iconOnly?: boolean;
    className?: string;
}

export default function ResetFilters({ iconOnly, className }: Props) {

    const grid = useDataGrid();
    const isDisabled = (grid.activeColumnFilters.length === 0) ? true : false;

    return (
        <>
            <ResetFiltersMol
                small
                onClick={() => grid.resetSearchQueries()}
                className={className}
                iconOnly={iconOnly}
                disabled={isDisabled}
            />
        </>
    )
}