import React from 'react';
import { IIcon } from '../interfaces';
import icoHazardous from '../../../../_assets/images/ico_hazardous.svg';

function Hazardous({ onClick, type, color, className, styles }: IIcon.Props) {
    return (
        <img src={icoHazardous} alt='' />
    )
}

export default Hazardous;