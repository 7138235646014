import React from "react";
import { ListItem } from '../../atoms';
import { ListGroup } from '../../molecules';
import { useMainMenu } from '../../../hooks';
import styles from './mainMenu.module.scss';
import { IMainMenu } from "./interfaces";

interface Props {
  navItems?: IMainMenu.NavItem[],
  localNavItems?: IMainMenu.NavItem[]
}

export default function MainMenu({ navItems, localNavItems }: Props) {

  const menu = useMainMenu();

  return (
    <div className={`${styles.compContainer} ${menu.isOpen === false ? styles.closeMainMenu : styles.openMainMenu}`}>
      <div className={`pt-3 pb-3 px-4 d-flex justify-content-between align-items-center ${styles.menuHeader}`}>
        <i className="fas fa-bars"></i>
        <span className="text-uppercase">Main Menu</span>
        <i className={`fas fa-times cursor`} onClick={() => menu.close()}></i>
      </div>
      <div className={styles.scrollableContainer}>
        <ListItem path={'/'} icon={<i className="fas fa-home"></i>} text={'Home'} onClick={() => menu.close()} />
        <ListGroup items={navItems} onClickItems={() => menu.close()} />
        <ListGroup items={localNavItems} itemClassName={styles.localNavItems} onClickItems={() => menu.close()} />
      </div>
    </div >
  );
}


