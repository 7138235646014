/*
  Render Rows
  - handles the rendering for row data and applies filtering and sorting to rows
*/
import React from "react";
import { useDataGridStore, TStore } from "../../_Store";
import Row from "../../row";

function RenderRows() {
  //subscribe to store
  const [
    { CustomRow, renderedTableData, activeGridPage, customRowProps },
  ] = useDataGridStore() as TStore;

  const handleRenderedRows = (activeGridPage: number) => {
    let pageAmount = (renderedTableData.length !== 0) ? renderedTableData.length - 1 : 0;
    let previousPage = renderedTableData[activeGridPage - 1];
    let currentPage = renderedTableData[activeGridPage];
    let nextpage = renderedTableData[activeGridPage + 1];

    if (!(activeGridPage > pageAmount)) {
      if (activeGridPage > 0) {
        let combinedRenderedRows = [...previousPage, ...currentPage];
        return combinedRenderedRows;
      }
      if (activeGridPage === 0 && renderedTableData.length > 1) {
        let combinedRenderedRows = [...currentPage, ...nextpage];
        return combinedRenderedRows;
      }
    }
    return currentPage;
  }

  return (
    <>
      {handleRenderedRows(activeGridPage)?.map((row: { [key: string]: any }) => {
        return (
          <React.Fragment key={row.trebleKey}>
            {CustomRow !== null ? (
              <CustomRow rowData={row} {...customRowProps} />
            ) : (
              <Row rowData={row} />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}

export default React.memo(RenderRows);
