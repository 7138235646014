import React from 'react';
import { ColumnSelector as ColumnSelectorMol } from '../../../molecules';
import useDataGrid from '../use-data-grid';
import useColumnSelector from './use-column-selector';
import styles from './columnSelector.module.scss';

interface Props {
    iconOnly?: boolean;
    className?: string;
}

export default function ColumnSelector({ iconOnly, className }: Props) {

    const grid = useDataGrid();
    const colSel = useColumnSelector();

    return (
        <>
            <ColumnSelectorMol
                small
                fields={grid.columnFields}
                onChange={colSel.onChange}
                onSelectAllChange={colSel.onSelectAll}
                iconOnly={iconOnly}
                className={className}
                classNameDropdown={styles.dropdown}
            />
        </>
    )
}