import React from 'react';
import DataGridContainer from './data-grid-container';
import {DataGridProvider, DataGridState, DataGridReducer} from './data-grid-context';
import './_data-grid.scss';

interface Props{
    header?: string,
    actions?: {
        export?: 'excel' | 'pdf',
        icon?: JSX.Element,
        color?: 'red' | 'yellow' | 'blue' | 'green' | 'default',
        link?: string
    }[],
    filter?: 'RGY'
    children: JSX.Element | JSX.Element[]
}

function DataGrid(props: Props){

    //props
    const header = props.header;
    const actions = props.actions;
    const filter = props.filter;
   
    return(
        <DataGridProvider initialState={DataGridState} reducer={DataGridReducer}>
            <DataGridContainer 
                header={header as string} 
                actions={actions as [object]}
                filter={filter}
            >
                {props.children}
            </DataGridContainer>
        </DataGridProvider>
        
    )
}

export default DataGrid;