import { useDebounce, useInput } from '../../../../hooks';
import useDataGrid from '../use-data-grid';

const useColumnSearchFilter = (field?: string, onChange?: (value?: string) => void) => {

    const grid = useDataGrid();

    const { value: inputValue, bind: bindToInput, setValue: setInputValue } = useInput('');

    const updateQuery = useDebounce((value: string, searchQueries: any[]) => {
        const queries = searchQueries.map((item) => {
            if (item.target === field) {
                return {
                    target: field,
                    query: value.toLowerCase(),
                    exact: false,
                    type: "string"
                };
            }
            return item;
        });
        if (onChange) {
            onChange(value);
        }
        grid.updateSearchQueries(queries as any);
    }, grid.filterDebounceTime);

    const onReset = (value?: string) => {
        if (typeof value === 'string' && value.length === 0) {
            setInputValue('');
        }
    }

    return {
        updateQuery,
        onReset,
        setInputValue,
        bindToInput,
        inputValue
    }

}

export default useColumnSearchFilter;