import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

interface Props {
    children?: JSX.Element | JSX.Element[];
    className?: string;
}

export default function Masthead({ children, className }: Props) {

    return (
        <>
            <Container fluid className={`${className}`}>
                <Row className={`py-2`}>
                    <Col className="d-flex justify-content-between align-items-center">
                        {children}
                    </Col>
                </Row>
            </Container>
        </>
    )
}