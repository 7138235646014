interface IQueryState {
  state: any[];
  searchQueries: any[];
}

interface ICheckMatches {
  exact: boolean;
  query:
  | string
  | {
    from: string;
    to: string;
  };
  target: string;
  type: "string" | "dateRange";
}

const queryState = ({ state, searchQueries }: IQueryState) => {
  if (searchQueries.length > 0) {
    let queriedState = state.filter((item) => {
      if (item !== null) {
        let checkMatches = searchQueries.map((searchQuery: ICheckMatches) => {
          //does a query dateRange check
          if (searchQuery.type === "dateRange") {
            let searchQueryKeys = Object.keys(searchQuery.query);
            if (
              searchQueryKeys.includes("from") &&
              searchQueryKeys.includes("to")
            ) {
              //takes a string or Date type and converts to format MM/DD/YY
              let dateFormatter = (unformattedDate: Date | string) => {
                let newDate = new Date(unformattedDate);
                let month = newDate.getMonth() + 1;
                let day = newDate.getDate();
                let year = newDate.getUTCFullYear();
                let formattedMonth = month < 10 ? `0${month}` : month;
                let formattedDay = day < 10 ? `0${day}` : day;
                let date = `${formattedMonth}/${formattedDay}/${year}`;
                return date;
              };

              //sets date variables
              let dateRange = searchQuery.query as { from?: string; to?: string };
              let fromDate: string | undefined = dateRange?.from
                ? dateFormatter(dateRange?.from)
                : undefined;
              let toDate: string | undefined = dateRange?.to
                ? dateFormatter(dateRange?.to)
                : undefined;
              let checkDate: string = dateFormatter(item[searchQuery.target]);

              //checks to see if the checkDate falls between the date range
              if (fromDate !== undefined && toDate !== undefined) {
                let from = new Date(dateFormatter(fromDate));
                let to = new Date(dateFormatter(toDate));
                let check = new Date(dateFormatter(checkDate));
                if (check >= from && check <= to) {
                  return true;
                } else {
                  return false;
                }
              }
            }
          }

          //does a query string check
          if (searchQuery.type === "string") {
            //if exact prop is set to true only return on exact matches
            if (
              searchQuery.exact === true &&
              typeof searchQuery.query === "string"
            ) {
              if (
                item[searchQuery.target]?.toString().toLowerCase() === searchQuery.query ||
                searchQuery?.query?.length === 0
              ) {
                return true;
              }
              return false;
            }

            //if exact prop is set to false return partial matches
            if (
              searchQuery.exact === false &&
              typeof searchQuery.query === "string"
            ) {
              if (
                item[searchQuery.target]
                  ?.toString()
                  .toLowerCase()
                  .indexOf(searchQuery.query) !== -1 ||
                searchQuery.query.length === 0
              ) {
                return true;
              }
              return false;
            }
          }
        });

        //checks the checkMatches array to see if there a false
        if (!checkMatches.includes(false)) {
          return item;
        }
      }
    });
    return queriedState;
  }
  return state;
};

export default queryState;
