/*
    Protrans React Config Settings
    - Holds state for app settings
*/

import { createStore } from "treble-gsm";
import IConfig from '../../config/interfaces';

export interface IStoreConfig {

  ptrui_appAlerts: boolean;
  ptrui_appMainMenu?: boolean;
  ptrui_appSearch?: IConfig.Search | false;
  ptrui_appLogo: IConfig.AppLogo;
  ptrui_appVersion: string;
  ptrui_baseURLTypes: IConfig.ApiBaseURLTypes;
  ptrui_appSettings: IConfig.Settings[];
  ptrui_apiFetchCredentials: IConfig.ApiFetchCredentials;
  ptrui_languageSwitcher?: boolean;
  ptrui_baseHeader: IConfig.ApiBaseHeaders[];
  ptrui_isFrontEndLogin: boolean;
  ptrui_noAuthRedirect?: string;
  ptrui_checkAuthBeforeRender?: boolean;
}

const actionKeys = {
  ['ptrui_enableAppAlerts']: 'ptrui_enableAppAlerts',
  ['ptrui_enableAppMainMenu']: 'ptrui_enableAppMainMenu',
  ['ptrui_enableAppSearch']: 'ptrui_enableAppSearch',
  ['ptrui_setAppLogo']: 'ptrui_setAppLogo',
  ['ptrui_setAppVersion']: 'ptrui_setAppVersion',
  ['ptrui_enableAppSettings']: 'ptrui_enableAppSettings',
  ['ptrui_setLanguageSwitcher']: 'ptrui_setLanguageSwitcher',
  ['ptrui_setIsFrontEndLogin']: 'ptrui_setIsFrontEndLogin',
  ['ptrui_baseURLTypes']: 'ptrui_baseURLTypes',
  ['ptrui_updateApiFetchCredentials']: 'ptrui_updateApiFetchCredentials',
  ['ptrui_baseHeader']: 'ptrui_baseHeader',
  ['ptrui_noAuthRedirect']: 'ptrui_noAuthRedirect',
  ['ptrui_setCheckAuthBeforeRender']: 'ptrui_setCheckAuthBeforeRender'
}
export type TStoreConfigActions = typeof actionKeys;

const StoreConfig = createStore([

  //app alerts ui
  {
    action: actionKeys.ptrui_enableAppAlerts,
    state: {
      ptrui_appAlerts: true
    }
  },

  //app main menu ui
  {
    action: actionKeys.ptrui_enableAppMainMenu,
    state: {
      ptrui_appMainMenu: true
    }
  },

  //app search ui
  {
    action: actionKeys.ptrui_enableAppSearch,
    state: {
      ptrui_appSearch: {
        data: [],
        placeHolder: false,
        baseURL: '',
        url: '',
        params: {},
        responseProp: '',
        credentials: undefined
      },
    }
  },

  //app logo
  {
    action: actionKeys.ptrui_setAppLogo,
    state: {
      ptrui_appLogo: "protrans"
    }
  },

  //app settings
  {
    action: actionKeys.ptrui_enableAppSettings,
    state: {
      ptrui_appSettings: undefined
    }
  },

  //set app version in footer
  {
    action: actionKeys.ptrui_setAppVersion,
    state: {
      ptrui_appVersion: "2.0.0"
    }
  },

  //set api base headers (used by PTRUI.useFetch)
  {
    action: actionKeys.ptrui_baseHeader,
    state: {
      ptrui_baseHeader: { 'Content-Type': "application/json" }
    }
  },

  //set api base URL types (used by PTRUI.useFetch)
  {
    action: actionKeys.ptrui_baseURLTypes,
    state: {
      ptrui_baseURLTypes: {
        default: ''
      }
    }
  },

  //set global fetch credentials for PTRUI.useFetch
  {
    action: actionKeys.ptrui_updateApiFetchCredentials,
    state: {
      ptrui_apiFetchCredentials: {
        default: 'include'
      }
    }
  },

  //lets PTRUI know if front end login is being used
  {
    action: actionKeys.ptrui_setIsFrontEndLogin,
    state: {
      ptrui_isFrontEndLogin: false
    }
  },

  //sets a global Redirect for when fetch authentication fails
  {
    action: actionKeys.ptrui_noAuthRedirect,
    state: {
      ptrui_noAuthRedirect: undefined
    }
  },

  //sets checkAuthBeforeRender
  {
    action: actionKeys.ptrui_setCheckAuthBeforeRender,
    state: {
      ptrui_checkAuthBeforeRender: false
    }
  },

  //language switcher header icon
  {
    action: actionKeys.ptrui_setLanguageSwitcher,
    state: {
      ptrui_languageSwitcher: false
    }
  }

]);

export default StoreConfig;
