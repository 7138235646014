import React from 'react';
import { LoadingIndicator } from '../../atoms';
import { useIsInViewport } from '../../../hooks';

interface Props {
    hide?: boolean;
    count?: number;
    onView?: () => void;
    label?: string;
}
export default function TableRowPlaceholder({ hide, count, onView, label }: Props) {

    const ref = React.useRef(null);
    const isInViewport = useIsInViewport(ref);
    const rows: number[] = Array.from({ length: (count) ? count : 0 });

    React.useEffect(() => {
        if (isInViewport) {
            if (onView) {
                onView();
            }
        }
    }, [isInViewport]);

    return (
        <>
            {
                rows.map((item, index) => (
                    <tr ref={ref} className={(hide) ? 'd-none' : ''} key={index}>
                        <td>
                            <div
                                className={`d-flex align-items-center justify-content-start`}
                                style={{ height: "100%" }}
                            >
                                {/* <p className={'mb-0'}>Loading...</p> */}
                                <LoadingIndicator size={1} />
                                <p className={'mb-0 pl-2 text-muted'}>{(label) ? label : 'Loading...'}</p>
                            </div>
                        </td>
                    </tr>
                ))
            }
        </>
    )
}