/*
  DataGrid
*/
import React from "react";
import { Content } from '..';
import { GridContainer } from '../../molecules';

export interface GridProps {
  children: JSX.Element | JSX.Element[];
  height?: string;
  className?: string;
  loading?: boolean;
  data: any[];
  as?: JSX.Element | object;
  pageSize?: number;
  rowProps?: any;
  expandable?: JSX.Element | object | boolean;
  exportPDF?: boolean;
  exportExcel?: boolean;
  fetchStatus?: any;
  totalDataCount?: number;
  customExportOptions?: {
    type: 'excel' | 'pdf',
    label: string,
    onClick: () => void
  }[];
  columnTitles?: boolean;
  gridStyles?: React.CSSProperties;
  expandableCheck?: (item: any) => boolean;
  debounceFilters?: number;
  columnSelector?: boolean;
  stickyColumns?: boolean;
  onSelect?: (selectedRows: any[]) => void;
  onRefresh?: () => void;
  onFilterChange?: (filterQueries: {
    target: string;
    query: string | number | {
      from?: string | undefined;
      to?: string | undefined;
    } | null;
    exact: boolean;
    type: 'string' | 'number' | 'dateRange';
  }[]) => void;
  onSortChange?: (activeSort: {
    field: string,
    type: "asc" | "desc" | "none"
  }) => void;
  onBottomScroll?: (meta: { activePage: number, isPageEnd: boolean }) => void;
}

export default function DataGrid({
  children,
  height,
  className,
  loading,
  data,
  as,
  pageSize,
  rowProps,
  expandable,
  exportPDF,
  exportExcel,
  fetchStatus,
  totalDataCount,
  customExportOptions,
  columnTitles,
  gridStyles,
  expandableCheck,
  debounceFilters,
  columnSelector,
  stickyColumns,
  onFilterChange,
  onSortChange,
  onBottomScroll,
  onSelect,
  onRefresh
}: GridProps) {

  return (
    <>
      <Content.Panel bodyClassName={'p-0'}>
        <GridContainer
          height={height}
          className={className}
          loading={loading}
          data={data}
          as={as}
          pageSize={pageSize}
          rowProps={rowProps}
          expandable={expandable}
          exportPDF={exportPDF}
          exportExcel={exportExcel}
          fetchStatus={fetchStatus}
          totalDataCount={totalDataCount || 0}
          customExportOptions={customExportOptions}
          columnTitles={columnTitles}
          gridStyles={gridStyles}
          expandableCheck={expandableCheck}
          debounceFilters={debounceFilters}
          columnSelector={columnSelector}
          stickyColumns={stickyColumns}
          onSelect={onSelect}
          onRefresh={onRefresh}
          onFilterChange={onFilterChange}
          onSortChange={onSortChange}
          onBottomScroll={onBottomScroll}
          disableClientRendering
        >
          {children}
        </GridContainer>
      </Content.Panel>
    </>
  );
}

