import React from 'react';
import { ModalBasic } from '../_components/molecules';

const useModal = () => {

    const [{ activeSideBarNavItem, sideBarNavItems: navItems, isSideBar, sideBarCollapse: isSideBarCollapsed }, Store, Util] = ModalBasic.useState();

    const [activeNavKey, setActiveNavKey] = React.useState<number | string | undefined>();
    const [activeNavLabel, setActiveNavLabel] = React.useState<string>('');
    const [activeNavIndex, setActiveNavIndex] = React.useState<number>(0);
    const [navItemCount, setNavItemCount] = React.useState<number>(0);
    const activeIndex = navItems.findIndex((item) => item.key === activeSideBarNavItem.key);

    const updateNavItems = (navItems: { text: string; key: string | number; }[]) => {
        Store.update(Util.actions.ptrui_modalBasic_udpateSideBarNavItems, navItems);
    }

    const updateActiveNavIndex = (activeIndex: number) => {
        const itemCount = navItems.length - 1;
        if (!(activeIndex > itemCount || activeIndex < 0)) {
            Store.update(Util.actions.ptrui_modalBasic_setActiveSideBarNavItem, navItems[activeIndex]);
            Store.update(Util.actions.ptrui_modalBasic_setActiveSideBarNavItemEventKey, `link-${activeIndex}`);
        }
    }

    const collapseSideBar = () => {
        Store.update(Util.actions.ptrui_modalBasic_collapseModalSideBar, true);
    }

    const unCollapseSideBar = () => {
        Store.update(Util.actions.ptrui_modalBasic_collapseModalSideBar, false);
    }

    const toggleSideBarCollapse = () => {
        Store.toggle(Util.actions.ptrui_modalBasic_collapseModalSideBar, isSideBarCollapsed);
    }

    React.useEffect(() => {
        setActiveNavKey(activeSideBarNavItem.key);
        setActiveNavLabel(activeSideBarNavItem.text);
        setActiveNavIndex((activeIndex >= 0) ? activeIndex : 0);
        setNavItemCount((navItems) ? navItems.length : 0);
    }, [activeSideBarNavItem, activeNavLabel, navItems, activeSideBarNavItem, activeIndex, navItems]);

    return {
        activeNavKey,
        activeNavLabel,
        activeNavIndex,
        navItemCount,
        updateActiveNavIndex,
        navItems,
        isSideBar,
        isSideBarCollapsed,
        collapseSideBar,
        toggleSideBarCollapse,
        updateNavItems,
        unCollapseSideBar
    }

}

export default useModal;