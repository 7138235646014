import React from 'react';
import './_scene-container.scss';
import SceneContainerContext from './scene-container-context';

interface Props{
    children: JSX.Element[] | JSX.Element,
    backgroundImage?: string,
    collapsed?: boolean,
    sceneContext?: {
        collapsed: boolean
    }
}

function SceneContainer(props: Props){

    //prop variables
    const backgroundImage = props.backgroundImage;
    const collapsed = props.collapsed;
    const sceneContext = props.sceneContext;

    return(
        <div className='scene-container container-fluid px-lg-0'
        style={(backgroundImage) ? {backgroundImage: `url(${backgroundImage as string})`} : {}}
        >
            <div className={`row d-flex ${
                (collapsed !== undefined) ?
                (collapsed === false) ?  'justify-content-center' : 'justify-content-start' :
                'justify-content-center'
                } no-gutters`}>
                <SceneContainerContext.Provider value={sceneContext}>
                    {props.children}
                </SceneContainerContext.Provider>
            </div>
        </div>
    )
}

export default SceneContainer;