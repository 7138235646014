import { exportExcel, exportPDF } from '../exports';

const useDataExports = () => {

    const exportToExcel = exportExcel;
    const exportToPDF = exportPDF;
    return {
        exportToExcel,
        exportToPDF
    }
}


export default useDataExports;