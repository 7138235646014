import React from 'react';
import { useDataGridStore } from "../../_Store";
import styles from './thead.module.scss';

interface Props {
    stickyColumns: boolean;
    columnTitleState: boolean;
    children: JSX.Element | JSX.Element[];
}
export default function THead({ stickyColumns, columnTitleState, children }: Props) {

    const theadRef = React.useRef<any>(null);
    const [, Store, Util] = useDataGridStore();

    //updates theadRef in store so it can be shared to other components
    React.useLayoutEffect(() => {
        Store.update(Util.actions.updateTheadRef, theadRef);
    }, [theadRef]);

    return (
        <>
            <thead ref={theadRef} className={`${styles.thead} ${(!columnTitleState) ? 'd-none' : ''} ${(stickyColumns) ? styles.stickyTHead : ''}`}>
                {/**Generates column table headers */}
                <tr>
                    {React.Children.map(children, (child, index) => {
                        return <>{
                            (index !== 0) ? <>{child}</> : child
                        }</>;
                    })}
                </tr>
            </thead>
        </>
    )
}