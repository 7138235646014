import React from 'react';
import { Link } from 'react-router-treble';
import { ListItem, ListItemHeader, ListItemDivider, ListItemLabel } from '../../../atoms';
import { IconWithDropdown } from '../../../molecules';
import { usePageState } from '../../../../hooks';
import styles from './sitePath.module.scss';

interface Props {
    icon: JSX.Element;
    paths: {
        name?: string,
        url?: string,
        toolTip?: string
    }[]
}

export default function SitePath({ icon, paths }: Props) {


    const page = usePageState();
    const lastIndex = (paths.length > 1) ? paths.length - 1 : 0;

    const PathComp = ({ className }: { className: string }) => (
        <span className={`${className}`}>
            {
                paths.map(
                    (path: { name?: string; url?: string; toolTip?: string }, i, arr) => (
                        <React.Fragment key={i}>
                            <Link
                                to={path.url || ""}
                                title={path.toolTip}
                                className={`${styles.link}`}
                            >
                                {path.name}
                            </Link>
                            {arr.length - 1 === i ? "" : " / "}
                        </React.Fragment>
                    )
                )
            }
        </span>
    );

    return (
        <>
            <div className={`pl-4 justify-self-start`}>
                <div className="text-uppercase d-flex align-items-end text-white">
                    {
                        (!page.isMobile) ?
                            <span className={`${styles.sitePath} pr-3`}>
                                {icon.type === "img" ? (
                                    <img src={icon.props.src} width="35px" alt="icon" />
                                ) : (
                                    icon
                                )}
                            </span>
                            :
                            <IconWithDropdown
                                caret={(paths.length > 2) ? true : false}
                                icon={
                                    <span>
                                        {icon.type === "img" ? (
                                            <img src={icon.props.src} width="35px" alt="icon" />
                                        ) : (
                                            icon
                                        )}
                                    </span>
                                }
                                className={`text-white pr-2 mb-1`}
                                toolTip={false}
                            >
                                {
                                    (paths.length > 2) ?
                                        paths.map((item, index) => {
                                            return (
                                                <>
                                                    {
                                                        (index === 0) ?
                                                            <React.Fragment key={index}>
                                                                <ListItemHeader>
                                                                    <ListItemLabel
                                                                        icon={
                                                                            <span>
                                                                                {icon.type === "img" ? (
                                                                                    <img src={icon.props.src} width="35px" alt="icon" />
                                                                                ) : (
                                                                                    icon
                                                                                )}
                                                                            </span>
                                                                        }
                                                                        text={item.name}
                                                                    />
                                                                </ListItemHeader>
                                                                <ListItemDivider />
                                                            </React.Fragment> :
                                                            <React.Fragment key={index}>
                                                                <ListItem path={item.url} text={item.name} className={'pt-2'} />
                                                            </React.Fragment>
                                                    }
                                                </>
                                            )
                                        }) : <></>
                                }
                            </IconWithDropdown>
                    }


                    {
                        (!page.isMobile) ?
                            <PathComp className={`${styles.sitePath}`} /> :
                            (paths.length > 2) ? <>
                                <span className='mb-1'>
                                    <span className='pr-2'> /</span>
                                    <Link
                                        to={paths[lastIndex].url || ""}
                                        title={paths[lastIndex].toolTip}
                                        className={styles.link}
                                    >
                                        {paths[lastIndex].name}
                                    </Link>
                                </span>
                            </> : <PathComp className={`${styles.link} mb-1`} />
                    }

                </div>
            </div>
        </>
    )
}