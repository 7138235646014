import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { withTreble } from 'treble-gsm';
import { Store, useFilterListStore } from './Store';
import { InputOption } from '../../atoms';
import InputDropdown from '../input-dropdown';
import * as customToggle from './custom-toggle';
import uniqid from 'uniqid';
import { useNonInitialEffect } from '../../../hooks';
import useFilterList from './use-filter-list';
import { IAutoSuggest } from '../auto-suggest/interfaces';

interface Props {
    mapValueTo: string;
    mapTextTo: string;
    loading?: boolean;
    fetchStatus?: null | object;
    data: any;
    defaultValue?: IAutoSuggest.MenuItem;
    customData?: (props: { option: IAutoSuggest.MenuItem, [key: string]: any }) => JSX.Element;
    placeHolder?: string;
    className?: string;
    icon?: JSX.Element | boolean;
    onSelect?: (item?: IAutoSuggest.MenuItem) => void;
    width?: string;
    minWidth?: string;
    size?: 'sm' | 'lg';
    disabled?: boolean;
    onChange?: (query: { query: string }) => React.SetStateAction<{
        query: string;
    }>;
}

function FilterListComp({
    data,
    defaultValue,
    customData,
    placeHolder,
    className,
    icon,
    onSelect,
    width,
    minWidth,
    size,
    onChange,
    mapTextTo,
    mapValueTo,
    loading
}: Props) {


    const [{ searchQuery, fetchStatusCode, activeInputValue }] = useFilterListStore();
    const filterList = useFilterList();
    const [mappedData, setMappedData] = React.useState([]);

    // //add static props to store
    React.useEffect(() => {

        filterList.setPrependIcon((icon) ? icon : false);
        filterList.setInputSize(size);
        filterList.setActiveInputValue(defaultValue);
        filterList.setDefaultInputValue(defaultValue);
        filterList.setCustomDataComp(customData);
        filterList.setPlaceHolderText(placeHolder);
        filterList.setPrependIcon(icon);

        if (onSelect) {
            onSelect(defaultValue);
        }

    }, []);

    React.useEffect(() => {
        if(data)
            setMappedData(filterList.createData(data, mapTextTo, mapValueTo));
    }, [data]);

    // //update default value to store and sets active value to default value
    useNonInitialEffect(() => {

        filterList.setDefaultInputValue(defaultValue);
        if (onSelect) {
            onSelect(defaultValue);
        }

    }, [defaultValue]);

    // //update onChange handler
    useNonInitialEffect(() => {

        if (onChange) {
            let filteredData = (data as any)?.filter((x:any) => 
                x[mapValueTo].toString().toUpperCase().indexOf(searchQuery.query.toString().toUpperCase()) == 0
                || x[mapTextTo].toString().toUpperCase().indexOf(searchQuery.query.toString().toUpperCase()) == 0
            ); 
            setMappedData(filterList.createData(filteredData, mapTextTo, mapValueTo));
        }
        filterList.resetActiveInputValue(searchQuery);

    }, [searchQuery]);

    useNonInitialEffect(() => {
        if (onSelect) {
            onSelect(activeInputValue);
        }
    }, [activeInputValue]);

    return (
        <>
            <Dropdown
                className={className}
                style={{ width: (width) ? width : 'auto', minWidth: (minWidth) ? minWidth : 'none' }}
                onToggle={(isOpen, event, metadata) => {
                    filterList.setToggleMeta({ isOpen: isOpen, event: event, metadata: metadata });
                }}
            >
                <Dropdown.Toggle as={customToggle as any} id={`ptrui-auto-suggest-${uniqid()}`} />
                <InputDropdown
                    withInputIcon={false}
                    showChildren={(mappedData?.length > 0) ? true : false}
                    loading={loading}
                    error={fetchStatusCode}
                >
                    {
                        mappedData?.map((item : any, index : number) => (
                            <React.Fragment key={item.value}>
                                <InputOption
                                    option={item}
                                    customOption={customData}
                                    index={index}
                                    onSelect={(option) => {
                                        if (option) {
                                            filterList.setActiveInputValue(option);
                                            if (onSelect) {
                                                onSelect(option);
                                            }
                                        }
                                    }}
                                    onKeyDown={(option, e) => { (onSelect) ? onSelect(option) : null; filterList.setActiveInputValue(option); }}
                                />
                            </React.Fragment>
                        ))
                    }
                </InputDropdown>
            </Dropdown>
        </>
    )
}

const FilterList = withTreble(FilterListComp, { store: Store });
export default FilterList;