import React from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import styles from './inputLoading.module.scss';

export default function InputLoading() {

    return (
        <>
            <Dropdown.ItemText className='d-flex align-items-center'>
                <span className='text-info'><Spinner className={`${styles.spinnerIcon} mr-2`} animation={'border'} variant={'info'} />Loading...</span>
            </Dropdown.ItemText>
        </>
    )
}