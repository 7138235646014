import { ReactChildren } from 'react';
import { createStore, useScopedTreble, useTreble, TUseTreble } from 'treble-gsm';

interface IState {
    activeSubMenu: {
        icon: JSX.Element,
        title: string,
        ReactChildren: ReactChildren,
        children: JSX.Element | JSX.Element[] | undefined
    } | null;
    isSubMenuOpen: boolean;
    disableHoverMenu: boolean;
}

interface IAction {
    'setActiveSubMenu': string;
    'setDisableHoverMenu': string;
}

const Context = useScopedTreble();
export const usePageNavStore = () => useTreble(Context) as TUseTreble<IState, IAction>;

export const Store = createStore([
    {
        action: 'setActiveSubMenu',
        state: {
            activeSubMenu: null
        }
    }
], {
    context: Context
});