import React from 'react';
import PDFBtn from './pdf-btn';
import ExcelBtn from './excel-btn';

export default function ExportBtns() {

    return (
        <>
            <PDFBtn />
            <ExcelBtn />
        </>
    )
}