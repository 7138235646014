import React from 'react';
import { Panel as PanelAtom } from '../../../molecules';
interface Props {
    header?: string,
    style?: any,
    className?: string,
    bodyClassName?: string,
    half?: boolean,
    noPadding?: boolean,
    children: JSX.Element[] | JSX.Element
}

export default function Panel(props: Props) {


    return (
        <>
            <PanelAtom {...props}>
                {props.children}
            </PanelAtom>
        </>
    )
}