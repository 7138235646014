/*
Treble middleware that processes data state
*/
interface IProcessState {
  tableData: any[];
  page: boolean;
  size: number;
}
const processState = ({ tableData, page, size }: IProcessState) => {
  let chunk = (arr: any, size: number) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );
  if (page) {
    let pagefiedState = chunk(tableData, size);
    return pagefiedState;
  }
  return [tableData];
};

export default processState;
