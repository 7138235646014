import { createStore } from "treble-gsm";

export interface IFilterStore {
  columnGroupTarget: {
    field: string;
    title: string;
  };
  columnSortState: boolean | React.MutableRefObject<null>;
  defaultColumnSort: boolean;
  columnSearches: [];
  searchQueries: {
    target: string;
    query: string | { from?: string; to?: string } | null;
    exact: boolean;
    type: 'string' | 'dateRange' | 'dateTime';
  }[];
  liveRender: boolean;
  resetFilterInputs: boolean;
  activeColumnSort: {
    target: string,
    sortType: 'desc' | 'asc' | 'reset'
  }[],
  resetColumnFilters: any[]
}

const actionKeys = {
  ['changeColumnGroupTarget']: 'changeColumnGroupTarget',
  ['changeColumnSortState']: 'changeColumnSortState',
  ['setDefaultColumnSort']: 'setDefaultColumnSort',
  ['createColumnSearch']: 'createColumnSearch',
  ['updateSearchQueries']: 'updateSearchQueries',
  ['activateLiveRender']: 'activateLiveRender',
  ['toggleResetFilterInputs']: 'toggleResetFilterInputs',
  ['setActiveColumnSort']: 'setActiveColumnSort',
  ['resetFilterInputState']: 'resetFilterInputState'
}

export type TFilterActions = typeof actionKeys

const FilterStore = createStore([
  {
    action: actionKeys.changeColumnGroupTarget,
    state: {
      columnGroupTarget: {
        field: "",
        title: "",
      },
    },
  },
  {
    action: actionKeys.changeColumnSortState,
    state: {
      columnSortState: false,
    },
  },
  {
    action: actionKeys.setDefaultColumnSort,
    state: {
      defaultColumnSort: false,
    },
  },
  {
    action: actionKeys.createColumnSearch,
    state: {
      columnSearches: [],
    },
  },
  {
    action: actionKeys.updateSearchQueries,
    state: {
      searchQueries: [],
    },
    features: {
      keys: true
    }
  },
  {
    action: actionKeys.activateLiveRender,
    state: {
      liveRender: false,
    },
  },
  {
    action: actionKeys.toggleResetFilterInputs,
    state: {
      resetFilterInputs: false,
    },
  },
  {
    action: actionKeys.setActiveColumnSort,
    state: {
      activeColumnSort: [{
        target: '',
        sortType: 'reset'
      }]
    }
  },
  {
    action: actionKeys.resetFilterInputState,
    state: {
      resetFilterInputs: false
    }
  }
]);

export default FilterStore;
