import React from 'react';
import { useNonInitialLayoutEffect } from '../../../hooks';
import { Col, Form, Row } from 'react-bootstrap';
import { withTreble } from 'treble-gsm';
import { Store, useCardGridStore } from './_Store/Store';
import { ICardGridComp } from './interfaces';
import RenderData from './render-data';
import { useAppStore } from '../../../_Store';

function CardGridComp({ data, loading, customCard, checkType, children, searchTargets, alphaFilterTarget, searchDebounce, onCheck, id, fetchStatus, classNameCardContainer, persistCheckState }: ICardGridComp) {

    const [{ ptrui_CardGridCheckList }, AppStore, AppUtil] = useAppStore();
    const [{ searchQuery, cardGridData, alphaFilter, checkedList, queriedCardGridData }, Store, Util] = useCardGridStore();

    //set fetchStatus
    React.useEffect(() => {
        if (fetchStatus) {
            Store.update(Util.actions.setFetchStatusCode, fetchStatus);
        }
    }, [fetchStatus])

    //add data to store
    React.useEffect(() => {
        if (data) {
            Store.update(Util.actions.updateCardGridData, data);
        }
    }, [data]);

    //update queried data
    useNonInitialLayoutEffect(() => {
        Store.update(Util.actions.updateQueriedCardGridData, cardGridData)
    }, [cardGridData, searchQuery, alphaFilter]);

    //set loading state
    React.useLayoutEffect(() => {
        Store.update(Util.actions.setLoadingState, loading);
    }, [loading]);

    //set check type
    React.useLayoutEffect(() => {
        Store.update(Util.actions.setCardCheckType, checkType);
    }, [checkType]);

    //set Custom Card component
    React.useLayoutEffect(() => {
        Store.update(Util.actions.setCustomCard, customCard);
    }, [customCard]);

    //sets search targets
    React.useLayoutEffect(() => {
        if (searchTargets) {
            Store.update(Util.actions.updateSearchQuery, { ...searchQuery, targets: [...searchTargets], query: searchQuery.query });
        }
    }, [searchTargets]);

    //sets alphaFilter target
    React.useLayoutEffect(() => {
        if (alphaFilterTarget) {
            Store.update(Util.actions.updateAlphaFilter, { ...alphaFilter, target: alphaFilterTarget });
        }
    }, [alphaFilterTarget])

    //set search debounce
    React.useLayoutEffect(() => {
        if (searchDebounce) {
            Store.update(Util.actions.setSearchDebounce, searchDebounce);
        }
    }, [searchDebounce]);

    //return checked cards
    useNonInitialLayoutEffect(() => {

        if (onCheck) {
            setTimeout(() => {

                const checkedCardData = () => {
                    let checkedCards: { [key: string]: any }[] = [];
                    checkedList.map((checkID) => {
                        if (cardGridData[checkID]) {
                            checkedCards = [...checkedCards, cardGridData[checkID]]
                        }
                    })
                    return checkedCards
                }
                onCheck(checkedCardData());

            }, 0);
        }
    }, [checkedList]);

    //hydrate checkedList with persisted checks
    React.useLayoutEffect(() => {

        if (id) {
            if (ptrui_CardGridCheckList[id]?.length > 0) {
                Store.update(Util.actions.updateCheckedList, ptrui_CardGridCheckList[id])
            }
        }
    }, [])

    React.useLayoutEffect(() => {
        if (persistCheckState) {
            if (id) {
                setTimeout(() => {
                    AppStore.update(AppUtil.actions.ptrui_updateCardGridCheckList, {
                        ...ptrui_CardGridCheckList,
                        [id]: checkedList
                    });
                }, 0);
            }
        }
    }, [checkedList]);

    React.useLayoutEffect(() => {
        Store.update(Util.actions.updateRenderedCardData, {
            data: Object.values(queriedCardGridData),
            page: true,
            size: 20
        });
    }, [queriedCardGridData]);

    return (
        <>
            <Col>
                <Form>
                    <Form.Row>
                        {
                            React.Children?.map(children, (child) => {
                                if (child?.type?.displayName === 'CardGridHeader') {
                                    return child
                                }
                            })
                        }
                    </Form.Row>
                </Form>
                <RenderData className={classNameCardContainer} />
                {
                    React.Children?.map(children, (child) => {
                        if (child?.type?.displayName === 'CardGridFooter') {
                            return (
                                <Row className='pt-3'>
                                    <Col>
                                        {child}
                                    </Col>
                                </Row>
                            )
                        }
                    })
                }

            </Col>
        </>
    )
}

const CardGrid = withTreble(CardGridComp, { store: Store });
export default CardGrid;