import React from "react";
import { Spinner } from "react-bootstrap";
import { useDataGridStore, TStore } from "../../_Store";
import styles from './loadingData.module.scss';
interface Props {
  width: number
}

function LoadingData({ width }: Props) {
  const [{ fallBackGridHeight }] = useDataGridStore() as TStore;

  return (
    <>
      <tr>
        <td
          className={`${styles.loadingData} align-items-center`}
          style={{ height: fallBackGridHeight }}
        >
          <div
            className={`${(width > 0) ? 'd-flex' : 'd-none'} flex-column align-items-center justify-content-center`}
            style={{ height: "100%", width: `${width + 'px'}` }}
          >
            <Spinner animation="border" variant={"info"} />
          </div>
        </td>
      </tr>
    </>
  );
}

export default React.memo(LoadingData);
