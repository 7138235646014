import XLSX from "xlsx";
import { saveAs } from "file-saver";

const exportExcel = (data: any[], columnFields: { field: string, label?: string }[], callback?: () => void) => {
  //process data so it can be converted into an excel workbook
  let processedData = () => {
    let flattenedArrayData = data.map((item) => {
      let arrayItem = columnFields.map(({ field }) => {
        return item[field];
      });
      return arrayItem;
    });

    let flattendColumnFields = columnFields.map(({ field, label }) => {
      if (label) {
        return label
      }
      return field;
    });
    let excelReadyData = [flattendColumnFields, ...flattenedArrayData];
    return excelReadyData;
  };
  //convert data to excel workbook
  var worksheet = XLSX.utils.aoa_to_sheet(processedData());
  var workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "SheetJS");
  var output = XLSX.write(workbook, {
    bookType: "xlsx",
    bookSST: false,
    type: "binary",
  });
  let s2ab = (s: any) => {
    let buf = new ArrayBuffer(s.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
  //download workbook
  saveAs(
    new Blob([s2ab(output)], { type: "application/octet-stream" }),
    `export.xlsx`
  )
  if (typeof callback === 'function') {
    callback();
  }

};

export default exportExcel;
