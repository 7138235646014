import React from "react";
import { Col, Row as BSRow } from 'react-bootstrap';

interface Props {
  half?: boolean;
  left?: boolean;
  right?: boolean;
  full?: boolean;
  className?: string;
  noBottomPadding?: boolean;
  children: JSX.Element[] | JSX.Element;
}

function ContentRow({ className, children, noBottomPadding }: Props) {

  return (
    <Col xs={'12'} className={`${(noBottomPadding) ? '' : 'pb-4'} ${className}`}>
      <BSRow className='mx-lg-0 px-lg-3' style={{ height: 100 + "%" }}>
        {children}
      </BSRow>
    </Col>
  );
}

export default ContentRow;


